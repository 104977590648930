export default {
  header: {
    top: '0',
    zIndex: 10,
    position: 'sticky',
    paddingX: 0,
  },
  title: {
    fontSize: { base: 'md', md: 'lg' },
  },
  menu: {
    item: {
      as: 'a',
    },
  },
};
