import React from 'react';
import {
  Box,
  Card,
  Grid,
  Text,
  Icon,
  VStack,
  Heading,
  CardBody,
  Divider,
  Button,
} from 'ui';
import { GreenChecklistImage, BuroUnauthorizedImage } from '@assets/images';
import { jsx, tscope } from '@i18n/index';
import { ReferenceFields } from './components';
import { useController } from './hooks';
import { styles as s } from './References.style';

export const ts = tscope('Requisition.references');

export const References = () => {
  const {
    control,
    onSubmit,
    isLoading,
    username: name,
    personalReferences,
  } = useController({ t: ts });

  return (
    <>
      <Box {...s.imageBox}>
        <BuroUnauthorizedImage {...s.image} />
      </Box>
      <Grid {...s.container}>
        <Heading {...s.title}>
          {ts('title')}
        </Heading>
        <Card>
          <CardBody {...s.bannerCardBody}>
            <Icon as={GreenChecklistImage} {...s.icon} />
            <Text data-testid="References.bannerContent" {...s.text}>
              {jsx('information', {
                t: ts,
                name: (
                  <Text as="span" {...s.boldSpan}>
                    {name}
                  </Text>
                ),
                percentage: (
                  <Text as="span" {...s.boldSpan}>
                    {ts('percentage')}
                  </Text>
                ),
              })}
            </Text>
          </CardBody>
        </Card>
        <Box {...s.textContainer}>
          <Text {...s.boldInstructions}>
            {ts('instructions')}
          </Text>
          <Text {...s.text}>
            {ts('privacy')}
          </Text>
        </Box>
        <Grid as="form" onSubmit={onSubmit}>
          <Card>
            <CardBody {...s.cardBody}>
              <VStack {...s.form}>
                <ReferenceFields
                  personalReferences={personalReferences}
                  name="reference1"
                  control={control}
                  isFamilyMandatory
                  isLoading={isLoading}
                  data-testid="References.reference1"
                />
                <Divider />
                <ReferenceFields
                  personalReferences={personalReferences}
                  isLoading={isLoading}
                  name="reference2"
                  control={control}
                  isFamilyMandatory
                  data-testid="References.reference2"
                />
                <Divider />
                <ReferenceFields
                  personalReferences={personalReferences}
                  name="reference3"
                  control={control}
                  isLoading={isLoading}
                  data-testid="References.reference3"
                />
              </VStack>
            </CardBody>
          </Card>
          <Button
            {...s.submit}
            type="submit"
            isDisabled={isLoading}
          >
            {ts('actions.submit')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
