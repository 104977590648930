import React from 'react';
import PropTypes from 'prop-types';
import { VStack, LateralMenu } from 'ui';
import { StatusTag } from './components';
import { calculateBarStatus } from './utils';

const { tscope } = require('@i18n/index');

const ts = tscope('Requisition');

export const LateralBar = (props) => {
  const { currentStep, ...contentProps } = props;
  const steps = ts('steps');

  return (
    <LateralMenu {...contentProps}>
      <VStack spacing="2.5rem" pt="6rem">
        {
          steps.map((stepLabel, index) => (
            <StatusTag
              key={stepLabel}
              stepLabel={stepLabel}
              status={calculateBarStatus(index, currentStep)}
            />
          ))
        }
      </VStack>
    </LateralMenu>
  );
};

LateralBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
};
