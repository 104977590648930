import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePasswordValidation, useValidationWatcher } from '@hooks/form';
import { validatePasswordSync } from '@lib/validatePasswordSync';
import { resolver } from '../resolver';

const PASS_INPUT = 'password';

export const useController = ({ onSubmit, email }) => {
  const [password, setPassword] = useState(null);
  const syncErrors = useMemo(() => validatePasswordSync({ password, email }), [password, email]);

  const passwordValidation = usePasswordValidation({
    email,
    password,
    errors: syncErrors,
  });

  const {
    watch,
    trigger,
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
  } = useForm({
    resolver,
    defaultValues: { password: '', confirmPassword: '' },
    context: { passIsValid: passwordValidation.isValid },
  });

  useValidationWatcher({
    watch,
    trigger,
    isSubmitted,
    inputName: PASS_INPUT,
    setInputState: setPassword,
    isValid: passwordValidation.isValid,
  });

  const submitForm = handleSubmit(onSubmit);

  return {
    control,
    submitForm,
    isSubmitting,
    passwordValidation,
  };
};
