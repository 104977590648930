import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import { Grid } from 'ui';
import { TextField } from '@components/Form/Field';
import { PASSWORD } from '@config/constants';
import { PasswordValidations } from '../PasswordValidations';

export const ConfirmPassword = ({
  name,
  errors,
  control,
  isLoading,
  'data-testid': testId,
  ...contentProps
}) => (
  <Grid
    gridRowGap="1rem"
    data-testid={testId}
    {...contentProps}
  >
    <TextField
      id={name}
      name={name}
      data-testid={name}
      control={control}
      type="password"
      autoComplete="new-password"
      labelText={t('ConfirmPassword.passwordField.label')}
      placeholder={t('ConfirmPassword.passwordField.placeholder', { length: PASSWORD.MIN_LENGTH })}
    />
    <PasswordValidations
      errors={errors}
      isLoading={isLoading}
      data-testid="password-validations"
    />
    <TextField
      id={`${name}Confirm`}
      name={`${name}Confirm`}
      data-testid={`${name}-confirm`}
      control={control}
      type="password"
      autoComplete="new-password"
      labelText={t('ConfirmPassword.passwordConfirmField.label')}
      placeholder={t('ConfirmPassword.passwordConfirmField.placeholder', { length: PASSWORD.MIN_LENGTH })}
    />
  </Grid>
);

ConfirmPassword.defaultProps = {
  name: 'password',
  'data-testid': null,
};

ConfirmPassword.propTypes = {
  name: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  'data-testid': PropTypes.string,
};

export default ConfirmPassword;
