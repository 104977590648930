import { useEffect, useState } from 'react';
import { useUserQuery } from './useUserQuery';
import { useSocket } from './useSocket';

export const usePrivateChannel = () => {
  const [channel, setChannel] = useState(null);
  const { data } = useUserQuery();
  const client = useSocket();
  const userId = data?.user?.id;

  useEffect(() => {
    if (!userId || !client) return;
    setChannel(client.subscribe(`private-${userId}`));
  }, [client, userId]);

  return channel;
};
