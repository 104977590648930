import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListIcon,
} from 'ui';
import TYPES, * as typesOptions from './types';

function CheckItem(props) {
  const { children, type, ...contentProps } = props;
  const { icon, color } = TYPES[type] || TYPES.default;

  return (
    <ListItem
      color={color}
      fontSize="0.786rem"
      verticalAlign="center"
      data-type-prop={type}
      {...contentProps}
    >
      <ListIcon
        as={icon}
        color={color}
        w="0.8rem"
        h="0.8rem"
        mb="0.1rem"
      />
      {children}
    </ListItem>
  );
}

CheckItem.defaultProps = {
  children: null,
  type: typesOptions.keyList[0],
};

CheckItem.propTypes = {
  children: PropTypes.node,
  type: typesOptions.propTypes,
};

export default CheckItem;
