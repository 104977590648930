import React from 'react';
import { Outlet } from 'react-router-dom';
import { Flex, Grid } from 'ui';
import { Footer } from '@components/Footer';
import { CreditRequestHeader } from './components/Header';
import { LateralBar } from './components/LateralBar/LateralBar';
import { useController } from './hooks';
import { styles as s } from './CreditLayout.style';

export const CreditLayout = (props) => {
  const {
    username,
    contentRef,
    currentStep,
    displayLateralBar,
    displayEditableLoan,
  } = useController();

  const containerStyle = displayLateralBar ? s.container : s.containerWithoutLateralBar;
  const contentStyle = displayLateralBar ? s.content : s.contentWithoutLateralBar;

  return (
    <Grid {...containerStyle}>
      {displayLateralBar && (
        <LateralBar
          {...s.sideBar}
          currentStep={currentStep}
        />
      )}
      <CreditRequestHeader
        {...s.header}
        noOfLines={1}
        title={username}
        displayEditableLoan={displayEditableLoan}
      />
      <Flex {...contentStyle} {...props}>
        <Flex ref={contentRef} {...s.innerContent}>
          <Outlet />
        </Flex>
        <Footer {...s.footer} />
      </Flex>
    </Grid>
  );
};
