import React from 'react';
import { tscope } from '@i18n/index';
import { ReactComponent as Cellphone } from '@assets/components/Footer/cellphone.svg';
import { ReactComponent as Whatsapp } from '@assets/components/Footer/whatsapp.svg';
import { ReactComponent as Location } from '@assets/components/Footer/location.svg';
import { ReactComponent as Email } from '@assets/components/Footer/email.svg';
import {
  Box,
  Icon,
  Link,
  Text,
  Divider,
} from 'ui';
import { useController } from './hooks';
import styles from './Footer.styles';

const ts = tscope('Common.brand');

export const Footer = ({ role, ...props }) => {
  const {
    email,
    phone,
    whatsapp,
    copyright,
    whatsappNumber,
  } = useController({ role });

  return (
    <Box
      {...styles.container}
      {...props}
    >
      <Box {...styles.contactContainer}>
        <Box {...styles.row}>
          <Whatsapp height="1.5rem" data-testid="whatsapp" />
          <Link target="_blank" href={ts('whatsappLink', { number: whatsappNumber })} {...styles.contactText}>{whatsapp}</Link>
        </Box>
        <Box {...styles.row}>
          <Cellphone height="1.5rem" data-testid="cellphone" />
          <Link href={`tel:${phone}`} {...styles.contactText}>{phone}</Link>
        </Box>
        <Box {...styles.row}>
          <Email height="1.2rem" data-testid="email" />
          <Link {...styles.contactText} href={`mailto:${email}`}>{email}</Link>
        </Box>
      </Box>
      <Box {...styles.addressContainer}>
        <Icon {...styles.location}>
          <Location data-testid="location" />
        </Icon>
        <Text {...styles.text}>{ts('address')}</Text>
      </Box>
      <Box {...styles.linksContainer}>
        <Link {...styles.link} href={ts('terms.link')} target="_blank">
          {ts('terms.label')}
        </Link>
        <Divider {...styles.divider} />
        <Link {...styles.link} href={ts('privacy.link')} target="_blank">
          {ts('privacy.label')}
        </Link>
      </Box>
      <Box {...styles.legalContainer}>
        <Text {...styles.dimText}>{copyright}</Text>
      </Box>
    </Box>
  );
};
