import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from '@providers/socket';
import { readCredentials } from '@lib/storage/credentials';
import { setExpiredByAnotherDevice, selectExpiredByAnotherDevice } from '@reducks/session';

const isExpiredClient = (current, expired) => !!current && current === expired;

export const useEventListener = () => {
  const dispatch = useDispatch();
  const expiredByAnotherDevice = useSelector(selectExpiredByAnotherDevice);

  const onLogoutByAnotherSession = useCallback((data) => {
    const { client } = readCredentials() || {};
    const expiredClient = data?.client;
    if (!expiredByAnotherDevice && isExpiredClient(client, expiredClient)) {
      dispatch(setExpiredByAnotherDevice());
    }
  }, [
    dispatch,
    expiredByAnotherDevice,
  ]);

  useEvent('session_information', onLogoutByAnotherSession);
};
