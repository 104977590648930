export const styles = {
  container: {
    padding: '5',
    width: 'full',
    flexDirection: 'column',
  },
  card: {
    gap: '5',
    maxWidth: 'sm',
    boxShadow: 'md',
    bgColor: 'white',
    borderRadius: 'xl',
    overflow: 'hidden',
    placeSelf: 'center',
    padding: { base: '8', sm: '10' },
  },
  imageBox: {
    boxSize: '100px',
    placeSelf: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  heading: {
    as: 'h1',
    fontSize: '2xl',
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
  errorCode: {
    color: 'red.400',
  },
  contactUs: {
    maxWidth: 'sm',
    placeSelf: 'center',
  },
};
