import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { RENT_PROPERTY_ID } from './constants';

function resolver(values) {
  const {
    street,
    extNumber,
    intNumber,
    zip,
    neighborhood,
    stateName,
    municipality,
    city,
    addressYears,
    homeOwnershipId,
    rentAmount,
  } = values;
  const rentedProperty = Number(homeOwnershipId) === RENT_PROPERTY_ID;
  const rentAmountParsed = Number(rentAmount);

  const errors = {
    street: Validator(street).required().result(),
    extNumber: Validator(extNumber).required().result(),
    intNumber: Validator(intNumber).result(),
    zip: Validator(zip).required().zipCode().result(),
    neighborhood: Validator(neighborhood).required().result(),
    stateName: Validator(stateName).required().result(),
    municipality: Validator(municipality).required().result(),
    city: Validator(city).required().result(),
    addressYears: Validator(addressYears).required().result(),
    homeOwnershipId: Validator(homeOwnershipId).required().result(),
    rentAmount: rentedProperty
      ? Validator(rentAmountParsed)
        .required()
        .isZero()
        .result()
      : null,
  };
  return { values, errors: removeNilValues(errors) };
}

export default resolver;
