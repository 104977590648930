import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookie } from './useCookie';

// Reads a search parameter from the URL and stores it in a cookie.
// Returns value from either the URL or the cookie.
export const useStickyParam = ({
  searchName,
  cookieName,
  expirationDays = null,
}) => {
  const [searchParams] = useSearchParams();
  const urlValue = searchParams.get(searchName);

  const [value, setCookie] = useCookie({
    name: cookieName,
    days: expirationDays,
  });

  useEffect(
    () => { if (urlValue) setCookie(urlValue); },
    [urlValue, setCookie],
  );

  return value;
};
