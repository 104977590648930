import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useMemo, useState } from 'react';
import { CACHE_AND_NETWORK } from '@config/apollo/fetchPolicies';
import { useUpdateConfirmForm } from './useUpdateConfirmForm';
import { useUpsertRequisitionMutation, useLoanDataQuery } from './graphql';

export const useController = () => {
  const navigate = useNavigate();
  const [hasFocus, setHasFocus] = useState(false);
  const setFocusOn = () => setHasFocus(true);
  const setFocusOff = () => setHasFocus(false);

  const {
    data,
    loading: isLoadingCatalog,
  } = useLoanDataQuery({
    fetchPolicy: CACHE_AND_NETWORK,
  });

  const loanInformation = data?.activeRequisition?.loanInformation;

  const requisitionConfig = data?.user.requisitionConfig;

  const paymentTerms = requisitionConfig?.paymentTerms || [];

  const maximumAmount = requisitionConfig?.maximumAmount || 0;

  const minimumAmount = requisitionConfig?.minimumAmount || 0;

  const sortedPurposes = useMemo(
    () => [...(data?.user.requisitionConfig.purposes || [])]
      .sort((a, b) => a.id - b.id),
    [data],
  );

  const goToNextStep = () => {
    navigate(`../${PATHS.ADDRESS}`);
  };

  const [
    upsertRequisition,
    { loading: isSubmitting },
  ] = useUpsertRequisitionMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const { control, onSubmit, commentValue } = useUpdateConfirmForm({
    loanInformation,
    paymentTerms,
    maximumAmount,
    minimumAmount,
    sortedPurposes,
    upsertRequisition,
  });

  const previewPersonalInformationDisclaimer = !!hasFocus || !!commentValue;

  return {
    control,
    onSubmit,
    setFocusOn,
    setFocusOff,
    isSubmitting,
    paymentTerms,
    maximumAmount,
    minimumAmount,
    sortedPurposes,
    isLoadingCatalog,
    previewPersonalInformationDisclaimer,
  };
};
