import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';
import { selectIsAuthenticated } from '@reducks/session';
import { useSelector } from 'react-redux';

const DEBOUNCE_WAIT = 100;

const getMemoKey = ({ email, password }) => `email:${email || ''};password:${password || ''}`;

const matchErrorList = (errors1, errors2) => {
  if (!errors2) return errors1;
  const matchedErrors = [...errors1, ...errors2];
  // `Set Class` removes duplicated elements of a single Array;
  const errorsSet = new Set(matchedErrors);
  // casts and returns a single Array by using spread operator.
  return [...errorsSet];
};

export const VALIDATE_PASSWORD_QUERY = gql`
mutation ValidatePassword($input: ValidatePasswordMutationInput!) {
  validatePassword(input: $input) {
    errors
    isValid
  }
}
`;

const useValidatePassword = (options) => useMutation(VALIDATE_PASSWORD_QUERY, options);

export const usePasswordValidation = ({
  email,
  password,
  errors: otherErrors = null,
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [memo, setMemo] = useState({});
  const [errors, setErrors] = useState(otherErrors);
  const handleValidatePasswordCompleted = (data, options) => {
    const key = getMemoKey({ email, password: options.variables.input.password });
    const errorsResult = data?.validatePassword?.errors || [];
    const result = matchErrorList(errorsResult, otherErrors);
    setMemo((prev) => ({ ...prev, [key]: result }));
  };
  const [validatePassword, { loading }] = useValidatePassword({
    onCompleted: handleValidatePasswordCompleted,
    context: { clientName: isAuthenticated ? '' : 'public' },
  });

  const memoized = useMemo(
    () => memo[getMemoKey({ email, password })],
    [memo, email, password],
  );

  const validate = useMemo(
    () => debounce((args) => validatePassword({
      variables: { input: { password: args.password } },
    }), DEBOUNCE_WAIT),
    [validatePassword],
  );

  useEffect(() => {
    if (!password || memoized) return;
    validate({ email, password });
  }, [email, password, memoized, validate]);

  useEffect(() => {
    if (!password && !otherErrors) setErrors(null);
    if (memoized) setErrors(memoized);
  }, [memoized, password, otherErrors]);

  const isValid = errors || memoized ? !errors?.length : null;
  const isLoading = loading || (!!password && !(memoized || errors));

  return {
    errors,
    isValid,
    isLoading,
  };
};
