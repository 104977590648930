export default {
  title: {
    fontSize: '2xl',
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  footer: {
    padding: '2rem 3rem',
  },
  button: {
    width: '100%',
  },
  attempts: {
    color: 'red.500',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  alertContainer: {
    borderRadius: '12px',
  },
  warning: {
    marginRight: '3',
    boxSize: '5',
    color: 'red.500',
  },
  stack: {
    spacing: '7',
    mt: '6',
  },
};
