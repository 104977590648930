import React from 'react';
import { t } from '@i18n';
import { Heading, Image } from 'ui';
import PropTypes from 'prop-types';
import AsideImageSrc from '@assets/SignIn/aside.jpg';
import RightSideSrc from '@assets/SignIn/rightSide.svg';
import ytpBrand from '@assets/branding/ytp-default.png';
import TwoSidesLayout from '@components/layouts/TwoSides';

const LeftSideComponent = () => (
  <Heading as="h1" textAlign="center" size="lg" data-testid="title">
    {t('SignIn.welcome')}
  </Heading>
);

const RightSideComponent = ({ children }) => (
  <>
    <Heading>
      <Image src={ytpBrand} w="12rem" mb={{ base: '2rem', md: '4rem' }} />
    </Heading>
    <Heading
      as="h1"
      mb="4rem"
      size="lg"
      maxWidth="28rem"
      textAlign="center"
      display={{ base: 'block', md: 'none' }}
    >
      {t('SignIn.welcome')}
    </Heading>
    {children}
  </>
);

RightSideComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Layout = ({ children }) => (
  <TwoSidesLayout
    bgImageLeftSideSrc={AsideImageSrc}
    bgImageRightSideSrc={RightSideSrc}
    rightSideProps={{ overflowY: 'none' }}
    leftSide={<LeftSideComponent />}
    rightSide={(
      <RightSideComponent>
        {children}
      </RightSideComponent>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
