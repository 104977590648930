import { getBorrowersHomeUrl, getInvestorsHomeUrl } from '@config/index';
import { USER } from '@config/constants';
import { borrowerApi } from '@services/yoteprestoAPI';
import { useCallback } from 'react';

export const useGoToAppByRole = () => {
  const [signInBorrower] = borrowerApi.useSignInBorrowerMutation();

  const goToAppByRole = useCallback(async (roleName) => {
    if (roleName === USER.ROLES.BORROWER) {
      await signInBorrower().unwrap();
      window.location.assign(getBorrowersHomeUrl());
    } else if (roleName === USER.ROLES.INVESTOR) {
      window.location.assign(getInvestorsHomeUrl());
    }
  }, [signInBorrower]);

  return { goToAppByRole };
};
