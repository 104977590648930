import React from 'react';
import { tscope } from '@i18n';
import { Skeleton } from 'ui';
import { ReactComponent as ReferralIcon } from '@assets/components/referral.svg';
import { StateSwitcher } from '../StateSwitcher/StateSwitcher';
import { AddReferralCodeForm } from './components/AddReferralCodeForm/AddReferralCodeForm';
import { useController } from './hooks/useController';

const ts = tscope('Register.promos');

export const ReferralPromo = () => {
  const {
    open,
    step,
    cancel,
    referralCode,
    removeReferral,
    isLoadingReferral,
    isRemovingReferral,
  } = useController();

  return (
    <Skeleton
      isLoaded={!isLoadingReferral}
      data-loading={isLoadingReferral}
      borderRadius="0"
      endColor="gray.200"
      data-testid="referralPromo-loader"
    >
      <StateSwitcher
        initialText={ts('referral.gotReferral')}
        textCode={ts('referral.yourReferral')}
        data-testid="referralPromo"
        open={open}
        step={step}
        code={referralCode}
        leftIcon={ReferralIcon}
        onRemoveCode={removeReferral}
        isRemoving={isRemovingReferral}
        openFormComponent={(
          <AddReferralCodeForm
            key="referral"
            data-testid="addReferralForm"
            onCancel={cancel}
          />
      )}
      />
    </Skeleton>
  );
};
