import React from 'react';
import {
  Alert, AlertDescription, Button,
  Card, CardBody, CardFooter, Divider, Heading,
  Link, OrderedList, ListItem, Stack, Text,
} from 'ui';
import { BuroLockedImage } from '@assets/images';
import { tscope } from '@i18n/index';
import { PreApprovalContainer } from '../components';
import style from './BuroBlocked.style';
import { useController } from './hooks';

const ts = tscope('Requisition.buroBlocked');

export const BuroBlocked = () => {
  const {
    loading,
    showWarning,
    attemptsRemaining,
    showOneAttemptAlert,
    requestBuroReport,
  } = useController();

  return (
    <PreApprovalContainer>
      <Card>
        <CardBody>
          <BuroLockedImage
            data-testid="buroIcon"
            style={{ margin: 'auto' }}
          />
          <Stack {...style.stack}>
            <Heading {...style.title}>
              {ts('title')}
            </Heading>
            {showOneAttemptAlert && (
              <Alert status="error" {...style.alertContainer}>
                <AlertDescription>
                  {ts('alert')}
                </AlertDescription>
              </Alert>
            )}
            <Text {...style.text} data-testid="body">
              {ts('body')}
            </Text>
            <OrderedList spacing="3">
              <ListItem>
                <Text data-testid="instructionsStep0">
                  {ts('steps.0.0')}
                  <Link
                    href={ts('steps.0.1.href')}
                    data-testid="buro-link"
                  >
                    {ts('steps.0.1.anchorText')}
                  </Link>
                  {ts('steps.0.2')}
                  <Text as="b">{ts('steps.0.3')}</Text>
                </Text>
              </ListItem>
              <ListItem>
                <Text data-testid="instructionsStep1">
                  {ts('steps.1.0')}
                  <Link
                    href={ts('steps.1.1.href')}
                    data-testid="ytp-link"
                  >
                    {ts('steps.1.1.anchorText')}
                  </Link>
                  {ts('steps.1.2')}
                </Text>
              </ListItem>
            </OrderedList>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter {...style.footer}>
          <Button
            {...style.button}
            onClick={requestBuroReport}
            disabled={loading}
          >
            {ts('button')}
          </Button>
          {showWarning && (
            <Text fontSize="sm" mt="3" {...style.attempts}>
              {ts('remainingBuroRequests', { attemptsRemaining })}
            </Text>
          )}
        </CardFooter>
      </Card>
    </PreApprovalContainer>
  );
};
