import React from 'react';
import { tscope, toCurrency } from '@i18n';
import { TextField } from '@components/Form/Field';
import {
  UnorderedList, ListItem, Text,
} from 'ui';
import { CURRENCY } from '@config/constants';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';

const ts = tscope('Requisition.income');

export const Income = () => {
  const {
    onBack,
    control,
    onSubmit,
    isSubmitting,
  } = useController();

  return (
    <CreditForm
      onBack={onBack}
      title={ts('title')}
      onSubmit={onSubmit}
      loading={isSubmitting}
    >
      <TextField
        control={control}
        name="income"
        type="currency"
        maxLength={CURRENCY.LENGTH}
        data-testid="incomeField"
        labelText={ts('incomeLabel')}
        placeholder={toCurrency(0, { precision: 0 })}
      />
      <Text>
        {ts('instructions.main')}
      </Text>
      <UnorderedList>
        <ListItem>
          {ts('instructions.list.0')}
        </ListItem>
        <ListItem>
          {ts('instructions.list.1')}
        </ListItem>
      </UnorderedList>
      <Text as="i" fontWeight="500">{ts('notice')}</Text>
      <TextField
        control={control}
        name="otherIncome"
        type="currency"
        data-testid="otherIncomeField"
        labelText={ts('otherIncomeLabel')}
        maxLength={CURRENCY.LENGTH}
        placeholder={toCurrency(0, { precision: 0 })}
      />
    </CreditForm>
  );
};
