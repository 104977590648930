import React from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel,
  FormControl,
  FormHelperText,
  Box,
} from 'ui';
import { useController } from 'react-hook-form';

function Base(props) {
  const {
    id,
    name,
    control,
    children,
    labelText,
    noOfLines = 1,
    labelIcon = null,
    'data-testid': dataTestId,
    ...contentProps
  } = props;
  const { fieldState: { error } } = useController({ name, control });
  const testid = dataTestId ? `${dataTestId}-helper-text` : null;

  return (
    <FormControl id={id} {...contentProps}>
      {labelText
        && (
          <FormLabel noOfLines={noOfLines} mr="0" color="gray.700">
            {labelIcon && <Box as="span" mr="0.5rem">{labelIcon}</Box>}
            {labelText}
          </FormLabel>
        )}
      {children}
      {error && (
        <FormHelperText
          color="red.500"
          fontSize="sm"
          data-testid={testid}
        >
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Base.propTypes = {
  id: PropTypes.string,
  noOfLines: PropTypes.number,
  labelText: PropTypes.string,
  labelIcon: PropTypes.element,
  'data-testid': PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default Base;
