import React from 'react';
import PropTypes from 'prop-types';
import { tscope } from '@i18n';
import {
  Card,
  CardHeader,
  CardFooter,
  Grid,
  Flex,
  Text,
  Button,
} from 'ui';
import { OTPField } from '@components/Form/Field';
import { useController } from './hooks/useController';

const ts = tscope('Register.emailConfirm');

export const ConfirmEmailForm = ({ email, onChangeEmail }) => {
  const {
    control,
    maskedEmail,
    submitHandler,
    resendOTPHandler,
    resendButtonText,
    submitProps,
    resendOTPButtonProps,
  } = useController({ email });

  return (
    <Card w={{ base: '100%', sm: '28rem' }}>
      <CardHeader
        p={{ base: '2rem' }}
        padding={{ base: '1.5rem', md: '2rem' }}
      >
        <Grid rowGap="1rem">
          <Flex
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
            rowGap="0.5rem"
            marginBottom="0.5rem"
          >
            <Text>
              {ts('description')}
            </Text>
            <Text as="strong">{maskedEmail}</Text>
            <Button
              type="button"
              variant="link"
              color="blue.500"
              onClick={onChangeEmail}
              data-testid="back-button"
            >
              {ts('changeEmailButton')}
            </Button>
          </Flex>
          <form onSubmit={submitHandler}>
            <OTPField
              name="otp"
              data-testid="otp"
              control={control}
              labelText={ts('OTPField.labelText')}
            />
            <Button
              type="submit"
              colorScheme="blue"
              marginTop="1rem"
              float="right"
              width={{ base: '100%', md: '12rem' }}
              data-testid="submit"
              {...submitProps}
            >
              {ts('submit')}
            </Button>
          </form>
        </Grid>
      </CardHeader>
      <CardFooter
        padding={{ base: '1.5rem', md: '2rem' }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gridColumnGap={{ base: '0.5rem', sm: '1rem' }}
        >
          <Text
            color="gray.500"
          >
            {ts('didntReceive')}
          </Text>
          <Button
            type="button"
            variant="secondary-outline"
            alignSelf="end"
            minWidth={{ base: 'unset', md: '12rem' }}
            onClick={resendOTPHandler}
            data-testid="resend-button"
            {...resendOTPButtonProps}
          >
            {resendButtonText}
          </Button>
        </Flex>
      </CardFooter>
    </Card>
  );
};

ConfirmEmailForm.propTypes = {
  onChangeEmail: PropTypes.func.isRequired,
};
