import { t } from '@i18n';
import validateRfc from 'validate-rfc';

const INVALID_FORMAT_ERROR = 'INVALID_FORMAT';
const OPTIONS = { omitVerificationDigit: true };

function validate(value, message = null) {
  const { isValid, errors } = validateRfc(value, OPTIONS);
  if (!value || isValid) return null;

  const defaultMessage = (errors || []).includes(INVALID_FORMAT_ERROR)
    ? t('validation.rfc.invalidFormat')
    : t('validation.rfc.invalidData');

  return {
    type: 'danger',
    value: value.toUpperCase(),
    message: message || defaultMessage,
  };
}

export default validate;
