import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const REFERRAL_QUERY = gql`
  query GET_REFERRAL($referralToken: String!) {
    referral(token: $referralToken) {
      id
      user {
        id
        email
        cellPhoneNumber
      }
    }
  }
`;

export const useReferral = ({
  referralToken,
}) => {
  const { data } = useQuery(REFERRAL_QUERY, {
    skip: !referralToken,
    skipErrorHandler: true,
    variables: {
      referralToken,
    },
    context: {
      clientName: 'public',
    },
  });
  const { email, cellPhoneNumber: phoneNumber } = data?.referral?.user || {};

  return {
    email,
    phoneNumber,
  };
};
