import React from 'react';
import { Flex, Text } from 'ui';
import { tscope } from '@i18n/index';
import { useController } from './hooks';
import { styles } from './LoanInformation.style';

const ts = tscope('Requisition.loanEditor');

export const LoanInformation = () => {
  const { title } = useController();

  const titleDetails = ts('yourLoan');

  return (
    <Flex
      {...styles.titleContainer}
    >
      <Text>{titleDetails}</Text>
      <Text {...styles.title}>
        {title}
      </Text>
    </Flex>
  );
};
