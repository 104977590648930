import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const POSITION = {
  x: 0,
  y: 0,
};

export const useRestoreScroll = (elementRef) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname) return;
    if (elementRef) {
      elementRef.scrollTo(POSITION.x, POSITION.y);
      return;
    }
    window.scrollTo(POSITION.x, POSITION.y);
  }, [elementRef, pathname]);
};
