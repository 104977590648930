const responseHandler = async (response) => {
  const { errors, data } = await response.json();
  if (response.status === 401) {
    return { message: errors[0] };
  }

  const { settings, limits, ...user } = data;
  const result = {
    user,
    errors,
    settings,
    credentials: {
      accessToken: response.headers.get('access-token'),
      uid: response.headers.get('uid'),
      client: response.headers.get('client'),
      expiry: response.headers.get('expiry'),
      tokenType: response.headers.get('token-type'),
    },
  };

  return result;
};

export default responseHandler;
