import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_EMPLOYMENT = gql`
  mutation ($input: UpsertRequisitionMutationInput!) {
    upsertRequisition(input: $input) {
      activeRequisition {
        id
        income {
          id
          hasImss
          hasSgmm
          companyName
          companyYears
          companyPosition
          hasTaxSituation
          paymentFrequency
          companyPhoneNumber
          companyExtensionNumber
          employment {
            id
            name
          }
        }
      }
    }
  }
`;

export const useUpdateEmploymentMutation = (
  options,
) => useMutation(UPDATE_EMPLOYMENT, options);
