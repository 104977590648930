import React from 'react';
import PropTypes from 'prop-types';
import { t, jsx } from '@i18n';
import { TextField } from '@components/Form/Field';
import {
  Box,
  Card,
  Text,
  Button,
  Heading,
  CardHeader,
  CardFooter,
} from 'ui';
import { useController } from './hooks';
import styles from './SendEmailForm.styles';

export const SendEmailForm = ({ onSubmit, isLoading }) => {
  const {
    control,
    submitForm,
    isSubmitting,
  } = useController({ onSubmit });

  return (
    <Card
      size="sm"
      minWidth={{ base: '100%', sm: '28rem' }}
      maxWidth={{ base: '100%', sm: '28rem' }}
    >
      <CardHeader>
        <Heading
          as="h2"
          size="md"
          mb="1rem"
          textAlign="center"
        >
          {t('passwordRecovery.sendEmailForm.title')}
        </Heading>
        <Text>{t('passwordRecovery.sendEmailForm.description1')}</Text>
        <Text {...styles.instructions}>
          {jsx('passwordRecovery.sendEmailForm.description2', {
            email: <b>{t('Common.emails.notifications')}</b>,
          })}
        </Text>
        <Box>
          <form onSubmit={submitForm} noValidate>
            <TextField
              autoFocus
              id="email"
              labelText={t('passwordRecovery.sendEmailForm.emailInput.label')}
              placeholder={t('passwordRecovery.sendEmailForm.emailInput.placeholder')}
              control={control}
              name="email"
              data-testid="email"
              {...styles.form}
            />
            <Button
              size="lg"
              type="submit"
              colorScheme="green"
              isDisabled={isLoading || isSubmitting}
              isLoading={isLoading || isSubmitting}
              data-testid="email-submit"
              {...styles.button}
            >
              {t('passwordRecovery.sendEmailForm.submit')}
            </Button>
          </form>
        </Box>
      </CardHeader>
      <CardFooter {...styles.footer}>
        <Text color="gray.500">{t('passwordRecovery.footer')}</Text>
      </CardFooter>
    </Card>
  );
};

SendEmailForm.defaultProps = {
  isLoading: false,
};

SendEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
