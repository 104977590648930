import styled, { keyframes } from 'styled-components';
import { Box } from 'ui';

// Keyframes definitions
const firstDot = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const secondDot = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const lastDot = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

export const EllipsisContent = styled(Box)`
  display: inline-block;
  position: relative;
  height: 20px;
  width: 64px;
`;

export const Dot = styled(Box)`
  position: absolute;
  top: 5px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 6px;
    animation: ${firstDot} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 6px;
    animation: ${secondDot} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 26px;
    animation: ${secondDot} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 45px;
    animation: ${lastDot} 0.6s infinite;
  }
`;
