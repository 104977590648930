import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPSERT_REQUISITION = gql`
mutation ($input: UpsertRequisitionMutationInput!) {
  upsertRequisition(input: $input) {
    activeRequisition {
      id
      loanInformation {
        id
        paymentTerms
        requestedAmount
        loanDestination {
          id
        }
      }
    }
  }
}
`;

export const useUpsertRequisitionMutation = (options) => useMutation(UPSERT_REQUISITION, options);
