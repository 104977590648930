import { readString as defaultReader, writeString as defaultWriter } from '@lib/serializers';
import { useEffect, useCallback, useState } from 'react';
import { getCookiesDomain } from '@config';
import { isNil } from '@lib';
import Cookies from 'js-cookie';

const DEFAULT_EXPIRY = 365;

export const useCookie = ({
  name,
  reader = null,
  writer = null,
  days = DEFAULT_EXPIRY,
}) => {
  const [value, setValue] = useState(undefined);

  const readValue = useCallback(() => {
    const raw = Cookies.get(name);
    const read = reader || defaultReader;
    return read(raw) || null;
  }, [name, reader]);

  const refresh = useCallback(() => setValue(readValue()), [setValue, readValue]);

  const remove = useCallback(() => {
    Cookies.remove(name);
    refresh();
  }, [name, refresh]);

  const setter = useCallback((input) => {
    const raw = typeof input === 'function' ? input(readValue()) : input;
    const write = writer || defaultWriter;
    const parsed = write(raw);
    if (isNil(parsed)) return remove();
    const options = {
      expires: days || DEFAULT_EXPIRY,
      secure: true,
      domain: getCookiesDomain(),
    };
    Cookies.set(name, parsed, options);
    return refresh();
  }, [
    name,
    days,
    writer,
    remove,
    refresh,
    readValue,
  ]);

  useEffect(() => refresh(), [refresh]);

  return [value, setter];
};
