// Reference: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

/**
 * Name:    cache-first
 * Cache:   Yes
 * Network: Yes
 *
 * This is the default value. Always try reading data from your cache first.
 * Only fetch from the network if a cached result is not available.
 */
export const CACHE_FIRST = 'cache-first';

/**
 * Name:    cache-only
 * Cache:   Yes
 * Network: No
 *
 * It will never execute a query using your network interface.
 * Instead it will always try reading from the cache.
 * If the data for your query does not exist in the cache then an error will be thrown.
 */
export const CACHE_ONLY = 'cache-only';

/**
 * Name:    cache-and-network
 * Cache:   Yes
 * Network: Yes
 *
 * First, reads data from the cache.
 * If all the data is in the cache then that data will be returned.
 * However, regardless of whether or not the full data is in the cache,
 * it will always execute query with the network interface.
 */
export const CACHE_AND_NETWORK = 'cache-and-network';

/**
 * Name:    network-only
 * Cache:   Yes
 * Network: Yes
 *
 * It will never return you initial data from the cache.
 * It will always make a request using your network interface to the server.
 */
export const NETWORK_ONLY = 'network-only';

/**
 * Name:    no-cache
 * Cache:   No
 * Network: Yes
 *
 * It will never return your initial data from the cache.
 * Instead it will always make a request using your network interface to the server.
 * It also will not write any data to the cache after the query completes.
 */
export const NO_CACHE = 'no-cache';

/**
 * Name:    standby
 * Cache:   Yes
 * Network: Yes
 *
 * Uses the same logic as "cache-first", except it does not automatically update
 * when field values change.
 * You can still manually update data with refetch and updateQueries.
 */
export const STANDBY = 'standby';
