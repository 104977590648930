import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUserQuery } from '@navigators/Root/App/hooks';
import { selectExpiredByInactivity, selectExpiredByAnotherDevice } from '@reducks/session';
import { useEventListener } from './useEventListener';

export const MODALS = {
  INACTIVITY: 'INACTIVITY',
  ANOTHER_DEVICE: 'ANOTHER_DEVICE',
};

export const useController = () => {
  const [showModal, setShowModal] = useState(null);
  const { data: useData } = useUserQuery();
  const email = useData?.user?.email;
  const expiredByInactivity = useSelector(selectExpiredByInactivity);
  const expiredByAnotherDevice = useSelector(selectExpiredByAnotherDevice);

  useEffect(() => {
    if (!expiredByInactivity && !expiredByAnotherDevice) {
      setShowModal(null);
    } else if (expiredByInactivity && !showModal) {
      setShowModal(MODALS.INACTIVITY);
    } else if (expiredByAnotherDevice && !showModal) {
      setShowModal(MODALS.ANOTHER_DEVICE);
    }
  }, [
    showModal,
    expiredByInactivity,
    expiredByAnotherDevice,
  ]);

  useEventListener();

  return {
    email,
    showModal,
  };
};
