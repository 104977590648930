import endpoints from '@config/endpoints';
import transformResponse from '@config/services/transformResponse';

const reactivateAccount = (builder) => ({
  reactivateAccount: builder.mutation({
    query: (token) => ({
      url: endpoints.auth.reactivateAccount.url,
      method: endpoints.auth.reactivateAccount.method,
      body: {
        unlock_token: token,
      },
    }),
    transformResponse,
  }),
});

export default reactivateAccount;
