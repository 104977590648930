import { getApiUrl } from '@config';
import endpoints from '@config/endpoints';

const asyncIsEmailAvailable = async (email) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  };

  const url = new URL(`${getApiUrl()}${endpoints.public.emailAvailable.url}`);
  url.searchParams.append('email', email);

  const response = await fetch(url, options);

  return (response.status === 200);
};

export default asyncIsEmailAvailable;
