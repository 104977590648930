export default {
  note: {
    backgroundColor: 'yellow.50',
    padding: '1rem',
    borderRadius: '0.5rem',
  },
  footer: {
    backgroundColor: 'gray.50',
    paddingY: '1.5rem',
    fontSize: '13px',
  },
};
