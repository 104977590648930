import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import {
  Flex, PinInput, PinInputField, useBreakpointValue,
} from 'ui';
import Base from '../Base';
import styles from './OTP.styles';

function OTPField(props) {
  const {
    name, control, 'data-testid': dataTestId, ...contentProps
  } = props;
  const {
    field: {
      onBlur, onChange, value, ref,
    },
    fieldState: { error },
  } = useController({ name, control });

  const testid = dataTestId ? `${dataTestId}-input` : null;

  const size = useBreakpointValue({ base: 'sm', sm: 'md' });

  return (
    <Base
      name={name}
      control={control}
      data-testid={dataTestId}
      {...contentProps}
    >
      <Flex gridColumnGap={{ base: '0.2rem', sm: '0.5rem' }}>
        <PinInput
          otp
          type="number"
          onChange={onChange}
          value={value}
          placeholder=""
          isInvalid={!!error}
          size={size}
        >
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
          <PinInputField
            ref={ref}
            onBlur={onBlur}
            data-testid={testid}
            {...styles.pinInputField}
          />
        </PinInput>
      </Flex>
    </Base>
  );
}

OTPField.defaultProps = {
  'data-testid': '',
};

OTPField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  'data-testid': PropTypes.string,
};

export default OTPField;
