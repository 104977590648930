import { useEffect } from 'react';
import { usePrivateChannel } from './usePrivateChannel';

export const useEvent = (event, callback) => {
  const channel = usePrivateChannel();

  useEffect(() => {
    if (!channel) return;
    channel.bind(event, callback);
  }, [channel, event, callback]);
};
