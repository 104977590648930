import React from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { EmailStep, PasswordStep } from '@views/SignIn';
import useController from './hooks/useController';

export const PATHS = {
  PASSWORD: 'password',
};

export const SignIn = () => {
  useController({ PATHS });

  return (
    <Routes>
      <Route
        index
        element={<EmailStep />}
      />
      <Route
        path={PATHS.PASSWORD}
        element={<PasswordStep />}
      />
      <Route
        path="*"
        element={<Navigate to="./" replace />}
      />
    </Routes>
  );
};
