import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useMemo } from 'react';
import { useNavigate } from '@hooks/navigation';
import { useUpdateAddressForm } from './useUpdateAddressForm';
import { useGetCatalogQuery, useUpdateAddressMutation, useGetAddressQuery } from './graphql';
import { LENGTH_OF_RESIDENCE, RENT_PROPERTY_ID } from '../constants';

export const useController = () => {
  const navigate = useNavigate();

  const { data: catalogData } = useGetCatalogQuery();
  const { data: addressData } = useGetAddressQuery();

  const user = addressData?.user;
  const states = catalogData?.catalogs.states;
  const homeOwnerships = catalogData?.catalogs.homeOwnerships;
  const expenses = addressData?.activeRequisition?.spending?.expenses;
  const rentExpenseId = user?.homeOwnership?.expense?.id;

  const statesMap = useMemo(() => states?.reduce(
    (acc, state) => (
      { ...acc, [state.constId]: { name: state.name, id: state.id } }
    ),
    {},
  ), [states]) || {};

  const goToNextStep = () => {
    navigate(`../${PATHS.HOUSEHOLD}`);
  };

  const [updateAddressMutation, { loading: isSubmitting }] = useUpdateAddressMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const onBack = () => navigate(`../${PATHS.CONFIRM}`);

  const {
    zip,
    control,
    zipError,
    onSubmit,
    zipLoading,
    neighborhoods,
    selectedPropertyType,
  } = useUpdateAddressForm({
    user,
    expenses,
    statesMap,
    rentExpenseId,
    updateAddressMutation,
  });

  const showRent = selectedPropertyType === RENT_PROPERTY_ID;

  return {
    zip,
    onBack,
    control,
    zipError,
    onSubmit,
    showRent,
    zipLoading,
    isSubmitting,
    homeOwnerships,
    neighborhoods,
    lengthOfResidence: LENGTH_OF_RESIDENCE,
    selectedPropertyType,
  };
};
