import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { RadioButton as RadioButtonUI } from 'ui';
import { radioButtonsFieldContext } from './RadioButtonField';

function RadioButton(props) {
  const { value, children, ...contentProps } = props;
  const { name, control } = useContext(radioButtonsFieldContext);
  const { fieldState: { error } } = useController({ name, control });

  return (
    <RadioButtonUI
      isInvalid={!!error}
      // useForm needs it to be a string
      value={value.toString()}
      {...contentProps}
    >
      {children}
    </RadioButtonUI>
  );
}

RadioButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RadioButton;
