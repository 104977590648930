import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const WORK_CATALOG = gql`
query WorkingSectors {
  catalogs {
    id
    workingSectors {
      id
      name
    }
    workingActivities {
      id
      name
      sectorId
    }
    employments {
      id
      name
    }
  }
}
`;

const queryOptions = {
  context: {
    clientName: 'public',
  },
};

export const useEmploymentRelationCatalog = (options = {}) => useQuery(
  WORK_CATALOG,
  { ...queryOptions, ...options },
);
