import i18n, { t } from '@i18n';
import { isNil } from '@lib/Monads';

const FORMATTERS = {
  number: i18n.numberToRounded.bind(i18n),
  currency: i18n.numberToCurrency.bind(i18n),
  percentage: i18n.numberToPercentage.bind(i18n),
};

function validate(value, {
  min,
  max,
  message,
  type = 'number',
  precision = 0,
}) {
  if (isNil(value) || (value >= min && value <= max)) return null;
  const formatter = FORMATTERS[type] || FORMATTERS.number;

  return {
    type: 'danger',
    message: message || t('FormResolver.amount', {
      min: formatter(min, { precision }),
      max: formatter(max, { precision }),
    }),
  };
}

export default validate;
