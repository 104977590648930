import React from 'react';
import { t, tscope } from '@i18n';
import { Heading } from 'ui';
import {
  SelectField,
  CheckboxField,
  DateField,
} from '@components/Form/Field';
import { FunnelForm } from '../components';
import { useController } from './hooks';

const ts = tscope('Register.birthUpdate');

function UpdateBirth() {
  const {
    minYear,
    maxYear,
    states,
    control,
    onBack,
    onSubmit,
    countries,
    isForeign,
    isSubmitting,
    isLoadingCatalogs,
  } = useController();

  return (
    <FunnelForm
      onSubmit={onSubmit}
      title={ts('title')}
      subtitle={ts('description')}
      onBack={onBack}
      loading={isSubmitting}
    >
      <DateField
        control={control}
        minYear={minYear}
        maxYear={maxYear}
        name="birthDate"
        data-testid="dateField"
        mb="2rem"
      />
      <Heading fontSize="2xl" textAlign="center">
        {ts('title2')}
      </Heading>
      <SelectField
        labelText={ts('stateField.label')}
        control={control}
        name="birthStateId"
        data-testid="birthStateId"
        isDisabled={isForeign || isLoadingCatalogs}
        placeholder={(isLoadingCatalogs
          ? t('Common.loading')
          : ts('stateField.placeholder')
        )}
      >
        {states?.map(
          (item) => <option value={item.id} key={item.id}>{item.name}</option>,
        )}
      </SelectField>
      <CheckboxField
        control={control}
        data-testid="foreignerCheckbox"
        name="isForeign"
      >
        {ts('nationalityField.label')}
      </CheckboxField>
      {isForeign && (
        <SelectField
          labelText={ts('countryField.label')}
          control={control}
          name="countryId"
          data-testid="countryId"
          isDisabled={isLoadingCatalogs}
          placeholder={(isLoadingCatalogs
            ? t('Common.loading')
            : ts('countryField.placeholder')
          )}
        >
          {countries.map(
            (item) => <option value={item.id} key={item.id}>{item.name}</option>,
          )}
        </SelectField>
      )}
    </FunnelForm>
  );
}

export default UpdateBirth;
