import { useCallback, useEffect } from 'react';
import { useGenerateOTPMutation } from '@services/yoteprestoAPI';
import { useInterval } from '@hooks/time';
import { ONE_SECOND } from '@lib/durations';
import { tscope } from '@i18n';

const ts = tscope('Register.emailConfirm');

const RESEND_OTP_TIMEOUT = 30 * ONE_SECOND;

export const SENT_OTP_SUCCESS_ALERT = {
  description: ts('toast.otpSent'),
  status: 'success',
  id: 'otp-sent-success',
};

export const SENT_OTP_FAILED_ALERT = {
  description: ts('toast.otpSentError'),
  status: 'error',
};

export const useGenerateOTP = ({ toast }) => {
  const {
    humanize: { seconds },
    reinitialize: reinitializeTimer,
  } = useInterval({ initialTime: RESEND_OTP_TIMEOUT });
  const [
    generate,
    {
      isError,
      isSuccess,
      isLoading,
    },
  ] = useGenerateOTPMutation();

  const handleFailedSendOTP = useCallback(() => {
    toast(SENT_OTP_FAILED_ALERT);
  }, [toast]);

  const handleSendOTPSuccess = useCallback(() => {
    reinitializeTimer();
    if (!toast.isActive('otp-sent-success')) toast(SENT_OTP_SUCCESS_ALERT);
  }, [toast, reinitializeTimer]);

  useEffect(() => {
    if (isSuccess) handleSendOTPSuccess();
  }, [isSuccess, handleSendOTPSuccess]);

  useEffect(() => {
    if (isError) handleFailedSendOTP();
  }, [isError, handleFailedSendOTP]);

  const generateOTP = () => generate({ type: 'email' });

  const newOtpUnavailable = isLoading || seconds > 0;

  return {
    generateOTP,
    timeLeft: seconds,
    newOtpUnavailable,
  };
};
