import { EMPLOYMENTS } from '@config/constants';
import { useNavigateResolver } from './useNavigateResolver';
import { useUpdateUserForm } from './useUpdateUserForm';
import { useWorkOptions } from './useWorkOptions';

export const useController = () => {
  const {
    goBack,
    goNext,
  } = useNavigateResolver();

  const {
    control,
    onSubmit,
    employmentId,
    workingSectorId,
    loading: loadingForm,
  } = useUpdateUserForm({
    onSuccess: goNext,
  });

  const {
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
    loading: loadingOptions,
  } = useWorkOptions({ workingSectorId });
  const loading = loadingForm || loadingOptions;
  const displayOccupationDisclaimer = employmentId === EMPLOYMENTS.OWNER;

  return {
    goBack,
    control,
    loading,
    onSubmit,
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
    displayOccupationDisclaimer,
    isLoadingForm: loadingForm,
  };
};
