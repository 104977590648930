import { I18n } from 'i18n-js';
import { getDefaultLocale } from '@config/i18n';
import { isTesting, isDevelopment } from '@config/';
import { buildJsx } from './buildJsx';
import * as esMX from './locales/es-MX';

const i18n = new I18n({ 'es-MX': esMX });

i18n.defaultLocale = getDefaultLocale();
i18n.locale = getDefaultLocale();
i18n.enableFallback = true;

if (isTesting || isDevelopment) {
  i18n.missingBehavior = 'error';
}

export const t = i18n.t.bind(i18n);
export const l = i18n.l.bind(i18n);
export const toCurrency = i18n.numberToCurrency.bind(i18n);
export const strftime = i18n.strftime.bind(i18n);
export const tscope = (scope) => (exactScope, options) => t(exactScope, { scope, ...options });
export const jsx = buildJsx(t);

export default i18n;
