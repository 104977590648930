import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_ADDRESS = gql`
  query{
    user {
    id
    addressYears
    homeOwnership {
      id
      name
      expense {
        id
        name
      }
    }
    address {
      street
      extNumber
      intNumber
      zip
      neighborhood
      }
    }
    activeRequisition {
      id
      spending {
        id
        expenses {
          id
          expenseId
          amount
          name
        }
      }
    }
  }
`;

export const useGetAddressQuery = (options = {}) => useQuery(GET_ADDRESS, {
  ...options,
});
