import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCheckedStatusAt,
  selectIsUnavailableService,
} from '@reducks/serviceStatus/serviceStatus';
import { PATHS as ROOT_PATHS } from '@navigators/Root/constants';
import { useApiServiceStatusQuery } from '@services/yoteprestoAPI';
import { POLLING_SERVICE_STATUS, UNAVAILABLE_SERVICE_STATUS } from '@config';
import { useNavigate } from '@hooks/navigation';
import { useTimer } from '@hooks/time';

export const useApiService = () => {
  const navigate = useNavigate();
  const isUnavailableService = useSelector(selectIsUnavailableService);
  const checkedStatusAt = useSelector(selectCheckedStatusAt);
  const isOnMaintenance = useRef(false);
  const {
    error,
    refetch,
    isError,
    isSuccess,
  } = useApiServiceStatusQuery();
  const onFinish = isError ? () => refetch() : null;
  const { reinitialize, setTime } = useTimer({
    time: POLLING_SERVICE_STATUS,
    onFinish,
  });

  useEffect(() => {
    if (isUnavailableService && checkedStatusAt) {
      navigate(ROOT_PATHS.UNAVAILABLE_SERVICE);
    }
  }, [
    navigate,
    checkedStatusAt,
    isUnavailableService,
  ]);

  useEffect(() => {
    if (isError && error?.originalStatus === UNAVAILABLE_SERVICE_STATUS) {
      isOnMaintenance.current = true;
      reinitialize();
      return;
    }

    if (isError) setTime(null);
  }, [
    isError,
    setTime,
    reinitialize,
    error?.originalStatus,
  ]);

  useEffect(() => {
    if (isSuccess && isOnMaintenance.current === true) {
      isOnMaintenance.current = false;
      navigate(ROOT_PATHS.ROOT);
    }
  }, [navigate, isSuccess]);
};
