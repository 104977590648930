import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query {
    user {
      id
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
