import { useEffect } from 'react';
import { useLocation, useNavigate } from '@hooks/navigation';
import { useRedirectEmailPath } from './useRedirectEmailPath';
import { useRedirectOutOfSite } from './useRedirectOutOfSite';
import { useRecoveryPasswordPath } from './useRecoveryPasswordPath';

export const useRedirect = ({
  role,
  userId,
  status,
  isEmailConfirmed,
  activeRequisition,
  isPersonConfirmed,
  skipEmailValidation,
  forcePasswordUpdate,
  isRecoveringPassword,
}) => {
  const navigate = useNavigate();
  const { willRedirect, goToAppByRole } = useRedirectOutOfSite({
    role,
    status,
    isPersonConfirmed,
    activeRequisition,
  });
  const { pathname } = useLocation();
  const recoverPasswordPath = useRecoveryPasswordPath({
    forcePasswordUpdate,
    isRecoveringPassword,
  });
  const redirectEmailPath = useRedirectEmailPath({ isEmailConfirmed, skipEmailValidation });
  const needToFinishProfile = [recoverPasswordPath, redirectEmailPath].some(Boolean);
  const isLoadingRedirection = willRedirect && !needToFinishProfile;

  useEffect(() => {
    if (!userId) return;
    if (recoverPasswordPath) navigate(recoverPasswordPath, { replace: true });
    else if (redirectEmailPath) navigate(redirectEmailPath, { replace: true });
    else if (willRedirect) goToAppByRole();
  }, [
    userId,
    navigate,
    pathname,
    willRedirect,
    goToAppByRole,
    redirectEmailPath,
    recoverPasswordPath,
  ]);

  return {
    isLoadingRedirection,
  };
};
