import { useCallback } from 'react';
import { useLocationEvents } from '@hooks/navigation';
import * as Tracker from '@lib/Tracker';

export const useLocationTracker = () => {
  const onLocationChange = useCallback((location) => {
    Tracker.sendPageView(location);
  }, []);

  useLocationEvents({ onLocationChange });
};
