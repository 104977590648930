import { isNil } from '../Monads';
import { readString } from './string';

export const readJson = (value) => {
  const parsed = readString(value);
  if (!parsed) return parsed;
  try {
    return JSON.parse(parsed);
  } catch {
    return null;
  }
};

export const writeJson = (value) => {
  if (isNil(value)) return null;
  if (!String(value).trim()) return null;
  return JSON.stringify(value);
};
