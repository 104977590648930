import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from '@hooks/navigation';
import { t } from '@i18n';
import { useCustomToast } from 'ui';
import { selectSession } from '@reducks/session';
import { useUpdatePasswordMutation } from './useUpdatePasswordMutation';

export const useController = () => {
  const navigate = useNavigate();
  const toast = useCustomToast();
  const session = useSelector(selectSession);

  const handleSuccess = useCallback(async () => {
    toast({
      description: t('passwordRecovery.toast.forcedPasswordChangedSuccess'),
      status: 'success',
    });
    navigate('/');
  }, [toast, navigate]);

  const invalidPasswordError = useCallback(() => {
    toast({
      description: t('passwordRecovery.toast.invalidPassword'),
      status: 'error',
    });
  }, [toast]);

  const handleError = useCallback(() => {
    const description = t('Common.errors.default');
    toast({
      description,
      status: 'error',
    });
  }, [toast]);

  const [updatePassword, {
    loading,
  }] = useUpdatePasswordMutation({
    onErrorCode: {
      INVALID_PASSWORD_ERROR: invalidPasswordError,
    },
    onError: handleError,
    onCompleted: handleSuccess,
  });

  const updatePasswordOnSubmit = useCallback(({ password }) => updatePassword({
    variables: {
      input: {
        newPassword: password,
      },
    },
  }), [updatePassword]);

  return {
    loading,
    updatePasswordOnSubmit,
    email: session?.email,
  };
};
