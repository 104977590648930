import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const REMOVE_COUPON = gql`
mutation removeCoupon($coupon: RemoveCouponMutationInput!) {
  removeCoupon(input: $coupon) {
    user {
      id
      metaData {
        coupon
      }
      requisitionConfig {
        maximumAmount
        minimumAmount
        paymentTerms
        id
        purposes {
          id
          name
        }
      }
    }
  }
}
`;

export const useRemoveCouponMutation = (options) => useMutation(REMOVE_COUPON, options);
