import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_HOUSEHOLD = gql`
  mutation(
    $input: UpsertRequisitionMutationInput!
    $userInput: UpdateUserMutationInput!
  ) {
    upsertRequisition(input: $input) {
      activeRequisition {
        id
        spending {
          id
          hasCar
          dependentsNumber
        }
      }
    }
    updateUser(input: $userInput){
      user {
        id
        educationLevel {
          id
          name
        }
        maritalStatus {
          id
          name
        }
      }
    }
  }
`;

export const useUpdateHouseholdMutation = (options) => useMutation(UPDATE_HOUSEHOLD, options);
