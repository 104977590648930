import i18n from '@i18n';
import { parseDigits } from './parseDigits';
import { isNil } from '../Monads';

export const currencyToNumber = (value) => {
  const parsed = parseDigits(value);
  return Number(parsed);
};

export const numberToCurrency = (value) => {
  if (isNil(value)) return '';
  if (value === '') return '';

  return i18n.numberToCurrency((value), { precision: 0, roundMode: 'up' });
};

export const parseAmount = (input) => {
  const digits = parseDigits(input);
  return digits ? parseInt(digits, 10) : null;
};
