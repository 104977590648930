import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'ui';

function CheckList(props) {
  const { children, ...contentProps } = props;
  return (
    <List {...contentProps}>{children}</List>
  );
}

CheckList.defaultProps = {
  children: null,
};

CheckList.propTypes = {
  children: PropTypes.node,
};

export default CheckList;
