export * from './password';
export * from './user';
export * from './requisition';

export const OTP_LENGTH = 6;

export const MAX_RFC_LENGTH = 13;

export const MAX_CURP_LENGTH = 18;

export const CONFIRM_REQUISITION_PATH = '/requisition/confirm';
