import { getGoogleTagManagerId, getSnowplowConfig } from '@config';
import * as GoogleTagManagerTracker from './googleTagManager';
import * as SnowplowTracker from './snowplow';

export const init = () => {
  GoogleTagManagerTracker.init(getGoogleTagManagerId());
  SnowplowTracker.init(getSnowplowConfig());
};

export const addContext = (context) => {
  GoogleTagManagerTracker.addContext(context);
  SnowplowTracker.addContext(context);
};

export const sendEvent = (name, properties = {}) => {
  GoogleTagManagerTracker.sendEvent(name, properties);
  SnowplowTracker.sendEvent(name, properties);
};

export const sendPageView = (location) => {
  GoogleTagManagerTracker.sendPageView(location);
  SnowplowTracker.sendPageView(location);
};
