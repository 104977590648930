import { t, tscope } from '@i18n';
import { calculateAge } from '@lib/Date';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

const ts = tscope('Register.birthUpdate');

const VALID_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

const wrapError = (message) => ({ message, type: 'danger' });

const validateBirthdate = (input, { min, max }) => {
  if (!input || input === '--') return wrapError(t('FormResolver.required'));
  if (!VALID_DATE_REGEX.test(input)) return wrapError(ts('dateField.errors.incomplete'));
  const [year, month, day] = input.split('-');
  const birthdate = new Date(year, month - 1, day);

  const maxAge = calculateAge(birthdate, { maxDaysAllowed: -1 });
  const minAge = calculateAge(birthdate);
  if (maxAge > max) return wrapError(ts('dateField.errors.maxAge', { max }));
  if (minAge < min) return wrapError(ts('dateField.errors.minAge', { min }));
  return null;
};

function resolver(values, context) {
  const { minAge, maxAge } = context;
  const {
    birthDate,
    isForeign,
    birthStateId,
    countryId,
  } = values;

  const errors = {
    birthDate: validateBirthdate(birthDate, { min: minAge, max: maxAge }),
    birthStateId: Validator(birthStateId).requiredIf(!isForeign).result(),
    countryId: Validator(countryId).requiredIf(isForeign).result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
