import React from 'react';
import { t, tscope, jsx } from '@i18n';
import { BuroSearchImage } from '@assets/images';
import {
  Box,
  Text,
  Grid,
  Heading,
} from 'ui';
import { PreApprovalContainer } from '../components';
import { useController } from './hooks';
import { styles as s } from './BuroUnprocessable.style';

const ts = tscope('Requisition.buroUnprocessable');

export const BuroUnprocessable = () => {
  const {
    buroErrorCode,
  } = useController();

  return (
    <PreApprovalContainer>
      <Grid {...s.card}>
        <Box {...s.imageBox}>
          <BuroSearchImage {...s.image} />
        </Box>
        <Heading {...s.heading}>
          {ts('title')}
        </Heading>
        <Text
          {...s.description}
          data-testid="BuroUnprocessable.description"
        >
          {jsx('description', {
            t: ts,
            errorCode: <Text as="b" {...s.errorCode}>{buroErrorCode}</Text>,
            contact: <b>{t('Common.brand.whatsappHelp')}</b>,
          })}
        </Text>
      </Grid>
    </PreApprovalContainer>
  );
};
