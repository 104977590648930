import { readJson, writeJson } from '@lib/serializers';
import { useCallback } from 'react';
import { useCookie } from './useCookie';

export const useUserCookie = ({
  name,
  userId,
  days = null,
}) => {
  const [values, setCookie] = useCookie({
    name,
    days,
    reader: readJson,
    writer: writeJson,
  });

  const setUserCookie = useCallback((newValue) => {
    if (userId) setCookie((prev) => ({ ...prev, [userId]: newValue }));
  }, [userId, setCookie]);

  const userValue = values === undefined || !userId
    ? undefined
    : (values || {})[userId] || null;

  return [userValue, setUserCookie];
};
