import React from 'react';
import { t } from '@i18n';
import RightSideSrc from '@assets/SignIn/rightSide.svg';
import ytpBrand from '@assets/branding/ytp-default.png';
import AsideImageSrc from '@assets/PasswordRecovery/aside.jpg';
import { TwoSidesLayout } from '@components/layouts';
import { Heading, Image } from 'ui';
import { UpdatePasswordForm } from '../components';
import { useController } from './hooks';

export const RequireUpdate = () => {
  const {
    email,
    isLoading,
    updatePasswordOnSubmit,
  } = useController();

  return (
    <TwoSidesLayout
      bgImageLeftSideSrc={AsideImageSrc}
      bgImageRightSideSrc={RightSideSrc}
      rightSide={(
        <>
          <Heading>
            <Image src={ytpBrand} w="12rem" mb="4rem" />
          </Heading>
          <UpdatePasswordForm
            email={email || ''}
            isLoading={isLoading}
            onSubmit={updatePasswordOnSubmit}
            data-testid="update-password-form"
            title={t('passwordRecovery.resetPasswordForm.title')}
            subtitle={t('passwordRecovery.resetPasswordForm.subtitle')}
            submitText={t('passwordRecovery.resetPasswordForm.submit')}
          />
        </>
      )}
    />
  );
};
