import { PATHS as APP_PATHS } from '@navigators/Root/App/constants';
import { PATHS as PASS_PATHS } from '@navigators/Root/App/PasswordRecovery/constants';

export const REQUIRE_UPDATE_PATH = `/${APP_PATHS.PASSWORD_RECOVERY}/${PASS_PATHS.REQUIRED}`;
export const UPDATE_PATH = `/${APP_PATHS.PASSWORD_RECOVERY}/${PASS_PATHS.UPDATE}`;

export const useRecoveryPasswordPath = ({ forcePasswordUpdate, isRecoveringPassword }) => {
  const forceUpdatePath = (forcePasswordUpdate
    ? REQUIRE_UPDATE_PATH
    : null
  );
  const updatePath = (isRecoveringPassword
    ? UPDATE_PATH
    : null
  );

  return forceUpdatePath || updatePath;
};
