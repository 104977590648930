import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import { ExpiredSessionModal } from './components';
import { MODALS, useController } from './hooks';

const MODAL_CONFIG = {
  [MODALS.ANOTHER_DEVICE]: {
    title: t('SignIn.anotherDeviceModal.title'),
    description: t('SignIn.anotherDeviceModal.description'),
    'data-testid': 'SessionKeeper.AnotherDeviceModal',
  },
  [MODALS.INACTIVITY]: {
    title: t('SignIn.inactivityModal.title'),
    description: t('SignIn.inactivityModal.description'),
    'data-testid': 'SessionKeeper.InactivityModal',
  },
};

export const SessionKeeper = ({ children }) => {
  const {
    email,
    showModal,
  } = useController();

  return (
    <>
      {children}
      {showModal ? (
        <ExpiredSessionModal
          isOpen
          email={email}
          {...MODAL_CONFIG[showModal]}
        />
      ) : null}
    </>
  );
};

SessionKeeper.defaultProps = {
  children: null,
};

SessionKeeper.propTypes = {
  children: PropTypes.node,
};
