import { useSendEmailVerification } from './useSendEmailVerification';

export const useController = () => {
  const {
    submit,
    isLoading,
    passwordRecoveryId,
  } = useSendEmailVerification();
  const showConfirmation = !!passwordRecoveryId;

  return {
    submit,
    isLoading,
    showConfirmation,
    passwordId: passwordRecoveryId,
  };
};
