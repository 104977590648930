import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { OTP_LENGTH } from '@config/constants';

function resolver(values) {
  let errors = {};
  const {
    otp,
  } = values;

  errors = {
    otp: Validator(otp).required().length({ min: OTP_LENGTH, max: OTP_LENGTH }).result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
