import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query User {
    user {
      id
      workingActivity {
        id
        name
        sectorId
      }
      workingSector {
        id
        name
      }
    }
    activeRequisition {
      id
      income {
        id
        employment {
          id
          name
        }
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
