import React from 'react';
import { Tag, TagLabel, TagRightIcon } from 'ui';
import { useConfig } from './hooks';

export const StatusTag = (props) => {
  const { stepLabel, status } = props;
  const config = useConfig(status);

  return (
    <Tag
      size="lg"
      display="flex"
      variant="solid"
      width="11.5rem"
      colorScheme="transparent"
      justifyContent="space-between"
    >
      <TagLabel
        color={config.tagLabel.color}
      >
        {stepLabel}
      </TagLabel>
      <TagRightIcon>
        <config.Icon />
      </TagRightIcon>
    </Tag>
  );
};
