import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_PASSWORD_MUTATION = gql`
mutation UpdatePassword($input: UpdatePasswordMutationInput!) {
  updatePassword(input: $input) {
    user {
      id
      roleName
      flags {
        id
        forcePasswordUpdate
      }
    }
  }
}
`;

export const useUpdatePasswordMutation = (options) => useMutation(
  UPDATE_PASSWORD_MUTATION,
  options,
);
