import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_CATALOG = gql`
query  GET_CATALOG{
  catalogs {
    id
    states {
      id
      constId
      name
    }
    homeOwnerships {
      id
      name
    }
  }
}
`;

export const useGetCatalogQuery = (options = {}) => useQuery(GET_CATALOG, {
  context: {
    clientName: 'public',
  },
  ...options,
});
