import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const REFERENCES_CATALOG_GQL = gql`
query REFERENCES_CATALOG_GQL {
  catalogs {
    personalReferences {
      id
      name
    }
  }
}
`;

export const useReferencesCatalogQuery = (options) => useQuery(REFERENCES_CATALOG_GQL, {
  context: {
    clientName: 'public',
  },
  ...options,
});
