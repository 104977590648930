import { useEffect } from 'react';
import { useNavigate } from '@hooks/navigation';
import { USER } from '@config/constants';
import { PATHS } from '../../constants';
import { PATHS as PROFILE_PATHS } from '../constants';
import { useUserQuery } from './graphql/useUserQuery';

const getIsBasicInfoIncomplete = (user) => {
  if (!user) return null;
  const isBasicInfoComplete = [
    user.gender,
    user.fullName,
    user.cellPhoneNumber,
  ].every(Boolean);
  return !isBasicInfoComplete;
};

export const useNavigationResolver = () => {
  const navigate = useNavigate();
  const { data, loading } = useUserQuery();
  const user = data?.user || {};
  const { flags } = user;
  const isBasicInfoIncomplete = getIsBasicInfoIncomplete(user);

  useEffect(() => {
    if (loading) return;
    if (isBasicInfoIncomplete === true) navigate(`/${PATHS.PROFILE}`);
    else if (!user.birthDate) navigate(`/${PATHS.PROFILE}/${PROFILE_PATHS.BIRTH}`);
    else if (!user.rfc) navigate(`/${PATHS.PROFILE}/${PROFILE_PATHS.IDENTIFICATION}`);
    if (USER.ROLES.BORROWER !== user.roleName) return;
    if (flags?.isPersonConfirmed) navigate(`/${PATHS.REQUISITION}`);
  }, [
    loading,
    navigate,
    user.rfc,
    user.roleName,
    user.birthDate,
    isBasicInfoIncomplete,
    flags?.isPersonConfirmed,
  ]);

  return {
    loading,
  };
};
