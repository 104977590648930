import { t } from '@i18n';

const DEFAULT_DAYS_COUNT = 31;

/**
  count days within a month and year
  @param  {Number}  month   specific month
  @param  {Number}  year    specific year
  @return {Number}          count days
*/
export const countDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

/**
  List all days from 1 to last day of passed month
  @param  {Number}  month   specific month
  @param  {Number}  year    specific year
  @return {Array<Number>}          all days
*/
export const getDayList = (month, year) => Array
  .from({ length: countDaysInMonth(month, year) || DEFAULT_DAYS_COUNT })
  .map((_, index) => index + 1);

export const getMonthList = () => [...Array(12).keys()]
  .map((index) => ({
    id: (index + 1).toString().padStart(2, '0'),
    name: t(`date.month_names.${index + 1}`),
  }));

/**
  Creates an array of years starting from a specific year
  @param  {Object({
    from: Number,
    span: Number
  })}  options              options includes specifications to generate list
  @return {Array<String>}   Array of years
*/
export const getYearList = ({ from = 1960, span = 60 } = {}) => [...Array(span).keys()]
  .reduce(
    (years) => [...years, Number(years.slice(-1)) + 1],
    [from],
  );
