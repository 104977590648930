import { PATHS } from '../../constants';
import { creditResolvePath } from '../components/PathResolver/hooks';

export const usePreventMount = ({
  user,
  isLoading,
  activeRequisition,
}) => {
  const navigateTo = isLoading
    ? null
    : creditResolvePath({
      user,
      activeRequisition,
      onCreditCompletePath: true,
      defaultPath: true,
    });

  const preventMountOptions = {
    [PATHS.AUTHORIZE]: {
      isAllowed: navigateTo === true,
      redirectTo: navigateTo,
    },
  };

  return preventMountOptions;
};
