import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

async function resolver(values) {
  const { email } = values;
  const emailNormalized = email?.trim();

  return {
    values: {
      ...values,
      email: emailNormalized,
    },
    errors: removeNilValues({
      email: Validator(emailNormalized)
        .required()
        .email(undefined, { ignoreSpecialChars: true })
        .result(),
    }),
  };
}

export default resolver;
