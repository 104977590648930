import { createSlice } from '@reduxjs/toolkit';
import { api } from '@services/yoteprestoAPI';

const initialState = {
  credentials: null,
  rememberUser: false,
  initialAppPath: null,
  isAuthenticated: null,
  expiredByInactivity: false,
  expiredByAnotherDevice: false,
};

const sessionReducer = createSlice({
  name: 'session',
  initialState,
  reducers: {
    saveUserOnBrowser(state, { payload }) {
      return {
        ...state,
        rememberUser: payload.rememberUser,
        fullName: payload.fullName,
      };
    },
    setUserInfo(state, { payload }) {
      return {
        ...state,
        fullName: payload.fullName,
        email: payload.email,
      };
    },
    setAuthenticated(state) {
      return {
        ...state,
        isAuthenticated: true,
      };
    },
    setUnauthenticated(state) {
      return {
        ...state,
        isAuthenticated: false,
      };
    },
    logout(state) {
      return {
        ...state,
        credentials: null,
        isAuthenticated: false,
      };
    },
    setExpiredByInactivity(state) {
      return {
        ...state,
        expiredByInactivity: true,
      };
    },
    setExpiredByAnotherDevice(state) {
      return {
        ...state,
        expiredByAnotherDevice: true,
      };
    },
    setInitialAppPath(state, { payload }) {
      return {
        ...state,
        initialAppPath: payload.path || null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        credentials: payload.credentials,
        isAuthenticated: true,
        expiredByInactivity: false,
        expiredByAnotherDevice: false,
      }),
    );
    builder.addMatcher(
      api.endpoints.signUp.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        credentials: payload.credentials,
        isAuthenticated: true,
        expiredByInactivity: false,
        expiredByAnotherDevice: false,
      }),
    );
  },
});

export const { actions, reducer } = sessionReducer;

export const {
  logout,
  setUserInfo,
  setLoadingAppOn,
  setAuthenticated,
  saveUserOnBrowser,
  setInitialAppPath,
  setUnauthenticated,
  setExpiredByInactivity,
  setExpiredByAnotherDevice,
} = actions;

export const selectSession = (state) => state.session;

export const selectCredentials = (state) => state.session.credentials;

export const selectInitialAppPath = (state) => state.session.initialAppPath;

export const selectIsAuthenticated = (state) => state.session.isAuthenticated;

export const selectExpiredByInactivity = (state) => state.session.expiredByInactivity;

export const selectExpiredByAnotherDevice = (state) => state.session.expiredByAnotherDevice;

export const selectSessionExpired = (state) => state.session.expiredByInactivity
  || state.session.expiredByAnotherDevice;
