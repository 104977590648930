import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { t } from '@i18n';
import { formatBirthInfo, formatGender } from '@lib/format/user';
import { useConfirmMutation } from './useConfirmMutation';
import { useUserQuery } from './useUserQuery';
import resolver from '../utils/resolver';

export const useController = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    resolver,
    defaultValues: { term2: false },
  });
  const { loading, data: profile } = useUserQuery();
  const user = useMemo(() => profile?.user, [profile]);
  const [confirmUser, { loading: isSubmitting }] = useConfirmMutation();
  const isLoading = loading || !profile;

  const confirmUserHandler = handleSubmit(() => confirmUser({
    variables: {
      input: {},
    },
  }));

  const labels = {
    name: user?.fullName || t('Common.na'),
    curp: user?.curp || t('Common.na'),
    rfc: user?.rfc || t('Common.na'),
    gender: formatGender(user),
    birth: formatBirthInfo(user),
  };

  useEffect(() => {
    if (user?.isProfileCompleted !== false) return;

    navigate('../');
  }, [navigate, user?.isProfileCompleted]);

  return {
    user,
    control,
    labels,
    isSubmitting,
    confirmUserHandler,
    isLoading,
  };
};
