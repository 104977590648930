export default {
  instructions: {
    marginTop: '1rem',
  },
  form: {
    marginY: '2rem',
    width: '100%',
  },
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: 'gray.50',
    paddingY: '1.5rem',
    fontSize: '13px',
  },
};
