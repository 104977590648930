import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { isNil } from '@lib/Monads';
import resolver from '../resolver';
import { shapeVariables } from './shapeVariables';

const defaultValues = {
  hasCar: '',
  maritalStatus: '',
  educationLevel: '',
  dependentsNumber: '',
};

export const useUpdateHouseholdForm = ({
  household,
  updateHouseholdMutation,
}) => {
  const {
    control,
    reset,
    handleSubmit,
  } = useForm(
    {
      resolver,
      defaultValues,
    },
  );

  const submitHandler = (values) => updateHouseholdMutation(shapeVariables(values));

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!household) return;

    // this is to avoid evaluating to 'null'
    const hasCar = household?.activeRequisition?.spending?.hasCar;
    const parsedHasCar = isNil(hasCar) ? '' : String(hasCar);

    // this is to avoid evaluating to 'null'
    const dependents = household?.activeRequisition?.spending?.dependentsNumber;
    const parsedDependents = isNil(dependents) ? '' : String(dependents);

    reset({
      maritalStatus: household?.user.maritalStatus?.id || '',
      educationLevel: household?.user.educationLevel?.id || '',
      hasCar: parsedHasCar,
      dependentsNumber: parsedDependents,
    });
  }, [household, reset]);

  return {
    control,
    onSubmit,
  };
};
