import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const AUTHORIZE_CREDIT_REPORT = gql`
mutation AuthorizeCreditReport($input: AuthorizeCreditReportMutationInput!) {
  authorizeCreditReport(input: $input) {
    activeRequisition {
      id
      status
    }
    user {
      id
    }
  }
}
`;

export const useAuthorizeCreditReport = (options) => useMutation(AUTHORIZE_CREDIT_REPORT, options);
