import React from 'react';
import PropTypes from 'prop-types';
import checkSuccessImageSrc from '@assets/PasswordRecovery/checkSuccess.svg';
import { t, jsx } from '@i18n';
import {
  Box,
  Text,
  Flex,
  Card,
  Image,
  Heading,
  CardHeader,
  CardFooter,
} from 'ui';
import styles from './ConfirmCard.styles';

export const ConfirmCard = ({ passwordId }) => (
  <Card
    size="sm"
    minWidth={{ base: '100%', sm: '28rem' }}
    maxWidth={{ base: '100%', sm: '28rem' }}
  >
    <CardHeader>
      <Flex alignItems="center" mb="2rem" mx={{ base: '0', md: '1rem' }}>
        <Image src={checkSuccessImageSrc} mr={{ base: '1rem', md: '1.5rem' }} />
        <Heading as="h2" size="md">
          {t('passwordRecovery.confirmCard.title')}
        </Heading>
      </Flex>
      <Text mb="1rem">
        {jsx('passwordRecovery.confirmCard.description', {
          id: <strong>{t('passwordRecovery.confirmCard.id', { id: passwordId })}</strong>,
        })}
      </Text>
      <Box {...styles.note}>
        <Text>
          {jsx('passwordRecovery.confirmCard.noticeBox.content', {
            opening: <strong>{t('passwordRecovery.confirmCard.noticeBox.opening')}</strong>,
          })}
        </Text>
      </Box>
    </CardHeader>
    <CardFooter {...styles.footer}>
      <Text color="gray.500">
        {t('passwordRecovery.footer')}
      </Text>
    </CardFooter>
  </Card>
);

ConfirmCard.defaultProps = {};

ConfirmCard.propTypes = {
  passwordId: PropTypes.string.isRequired,
};
