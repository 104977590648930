import React from 'react';
import { tscope } from '@i18n';
import PropTypes from 'prop-types';
import { useLogout } from '@hooks/auth';
import {
  Header,
  Heading,
  MenuItem,
  MenuDivider,
} from 'ui';
import style from './Header.style';

const t = tscope('headers.public');

export const PublicHeader = (props) => {
  const { title, hideLogOutItem, ...styleProps } = props;
  const logout = useLogout();

  return (
    <Header
      {...style.header}
      menuListComponents={(
        <>
          <MenuItem
            {...style.menu.item}
            key="item1"
            href={t('menu.item1.link')}
          >
            {t('menu.item1.label')}

          </MenuItem>
          <MenuDivider key="divider1" />
          <MenuItem
            {...style.menu.item}
            key="item2"
            href={t('menu.item2.link')}
          >
            {t('menu.item2.label')}

          </MenuItem>
          <MenuDivider key="divider2" />
          <MenuItem
            {...style.menu.item}
            key="item3"
            href={t('menu.item3.link')}
          >
            {t('menu.item3.label')}

          </MenuItem>
          {hideLogOutItem
            ? null
            : (
              <>
                <MenuDivider key="divider3" />
                <MenuItem key="item4" onClick={logout}>{t('menu.item4.label')}</MenuItem>
              </>
            )}
        </>
      )}
      {...styleProps}
    >
      {title && (
        <Heading {...style.title}>
          {title}
        </Heading>
      )}
    </Header>
  );
};

PublicHeader.defaultProps = {
  title: '',
  hideLogOutItem: false,
};

PublicHeader.propTypes = {
  title: PropTypes.string,
  hideLogOutItem: PropTypes.bool,
};
