export default {
  list: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gridRowGap: '5',
  },
  item: {
    columnGap: '2',
    alignItems: 'center',
  },
};
