import { useCallback } from 'react';
import { t } from '@i18n';
import { useCustomToast } from 'ui';
import { useRecoveryPassword } from './graphql/useRecoveryPassword';

export const useSendEmailVerification = () => {
  const showToast = useCustomToast();
  const handleSuccess = () => {
    showToast({
      description: t('passwordRecovery.toast.userHasBeenEmailedSuccess'),
      status: 'success',
    });
  };
  const handleError = () => {
    const description = t('Common.errors.default');
    showToast({
      description,
      status: 'error',
    });
  };
  const [
    recoveryPassword,
    {
      data,
      loading,
    },
  ] = useRecoveryPassword({
    onError: handleError,
    onCompleted: handleSuccess,
  });
  const passwordRecoveryId = data?.passwordRecovery?.passwordRecoveryId;
  const submit = useCallback(
    ({ email }) => recoveryPassword({ variables: { input: { email } } }),
    [recoveryPassword],
  );

  return {
    submit,
    passwordRecoveryId,
    isLoading: loading,
  };
};
