import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const IDENTIFICATION_QUERY = gql`
  query {
      user {
        id
        rfc
        curp
        suggestedRfc
        suggestedCurp
        flags {
          id
          isPersonCompleted
        }
      }
  }
`;

export const useIdentificationQuery = (options) => useQuery(IDENTIFICATION_QUERY, options);
