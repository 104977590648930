import { getDaysLeft } from '@lib/Date';
import { useUserQuery } from '../graphql';
import { getContentFromStatus } from '../utils';

export const useController = () => {
  const { data, loading: isLoading } = useUserQuery();
  const { user, activeRequisition } = data || {};
  const { firstName, roleName } = user || {};

  const {
    status,
    dateToStartANewRequisition,
  } = activeRequisition || {};

  const daysLeft = getDaysLeft(dateToStartANewRequisition, new Date());

  const content = getContentFromStatus({ status, daysLeft }) || {};

  return {
    content,
    roleName,
    isLoading,
    name: firstName || '',
    dateToStartANewRequisition,
  };
};
