import { useMemo } from 'react';
import { numberToCurrency } from '@lib/parsers';
import { tscope } from '@i18n/index';
import { useDisclosure } from 'ui';
import { useLoanDataQuery, useUpsertRequisitionMutation } from './graphql';
import { useUpdateRequisitionForm } from './useUpdateRequisitionForm';

const PAY_DEBT_ID = '2';
const ts = tscope('Requisition.loanEditor');

export const useController = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = useLoanDataQuery();

  const loanInformation = data?.activeRequisition?.loanInformation;

  const requisitionConfig = data?.user.requisitionConfig;

  const paymentTerms = requisitionConfig?.paymentTerms || [];

  const maximumAmount = requisitionConfig?.maximumAmount || 0;

  const minimumAmount = requisitionConfig?.minimumAmount || 0;

  const sortedPurposes = useMemo(
    () => [...(data?.user.requisitionConfig.purposes || [])]
      .sort((a, b) => a.id - b.id),
    [data],
  );

  const [upsertRequisition, { loading: isSubmitting }] = useUpsertRequisitionMutation({
    onCompleted: () => { onClose(); },
  });

  const {
    loanDestination, control, onSubmit,
  } = useUpdateRequisitionForm({
    shouldResetData: !isOpen,
    maximumAmount,
    minimumAmount,
    loanInformation,
    upsertRequisition,
  });

  const displayDetails = loanDestination === PAY_DEBT_ID;

  const requestedAmount = numberToCurrency(loanInformation?.requestedAmount);
  const destinationName = loanInformation?.loanDestination?.name;
  const term = loanInformation?.paymentTerms;

  const title = loanInformation ? `${requestedAmount} - ${ts('months', { count: term })} - ${destinationName}` : '';

  return {
    title,
    isOpen,
    onOpen,
    onClose,
    control,
    onSubmit,
    isSubmitting,
    paymentTerms,
    displayDetails,
    sortedPurposes,
  };
};
