import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

export const resolver = (values) => {
  const { rfc, curp } = values;

  const errors = {
    rfc: Validator(rfc)
      .required()
      .rfc()
      .result(),
    curp: Validator(curp)
      .required()
      .curp()
      .result(),
  };

  return { values, errors: removeNilValues(errors) };
};
