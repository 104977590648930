import { useMemo } from 'react';
import { t } from '@i18n';
import { usePhoneCodes } from '@hooks/form';
import { CATALOG_RELATIONSHIP_IDS } from '@views/Approval/References/constants';

const translateRelationShipById = {
  [CATALOG_RELATIONSHIP_IDS.FAMILY]: t('Common.relationships.family'),
  [CATALOG_RELATIONSHIP_IDS.FRIEND]: t('Common.relationships.friend'),
  [CATALOG_RELATIONSHIP_IDS.COWORKER]: t('Common.relationships.coworker'),
  [CATALOG_RELATIONSHIP_IDS.NEIGHBOR]: t('Common.relationships.neighbor'),
};

const ACCEPTED_COUNTRIES_CODES = ['US', 'MX'];

const buildRelationShipOptions = (catalog, { isFamilyMandatory }) => {
  if (!catalog) return null;
  return catalog
    .filter(({ id }) => (isFamilyMandatory
      ? id === CATALOG_RELATIONSHIP_IDS.FAMILY
      : id !== CATALOG_RELATIONSHIP_IDS.FAMILY))
    .filter(({ id }) => Object.values(CATALOG_RELATIONSHIP_IDS).includes(id))
    .map(({ id }) => ({
      label: translateRelationShipById[id],
      value: id,
    }));
};

const sorting = (a, b) => b.name.localeCompare(a.name);

export const useController = ({
  isFamilyMandatory,
  personalReferences,
}) => {
  const phoneCodes = usePhoneCodes({
    sorting,
    acceptedCountries: ACCEPTED_COUNTRIES_CODES,
  });
  const relationshipOptions = useMemo(() => buildRelationShipOptions(
    personalReferences,
    { isFamilyMandatory },
  ), [isFamilyMandatory, personalReferences]);

  return {
    phoneCodes,
    relationshipOptions,
  };
};
