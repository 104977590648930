import { tscope } from '@i18n/index';

const ts = tscope('Requisition.address');

// Home Ownership ID in the catalogue for rental properties
export const RENT_PROPERTY_ID = 1;

const baseArray = [...Array(10).keys()].slice(1);

const tenYears = { id: 10, name: ts('lengthOfResidence.10yearsOrMore') };

export const LENGTH_OF_RESIDENCE = [
  ...baseArray.reduce(
    (acc, year) => [
      ...acc,
      {
        id: year,
        name: `${ts('lengthOfResidence.years', { count: year })}`,
      }],
    [],
  ),
  tenYears,
];
