import React from 'react';
import { PrimaryLayout } from '@components/layouts';
import { t } from '@i18n';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {
  BasicUpdate,
  Birth,
  Confirm,
  IdentificationUpdate,
} from '@views/Register/Profile';
import { FullscreenLoader } from '@components/FullscreenLoader';
import { PATHS } from './constants';
import { useController } from './hooks/useController';

export const Profile = () => {
  const { loading } = useController();

  if (loading) return <FullscreenLoader />;

  return (
    <PrimaryLayout
      title={t('Register.header.title')}
    >
      <Routes>
        <Route
          index
          element={<BasicUpdate />}
        />
        <Route
          path={PATHS.BIRTH}
          element={<Birth />}
        />
        <Route
          path={PATHS.IDENTIFICATION}
          element={<IdentificationUpdate />}
        />
        <Route
          path={PATHS.CONFIRM}
          element={<Confirm />}
        />
        <Route
          path="*"
          element={<Navigate to="./" replace />}
        />
      </Routes>
    </PrimaryLayout>
  );
};
