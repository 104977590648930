import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo/';

export const UPDATE_EMAIL_GQL = gql`
mutation UPDATE_EMAIL ($input: UpdateEmailMutationInput!) {
  updateEmail(input: $input) {
    user {
      id
      email
    }
  }
}
`;

export const useUpdateEmailMutation = (options) => useMutation(UPDATE_EMAIL_GQL, options);
