import PropTypes from 'prop-types';
import {
  CheckIcon,
  SmallCloseIcon,
  QuestionOutlineIcon,
} from 'ui';

const types = {
  success: {
    icon: CheckIcon,
    color: 'green.500',
  },
  error: {
    icon: SmallCloseIcon,
    color: 'red.500',
  },
  default: {
    icon: QuestionOutlineIcon,
    color: 'gray.500',
  },
};

export const keyList = Object.keys(types);

export const propTypes = PropTypes.oneOf(keyList);

export default types;
