import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import resolver from '../resolver';

const parseRequisition = (requisition) => {
  const {
    requestedAmount,
    loanDestination,
    paymentTerms,
  } = requisition;
  return {
    loanInformation: {
      loanDestination,
      paymentTerms: Number(paymentTerms),
      requestedAmount: Number(requestedAmount),
    },
  };
};

export const useUpdateRequisitionForm = ({
  shouldResetData,
  maximumAmount,
  minimumAmount,
  loanInformation,
  upsertRequisition,
}) => {
  const {
    control, handleSubmit, reset, watch,
  } = useForm(
    {
      resolver,
      context: {
        maximumAmount,
        minimumAmount,
      },
      defaultValues: {
        requestedAmount: '',
        loanDestination: '',
        paymentTerms: '',
      },
    },
  );

  const loanDestination = watch('loanDestination');

  const submitHandler = (requisition) => upsertRequisition({
    variables: {
      input: {
        requisition: parseRequisition(requisition),
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!loanInformation || !shouldResetData) return;

    reset({
      requestedAmount: loanInformation?.requestedAmount,
      loanDestination: loanInformation?.loanDestination?.id || '',
      paymentTerms: String(loanInformation.paymentTerms || ''),
    });
  }, [loanInformation, reset, shouldResetData]);

  return {
    control,
    onSubmit,
    loanDestination,
  };
};
