import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query BASIC_USER_DATA {
    user {
      id
      rfc
      fullName
      birthDate
      gender
      roleName
      cellPhoneNumber
      flags {
        id
        isPersonConfirmed
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
