import { EMPLOYMENTS } from '@config/constants';
import { tscope } from '@i18n';

export const ts = tscope('Requisition.employment');

export const EmploymentFormTitle = {
  [EMPLOYMENTS.SELF_EMPLOYEE]: ts('titleSelfEmployee'),
  [EMPLOYMENTS.OWNER]: ts('titleOwner'),
  [EMPLOYMENTS.RETIRED]: ts('titleRetired'),
  default: ts('title'),
};

export const companyNameLabel = {
  [EMPLOYMENTS.EMPLOYEE]: ts('companyNameLabel'),
  [EMPLOYMENTS.SELF_EMPLOYEE]: ts('companyNameSelfEmployeeLabel'),
  [EMPLOYMENTS.OWNER]: ts('companyNameOwnerLabel'),
  default: ts('companyNameLabel'),
};
