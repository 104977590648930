import { useMemo } from 'react';
import { t } from '@i18n';
import { useUserQuery } from './graphql';
import { useCreditForm } from './useCreditForm';

const buildInputOptions = (config) => {
  if (!config) return ({ termsOptions: [], purposesOptions: [] });
  const { paymentTerms, purposes: purposesConfig } = config;
  return {
    termsOptions: paymentTerms.map((term) => ({ label: t('Common.months', { count: term }), value: term })),
    purposesOptions: purposesConfig.map((purpose) => ({ label: purpose.name, value: purpose.id })),
  };
};

export const useController = () => {
  const { data, loading: isDataLoading } = useUserQuery();
  const requisitionConfig = data?.user?.requisitionConfig;
  const activeRequisition = data?.activeRequisition;
  const creditInformation = activeRequisition?.loanInformation;
  const {
    control,
    onSubmit,
    reasonValue,
    isLoading: isFormLoading,
  } = useCreditForm({
    creditInformation,
    activeRequisition,
    creditConfig: {
      minimumAmount: requisitionConfig?.minimumAmount,
      maximumAmount: requisitionConfig?.maximumAmount,
    },
  });
  const {
    termsOptions,
    purposesOptions,
  } = useMemo(() => buildInputOptions(requisitionConfig), [requisitionConfig]);
  const displayReasonNote = !!reasonValue;
  const isLoading = isDataLoading || isFormLoading;

  return ({
    control,
    onSubmit,
    isLoading,
    termsOptions,
    purposesOptions,
    displayReasonNote,
    requisitionId: activeRequisition?.portfolioSystemId,
  });
};
