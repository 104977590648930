import { useForm } from 'react-hook-form';
import { useCustomToast } from 'ui';
import { tscope } from '@i18n';
import { useUpdateEmailMutation } from './useUpdateEmailMutation';
import resolver from '../utils/resolver';

const ts = tscope('Register.emailConfirm');

const CHANGE_EMAIL_SUCCESS_ALERT = {
  description: ts('toast.success'),
  status: 'success',
};

export const useController = ({
  onChangeEmailSuccess,
}) => {
  const toast = useCustomToast();

  const [changeEmail, { loading: isSubmitting }] = useUpdateEmailMutation({
    onCompleted: () => {
      onChangeEmailSuccess();
      toast(CHANGE_EMAIL_SUCCESS_ALERT);
    },
  });

  const { control, handleSubmit } = useForm({
    resolver,
    defaultValues: { email: '' },
  });

  const submitHandler = handleSubmit(({ email }) => changeEmail({
    variables: {
      input: {
        email,
      },
    },
  }));

  return {
    control,
    submitHandler,
    isSubmitting,
  };
};
