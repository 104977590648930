import { createGlobalStyle } from 'styled-components';
import PasswordFontTTF from '@assets/fonts/Password.ttf';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: proxima-nova;
  }

  #root {
    margin: 0;
    height: 100%;
  }

  @font-face {
    font-family: "Password";
    src: url(${PasswordFontTTF}) format("truetype");
  }
`;

export default GlobalStyle;
