import React, { createContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { RadioButtonGroup } from 'ui';
import Base from '../Base';

export const radioButtonsFieldContext = createContext(null);

function RadioButtonField(props) {
  const {
    id,
    name,
    control,
    variant,
    children,
    ...contentProps
  } = props;

  const {
    field: {
      value, onChange, onBlur, ref,
    },
  } = useController({ name, control });

  const radioButtonsFieldValue = useMemo(() => ({
    control, name, value,
  }), [control, name, value]);

  const handleChange = useCallback((ev) => {
    onBlur();
    onChange(ev);
  }, [onBlur, onChange]);

  return (
    <Base id={id} control={control} name={name} {...contentProps}>
      <radioButtonsFieldContext.Provider value={radioButtonsFieldValue}>
        <RadioButtonGroup
          id={`${id}-group`}
          ref={ref}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          variant={variant}
        >
          {children}
        </RadioButtonGroup>
      </radioButtonsFieldContext.Provider>
    </Base>
  );
}

RadioButtonField.defaultProps = {
  control: null,
  children: null,
};

RadioButtonField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}),
  children: PropTypes.node,
};

export default RadioButtonField;
