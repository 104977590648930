import { useEffect } from 'react';
import { fullPath } from '@config';
import { PATHS } from '@navigators/Root/App/constants';
import { useLocation } from './useLocation';
import { useUserCookie } from '../storage';

const IGNORE_PATHS = [
  fullPath(),
  '/',
  PATHS.PASSWORD_RECOVERY,
];
export const COOKIE_NAME = 'last_location';
export const COOKIE_EXPIRATION_DAYS = 30;

export const useLastLocation = ({ userId }) => {
  const [lastLocation] = useUserCookie({
    userId,
    name: COOKIE_NAME,
    days: COOKIE_EXPIRATION_DAYS,
  });

  return lastLocation;
};

export const useTrackLastLocation = ({ userId }) => {
  const { location, pathname } = useLocation();
  const ignore = IGNORE_PATHS.includes(pathname);
  const currentLocation = ignore ? null : location;

  const [, setLastLocation] = useUserCookie({
    userId,
    name: COOKIE_NAME,
    days: COOKIE_EXPIRATION_DAYS,
  });

  useEffect(() => {
    if (currentLocation) setLastLocation(currentLocation);
  }, [currentLocation, setLastLocation]);
};
