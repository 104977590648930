import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from '@components';
import {
  Grid,
  Box,
  Center,
} from 'ui';

function TwoSides(props) {
  const {
    leftSide,
    rightSide,
    rightSideProps,
    bgImageLeftSideSrc,
    bgImageRightSideSrc,
    enableScrollYRightSide,
  } = props;

  const bgImageLeftSide = bgImageLeftSideSrc ? `url(${bgImageLeftSideSrc})` : null;
  const bgImageRightSide = bgImageRightSideSrc ? `url(${bgImageRightSideSrc})` : null;

  return (
    <Grid
      templateColumns={{ base: '100%', md: '40% 60%' }}
      templateRows={{
        base: 'unset', sm: '83% 17%', md: '77% 23%', xl: '83% 17%',
      }}
      gap={0}
      height="100%"
      overflow={{ base: 'auto', md: 'hidden' }}
    >
      <Box
        display={{ base: 'none', md: 'block' }}
        maxHeight="100vh"
        bgImage={bgImageLeftSide}
        bgPosition="center"
        bgSize="cover"
        py="4rem"
        px="2rem"
        gridArea="1/1/3/-2"
      >
        {leftSide}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        minHeight="100vh"
        overflowY="scroll"
      >
        <Center
          flexGrow="1"
          flexDirection="column"
          height="auto"
          justifyContent={enableScrollYRightSide ? 'flex-start' : 'center'}
          padding={{ base: '2rem', md: '4rem 2rem' }}
          bgImage={{ base: bgImageRightSide, md: '' }}
          bgPosition="top"
          bgRepeat="no-repeat"
          bgSize="contain"
          {...rightSideProps}
        >
          {rightSide}
        </Center>
        <Footer />
      </Box>
    </Grid>
  );
}

TwoSides.defaultProps = {
  leftSide: null,
  rightSide: null,
  bgImageLeftSideSrc: '',
  bgImageRightSideSrc: '',
  enableScrollYRightSide: false,
};

TwoSides.propTypes = {
  leftSide: PropTypes.node,
  rightSide: PropTypes.node,
  bgImageLeftSideSrc: PropTypes.string,
  bgImageRightSideSrc: PropTypes.string,
  enableScrollYRightSide: PropTypes.bool,
};

export default TwoSides;
