export const styles = {
  imageBox: {
    top: '0',
    left: '0',
    right: '0',
    position: 'absolute',
    height: { base: '64', md: '240px' },
    zIndex: 'hide',
  },
  image: {
    width: '100%',
    height: '100%',
    bgColor: 'blue.100',
    rightCircleOpacity: '1',
    rightCircleColor: 'green.500',
  },
  container: {
    gap: 5,
    width: '100%',
    zIndex: 'base',
    alignItems: 'start',
    alignContent: 'start',
    justifyItems: 'center',
    gridTemplateRows: 'auto auto auto',
    paddingX: 3,
  },
  title: {
    maxWidth: 'xl',
    textAlign: 'center',
  },
  titlePadding: {
    maxWidth: 'xl',
    textAlign: 'center',
    paddingTop: 9,
  },
  subtitle: {
    maxWidth: 'md',
    textAlign: 'center',
    bgColor: { base: 'blue.100', md: 'transparent' },
    borderRadius: 'md',
    fontSize: 'lg',
  },
};
