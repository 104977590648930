import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui';
import { useController } from 'react-hook-form';
import Base from '../Base';

const defaultOnChange = (value) => value;

function SelectField(props) {
  const {
    name,
    size,
    variant,
    control,
    children,
    iconColor,
    inputProps,
    isDisabled,
    placeholder,
    defaultValue,
    'data-testid': dataTestId,
    onChange: onChangeValue = defaultOnChange,
    ...contentProps
  } = props;
  const {
    field: {
      ref,
      value,
      onBlur,
      onChange,
    },
    fieldState: { error },
  } = useController({ name, control, defaultValue });
  const onChangeHandler = (ev) => {
    onChangeValue(ev);
    onChange(ev);
  };

  const testid = dataTestId ? `${dataTestId}-input` : null;

  return (
    <Base
      control={control}
      name={name}
      {...contentProps}
    >
      <Select
        name={name}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlur}
        isInvalid={!!error}
        borderColor="gray.400"
        errorBorderColor="red.500"
        isDisabled={isDisabled}
        placeholder={placeholder}
        size={size}
        variant={variant}
        borderRadius="xl"
        iconColor={iconColor}
        ref={ref}
        data-testid={testid}
        {...inputProps}
      >
        {children}
      </Select>
    </Base>
  );
}

SelectField.defaultProps = {
  placeholder: '',
  iconColor: 'gray.500',
  'data-testid': '',
  children: null,
  inputProps: {},
  size: 'md',
};

SelectField.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  inputProps: PropTypes.shape({}),
  placeholder: PropTypes.string,
  iconColor: PropTypes.string,
  'data-testid': PropTypes.string,
};

export default SelectField;
