import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_STATUS } from './constants';

const initialState = {
  status: SERVICE_STATUS.ACTIVATED,
  checkedStatusAt: null,
};

export const apiStatusSlice = createSlice({
  name: 'apiStatus',
  initialState,
  reducers: {
    setServiceUp(state) {
      return {
        ...state,
        status: SERVICE_STATUS.ACTIVATED,
      };
    },
    setUnavailableService(state) {
      return {
        ...state,
        status: SERVICE_STATUS.UNAVAILABLE,
        checkedStatusAt: new Date().getTime(),
      };
    },
  },
});

export const {
  setServiceUp,
  setUnavailableService,
} = apiStatusSlice.actions;

export const selectIsUnavailableService = (state) => state
  .apiStatus
  .status === SERVICE_STATUS.UNAVAILABLE;

export const selectCheckedStatusAt = (state) => state
  .apiStatus
  .checkedStatusAt;
