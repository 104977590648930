import { useEffect } from 'react';
import { useLocation } from './useLocation';

export const useLocationEvents = ({ onLocationChange, onPathnameChange }) => {
  const { location, pathname } = useLocation();

  useEffect(() => {
    if (onLocationChange) onLocationChange(location);
  }, [onLocationChange, location]);

  useEffect(() => {
    if (onPathnameChange) onPathnameChange(pathname);
  }, [onPathnameChange, pathname]);
};
