import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const COUPON_QUERY = gql`
  query GET_COUPON {
      user {
        id
        metaData {
          coupon
        }
      }
  }
`;

export const useCouponQuery = (options) => useQuery(COUPON_QUERY, options);
