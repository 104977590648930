import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {
  Box,
  Icon,
  Text,
} from 'ui';

const Indicator = () => (
  <Icon viewBox="0 0 200 200" color="gray.400">
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

export const Testimonials = ({ list, ...props }) => (
  <Box
    as={Slide}
    sx={{ '.indicators': { gap: '0.5rem' }, '.indicators .active': { color: 'blue.500' } }}
    arrows={false}
    transitionDuration={50}
    indicators={Indicator}
    defaultIndex={0}
    textAlign="center"
    {...props}
  >
    {list.map(({ name, description, location }) => (
      <Box key={name}>
        <Text mb="2.8rem">
          {description}
        </Text>
        <Text fontWeight="bold">
          {name}
          {' - '}
          <Text as="span" fontWeight="normal">{location}</Text>
        </Text>
      </Box>
    ))}
  </Box>
);

Testimonials.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
