import { removeNilValues } from '@lib/Object';
import Validator from '@lib/Validator';

export const resolver = (values) => {
  const {
    password,
  } = values;

  return {
    values,
    errors: removeNilValues({
      password: Validator(password).required().result(),
    }),
  };
};
