import { useActiveRequisition } from './graphql';

export const useController = () => {
  const { data } = useActiveRequisition();
  const buroErrorCode = data?.activeRequisition?.buroErrorCode;

  return {
    buroErrorCode,
  };
};
