import { useRef } from 'react';
import { useScrollToTop } from '@components/ScrollToTop';
import { useUserQuery } from './graphql';
import { useConfig } from './useConfig';

export const useController = () => {
  const contentRef = useRef(null);
  const { data } = useUserQuery();
  const username = data?.user?.firstName;
  const config = useConfig();

  useScrollToTop(contentRef);

  return {
    username,
    contentRef,
    ...config,
  };
};
