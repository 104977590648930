import { ONE_MINUTE, ONE_SECOND } from '@lib/durations';
import { REQUISITION, USER } from './constants';
import pkg from '../../package.json';
import { getDefaultLang } from './i18n';

const env = { ...process.env };

// ENVIRONMENT TAGS
export const isTesting = env.NODE_ENV === 'test';
export const isStaging = env.NODE_ENV === 'staging';
export const isProduction = env.NODE_ENV === 'production';
export const isDevelopment = env.NODE_ENV === 'development';

export const getSubDirectory = () => env.PUBLIC_URL || '';

export const getApiUrl = () => env.REACT_APP_API_URL;

export const getZipApiUrl = () => env.REACT_APP_ZIP_URL;

export const getAppSubdomain = () => env.REACT_APP_APP_SUBDOMAIN;

export const getAppUrl = () => window.location.origin;

export const getAppHost = () => window.location.hostname || window.location.host;

export const getAppVersion = () => pkg.version;

export const getAppName = () => 'web';

export const getSessionConfig = () => ({
  ttl: 5 * ONE_MINUTE,
  alertBefore: ONE_MINUTE,
});

export const getAppDomain = () => {
  const host = getAppHost() || '';
  const components = host.split('.');
  return components.slice(-2).join('.');
};

export const getCookiesDomain = () => (isDevelopment ? 'localhost' : `.${getAppDomain()}`);

export const getCookiesSameSite = () => (isDevelopment ? 'none' : 'lax');

const sessionLengthMinutes = () => (env.REACT_APP_SESSION_EXPIRY_MINUTES || 200) * ONE_MINUTE;

export const getSessionCookieExpiry = () => (new Date(
  new Date().getTime() + sessionLengthMinutes(),
));

export const REMEMBER_USER_EXPIRY = 365;

export const fullPath = (path = '') => {
  const dir = getSubDirectory();
  const root = dir ? `/${dir}/` : '/';
  const parsed = path.charAt(0) === '/' ? path.substring(1) : path;
  return `${root}${parsed}`;
};

export const buildAppUri = (path = '') => `${getAppUrl()}${fullPath(path)}`;

export const getBorrowersAppUrl = () => env.REACT_APP_BORROWER_URL;

export const getBorrowersHomeUrl = () => `${getBorrowersAppUrl()}/user/profile`;

export const getRequiredPasswordUpdateUrl = () => `${getBorrowersAppUrl()}/update_password`;

export const getInvestorsHomeUrl = () => env.REACT_APP_INVESTOR_HOME_URL;

export const getGoogleTagManagerId = () => env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const getDefaultPhoneCode = () => '52';

export const getSnowplowConfig = () => ({
  appId: env.REACT_APP_SNOWPLOW_APP_ID,
  endpoint: env.REACT_APP_SNOWPLOW_ENDPOINT,
  cookieDomain: getCookiesDomain(),
});

export const getPusherConfig = () => ({
  key: env.REACT_APP_PUSHER_KEY,
  cluster: env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${getApiUrl()}/v2/pusher/auth`,
});

export const getSentryConfig = () => ({
  dsn: env.REACT_APP_SENTRY_DSN,
  environment: env.NODE_ENV,
  tracesSampleRate: isProduction ? 0.1 : 1.0,
  release: `${pkg.name}@${pkg.version}`,
});

export const getZendeskConfig = () => ({
  investorsKey: env.REACT_APP_ZENDESK_INVESTORS_KEY,
  borrowersKey: env.REACT_APP_ZENDESK_BORROWERS_KEY,
  zIndex: 1000,
});

export const documentAttributes = {
  lang: getDefaultLang(),
};

export const getValidUserAges = ({ role }) => ({
  min: 18,
  max: role === USER.ROLES.BORROWER ? 65 : 99,
});

export const POLLING_SERVICE_STATUS = 30 * ONE_SECOND;

export const UNAVAILABLE_SERVICE_STATUS = 503;

export const ZIP_CODE_LENGTH = 5;

export const ORIGIN_COUNTRY_ID_DEFAULT = 'MX';

export const getIsRequisitionStatusHandled = (status) => {
  if (!status) return null;
  return Object
    .entries(REQUISITION.STATUS)
    .some(([, statusRequisition]) => statusRequisition === status);
};

export const getIsUserStatusHandled = (status) => {
  if (!status) return null;
  return Object
    .values(USER.STATUS_BORROWER)
    .some((statusUser) => statusUser === status);
};
