import React from 'react';
import { tscope } from '@i18n/index';
import {
  ProgressList,
  ProgressItem,
} from '@components/ProgressList';
import { PreApprovalContainer } from '../components';
import { useController } from './hooks';

export const ts = tscope('Requisition.buroSteps');
export const BuroAnalysis = () => {
  const { itemsList } = useController();

  return (
    <PreApprovalContainer
      title={ts('title')}
    >
      <ProgressList>
        {itemsList.map((item) => (
          <ProgressItem
            key={item.id}
            data-testid={`step-${item.id}`}
            state={item.state}
            stepText={item.stepText}
          />
        ))}
      </ProgressList>
    </PreApprovalContainer>
  );
};
