import { STEPS_STATES } from '@components/ProgressList';
import { REQUISITION } from '@config/constants';
import { tscope } from '@i18n/index';

export const ts = tscope('Requisition.buroSteps');

export const getListStateForBuroAnalyzing = (requisitionStatus) => {
  const states = {
    [REQUISITION.STATUS.BURO]: [
      { id: '1', state: STEPS_STATES.IN_PROGRESS, stepText: ts('score') },
      { id: '2', state: STEPS_STATES.INITIAL, stepText: ts('report') },
      { id: '3', state: STEPS_STATES.INITIAL, stepText: ts('analysis') },
    ],
    [REQUISITION.STATUS.BURO_REPORT]: [
      { id: '1', state: STEPS_STATES.COMPLETED, stepText: ts('score') },
      { id: '2', state: STEPS_STATES.IN_PROGRESS, stepText: ts('report') },
      { id: '3', state: STEPS_STATES.INITIAL, stepText: ts('analysis') },
    ],
    [REQUISITION.STATUS.YODA]: [
      { id: '1', state: STEPS_STATES.COMPLETED, stepText: ts('score') },
      { id: '2', state: STEPS_STATES.COMPLETED, stepText: ts('report') },
      { id: '3', state: STEPS_STATES.IN_PROGRESS, stepText: ts('analysis') },
    ],
  };
  return states[requisitionStatus] || [];
};
