import React from 'react';
import { Icon } from 'ui';

export const UmbrellaIcon = (props) => (
  <Icon viewBox="0 0 21 21" {...props}>
    <mask id="mask0_1923_1144" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
      <path d="M2.55519 9.34888C3.04819 5.40888 6.42019 2.34888 10.4922 2.34888C14.5652 2.34888 17.9372 5.40888 18.4302 9.34888H2.55519ZM10.4922 0.348877C4.97919 0.348877 0.492188 4.83488 0.492188 10.3489C0.492188 10.9019 0.940187 11.3489 1.49319 11.3489H9.49219V17.3489C9.49219 19.0029 10.8382 20.3489 12.4932 20.3489C14.1472 20.3489 15.4932 19.0029 15.4932 17.3489C15.4932 16.7959 15.0452 16.3489 14.4932 16.3489C13.9402 16.3489 13.4932 16.7959 13.4932 17.3489C13.4932 17.9009 13.0442 18.3489 12.4932 18.3489C11.9412 18.3489 11.4932 17.9009 11.4932 17.3489V11.3489H19.4922C20.0452 11.3489 20.4922 10.9019 20.4922 10.3489C20.4922 4.83488 16.0062 0.348877 10.4922 0.348877Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1923_1144)">
      <path d="M2.55519 9.34888C3.04819 5.40888 6.42019 2.34888 10.4922 2.34888C14.5652 2.34888 17.9372 5.40888 18.4302 9.34888H2.55519ZM10.4922 0.348877C4.97919 0.348877 0.492188 4.83488 0.492188 10.3489C0.492188 10.9019 0.940187 11.3489 1.49319 11.3489H9.49219V17.3489C9.49219 19.0029 10.8382 20.3489 12.4932 20.3489C14.1472 20.3489 15.4932 19.0029 15.4932 17.3489C15.4932 16.7959 15.0452 16.3489 14.4932 16.3489C13.9402 16.3489 13.4932 16.7959 13.4932 17.3489C13.4932 17.9009 13.0442 18.3489 12.4932 18.3489C11.9412 18.3489 11.4932 17.9009 11.4932 17.3489V11.3489H19.4922C20.0452 11.3489 20.4922 10.9019 20.4922 10.3489C20.4922 4.83488 16.0062 0.348877 10.4922 0.348877Z" fill="currentColor" />
    </g>
  </Icon>
);
