import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const signInBorrower = (builder) => ({
  signInBorrower: builder.mutation({
    query: (credentials) => ({
      url: endpoints.borrower.signInWithToken.url,
      method: endpoints.borrower.signInWithToken.method,
      body: credentials,
    }),
    transformResponse,
  }),
});

export default signInBorrower;
