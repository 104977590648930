import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const validateEmail = (builder) => ({
  validateEmail: builder.query({
    query: ({ email }) => ({
      url: endpoints.users.emailValidation.url,
      method: endpoints.users.emailValidation.method,
      params: { email },
      validateStatus: ({ status }) => status !== 204 && status >= 200 && status <= 299,
    }),
    transformResponse,
  }),
});

export default validateEmail;
