import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_INCOME = gql`
  query GET_INCOME {
    activeRequisition {
      id
      income {
        id
        income
        otherIncome
      }
    }
  }
`;

export const useGetIncomeQuery = (options = {}) => useQuery(GET_INCOME, {
  ...options,
});
