import { useForm } from 'react-hook-form';
import { getDefaultPhoneCode } from '@config';
import { useEffect } from 'react';
import { usePhoneCodes } from '@hooks/form';
import { resolver } from './utils/resolver';
import { CATALOG_RELATIONSHIP_IDS } from '../constants';

const defaultValues = {
  id: '',
  name: '',
  firstLastName: '',
  secondLastName: '',
  relationship: '',
  phoneNumber: '',
  phoneCode: getDefaultPhoneCode(),
};

const buildReference = (reference) => {
  const { relationship, secondLastName } = reference;
  const isFamily = relationship === CATALOG_RELATIONSHIP_IDS.FAMILY;

  const referenceOutput = {
    id: reference.id,
    firstName: reference.name,
    lastName: reference.firstLastName,
    phoneNumber: reference.phoneNumber,
    phoneCode: reference.phoneCode,
    isFamily,
  };
  if (!isFamily) referenceOutput.referenceTypeId = relationship;
  if (secondLastName) referenceOutput.mothersLastName = secondLastName;

  return referenceOutput;
};

const buildReferencesPayload = (values) => {
  const { reference1, reference2, reference3 } = values;

  const references = [
    buildReference(reference1),
    buildReference(reference2),
    buildReference(reference3),
  ];
  return { variables: { references } };
};

export const useReferencesForm = ({
  t,
  references,
  userPhoneNumber,
  updateReferencesMutation,
}) => {
  const phoneCodes = usePhoneCodes();
  const {
    reset,
    control,
    trigger,
    handleSubmit,
  } = useForm({
    resolver,
    mode: 'onChange',
    context: {
      t,
      userPhoneNumber,
      phoneCodeValidations: phoneCodes.validations,
    },
    defaultValues: {
      reference1: defaultValues,
      reference2: defaultValues,
      reference3: defaultValues,
    },
  });

  const submitHandler = (values) => {
    const payload = buildReferencesPayload(values);
    updateReferencesMutation(payload);
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!references) return;
    const [reference1, reference2, reference3] = references;
    reset({
      reference1: {
        ...defaultValues,
        id: reference1.id,
        relationship: CATALOG_RELATIONSHIP_IDS.FAMILY,
      },
      reference2: {
        ...defaultValues,
        id: reference2.id,
        relationship: CATALOG_RELATIONSHIP_IDS.FAMILY,
      },
      reference3: {
        ...defaultValues,
        id: reference3.id,
      },
    });
  }, [reset, references]);

  return {
    control,
    trigger,
    onSubmit,
  };
};
