import * as validations from './validators';

function Validator(value, errors = []) {
  const validate = (validation) => (...options) => (
    Validator(value, [...errors, validation(value, ...options)])
  );

  const result = () => errors.filter(Boolean)[0];
  const results = () => errors.filter(Boolean);

  const extraValidations = Object.entries(validations).reduce((acc, [name, validator]) => ({
    ...acc,
    [name]: validate(validator),
  }), {});

  return {
    result,
    results,
    ...extraValidations,
  };
}

export default Validator;
