import React from 'react';
import { t } from '@i18n';
import { Box, Text } from 'ui';
import {
  TextField,
  RadioButton,
  SelectField,
  TextAreaField,
  RadioButtonField,
} from '@components/Form/Field';
import { ts } from './constants';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';

export const ConfirmRequisition = () => {
  const {
    control,
    onSubmit,
    setFocusOn,
    setFocusOff,
    isSubmitting,
    paymentTerms,
    sortedPurposes,
    isLoadingCatalog,
    previewPersonalInformationDisclaimer,
  } = useController();

  return (
    <CreditForm
      title={ts('title')}
      onSubmit={onSubmit}
      loading={isSubmitting}
      showWidget
    >
      <TextField
        type="currency"
        name="requestedAmount"
        maxLength={12}
        data-testid="amountField"
        control={control}
        labelText={ts('amountLabel')}
      />
      <RadioButtonField
        name="paymentTerms"
        data-testid="termField"
        w="100%"
        variant="inline"
        control={control}
        labelText={ts('termLabel')}
      >
        {
          paymentTerms.map((term) => (
            <RadioButton
              flexGrow="1"
              value={term}
              key={term}
            >
              {term}
            </RadioButton>
          ))
        }
      </RadioButtonField>
      <SelectField
        labelText={ts('destination.label')}
        control={control}
        name="loanDestination"
        data-testid="destinationField"
        placeholder={(isLoadingCatalog
          ? t('Common.loading')
          : ts('destination.placeholder')
        )}
      >
        {sortedPurposes.map(
          (item) => <option value={item.id} key={item.id}>{item.name}</option>,
        )}
      </SelectField>
      <Box>
        <TextAreaField
          name="comment"
          control={control}
          data-testid="detailsField"
          labelText={ts('details.label')}
          placeholder={ts('details.placeholder')}
          noOfLines={2}
          inputHeight="6rem"
          onBlur={setFocusOff}
          onFocus={setFocusOn}
        />
        {previewPersonalInformationDisclaimer && (
          <Text as="i" color="gray.500">{ts('details.textHelper')}</Text>
        )}
      </Box>
    </CreditForm>
  );
};
