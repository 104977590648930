import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { resolver } from '../resolver';

export const useIdentificationForm = ({
  user,
  updateUser,
}) => {
  const { control, handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {
      rfc: '',
      curp: '',
    },
  });

  const submitHandler = ({ rfc, curp }) => updateUser({
    variables: {
      profile: {
        rfc,
        curp,
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!user) return;
    reset({
      rfc: user.rfc || user.suggestedRfc || '',
      curp: user.curp || user.suggestedCurp || '',
    });
  }, [user, reset]);

  return {
    control,
    onSubmit,
  };
};
