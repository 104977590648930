import React from 'react';
import { tscope, toCurrency } from '@i18n';
import { ZIP_CODE_LENGTH } from '@config';
import {
  TextField,
  RadioButton,
  SelectField,
  RadioButtonField,
} from '@components/Form/Field';
import { HStack, Spinner, Text } from 'ui';
import { CURRENCY } from '@config/constants';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';

export const ts = tscope('Requisition.address');

export const Address = () => {
  const {
    onBack,
    control,
    zipError,
    showRent,
    onSubmit,
    zipLoading,
    isSubmitting,
    neighborhoods,
    homeOwnerships,
    lengthOfResidence,
  } = useController();

  return (
    <CreditForm
      onBack={onBack}
      title={ts('title')}
      onSubmit={onSubmit}
      loading={isSubmitting}
      canSubmit={!zipError}
    >
      <Text>{ts('notice')}</Text>
      <TextField
        name="street"
        data-testid="streetField"
        control={control}
        labelText={ts('streetLabel')}
      />
      <HStack alignItems="flex-start">
        <TextField
          control={control}
          name="extNumber"
          data-testid="extNumberField"
          maxLength={10}
          labelText={ts('extNumberLabel')}
        />
        <TextField
          control={control}
          name="intNumber"
          data-testid="intNumberField"
          maxLength={10}
          labelText={ts('intNumberLabel')}
        />
        <TextField
          name="zip"
          type="number"
          data-testid="zipField"
          control={control}
          labelText={ts('zipLabel')}
          maxLength={ZIP_CODE_LENGTH}
        />
      </HStack>
      {zipLoading && <Spinner color="gray.500" mx="auto" my="2rem" />}
      {!zipLoading && neighborhoods && (
        <>
          <SelectField
            labelText={ts('neighborhoodLabel')}
            placeholder={ts('neighborhoodPlaceholder')}
            control={control}
            name="neighborhood"
            data-testid="neighborhoodField"
          >
            {neighborhoods.map(
              (item) => <option value={item} key={item}>{item}</option>,
            )}
          </SelectField>
          <TextField
            control={control}
            name="stateName"
            data-testid="stateNameField"
            labelText={ts('stateNameLabel')}
            isReadOnly
            isDisabled
          />
          <TextField
            control={control}
            name="municipality"
            data-testid="municipalityField"
            labelText={ts('municipalityLabel')}
            isReadOnly
            isDisabled
          />
          <TextField
            control={control}
            name="city"
            data-testid="cityField"
            labelText={ts('cityLabel')}
            isReadOnly
            isDisabled
          />
        </>
      )}

      <SelectField
        labelText={ts('lengthOfResidence.label')}
        control={control}
        name="addressYears"
        data-testid="addressYearsField"
        placeholder={ts('lengthOfResidence.placeholder')}
      >
        {lengthOfResidence.map(
          (item) => <option value={item.id} key={item.name}>{item.name}</option>,
        )}
      </SelectField>
      <RadioButtonField
        name="homeOwnershipId"
        control={control}
        data-testid="homeOwnershipIdField"
        labelText={ts('homeOwnershipIdLabel')}
        w="100%"
      >
        {
          homeOwnerships?.map((type) => (
            <RadioButton
              value={type.id}
              key={type.id}
            >
              {type.name}
            </RadioButton>
          ))
        }
      </RadioButtonField>
      {
        showRent
        && (
          <TextField
            type="currency"
            name="rentAmount"
            data-testid="rentAmountField"
            control={control}
            labelText={ts('rentAmountLabel')}
            maxLength={CURRENCY.LENGTH}
            placeholder={toCurrency(0, { precision: 0 })}
          />
        )
      }
    </CreditForm>
  );
};
