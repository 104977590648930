import { t } from '@i18n';
import { isZipCode } from '@lib/Monads/isZipCode';

function validate(input, message = null) {
  if (isZipCode(input)) return null;

  return {
    type: 'danger',
    message: message || t('FormResolver.zip'),
    value: input,
  };
}

export default validate;
