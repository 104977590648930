import { t } from '@i18n';

function validate(value, { max }, message = t('FormResolver.maxLength', { length: max })) {
  if (!value || (value.length <= max)) return null;

  return {
    type: 'danger',
    message,
  };
}

export default validate;
