import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const APPLY_COUPON = gql`
mutation ($input: ApplyCouponMutationInput!) {
  applyCoupon(input: $input) {
    user {
      id
      metaData {
        coupon
      }
      requisitionConfig {
        maximumAmount
        minimumAmount
        paymentTerms
        id
        purposes {
          id
          name
        }
      }
    }
  }
}
`;

export const useAddCouponMutation = (options) => useMutation(APPLY_COUPON, options);
