import React, { Fragment } from 'react';

const getReplaceableString = (name) => `[missing "{{${name}}}" value]`;

const replaceJsx = (string, params, key = 'main') => {
  if (!string) return [];

  const name = Object.keys(params).find((str) => {
    const replaceable = getReplaceableString(str);
    return string.includes(replaceable);
  });

  if (!name) return [<Fragment key={key}>{string}</Fragment>];

  const [first, second] = string.split(getReplaceableString(name));

  return [
    ...replaceJsx(first, params, `${name}First`),
    <Fragment key={`${name}Value`}>{params[name]}</Fragment>,
    ...replaceJsx(second, params, `${name}Second`),
  ];
};

export const buildJsx = (ts) => (key, params = {}) => {
  const t = params.t || ts;
  const translated = t(key, { count: params.count });
  return (<>{replaceJsx(translated, params)}</>);
};
