import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';
import responseHandler from './responseHandler';

const login = (builder) => ({
  login: builder.mutation({
    query: (credentials) => ({
      url: endpoints.auth.signIn.url,
      method: endpoints.auth.signIn.method,
      body: credentials,
      responseHandler,
    }),
    transformResponse,
  }),
});

export default login;
