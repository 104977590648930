import React from 'react';
import {
  Box,
  Card,
  Divider,
} from 'ui';
import { ReferralPromo, CouponPromo } from './components';
import { useController } from './hooks';

export const PromoCodesWidget = (props) => {
  const { shouldDisplayCoupon } = useController();

  return (
    <Box
      id="promoCodesWidget"
      data-testid="promoCodesWidget"
      {...props}
    >
      <Card
        width="full"
        data-testid="promoCodesWidget-card"
      >
        <ReferralPromo />
        {shouldDisplayCoupon && (
          <>
            <Divider
              borderColor="gray.200"
            />
            <CouponPromo />
          </>
        )}
      </Card>
    </Box>
  );
};
