import { useEffect } from 'react';
import { USER } from '@config/constants';
import { useSignUpMutation } from '@services/yoteprestoAPI';
import { useCoupon, useGclid, useReferralToken } from '@hooks/params';
import { useSearchParams } from 'react-router-dom';
import { currencyParser } from '@lib/CurrencyFormat';
import { useRegisterForm } from './useRegisterForm';
import { parseString, parseRole } from '../../../utils';
import { useRequisitionCatalog } from './useRequisitionCatalog';

const TAB_ORDER = [USER.ROLES.BORROWER, USER.ROLES.INVESTOR];

export const useController = ({ onSuccess }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const role = parseRole(searchParams.get('role'));
  const coupon = useCoupon() || '';
  const gclid = useGclid() || '';
  const referralToken = useReferralToken();
  const term = parseString(searchParams.get('term'));
  const amount = parseString(searchParams.get('amount'));
  const destination = parseString(searchParams.get('destination'));
  const showRoleTab = parseString(searchParams.get('hide-role')) !== 'true';
  const showCreditOptions = parseString(searchParams.get('hide-credit')) !== 'true';

  const [signUp, { isSuccess, error: signUpError, isLoading }] = useSignUpMutation();

  useEffect(() => { if (isSuccess && onSuccess) onSuccess(); }, [isSuccess, onSuccess]);

  const {
    loading: loadingCatalogs,
    data,
  } = useRequisitionCatalog({
    variables: { coupon },
  });

  const { requisitionConfig } = data?.catalogs || {};
  const unsortedPurposes = requisitionConfig?.purposes || [];
  const minimumAmount = requisitionConfig?.minimumAmount || '';
  const maximumAmount = requisitionConfig?.maximumAmount || '';
  const paymentTerms = requisitionConfig?.paymentTerms || [];
  const purposes = [...unsortedPurposes].sort(
    (a, b) => a.name.localeCompare(b.name),
  );

  const parsedMax = currencyParser.format(maximumAmount);
  const parsedMin = currencyParser.format(minimumAmount);

  const onRoleChange = (index) => {
    const roleSelected = parseRole(TAB_ORDER[index]);
    searchParams.set('role', roleSelected);
    setSearchParams(searchParams);
  };

  const roleIndex = TAB_ORDER.indexOf(role);

  const {
    control,
    onSubmit,
    phoneCodes,
    passwordValidation,
  } = useRegisterForm({
    role,
    term,
    gclid,
    amount,
    coupon,
    signUp,
    destination,
    signUpError,
    minimumAmount,
    maximumAmount,
    referralToken,
  });

  return {
    control,
    onSubmit,
    purposes,
    isLoading,
    roleIndex,
    phoneCodes,
    showRoleTab,
    onRoleChange,
    paymentTerms,
    minimumAmount: parsedMin,
    maximumAmount: parsedMax,
    loadingCatalogs,
    showCreditOptions,
    passwordValidation,
  };
};
