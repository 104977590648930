import endpoints from '@config/endpoints';

const generateOTP = (builder) => ({
  generateOTP: builder.mutation({
    query: ({ type }) => ({
      url: endpoints.users.generateOTP.url,
      method: endpoints.users.generateOTP.method,
      params: { type },
    }),
  }),
});

export default generateOTP;
