export default {
  container: {
    margin: 'auto',
    rowGap: '2rem',
    paddingX: '1rem',
    width: { base: 'full', sm: '28rem' },
  },
  title: {
    fontSize: '2xl',
    color: 'gray.700',
    textAlign: 'center',
  },
  card: {
    p: { base: '1rem', md: '2rem' },
    gridRowGap: '1rem',
  },
};
