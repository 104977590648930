import React from 'react';
import { tscope } from '@i18n';
import { Grid } from 'ui';
import {
  PhoneField,
  SelectField,
  TextField,
} from '@components/Form/Field';
import { Controller } from 'react-hook-form';
import { useController } from './hooks';
import { styles as s } from './ReferenceFields.styles';

const ts = tscope('Requisition.references');

const getFieldName = (prefix) => ({
  id: `${prefix}.id`,
  name: `${prefix}.name`,
  firstLastName: `${prefix}.firstLastName`,
  secondLastName: `${prefix}.secondLastName`,
  relationship: `${prefix}.relationship`,
  phone: `${prefix}.phone`,
});

export const ReferenceFields = ({
  name,
  control,
  isLoading,
  isFamilyMandatory,
  personalReferences,
  'data-testid': dataTestId,
  ...props
}) => {
  const {
    phoneCodes,
    relationshipOptions,
  } = useController({
    isFamilyMandatory,
    personalReferences,
  });

  return (
    <Grid
      as="fieldset"
      data-testid={dataTestId}
      {...s.container}
      {...props}
    >
      <Controller
        name={getFieldName(name).id}
        control={control}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <TextField
        {...s.nameField}
        type="name"
        control={control}
        isDisabled={isLoading}
        name={getFieldName(name).name}
        labelText={ts('fields.name.label')}
        data-testid={`${dataTestId}.names`}
      />
      <TextField
        {...s.firstLastNameField}
        control={control}
        isDisabled={isLoading}
        name={getFieldName(name).firstLastName}
        labelText={ts('fields.firstLastName.label')}
        type="name"
        data-testid={`${dataTestId}.firstLastName`}
      />
      <TextField
        {...s.secondLastNameField}
        control={control}
        isDisabled={isLoading}
        name={getFieldName(name).secondLastName}
        labelText={ts('fields.secondLastName.label')}
        type="name"
        data-testid={`${dataTestId}.secondLastName`}
      />
      <SelectField
        {...s.relationshipField}
        control={control}
        name={getFieldName(name).relationship}
        placeholder={relationshipOptions?.length === 1 ? null : ts('fields.relationship.placeholder')}
        labelText={ts('fields.relationship.label')}
        data-testid={`${dataTestId}.relationship`}
        isDisabled={isFamilyMandatory || isLoading || !relationshipOptions}
      >
        {relationshipOptions?.map(({ value, label }) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </SelectField>
      <PhoneField
        {...s.phoneField}
        control={control}
        isDisabled={isLoading}
        options={phoneCodes.options}
        name={getFieldName(name).phone}
        data-testid={`${dataTestId}.phone`}
        labelText={ts('fields.phone.label')}
      />
    </Grid>
  );
};
