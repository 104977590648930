import { t } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import asyncIsEmailAvailable from '@lib/asyncValidateEmail';
import simpleMemoize from '@lib/Monads/simpleMemoize';

const asyncIsEmailAvailableMemoized = simpleMemoize(asyncIsEmailAvailable);

async function resolver(values) {
  let errors = {};
  const {
    email,
  } = values;

  const emailError = Validator(email).required().maxLength({ max: 50 }).email()
    .result();

  let asyncEmailError = null;

  if (!emailError) {
    const emailUnavailableError = {
      type: 'danger',
      message: t('Register.emailConfirm.changeEmailField.errors.existing'),
    };
    asyncEmailError = await asyncIsEmailAvailableMemoized(email) ? null : emailUnavailableError;
  }

  errors = {
    email: emailError || asyncEmailError,
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
