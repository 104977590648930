import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigateWithSearch } from '@hooks/navigation';
import { useReactivateAccount } from './useReactivateAccount';
import { useUnlockAccount } from './useUnlockAccount';

const useController = ({ PATHS }) => {
  const navigate = useNavigateWithSearch();
  /**
   * TODO: (@gediez)
   * Search for best way to have navigation function as a stable variable
   * this will allow to use it inside hooks without looping effects
   */
  const navigateRef = useRef(navigate);

  const {
    rememberUser,
  } = useSelector((state) => state.session);

  const refRememberUser = useRef(rememberUser);

  useEffect(() => {
    const initialRoute = refRememberUser.current
      ? PATHS.PASSWORD
      : './';

    navigateRef.current(initialRoute);
  }, [PATHS, refRememberUser]);

  useReactivateAccount();
  useUnlockAccount();
};

export default useController;
