import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import resolver from '../resolver';

const defaultValues = {
  income: '',
  otherIncome: '',
};

export const useUpdateIncomeForm = ({
  incomeData,
  updateIncomeMutation,
}) => {
  const {
    control,
    reset,
    handleSubmit,
  } = useForm(
    {
      resolver,
      defaultValues,
    },
  );
  const income = incomeData?.activeRequisition?.income || null;

  const submitHandler = (values) => updateIncomeMutation({
    variables: {
      input: {
        requisition: {
          income: {
            income: Number(values.income),
            otherIncome: Number(values.otherIncome),
          },
        },
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!income) return;
    const { income: mainIncome, otherIncome } = income;

    reset({
      otherIncome,
      income: mainIncome,
    });
  }, [income, reset]);

  return {
    control,
    onSubmit,
  };
};
