import { t } from '@i18n';

export const formatAddress = (address) => {
  if (!address) return t('Common.na');
  const {
    zip = null,
    street = null,
    extNumber = null,
    intNumber = null,
    neighborhood = null,
    municipality = null,
    state: { name: stateName = null },
  } = address;

  return [
    [street,
      extNumber,
      intNumber,
    ].filter(Boolean).join(' '),
    [
      neighborhood,
      municipality,
      stateName,
    ].filter(Boolean).join(', '),
    `C.P. ${zip}`,
  ].filter(Boolean).join('\n');
};
