import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import { useController } from 'react-hook-form';
import {
  HStack,
  Select,
  FormLabel,
  FormControl,
} from 'ui';
import {
  getDayList,
  getYearList,
  getMonthList,
  countDaysInMonth,
} from '@lib/Date/Calendar';
import Base from '../Base';

const strToValues = (value) => {
  if (!value) return { day: '', month: '', year: '' };
  const [year, month, day] = value.split('-');
  return { day, month, year };
};

const valuesToStr = ({ day, month, year }) => `${year}-${month}-${day}`;

export const DateField = ({
  name,
  control,
  minYear,
  maxYear,
  'data-testid': dataTestId,
  ...contentProps
}) => {
  const {
    field: {
      ref,
      value,
      onBlur,
      onChange,
    },
    fieldState: { error },
  } = useController({ name, control, defaultValue: '' });

  const { day, month, year } = strToValues(value);

  const dayTestId = dataTestId ? `${dataTestId}-day-input` : null;
  const monthTestId = dataTestId ? `${dataTestId}-month-input` : null;
  const yearTestId = dataTestId ? `${dataTestId}-year-input` : null;

  const updateValue = useCallback(({ newDay, newMonth, newYear }) => {
    const daysInMonth = countDaysInMonth(newMonth, newYear);
    const safeDay = !daysInMonth || !newDay ? '' : Math.min(daysInMonth, parseInt(newDay, 10))
      .toString()
      .padStart(2, '0');

    onChange(valuesToStr({ day: safeDay, month: newMonth, year: newYear }));
  }, [onChange]);

  const onDayChange = useCallback((event) => updateValue({
    newDay: event.target.value,
    newMonth: month,
    newYear: year,
  }), [updateValue, month, year]);

  const onMonthChange = useCallback((event) => updateValue({
    newDay: day,
    newMonth: event.target.value,
    newYear: year,
  }), [updateValue, day, year]);

  const onYearChange = useCallback((event) => updateValue({
    newDay: day,
    newMonth: month,
    newYear: event.target.value,
  }), [updateValue, day, month]);

  const daysOptions = useMemo(() => getDayList(month, year).map((index) => (
    <option key={index} value={`${index}`.padStart(2, '0')}>
      {index}
    </option>
  )), [month, year]);

  const monthsOptions = useMemo(() => getMonthList().map(({ name: monthName, id }) => (
    <option key={id} value={id}>
      {monthName}
    </option>
  )), []);

  const yearOptions = useMemo(() => getYearList({ from: minYear, span: (maxYear - minYear) })
    .sort((a, b) => b - a)
    .map((yearOption) => (
      <option key={yearOption} value={yearOption}>
        {yearOption}
      </option>
    )), [minYear, maxYear]);

  return (
    <Base control={control} name={name} data-testid={dataTestId} {...contentProps} labelText={null}>
      <HStack gridColumnGap={{ base: '0.5rem', md: '1rem' }}>
        <FormControl flexGrow="0.25" w="auto">
          <FormLabel>{t('Date.dayField.label')}</FormLabel>
          <Select
            name={`${name}Day`}
            placeholder={t('Date.dayField.placeholder')}
            value={day}
            onChange={onDayChange}
            onBlur={onBlur}
            isInvalid={!!error}
            borderColor="gray.400"
            errorBorderColor="red.500"
            h="3.142rem"
            borderRadius="xl"
            ref={ref}
            data-testid={dayTestId}
          >
            {daysOptions}
          </Select>
        </FormControl>
        <FormControl flexGrow="0.50" w="auto">
          <FormLabel>{t('Date.monthField.label')}</FormLabel>
          <Select
            name={`${name}Month`}
            placeholder={t('Date.monthField.placeholder')}
            value={month}
            onChange={onMonthChange}
            onBlur={onBlur}
            isInvalid={!!error}
            borderColor="gray.400"
            errorBorderColor="red.500"
            h="3.142rem"
            borderRadius="xl"
            ref={ref}
            data-testid={monthTestId}
          >
            {monthsOptions}
          </Select>
        </FormControl>
        <FormControl flexGrow="0.25" w="auto">
          <FormLabel>{t('Date.yearField.label')}</FormLabel>
          <Select
            name={`${name}Year`}
            placeholder={t('Date.yearField.placeholder')}
            value={year}
            onChange={onYearChange}
            onBlur={onBlur}
            isInvalid={!!error}
            borderColor="gray.400"
            errorBorderColor="red.500"
            h="3.142rem"
            borderRadius="xl"
            ref={ref}
            data-testid={yearTestId}
          >
            {yearOptions}
          </Select>
        </FormControl>
      </HStack>
    </Base>
  );
};

DateField.defaultProps = {
  'data-testid': '',
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number.isRequired,
  'data-testid': PropTypes.string,
};
