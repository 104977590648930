import React from 'react';
import { ConfirmPassword } from '@components/Form/ConfirmPassword';
import { t, tscope, jsx } from '@i18n';
import {
  Box,
  Grid,
  Text,
  Button,
  Link,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from 'ui';
import {
  TextField,
  PhoneField,
  SelectField,
  CheckboxField,
} from '@components/Form/Field';
import { useController } from './hooks';

const ts = tscope('Register.accountCreate');

export const CreateAccountForm = React.forwardRef(({ onSuccess, ...props }, ref) => {
  const {
    control,
    onSubmit,
    roleIndex,
    purposes,
    isLoading,
    phoneCodes,
    showRoleTab,
    onRoleChange,
    paymentTerms,
    minimumAmount,
    maximumAmount,
    loadingCatalogs,
    showCreditOptions,
    passwordValidation,
  } = useController({
    onSuccess,
  });

  return (
    <Box
      ref={ref}
      margin="0 1px"
      backgroundColor="white"
      {...props}
    >
      <form onSubmit={onSubmit}>
        <Tabs variant="button-group" index={roleIndex} onChange={onRoleChange}>
          {showRoleTab ? (
            <TabList paddingBottom={{ base: '1rem', md: '2rem' }} data-testid="tabList">
              <Tab>{ts('borrowerSwitchTab')}</Tab>
              <Tab>{ts('investorSwitchTab')}</Tab>
            </TabList>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showCreditOptions ? (
                <>
                  <Grid
                    gridGap="1rem 1.4rem"
                    gridTemplateColumns="repeat(2, 1fr)"
                  >
                    <Text gridColumn="1 / span 2" textAlign="center">{ts('selectText')}</Text>
                    <TextField
                      control={control}
                      name="amount"
                      type="currency"
                      labelText={ts('amountField.labelText')}
                      placeholder={loadingCatalogs ? '' : ts('amountField.placeholder', { minimumAmount, maximumAmount })}
                      gridColumn="1 / span 1"
                      data-testid="amount"
                    />
                    <SelectField
                      control={control}
                      isDisabled={loadingCatalogs}
                      name="term"
                      data-testid="term"
                      labelText={ts('termField.labelText')}
                      gridColumn="2"
                      placeholder={loadingCatalogs ? t('Common.loading') : t('Common.na')}
                    >
                      {paymentTerms.map((term) => (
                        <option key={term} value={term}>{`${term} meses`}</option>
                      ))}
                    </SelectField>
                    <SelectField
                      control={control}
                      isDisabled={loadingCatalogs}
                      labelText={ts('destinationField.labelText')}
                      name="destination"
                      data-testid="destination"
                      gridColumn="1 / span 2"
                      placeholder={loadingCatalogs ? t('Common.loading') : ts('destinationField.placeholder')}
                    >
                      {purposes.map((purpose) => (
                        <option key={purpose.id} value={purpose.id}>
                          {purpose.name}
                        </option>
                      ))}
                    </SelectField>
                  </Grid>
                  <Divider as="hr" bgColor="gray.200" my="2rem" />
                </>
              ) : null}
            </TabPanel>
            <TabPanel />
          </TabPanels>
        </Tabs>
        <Grid
          gridGap="1rem 0"
        >
          <TextField
            control={control}
            name="email"
            data-testid="email"
            labelText={ts('emailField.labelText')}
            placeholder={ts('emailField.placeholder')}
          />
          <PhoneField
            control={control}
            options={phoneCodes.options}
            name="phone"
            data-testid="phone"
            labelText={ts('phoneField.labelText')}
          />
          <ConfirmPassword
            name="password"
            control={control}
            errors={passwordValidation.errors}
            isLoading={passwordValidation.isLoading}
            data-testid="set-password-inputs"
          />
          <CheckboxField
            name="agreeWithTermConditions"
            data-testid="agreeTerms"
            control={control}
            size="lg"
            py="0.7rem"
            checkboxStyle={{ flex: 1, alignItems: 'flex-start' }}
          >
            <Text fontSize="sm">
              {
                jsx('confirmInformationField.description', {
                  t: ts,
                  termsLink: (
                    <Link href={ts('confirmInformationField.terms.link')} target="_blank">
                      {ts('confirmInformationField.terms.label')}
                    </Link>
                  ),
                  privacyLink: (
                    <Link href={ts('confirmInformationField.privacy.link')} target="_blank">
                      {ts('confirmInformationField.privacy.label')}
                    </Link>
                  ),
                })
              }
            </Text>
          </CheckboxField>
          <CheckboxField
            size="lg"
            py="0.7rem"
            name="publicity"
            data-testid="publicityField"
            control={control}
            checkboxStyle={{ flex: 1, alignItems: 'flex-start' }}
          >
            <Text fontSize="sm">
              {ts('publicityField.description')}
            </Text>
          </CheckboxField>
          <Button
            size="lg"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
            colorScheme="green"
          >
            {ts('submit')}
          </Button>
        </Grid>
      </form>
    </Box>
  );
});
