import React from 'react';
import { tscope, jsx } from '@i18n';
import {
  SelectField,
  RadioButton,
  RadioButtonField,
} from '@components/Form/Field';
import {
  Box, Collapse, Grid, Text,
} from 'ui';
import { CreditForm } from '../components/CreditForm';
import { useController } from './hooks';

const ts = tscope('Requisition.work');

export const Work = () => {
  const {
    goBack,
    loading,
    control,
    onSubmit,
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
    displayOccupationDisclaimer,
  } = useController();

  return (
    <CreditForm
      title={ts('title')}
      onBack={goBack}
      onSubmit={onSubmit}
      loading={loading}
    >
      <Grid rowGap="2rem" marginY="2rem">
        <Box>
          <RadioButtonField
            variant="wrap"
            name="employmentId"
            data-testid="Work.occupation"
            control={control}
            mb="0.4rem"
          >
            {occupationOptions.map((occupation) => (
              <RadioButton value={occupation.id} key={occupation.id}>
                {occupation.label}
              </RadioButton>
            ))}
          </RadioButtonField>
          <Collapse
            in={displayOccupationDisclaimer}
            animateOpacity
          >
            <Text color="gray.500" data-testid="employment.disclaimer">
              {jsx('fields.occupation.disclaimer', {
                t: ts,
                disclaimerDocs: <strong>{ts('fields.occupation.disclaimerDocuments')}</strong>,
              })}
            </Text>
          </Collapse>
        </Box>

        <RadioButtonField
          variant="wrap"
          name="workingSectorId"
          data-testid="Work.sector"
          control={control}
          labelText={ts('fields.sector.label')}
        >
          {workingSectorOptions.map((workingSector) => (
            <RadioButton value={workingSector.id} key={workingSector.id}>
              {workingSector.label}
            </RadioButton>
          ))}
        </RadioButtonField>
        <SelectField
          name="workingActivityId"
          control={control}
          data-testid="Work.activity"
          labelText={ts('fields.workingActivity.label')}
          placeholder={ts('fields.workingActivity.label')}
        >
          {workingActivitiesOptions.map((workingActivity) => (
            <option key={workingActivity.id} value={workingActivity.id}>
              {workingActivity.label}
            </option>
          ))}
        </SelectField>
      </Grid>
    </CreditForm>
  );
};
