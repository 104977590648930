import { t } from '@i18n';
import { PASSWORD } from '@config/constants';

export const includesMinChars = ({ password }) => (
  password.length >= PASSWORD.MIN_LENGTH
);

function validate(value, message = t('FormResolver.minLength', { length: PASSWORD.MIN_LENGTH })) {
  if (includesMinChars({ password: value })) return null;

  return {
    type: 'danger',
    message,
    value,
  };
}

export default validate;
