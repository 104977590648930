import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_ADDRESS = gql`
  mutation(
    $input: UpsertRequisitionMutationInput!
    $userInput: UpdateUserMutationInput!
  ) {
    upsertRequisition(input: $input) {
      activeRequisition {
        id
        spending {
          id
          expenses {
            id
            amount
            expenseId
          }
        }
      }
    }
    updateUser(input: $userInput){
      user {
        id
        addressYears
        homeOwnership {
          id
          name
        }
        address {
          street
          extNumber
          intNumber
          zip
          neighborhood
          state {
            id
            name
          }
          municipality
          city
        }
      }
    }
  }
`;

export const useUpdateAddressMutation = (options) => useMutation(UPDATE_ADDRESS, options);
