import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const ADD_REFERENCE = gql`
mutation addReferenceCode ($referral: AddReferenceCodeMutationInput!) {
  addReferenceCode(input: $referral) {
    user {
      id
      referred {
        referenceCode
      }
    }
  }
}
`;

export const useAddReferralCodeMutation = (options) => useMutation(ADD_REFERENCE, options);
