import React from 'react';
import { jsx, t } from '@i18n';
import isEmail from 'validator/lib/isEmail';

const USERNAME_ALLOWED_CHARS_REGEX = /^[a-zA-Z0-9._+-]+$/;

const validateUsername = (email) => {
  const [username] = email.split('@');
  return USERNAME_ALLOWED_CHARS_REGEX.test(username) === true;
};

const defaultMessage = jsx(
  'FormResolver.invalidEmail',
  { chars: <span style={{ fontWeight: 900, fontSize: '1rem', fontStyle: 'italic' }}>{t('FormResolver.validEmailChars')}</span> },
);

function validate(value, message = defaultMessage, options = {}) {
  const { ignoreSpecialChars = false } = options;
  const isValidUsername = ignoreSpecialChars || validateUsername(value);
  if (!value || (isEmail(value) && isValidUsername)) return null;

  const email = value.toLowerCase();

  return {
    type: 'danger',
    message,
    value: email,
  };
}

export default validate;
