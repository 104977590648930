import { useEffect, useCallback } from 'react';
import { useLoginPasswordForm, useLogout } from '@hooks/auth';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/Guest/constants';

export const useController = ({ email, isOpen }) => {
  const logout = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    // When no email, take user to login
    if (!email && isOpen) logout();
  }, [logout, email, isOpen]);

  const onForgotPassword = useCallback(() => {
    logout();
    navigate(PATHS.PASSWORD_RECOVERY);
  }, [logout, navigate]);

  const {
    control,
    onSubmit,
    isLoading,
  } = useLoginPasswordForm({ email });

  return {
    control,
    onSubmit,
    isLoading,
    logout,
    onForgotPassword,
  };
};
