import { t } from '@i18n';
import { USER } from '@config/constants';
import { getZendeskConfig } from '@config';

const createScriptTag = (key, onLoad) => {
  const script = document.createElement('script');
  script.defer = true;
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  script.addEventListener('load', onLoad);
  document.body.appendChild(script);
};

export const init = ({ role }) => {
  const { investorsKey, borrowersKey, zIndex } = getZendeskConfig({ role });
  const key = role === USER.ROLES.INVESTOR ? investorsKey : borrowersKey;
  if (window.zE) return;
  createScriptTag(key, () => {
    if (!window.zE) return;
    window.zE('messenger:set', 'locale', t('Common.chatLocale'));
    window.zE('messenger:set', 'zIndex', zIndex);
  });
};

export const show = () => {
  if (window.zE) window.zE('messenger', 'open');
};

export const hide = () => {
  if (window.zE) window.zE('messenger', 'close');
};
