export default {
  box: {
    width: 'full',
  },
  optional: {
    width: 'full',
    textAlign: 'right',
    marginTop: '0.5rem',
    color: 'gray.500',
  },
};
