import { selectExpiredByAnotherDevice, selectExpiredByInactivity } from '@reducks/session';
import { useSelector } from 'react-redux';

export const useIsSessionActive = () => {
  const expiredByInactivity = useSelector(selectExpiredByInactivity);
  const expiredByAnotherDevice = useSelector(selectExpiredByAnotherDevice);
  const hasExpiredSession = expiredByInactivity || expiredByAnotherDevice;

  return { hasExpiredSession };
};
