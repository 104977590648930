import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { stringify } from '@lib/serializers';
import { isNil } from '@lib/Monads';
import { PAY_FREQUENCY } from '@config/constants/requisition';
import resolver from '../resolver';
import { ts } from '../constants';

const defaultValues = {
  hasImss: '',
  hasSgmm: '',
  companyName: '',
  companyYears: '',
  companyPosition: '',
  hasTaxSituation: '',
  paymentFrequency: '',
  companyPhoneNumber: '',
  companyExtensionNumber: '',
};

export const useUpdateEmploymentForm = ({
  income,
  updateEmploymentMutation,
  isRequiredCompanyName,
  isRequiredPosition,
  isRequiredCompanyYears,
  isRequiredCompanyPhone,
  isRequiredHasTaxSituation,
  isRequiredPaymentFrequency,
}) => {
  const {
    watch,
    reset,
    control,
    handleSubmit,
  } = useForm(
    {
      resolver,
      defaultValues,
      context: {
        isRequiredPosition,
        isRequiredCompanyName,
        isRequiredCompanyYears,
        isRequiredCompanyPhone,
        isRequiredHasTaxSituation,
        isRequiredPaymentFrequency,
      },
    },
  );

  const hasTaxSituation = watch('hasTaxSituation') === 'yes';

  const submitHandler = (values) => updateEmploymentMutation({
    variables: {
      input: {
        requisition: {
          income: {
            hasImss: values.hasImss === 'yes',
            hasSgmm: values.hasSgmm === 'yes',
            companyName: values.companyName,
            companyYears: Number(values.companyYears),
            companyPosition: values.companyPosition,
            companyPhoneNumber: String(values.companyPhoneNumber),
            companyExtensionNumber: stringify(values.companyExtensionNumber),
            paymentFrequency: values.paymentFrequency || PAY_FREQUENCY.MONTHLY,
            hasTaxSituation: values.hasTaxSituation === 'yes',
          },
        },
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  const handleBoolReset = (value) => {
    if (value === true) return 'yes';
    if (value === false) return 'no';
    return '';
  };

  useEffect(() => {
    if (!income) return;
    reset({
      hasImss: handleBoolReset(income?.hasImss),
      hasSgmm: handleBoolReset(income?.hasSgmm),
      companyName: stringify(income?.companyName),
      companyYears: isNil(income?.companyYears) ? '' : income?.companyYears,
      companyPosition: stringify(income?.companyPosition),
      companyPhoneNumber: income?.companyPhoneNumber || '',
      companyExtensionNumber: income?.companyExtensionNumber || '',
      companyPhoneCode: ts('defaultPhoneCode'),
      paymentFrequency: stringify(income?.paymentFrequency),
      hasTaxSituation: handleBoolReset(income?.hasTaxSituation),
    });
  }, [income, reset]);

  return {
    control,
    onSubmit,
    hasTaxSituation,
  };
};
