import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const LOAN_DATA_QUERY = gql`
  query GET_LOAN_DATA {
    user {
      id
      requisitionConfig {
        maximumAmount
        minimumAmount
        paymentTerms
        id
        purposes {
          id
          name
        }
      }
    }
    activeRequisition {
      id
      loanInformation {
        id
        comment
        paymentTerms
        requestedAmount
        loanDestination {
          id
        }
      }
    }
  }
`;

export const useLoanDataQuery = (options) => useQuery(LOAN_DATA_QUERY, options);
