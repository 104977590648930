import React from 'react';
import PropTypes from 'prop-types';
import { usePusherClient, socketContext } from './hooks';

export const SocketProvider = ({ children }) => {
  const pusher = usePusherClient();

  return (
    <socketContext.Provider value={pusher}>
      {children}
    </socketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
