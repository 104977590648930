import { getApiUrl } from '@config';

const buildEndpoint = ({ url, ...attrs }) => ({ ...attrs, url, fullURL: `${getApiUrl()}${url}` });

const endpoints = {
  apiService: {
    status: buildEndpoint({ url: '/status', method: 'GET' }),
  },
  auth: {
    signIn: buildEndpoint({ url: '/auth/sign_in', method: 'POST' }),
    signUp: buildEndpoint({ url: '/auth', method: 'POST' }),
    signOut: buildEndpoint({ url: '/auth/sign_out', method: 'DELETE' }),
    createPassword: buildEndpoint({ url: '/auth/password', method: 'POST' }),
    updatePassword: buildEndpoint({ url: '/auth/password', method: 'PUT' }),
    reactivateAccount: buildEndpoint({ url: '/v1/user_inactives/activate', method: 'POST' }),
    unlockAccount: buildEndpoint({ url: '/auth/unlock', method: 'GET' }),
  },
  users: {
    emailValidation: buildEndpoint({ url: '/v1/validate_identity', method: 'GET' }),
    passwordValidation: buildEndpoint({ url: '/v1/passwords/validate', method: 'POST' }),
    generateOTP: buildEndpoint({ url: '/v2/second_factors/generate', method: 'GET' }),
  },
  public: {
    emailAvailable: buildEndpoint({ url: '/v1/email_available', method: 'GET' }),
  },
  borrower: {
    // This endpoint generates a cookie required to login in borrower's platform.
    signInWithToken: buildEndpoint({ url: '/sign_in_with_token', method: 'POST' }),
  },
};

export default endpoints;
