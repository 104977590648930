import { useEffect } from 'react';
import { useCustomToast } from 'ui';
import { tscope } from '@i18n';
import { useSwitchController } from '../../StateSwitcher/hooks/useSwitchController';
import { useCouponQuery } from './useCouponQuery';
import { useRemoveCouponMutation } from './useRemoveCouponMutation';

const ts = tscope('Register.promos');

const parseCouponCode = (user) => {
  if (!user) return { coupon: null };

  return {
    coupon: user.metaData.coupon || null,
  };
};

const REMOVE_COUPON_SUCCESS_ALERT = {
  status: 'success',
  description: ts('alerts.success.removeCoupon'),
};

const REMOVE_COUPON_FAILED_ALERT = {
  status: 'error',
  description: ts('alerts.error.removeCoupon'),
};

export const useController = () => {
  const toast = useCustomToast();
  const onRemoveCouponComplete = () => {
    toast(REMOVE_COUPON_SUCCESS_ALERT);
  };
  const onRemoveCouponError = () => {
    toast(REMOVE_COUPON_FAILED_ALERT);
  };
  const { data: profile, loading: loadingCoupon } = useCouponQuery();
  const [removeCoupon, { loading }] = useRemoveCouponMutation({
    onCompleted: onRemoveCouponComplete,
    onError: onRemoveCouponError,
  });
  const { coupon } = parseCouponCode(profile?.user);
  const {
    step,
    open,
    complete,
    cancel,
  } = useSwitchController();

  const removeCouponHandler = () => {
    removeCoupon(
      {
        variables: {
          coupon: {},
        },
      },
    );
  };

  useEffect(() => {
    if (coupon) {
      complete();
      return;
    }

    cancel();
  }, [coupon, complete, cancel]);

  return {
    step,
    open,
    cancel,
    coupon,
    loadingCoupon,
    isRemovingCoupon: loading,
    removeCoupon: removeCouponHandler,
  };
};
