import { useForm } from 'react-hook-form';
import { tscope } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { useCustomToast } from 'ui';
import { useAddReferralCodeMutation } from './useAddReferralCodeMutation';

export const INVALID_REFERRAL_CODE_ERROR = 'REFERENCE_NOT_FOUND_ERROR';

const ts = tscope('Register.promos');

const resolver = (values) => {
  const { referral } = values;

  return {
    values,
    errors: removeNilValues({
      referral: Validator(referral).required().result(),
    }),
  };
};

const ADDED_REFERRAL_CODE_SUCCESS_ALERT = {
  status: 'success',
  description: ts('alerts.success.addedReferralCode'),
};

export const useController = () => {
  const toast = useCustomToast();

  const { control, handleSubmit, setError } = useForm({
    resolver,
    defaultValues: { referral: '' },
  });

  const handleInvalidReferralCode = () => setError('referral', {
    type: 'danger',
    message: ts('referral.invalid'),
  });

  const onAddReferralCodeCompleted = () => {
    toast(ADDED_REFERRAL_CODE_SUCCESS_ALERT);
  };
  const [addReferenceCode, {
    loading: addingReferralCode,
  }] = useAddReferralCodeMutation({
    onCompleted: onAddReferralCodeCompleted,
    onErrorCode: {
      [INVALID_REFERRAL_CODE_ERROR]: handleInvalidReferralCode,
    },
  });

  const submitHandler = handleSubmit(({ referral }) => addReferenceCode({
    variables: {
      referral: {
        referenceCode: referral,
      },
    },
  }));

  return {
    control,
    submitHandler,
    isSubmitting: addingReferralCode,
  };
};
