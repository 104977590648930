import React from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {
  UpdatePassword,
  RequireUpdate,
} from '@views/PasswordRecovery';
import { PATHS } from './constants';

export const PasswordRecovery = () => (
  <Routes>
    <Route
      path={PATHS.UPDATE}
      element={<UpdatePassword />}
    />
    <Route
      path={PATHS.REQUIRED}
      element={<RequireUpdate />}
    />
    <Route
      path="*"
      element={<Navigate to="../" replace />}
    />
  </Routes>
);
