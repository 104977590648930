import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import {
  Box,
  Flex,
  Button,
  Heading,
} from 'ui';
import { PromoCodesWidget } from '@components';
import { ReferralCouponWrapper } from '../ReferralCouponWrapper';
import styles from './FunnelForm.styles';

export const FunnelForm = ({
  title,
  subtitle,
  children,
  onSubmit,
  onBack,
  loading,
  ...contentProps
}) => (
  <Flex
    {...contentProps}
    {...styles.container}
  >
    {subtitle && (
      <Heading
        fontSize="1rem"
        color="gray.500"
        fontWeight="normal"
      >
        {subtitle}
      </Heading>
    )}
    {title && (
      <Heading
        fontSize="2xl"
        color="gray.700"
        mt="1rem"
        mb="2rem"
      >
        {title}
      </Heading>
    )}
    <Box
      as="form"
      id="funnel-form"
      onSubmit={onSubmit}
      {...styles.form}
    >
      {children}
    </Box>

    <ReferralCouponWrapper marginTop="4rem">
      <PromoCodesWidget />
    </ReferralCouponWrapper>

    <Flex
      mt="2rem"
      justifyContent="space-between"
      gridColumnGap="2rem"
      width="100%"
    >
      {onBack && (
        <Button
          type="button"
          size="lg"
          variant="ghost"
          onClick={onBack}
          isDisabled={loading}
        >
          {t('Common.goBack')}
        </Button>
      )}
      <Button
        {...styles.submit}
        form="funnel-form"
        size="lg"
        type="submit"
        colorScheme="blue"
        isDisabled={loading}
      >
        {t('Common.continue')}
      </Button>
    </Flex>
  </Flex>
);

FunnelForm.defaultProps = {
  title: null,
  subtitle: null,
  children: null,
  onSubmit: () => { },
  onBack: null,
  loading: false,
};

FunnelForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
};

export default FunnelForm;
