import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_GEO_CATALOG = gql`
query GET_GEO_CATALOG {
  catalogs {
    id
    states {
      id
      name
    }
    countries {
      id
      name
      emoji
      phoneCode
      phoneMaxLength
      phoneMinLength
    }
  }
}
`;

export const useLocationQuery = (options) => useQuery(GET_GEO_CATALOG, {
  context: {
    clientName: 'public',
  },
  ...options,
});
