import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query USER_GQL {
    user {
      id
      requisitionConfig {
        id
        maximumAmount
        minimumAmount
        paymentTerms
        purposes {
          id
          name
        }
      }
    }
    activeRequisition {
      id
      portfolioSystemId
      loanInformation {
        id
        paymentTerms
        requestedAmount
        loanDestination {
          id
          name
        }
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_GQL, options);
