import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import {
  Box,
  Flex,
  Modal,
  Button,
  FormLabel,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  InputGroup,
  FormControl,
  InputRightElement,
} from 'ui';
import { TextField } from '@components/Form/Field';
import { useController } from './hooks';
import styles from './ExpiredSessionModal.styles';

export const ExpiredSessionModal = ({
  email,
  isOpen,
  title,
  description,
  'data-testid': testId,
}) => {
  const {
    control,
    onSubmit,
    isLoading,
    logout,
    onForgotPassword,
  } = useController({ email, isOpen });

  return (
    <Modal
      isOpen={isOpen}
      onClose={logout}
      closeOnOverlayClick={false}
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent data-testid={testId}>
        <Box {...styles.container}>
          <ModalHeader {...styles.header}>
            {title}
          </ModalHeader>
          <form onSubmit={onSubmit}>
            <Box {...styles.inputContainer}>
              <FormControl isRequired>
                <FormLabel>
                  {t('SignIn.expiredSession.label')}
                </FormLabel>
                <InputGroup>
                  <TextField
                    id="password"
                    control={control}
                    name="password"
                    type="password"
                    data-testid="password"
                    placeholder={t('SignIn.expiredSession.placeholder')}
                  />
                  <InputRightElement width="22">
                    <Button
                      size="md"
                      animationType="none"
                      borderTopLeftRadius={0}
                      borderBottomLeftRadius={0}
                      isDisabled={isLoading}
                      onClick={onSubmit}
                    >
                      {t('SignIn.expiredSession.signIn')}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Flex {...styles.buttonContainer}>
                <Button
                  variant="link"
                  colorScheme="blue"
                  onClick={onForgotPassword}
                >
                  {t('SignIn.forgotPassword')}
                </Button>
                <Button
                  ml="1.5rem"
                  variant="link"
                  colorScheme="blue"
                  onClick={logout}
                >
                  {t('Common.logout')}
                </Button>
              </Flex>
            </Box>
          </form>
          <ModalBody {...styles.body}>
            {description}
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  );
};

ExpiredSessionModal.defaultProps = {
  email: null,
  isOpen: false,
  'data-testid': null,
};

ExpiredSessionModal.propTypes = {
  email: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};
