export const shapeVariables = (values) => ({
  variables: {
    input: {
      requisition: {
        spending: {
          hasCar: values.hasCar === 'true',
          dependentsNumber: Number(values.dependentsNumber),
        },
      },
    },
    userInput: {
      profile: {
        maritalStatusId: Number(values.maritalStatus),
        educationId: Number(values.educationLevel),
      },
    },
  },
});
