import React from 'react';
import { tscope } from '@i18n';
import { TextField } from '@components/Form/Field';
import { Text } from 'ui';
import {
  EmploymentIcon, EntertainmentIcon, FoodIcon, UmbrellaIcon,
} from '@assets/icons';
import { CURRENCY } from '@config/constants';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';

const ts = tscope('Requisition.expenses');

export const Expenses = () => {
  const {
    onBack,
    control,
    onSubmit,
    isSubmitting,
  } = useController();

  return (
    <CreditForm
      onBack={onBack}
      title={ts('title')}
      onSubmit={onSubmit}
      loading={isSubmitting}
    >
      <Text>
        {ts('instructions')}
      </Text>
      <TextField
        placeholder={ts('amountPlaceholder')}
        control={control}
        name="household"
        type="currency"
        maxLength={CURRENCY.LENGTH}
        data-testid="householdField"
        labelText={ts('householdLabel')}
        labelIcon={<FoodIcon width="1.5rem" height="1.5rem" color="gray.700" />}
      />
      <TextField
        placeholder={ts('amountPlaceholder')}
        control={control}
        name="transportation"
        type="currency"
        maxLength={CURRENCY.LENGTH}
        data-testid="transportationField"
        labelText={ts('transportationLabel')}
        labelIcon={<EmploymentIcon width="1.5rem" height="1.5rem" color="gray.700" />}

      />
      <TextField
        placeholder={ts('amountPlaceholder')}
        control={control}
        name="entertainment"
        type="currency"
        maxLength={CURRENCY.LENGTH}
        data-testid="entertainmentField"
        labelText={ts('entertainmentLabel')}
        labelIcon={<EntertainmentIcon width="1.5rem" height="1.5rem" color="gray.700" />}
      />
      <TextField
        placeholder={ts('amountPlaceholder')}
        control={control}
        name="insurance"
        type="currency"
        maxLength={CURRENCY.LENGTH}
        data-testid="insuranceField"
        labelText={ts('insuranceLabel')}
        labelIcon={<UmbrellaIcon width="1.5rem" height="1.5rem" color="gray.700" />}
      />
    </CreditForm>
  );
};
