export const REQUISITION = {
  // Any status registered here keeps user in web-app, otherwise will be redirect to borrower-app.
  // See: https://hackmd.io/2bdedcqMTF-ofSXKk6gJ6g#Mappeo-de-estatus
  STATUS: {
    STARTED: 'started',
    LOAN_INFORMATION_COMPLETED: 'loan_information_completed',
    INCOMES_COMPLETED: 'incomes_completed',
    SPENDING_COMPLETE: 'spending_completed',
    BURO: 'buro',
    BURO_REPORT: 'buro_report',
    YODA: 'yoda',
    BURO_OFFLINE: 'buro_offline',
    BURO_FAILED: 'buro_failed',
    AUTH_UNCOMPLETED: 'auth_incompleted',
    REJECTED_BURO: 'rejected_buro',
    BURO_LOCKED: 'buro_locked',
    REFERENCES: 'referencing',
    REJECTED_LEAD_CRITERIA_COMPLIANCE: 'rejected_for_noncompliance_with_lead_criteria',
    CANCELED_FUNDING_DAYS_OVER: 'canceled_funding_days_over',
    CANCELED_WRONG_PROFILE: 'canceled_wrong_profile',
    CANCELED_PROPOSAL_REJECTED: 'canceled_proposal_rejected',
    CANCELED_NO_NEEDED: 'canceled_no_needed',
    CANCELED_DOUBLE_REQ: 'canceled_double_req',
    CANCELED_NO_AUTH: 'canceled_no_auth',
    CANCELED_MISSING_DOCS: 'canceled_missing_docs',
    CANCELED_MISSING_CLABE: 'canceled_missing_clabe',
    CANCELED_WRONG_AMOUNT: 'canceled_wrong_amount',
    CANCELED_CONSOLIDATION_NOT_ACCEPTED: 'canceled_consolidation_not_accepted',
    CANCELED_BY_BAD_REFERENCES: 'canceled_by_bad_references',
    CANCELED_BY_FAKE_INFO: 'canceled_by_fake_info',
    CANCELED_BC_EXPIRED: 'canceled_bc_expired',
    CANCELED_FORMALIZE_EXPIRED: 'canceled_formalize_expired',
    CANCELED_BY_RATE_NOT_ACCEPTED: 'canceled_by_rate_not_accepted',
    CANCELED_BY_UNDESIRED_AMOUNT: 'canceled_by_undesired_amount',
    CANCELED_GOT_BETTER_RATE: 'canceled_got_better_rate',
    CANCELED_GOT_ANOTHER_SOURCE: 'canceled_got_another_source',
    CANCELED_BY_OTHER_REASON: 'canceled_by_other_reason',
    CANCELED_BEFORE_BURO: 'canceled_before_buro',
    CANCELED_AFTER_REFERENCES: 'canceled_after_references',
    CANCELED_BY_FRAUD: 'canceled_by_fraud',
    CANCELED_BY_UNVALIDATED_INFORMATION: 'canceled_by_unvalidated_information',
    CANCELED_BY_RISKY_PROFILE: 'canceled_by_risky_profile',
    CANCELED_BY_INACTIVITY: 'canceled_by_inactivity',
    CANCELED_BY_BURO_LOCKED: 'canceled_by_buro_locked',
    REJECTED_FAKE_INFO: 'rejected_fake_info',
    REJECTED_UNABLE_CREDIT_INFO: 'rejected_unable_credit_info',
    REJECTED_PAYMENT_CAPACITY: 'rejected_payment_capacity',
    REJECTED_BY_BCSCORE: 'rejected_by_bcscore',
    REJECTED_INCOME_RECORDS: 'rejected_income_records',
    REJECTED_SHORT_CREDIT_HISTORY: 'rejected_short_credit_history',
    REJECTED_INSUFFICIENT_CREDIT_HISTORY: 'rejected_insufficient_credit_history',
    REJECTED_OVER_DEBTS: 'rejected_over_debts',
    REJECTED_OUTSIDE_THE_COLLECTION_AREA: 'rejected_outside_the_collection_area',
    REJECTED_BY_YODA: 'rejected_by_yoda',
    REJECTED_UNABLE_TO_VERIFY_INFO: 'rejected_unable_to_verify_info',
    REJECTED_GOVERNMENT_EMPLOYEE: 'rejected_government_employee',
    REJECTED_BAD_CREDIT_HISTORY: 'rejected_bad_credit_history',
  },
};

export const EMPLOYMENTS = {
  EMPLOYEE: '1',
  SELF_EMPLOYEE: '2',
  OWNER: '3',
  RETIRED: '4',
};

export const CURRENCY = {
  LENGTH: 6,
};

export const PAY_FREQUENCY = {
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
};
