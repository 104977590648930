import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_PROFILE_FIELDS = gql`
  fragment UserProfileFields on User {
    id
    fullName
    firstName
    secondName
    firstLastName
    secondLastName
    birthDate
    curp
    suggestedCurp
    rfc
    suggestedRfc
    gender
    isMexican
    roleName
    cellPhoneNumber
    country {
      id
      name
    }
    birthState {
      id
      name
    }
    flags {
      id
      isPersonCompleted
    }
  }
`;

export const USER_QUERY = gql`
  ${USER_PROFILE_FIELDS}

  query GET_USER_DATA {
    user {
      ...UserProfileFields
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
