const row = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const responsiveList = {
  gap: '1rem',
  flexDirection: {
    base: 'column',
    sm: 'row',
  },
};

export default {
  row,
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    bgColor: 'gray.100',
  },
  contactContainer: {
    ...row,
    ...responsiveList,
    paddingBottom: '1rem',
  },
  addressContainer: {
    ...row,
    paddingBottom: '2rem',
    borderBottom: '1px solid',
    borderColor: 'gray.200',
    alignItems: 'flex-start',
  },
  linksContainer: {
    ...row,
    ...responsiveList,
    paddingTop: '1rem',
  },
  legalContainer: {
    ...row,
    paddingTop: '1rem',
  },
  link: {
    fontWeight: 'normal',
    color: 'gray.400',
    mx: '0.5rem',
  },
  text: {
    mx: '0.35rem',
    color: 'gray.500',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  contactText: {
    mx: '0.35rem',
    color: 'gray.500',
    textAlign: 'center',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  dimText: {
    mx: '0.35rem',
    color: 'gray.400',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  divider: {
    orientation: 'vertical',
    borderColor: 'gray.300',
    display: {
      base: 'none',
      sm: 'block',
    },
  },
  location: {
    display: {
      base: 'none',
      sm: 'block',
    },
    height: '1.4rem',
    width: 'unset',
  },
};
