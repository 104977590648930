import { getZipApiUrl } from '@config/index';

const parseZipCodeInfo = ({ message = {} }) => {
  const neighborhoods = [...new Set(
    message.asentas.map((n) => n.asenta),
  )].sort((a, b) => a.localeCompare(b));

  return {
    municipality: message.d_mnpio,
    stateConstId: message.codigo_estado,
    stateName: message.d_estado,
    city: message.d_ciudad,
    zipCode: message.codigoPostal,
    neighborhoods,
    success: true,
  };
};

const handleBody = (body) => {
  const { status, message } = body;
  if (status === 'success') return parseZipCodeInfo({ message });

  return { success: false };
};

export const fetchZipCodeInfoCatalog = async (zipCode) => {
  try {
    const response = await fetch(`${getZipApiUrl()}/${zipCode}`, {
      method: 'GET',
    });
    const body = await response.json();
    return handleBody(body);
  } catch (error) {
    return { error };
  }
};
