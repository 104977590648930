import endpoints from '@config/endpoints';

const validateStatus = ({ status }) => status >= 200 && status <= 299;

const handleResponse = (response) => {
  const errors = (response || {}).errors || [];
  const valid = !errors.length;
  return { valid, errors };
};

const validatePassword = (builder) => ({
  validatePassword: builder.mutation({
    query: (body) => ({
      body,
      validateStatus,
      url: endpoints.users.passwordValidation.url,
      method: endpoints.users.passwordValidation.method,
    }),
    handleResponse,
  }),
});

export default validatePassword;
