import React from 'react';
import PropTypes from 'prop-types';
import ytpBrand from '@assets/branding/ytp-default.png';
import AsideImageSrc from '@assets/SignIn/aside.jpg';
import RightSideSrc from '@assets/SignIn/rightSide.svg';
import TwoSides from '@components/layouts/TwoSides';
import { t } from '@i18n';
import {
  Flex,
  Image,
  Heading,
} from 'ui';
import { Testimonials } from '../Testimonials';

const LeftSideComponent = ({ testimonials }) => (
  <Flex direction="column" h="100%" alignItems="center">
    <Image src={ytpBrand} w="10rem" mb={{ base: '2rem', md: '3rem' }} />
    <Heading textAlign="center">{t('SignIn.welcome')}</Heading>
    {testimonials ? (
      <Testimonials list={testimonials} w="100%" mt="auto" />
    ) : null}
  </Flex>
);

LeftSideComponent.defaultProps = {
  testimonials: null,
};

LeftSideComponent.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export const RegisterContainer = ({
  children,
  testimonials,
  enableScroll,
}) => (
  <TwoSides
    bgImageLeftSideSrc={AsideImageSrc}
    bgImageRightSideSrc={RightSideSrc}
    enableScrollYRightSide={enableScroll}
    leftSide={<LeftSideComponent testimonials={testimonials} />}
    rightSide={children}
  />
);

RegisterContainer.defaultProps = {
  enableScroll: false,
  testimonials: null,
};

RegisterContainer.propTypes = {
  children: PropTypes.node.isRequired,
  enableScroll: PropTypes.bool,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};
