import { tscope } from '@i18n';

const ts = tscope('FormResolver');

function validate(
  value,
  { min, max, t = ts },
  message = t('minLength', { length: min }),
) {
  if (!value || (value.length >= min && value.length <= max)) return null;

  return {
    type: 'danger',
    message,
  };
}

export default validate;
