import { useCallback, useEffect } from 'react';
import { useTimer } from '@hooks/time';
import { getSessionConfig } from '@config';
import { useDispatch, useSelector } from 'react-redux';
import { setExpiredByInactivity, selectSessionExpired } from '@reducks/session';
import { NETWORK_ONLY } from '@config/apollo/fetchPolicies';
import { useLogoutMutation } from '@services/yoteprestoAPI';
import { useActivityListener } from './useActivityListener';
import { useRefreshSessionQuery } from './useRefreshSessionQuery';

export const useController = () => {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const sessionExpired = useSelector(selectSessionExpired);
  const { ttl, alertBefore: toastTime } = getSessionConfig();
  const showToastAfter = ttl - toastTime;

  const {
    finished: finishedToastTimer,
    reinitialize: restartToastTimer,
  } = useTimer({ time: showToastAfter });

  const showToast = finishedToastTimer && !sessionExpired;

  const [refreshSession] = useRefreshSessionQuery({
    fetchPolicy: NETWORK_ONLY,
  });

  const onExtendSession = useCallback(() => {
    refreshSession();
    restartToastTimer();
  }, [refreshSession, restartToastTimer]);

  const onSessionExpire = useCallback(() => {
    dispatch(setExpiredByInactivity());
  }, [dispatch]);

  useActivityListener({ onExtendSession });

  useEffect(() => {
    if (sessionExpired) logout();
  }, [
    logout,
    sessionExpired,
  ]);

  return {
    toastTime,
    showToast,
    onSessionExpire,
    onExtendSession,
  };
};
