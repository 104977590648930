import { useEffect } from 'react';
import { useCustomToast } from 'ui';
import { tscope } from '@i18n';
import { useSwitchController } from '../../StateSwitcher/hooks/useSwitchController';
import { useReferralCodeQuery } from './useReferralCodeQuery';
import { useRemoveReferralMutation } from './useRemoveReferralMutation';

const ts = tscope('Register.promos');

const parseReferralCode = (user) => {
  if (!user) return { referralCode: null };

  return {
    referralCode: user.referred?.referenceCode || null,
  };
};

const REMOVE_REFERRAL_SUCCESS_ALERT = {
  status: 'success',
  description: ts('alerts.success.removeReferral'),
};

const REMOVE_REFERRAL_FAILED_ALERT = {
  status: 'success',
  description: ts('alerts.error.removeReferral'),
};

export const useController = () => {
  const toast = useCustomToast();
  const onRemoveReferralComplete = () => {
    toast(REMOVE_REFERRAL_SUCCESS_ALERT);
  };

  const onRemoveCouponError = () => {
    toast(REMOVE_REFERRAL_FAILED_ALERT);
  };
  const { data: profile, loading: isLoadingReferral } = useReferralCodeQuery();
  const { referralCode } = parseReferralCode(profile?.user);
  const [removeReferral, { loading }] = useRemoveReferralMutation({
    onCompleted: onRemoveReferralComplete,
    onError: onRemoveCouponError,
  });
  const {
    step,
    open,
    complete,
    cancel,
  } = useSwitchController();

  const removeReferralHandler = () => {
    removeReferral(
      {
        variables: {
          referral: {},
        },
      },
    );
  };

  useEffect(() => {
    if (referralCode) {
      complete();
      return;
    }

    cancel();
  }, [referralCode, complete, cancel]);

  return {
    step,
    open,
    cancel,
    referralCode,
    isLoadingReferral,
    isRemovingReferral: loading,
    removeReferral: removeReferralHandler,
  };
};
