import { getIsPreApprovalAllowed } from '../Credit/PreApproval/utils';
import { getIsRequestFunnelAllowed } from '../Credit/Request/utils';
import { PATHS } from '../constants';

export const useAllowedRoute = ({
  isEmailConfirmed,
  requisitionStatus,
  skipEmailValidation,
  forcePasswordUpdate,
  isRecoveringPassword,
  isCancelledRequisition,
  isReferencesRequisition,
}) => ({
  [PATHS.CONFIRM_EMAIL]: !isEmailConfirmed && !skipEmailValidation,
  [PATHS.PASSWORD_RECOVERY]: Boolean(forcePasswordUpdate || isRecoveringPassword),
  [PATHS.CANCELLED_REQUISITION]: Boolean(isCancelledRequisition),
  [PATHS.REQUISITION]: getIsRequestFunnelAllowed({ requisitionStatus }),
  [PATHS.PRE_APPROVAL]: getIsPreApprovalAllowed({ requisitionStatus }),
  [PATHS.REFERENCES]: Boolean(isReferencesRequisition),
});
