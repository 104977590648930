import React from 'react';
import { Icon } from 'ui';

export const EntertainmentIcon = (props) => (
  <Icon viewBox="0 0 19 22" {...props}>
    <mask id="mask0_1923_1099" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="22">
      <path d="M16.4922 17.9807C16.4922 18.5327 16.0442 18.9807 15.4922 18.9807H3.49219C2.94019 18.9807 2.49219 18.5327 2.49219 17.9807V7.98072C2.49219 7.42872 2.94019 6.98072 3.49219 6.98072H15.4922C16.0442 6.98072 16.4922 7.42872 16.4922 7.98072V17.9807ZM15.4922 4.98072H11.9062L14.1992 2.68772C14.5902 2.29672 14.5902 1.66472 14.1992 1.27372C13.8082 0.882719 13.1762 0.882719 12.7852 1.27372L9.49219 4.56672L6.19919 1.27372C5.80819 0.882719 5.17619 0.882719 4.78519 1.27372C4.39419 1.66472 4.39419 2.29672 4.78519 2.68772L7.07819 4.98072H3.49219C1.83819 4.98072 0.492188 6.32672 0.492188 7.98072V17.9807C0.492188 19.6347 1.83819 20.9807 3.49219 20.9807H15.4922C17.1462 20.9807 18.4922 19.6347 18.4922 17.9807V7.98072C18.4922 6.32672 17.1462 4.98072 15.4922 4.98072Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1923_1099)">
      <path d="M16.4922 17.9807C16.4922 18.5327 16.0442 18.9807 15.4922 18.9807H3.49219C2.94019 18.9807 2.49219 18.5327 2.49219 17.9807V7.98072C2.49219 7.42872 2.94019 6.98072 3.49219 6.98072H15.4922C16.0442 6.98072 16.4922 7.42872 16.4922 7.98072V17.9807ZM15.4922 4.98072H11.9062L14.1992 2.68772C14.5902 2.29672 14.5902 1.66472 14.1992 1.27372C13.8082 0.882719 13.1762 0.882719 12.7852 1.27372L9.49219 4.56672L6.19919 1.27372C5.80819 0.882719 5.17619 0.882719 4.78519 1.27372C4.39419 1.66472 4.39419 2.29672 4.78519 2.68772L7.07819 4.98072H3.49219C1.83819 4.98072 0.492188 6.32672 0.492188 7.98072V17.9807C0.492188 19.6347 1.83819 20.9807 3.49219 20.9807H15.4922C17.1462 20.9807 18.4922 19.6347 18.4922 17.9807V7.98072C18.4922 6.32672 17.1462 4.98072 15.4922 4.98072Z" fill="currentColor" />
    </g>
  </Icon>
);
