import React from 'react';
import { jsx, t, tscope } from '@i18n';
import {
  Box,
  Card,
  Grid,
  Text,
  Button,
  Divider,
  CardBody,
  FormLabel,
} from 'ui';
import {
  TextField,
  SelectField,
  TextAreaField,
} from '@components/Form/Field';
import { styles as s } from './CreditForm.styles';
import { useController } from './hooks';

export const ts = tscope('CancelledRequisition.Restart');

export const CreditForm = (props) => {
  const {
    control,
    onSubmit,
    isLoading,
    termsOptions,
    requisitionId,
    purposesOptions,
    displayReasonNote,
  } = useController();

  return (
    <Card {...s.card} {...props}>
      <CardBody {...s.cardBody}>
        <Text {...s.cardTitle}>
          {jsx('cardTitle', {
            t: ts,
            requisitionId: (
              <b>
                #
                {requisitionId}
              </b>),
          })}
        </Text>
        <Grid
          as="form"
          onSubmit={onSubmit}
          {...s.formContainer}
        >
          <FormLabel htmlFor="Restart.amount" {...s.label}>{ts('fields.amount.label')}</FormLabel>
          <TextField
            id="Restart.amount"
            control={control}
            data-testid="Restart.amount"
            name="amount"
            size="sm"
            type="currency"
            minDecimals={0}
            maxDecimals={0}
          />
          <Divider {...s.divider} />
          <FormLabel htmlFor="Restart.term" {...s.label}>{ts('fields.term.label')}</FormLabel>
          <SelectField
            id="Restart.term"
            control={control}
            data-testid="Restart.term"
            name="term"
            size="sm"
            placeholder={t('Common.na')}
          >
            {termsOptions.map((term) => (
              <option key={term.value} value={term.value}>{term.label}</option>
            ))}
          </SelectField>
          <Divider {...s.divider} />
          <FormLabel htmlFor="Restart.destination" {...s.label}>{ts('fields.destination.label')}</FormLabel>
          <SelectField
            id="Restart.destination"
            control={control}
            name="destination"
            data-testid="Restart.destination"
            size="sm"
            placeholder={t('Common.na')}
          >
            {purposesOptions.map((purpose) => (
              <option key={purpose.value} value={purpose.value}>{purpose.label}</option>
            ))}
          </SelectField>
          <Divider {...s.divider} />
          <Box {...s.destinationGroup}>
            <FormLabel htmlFor="Restart.reason" {...s.label}>{ts('fields.reason.label')}</FormLabel>
            <TextAreaField
              id="Restart.reason"
              control={control}
              name="reason"
              rows="7"
              autoFocus
              data-testid="Restart.reason"
              placeholder={ts('fields.reason.placeholder')}
            />
            {displayReasonNote && (
            <Text
              {...s.reasonNote}
            >
              {ts('noPersonalInformation')}
            </Text>
            )}
          </Box>
          <Divider {...s.divider} />
          <Button
            {...s.submit}
            isLoading={isLoading}
            type="submit"
          >
            {ts('actions.submit')}
          </Button>
          <Text {...s.footNote}>
            {jsx('footNote', {
              t: ts,
              noteStart: <b>{ts('noteStart')}</b>,
            })}
          </Text>
        </Grid>
      </CardBody>
    </Card>
  );
};
