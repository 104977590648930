// name is not a translation, it's in the DB.
// This Catalogue is not available from GraphAPI
export const EXPENSES = {
  debt: {
    expenseId: 3,
    name: 'Tarjetas y otras deudas',
  },
  entertainment: {
    expenseId: 7,
    name: 'Recreación personal y familiar',
  },
  services: {
    expenseId: 4,
    name: 'Servicios',
  },
  education: {
    expenseId: 6,
    name: 'Educación',
  },
  insurance: {
    expenseId: 8,
    name: 'Seguros',
  },
  rent: {
    expenseId: 2,
    name: 'Renta',
  },
  transportation: {
    expenseId: 5,
    name: 'Transporte y gasolina',
  },
  household: {
    expenseId: 1,
    name: 'Alimentos, salud y ropa',
  },
  other: {
    expenseId: 9,
    name: 'Otros gastos',
  },
};
