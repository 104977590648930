import React from 'react';
import { WaitingImage } from '@assets/images';
import { t, tscope } from '@i18n/index';
import { Flex, Text } from 'ui';
import { useController } from './hooks';
import { style as s } from './Countdown.style';

export const ts = tscope('CancelledRequisition.cancelledUseCases.foreignUserRejection');

export const Countdown = (props) => {
  const { retryDate } = props;
  const { count } = useController({ retryDate });

  return (
    <Flex {...s.container}>
      <WaitingImage data-testid="waitingImage" />
      <Text {...s.waitText}>{ts('waitBeforeReapply')}</Text>
      <Text {...s.count}>{t('date.days', { count })}</Text>
    </Flex>
  );
};
