import { maskEmailAddress } from '@lib/MaskData/masks';
import { useForm } from 'react-hook-form';
import { useCustomToast } from 'ui';
import { tscope } from '@i18n';
import { useNavigate } from 'react-router-dom';
import { useConfirmEmailMutation } from './useConfirmEmailMutation';
import { useGenerateOTP } from './useGenerateOTP';
import resolver from '../utils/resolver';

const ts = tscope('Register.emailConfirm');

export const INVALID_CODE_ERROR = 'INVALID_OTP_ERROR';

export const CONFIRM_EMAIL_FAILED_ALERT = {
  description: ts('toast.otpError'),
  status: 'error',
};

export const useController = ({ email }) => {
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { timeLeft, newOtpUnavailable, generateOTP } = useGenerateOTP({ toast });
  const { control, handleSubmit } = useForm({ resolver, defaultValues: { otp: '' } });
  const [confirmEmail, confirmEmailResult] = useConfirmEmailMutation({
    onCompleted: () => navigate('/'),
    onErrorCode: { [INVALID_CODE_ERROR]: () => toast(CONFIRM_EMAIL_FAILED_ALERT) },
  });
  const resendButtonText = timeLeft === 0 ? ts('resend') : ts('resendTimeout', { count: timeLeft });
  const resendOTPButtonProps = {
    isDisabled: newOtpUnavailable,
    width: timeLeft === 0 ? '130px' : 'auto',
  };
  const submitProps = {
    disabled: confirmEmailResult.loading,
  };

  const submitHandler = handleSubmit((values) => {
    const { otp: otpCode } = values;
    confirmEmail({
      variables: {
        input: { otpCode },
      },
    });
  });

  return {
    control,
    submitProps,
    submitHandler,
    resendButtonText,
    resendOTPButtonProps,
    resendOTPHandler: generateOTP,
    maskedEmail: maskEmailAddress(email),
  };
};
