import React from 'react';
import { t } from '@i18n';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '@navigators/Root/Guest/constants';
import { PASSWORD } from '@config/constants';
import { TextField } from '@components/Form/Field';
import {
  Button,
  Link,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
  Heading,
  Skeleton,
} from 'ui';
import { useController } from './hooks';

export const TypePasswordForm = () => {
  const {
    fullName,
    onSubmit,
    control,
    isLoading,
    isSubmitting,
  } = useController();

  return (
    <Card
      boxSizing="border-box"
      minWidth={{ base: '100%', sm: '28rem' }}
      maxWidth={{ base: '100%', sm: '28rem' }}
    >
      <CardHeader
        padding={{ base: '1.5rem 1.5rem 1rem 1.5rem', md: '2rem 2rem 1rem 2rem' }}
      >
        <Heading as="h3" size="md" textAlign="center" mb="1rem">
          {t('SignIn.login')}
        </Heading>
        <Skeleton
          width="100%"
          borderRadius="md"
          endColor="gray.200"
          isLoaded={!isLoading}
        >
          <Heading
            as="h3"
            size="lg"
            fontWeight="normal"
            mb="1rem"
            data-testid="masked-name-text"
            translate="no"
          >
            {fullName}
          </Heading>
        </Skeleton>
        <Text>
          <span>{`${t('SignIn.areTheseYourInitials')} `}</span>
          <Link
            as={RouterLink}
            to="../"
          >
            {t('SignIn.backLink')}
          </Link>
        </Text>
      </CardHeader>
      <CardBody
        padding={{ base: '1rem 1.5rem 1.5rem 1.5rem', md: '1rem 2rem 2rem 2rem' }}
      >
        <form onSubmit={onSubmit} noValidate aria-label="form">
          <TextField
            id="password"
            autoFocus
            control={control}
            mb="0.5rem"
            name="password"
            type="password"
            labelText={t('SignIn.passwordField.label')}
            placeholder={t('SignIn.passwordField.placeholder', { charsLength: PASSWORD.MIN_LENGTH })}
            data-testid="password"
          />
          <Flex justifyContent="flex-end" w="100%" mt="0.5rem">
            <Link
              as={RouterLink}
              to={`/${PATHS.PASSWORD_RECOVERY}`}
            >
              {t('SignIn.forgotPassword')}
            </Link>
          </Flex>
          <Button
            colorScheme="green"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            size="lg"
            w="100%"
            marginTop={{ base: '1rem', md: '2rem' }}
            data-testid="password-submit"
          >
            {t('SignIn.login')}
          </Button>
        </form>
      </CardBody>
    </Card>
  );
};
