import { useCustomToast } from 'ui';
import { useSelector } from 'react-redux';
import { selectCredentials } from '@reducks/session';
import { useSetNewPassword } from './useSetNewPassword';

export const useController = () => {
  const toast = useCustomToast();
  const credentials = useSelector(selectCredentials);

  const { isLoading, submitNewPassword } = useSetNewPassword({
    toast,
  });

  return {
    isLoading,
    submitNewPassword,
    email: credentials?.email,
  };
};
