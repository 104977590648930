import { useMemo } from 'react';
import { useEmploymentRelationCatalog } from './graphql/useWorkCatalog';

const getWorkFieldsOptions = ({ catalogs, workingSectorId }) => {
  const parseOptions = ({ name, id }) => ({ label: name, id });
  const filterWorkingActivitiesBySector = ({ sectorId: id }) => (
    String(id) === workingSectorId
  );
  const occupationOptions = catalogs?.employments.map(parseOptions) || [];
  const workingSectorOptions = catalogs?.workingSectors.map(parseOptions) || [];
  const workingActivitiesOptions = catalogs?.workingActivities
    .filter(filterWorkingActivitiesBySector)
    .map(parseOptions) || [];

  return {
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
  };
};

export const useWorkOptions = (options) => {
  const { workingSectorId } = options;
  const {
    data: catalogData,
    loading: loadingCatalog,
  } = useEmploymentRelationCatalog();
  const catalogs = catalogData?.catalogs || null;

  const {
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
  } = useMemo(
    () => getWorkFieldsOptions({ catalogs, workingSectorId }),
    [
      catalogs,
      workingSectorId,
    ],
  );

  return {
    occupationOptions,
    workingSectorOptions,
    workingActivitiesOptions,
    loading: loadingCatalog,
  };
};
