export default {
  container: {
    margin: 'auto',
    direction: 'column',
    alignItems: 'center',
    paddingX: { base: '2rem', sm: '1rem' },
    maxWidth: { base: '100%', sm: '28rem' },
    width: {
      md: '28rem',
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: '1rem',
  },
  submit: {
    marginLeft: 'auto',
    paddingX: '3rem',
  },
};
