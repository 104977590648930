import { useActiveRequisitionQuery } from './useActiveRequisitionQuery';

export const useUpdateActiveRequisition = () => {
  const { data } = useActiveRequisitionQuery();
  const activeRequisition = data?.activeRequisition;

  const updateActiveRequisition = ({ cache, newActiveRequisition }) => {
    if (!activeRequisition) return;
    const normalizedId = cache.identify(activeRequisition);
    cache.modify({
      id: normalizedId,
      fields(_, { fieldName, INVALIDATE }) {
        if (fieldName === 'id') return newActiveRequisition.id;
        if (fieldName === 'status') return newActiveRequisition.status;
        return INVALIDATE;
      },
    });
  };

  return updateActiveRequisition;
};
