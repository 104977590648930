import { t } from '@i18n';
import { USER } from '@config/constants';
import { parseDigits } from '@lib/parsers';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import asyncIsEmailAvailable from '@lib/asyncValidateEmail';
import simpleMemoize from '@lib/Monads/simpleMemoize';

const DEFAULT_PHONE_LENGTH = 10;
const EXISTING_EMAIL_ERROR = {
  type: 'danger',
  message: t('Register.accountCreate.emailField.errors.taken'),
};

const asyncIsEmailAvailableMemoized = simpleMemoize(asyncIsEmailAvailable);

const wrapError = (message) => ({ message, type: 'danger' });

const validatePasswordField = ({ password, isValid }) => {
  if (!password) return wrapError(t('FormResolver.required'));
  return isValid === true
    ? null
    : wrapError(t('ConfirmPassword.passwordField.errors.verify'));
};

const validatePasswordConfirmField = ({ password, passwordConfirm }) => {
  if (!passwordConfirm) return wrapError(t('FormResolver.required'));
  if (password !== passwordConfirm) return wrapError(t('ConfirmPassword.passwordConfirmField.errors.notMatch'));
  return null;
};

const validateEmail = async ({ email }) => {
  const formatError = Validator(email).maxLength({ max: 50 }).required().email()
    .result();
  if (formatError) return formatError;

  const emailTaken = await asyncIsEmailAvailableMemoized(email);
  return emailTaken ? null : EXISTING_EMAIL_ERROR;
};

const validatePhone = ({ phoneNumber, phoneCode, phoneCodeValidations = {} }) => {
  const lengthValidations = phoneCodeValidations[phoneCode] || {};
  const min = lengthValidations.min || DEFAULT_PHONE_LENGTH;
  const max = lengthValidations.max || DEFAULT_PHONE_LENGTH;
  const parsed = phoneNumber?.match(/(\d+)/g)?.join('');
  if (!parsed) return wrapError(t('FormResolver.required'));

  const lengthError = parsed.length < min || parsed.length > max;
  if (lengthError) {
    if (min === max) return wrapError(t('FormResolver.exactDigits', { count: min }));
    return wrapError(t('FormResolver.minMaxDigits', { min, max }));
  }

  return null;
};

export const parseAmount = (input) => {
  const digits = parseDigits(input);
  return digits ? parseInt(digits, 10) : null;
};

async function resolver(values, context) {
  const {
    role,
    maximumAmount,
    minimumAmount,
    passIsValid,
    phoneCodeValidations,
  } = context;

  const {
    email,
    amount,
    phoneCode,
    phoneNumber,
    password,
    passwordConfirm,
    agreeWithTermConditions,
  } = values;

  const borrowerErrors = role === USER.ROLES.BORROWER ? ({
    amount: Validator(parseAmount(amount))
      .range({
        min: minimumAmount, max: maximumAmount, type: 'currency', precision: 0,
      })
      .result(),
    term: null,
    destination: null,
  }) : {};

  const phoneNumberError = validatePhone({
    phoneCode,
    phoneNumber,
    phoneCodeValidations,
  });

  const errors = {
    ...borrowerErrors,
    email: await validateEmail({ email }),
    phoneNumber: phoneNumberError,
    phoneCode: phoneNumberError ? { ...phoneNumberError, message: '' } : null,
    password: validatePasswordField({ password, isValid: passIsValid }),
    passwordConfirm: validatePasswordConfirmField({ password, passwordConfirm }),
    agreeWithTermConditions: Validator(agreeWithTermConditions).truthy().result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
