import React from 'react';
import { t } from '@i18n';
import { Controller } from 'react-hook-form';
import { TextField } from '@components/Form/Field';
import {
  Flex,
  Button,
  Card,
  Heading,
  Checkbox,
} from 'ui';
import { useController } from './hooks';

export const TypeEmailForm = (props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
  } = useController();

  return (
    <Card
      {...props}
      boxSizing="border-box"
      padding={{ base: '1.5rem', md: '2rem' }}
      minWidth={{ base: '100%', sm: '28rem' }}
      maxWidth={{ base: '100%', sm: '28rem' }}
    >
      <form onSubmit={onSubmit} aria-label="form" noValidate>
        <Heading as="h3" size="md" textAlign="center" mb="2rem">
          {t('SignIn.login')}
        </Heading>
        <TextField
          id="email"
          autoFocus
          control={control}
          name="email"
          type="email"
          displayAsPassword
          labelText={t('SignIn.emailField.label')}
          placeholder={t('SignIn.emailField.placeholder')}
          mb="0.5rem"
          data-testid="email"
        />
        <Controller
          name="rememberUser"
          control={control}
          render={({
            field: {
              onBlur,
              onChange,
              value,
              name,
            },
          }) => (
            <Flex direction="row" alignItems="center" py="0.5rem">
              <Checkbox
                name={name}
                isChecked={value}
                onChange={onChange}
                onBlur={onBlur}
                size="md"
                id="checkbox-input"
                data-testid="checkbox-input"
              >
                {t('SignIn.rememberUser')}
              </Checkbox>
            </Flex>
          )}
        />
        <Button
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
          size="lg"
          w="100%"
          marginTop={{ base: '1rem', md: '2rem' }}
          data-testid="email-submit"
        >
          {t('SignIn.continue')}
        </Button>
      </form>
    </Card>
  );
};
