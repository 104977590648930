import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import {
  Grid,
  Box,
  FormLabel,
} from 'ui';
import SelectField from '../Select';
import TextGroup from '../Text/TextGroup';

export const PhoneField = ({
  id,
  name,
  size,
  control,
  options,
  showCode,
  labelText,
  isDisabled,
  placeholder,
  'data-testid': dataTestId,
  ...contentProps
}) => {
  const codeName = `${name}Code`;
  const numberName = `${name}Number`;
  const flagName = `${name}Flag`;
  const { field: codeField } = useController({ name: codeName, control });
  const codeTestId = dataTestId ? `${dataTestId}-code` : null;
  const gridColumn = showCode ? '2' : '0';
  const leftContent = showCode ? `+${codeField.value}` : null;
  const phoneLength = options.find(({ value }) => value === codeField.value)?.phoneMaxLength;

  return (
    <Grid templateColumns="auto 1fr" columnGap="1rem" {...contentProps}>
      <FormLabel
        htmlFor={numberName}
        gridColumn="1 / -1"
      >
        {labelText}
      </FormLabel>
      <input {...codeField} type="hidden" />
      {showCode && (
        <SelectField
          size={size}
          id={codeName}
          name={flagName}
          control={control}
          gridColumn="1"
          width="66px"
          defaultValue="MX-52"
          onChange={({ target: { value } }) => { codeField.onChange(value.split('-')[1]); }}
          isDisabled={isDisabled}
          inputProps={{ fontSize: '16px' }}
          data-testid={codeTestId}
        >
          {
            options.map(({
              key, value, description,
            }) => (
              <Box key={key} as="option" color="black" value={`${key}-${value}`}>
                {description}
              </Box>
            ))
          }
        </SelectField>
      )}
      <TextGroup
        id={numberName}
        name={numberName}
        control={control}
        placeholder={placeholder}
        type="telephone"
        phoneLength={phoneLength}
        isDisabled={isDisabled}
        autoComplete="tel-national"
        gridColumn={gridColumn}
        leftContent={leftContent}
        data-testid={dataTestId}
        size={size}
      />
    </Grid>
  );
};

PhoneField.defaultProps = {
  id: '',
  labelText: '',
  placeholder: '',
  'data-testid': '',
  showCode: true,
};

PhoneField.propTypes = {
  id: PropTypes.string,
  showCode: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  'data-testid': PropTypes.string,
};
