import { REQUISITION } from '@config/constants';
import { isNil } from '@lib/Monads';

export const getIsRequestFunnelAllowed = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;
  if (requisitionStatus === '') return true;
  const allowedStatuses = [
    REQUISITION.STATUS.STARTED,
    REQUISITION.STATUS.LOAN_INFORMATION_COMPLETED,
    REQUISITION.STATUS.INCOMES_COMPLETED,
    REQUISITION.STATUS.SPENDING_COMPLETE,
  ];
  return allowedStatuses.includes(requisitionStatus);
};
