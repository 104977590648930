import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { readCredentials, hasCredentials } from '@lib/storage/credentials';
import { getPusherConfig } from '@config';

export const usePusherClient = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const credentials = readCredentials();
    if (!client && hasCredentials(credentials)) {
      const { key, authEndpoint, ...options } = getPusherConfig();
      const params = {
        ...options,
        channelAuthorization: {
          endpoint: authEndpoint,
          headers: {
            uid: credentials.uid,
            expiry: credentials.expiry,
            client: credentials.client,
            'token-type': credentials.tokenType,
            'access-token': credentials.accessToken,
          },
        },
      };
      setClient(new Pusher(key, params));
    }

    return () => {
      if (!client) return;
      client.disconnect();
      setClient(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, hasCredentials]);

  return client;
};
