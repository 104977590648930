import { t } from '@i18n';

export const includesSpecialChars = ({ password }) => /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password);

function validate(password, message = t('FormResolver.includesSpecialChars')) {
  if (includesSpecialChars({ password })) return null;

  return {
    type: 'danger',
    message,
    value: password,
  };
}

export default validate;
