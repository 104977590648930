import { t, tscope } from '@i18n';
import { formatAddress } from '@lib/format/address';
import * as Logger from '@lib/Logger';
import {
  formatGender,
  formatBirthInfo,
} from '@lib/format/user';
import { useCustomToast } from 'ui';
import { useAuthorizeCreditReport, useUserQuery } from './graphql';

const ts = tscope('Requisition.authorize');

export const useController = () => {
  const toast = useCustomToast();
  const handleUserQueryError = () => {
    toast({
      status: 'error',
      description: ts('alerts.error.failedQueryUser'),
    });
  };

  const [authorizeCreditReport, { loading: isSubmitting }] = useAuthorizeCreditReport({
    onError: (error) => {
      Logger.reportGraphQLError(error, { hint: 'AUTHORIZE_UNHANDLED_ERROR' });
    },
    onErrorCode: {
      FORBIDDEN_ERROR: (error) => {
        // @log: for credit authorization errors
        Logger.reportGraphQLError(error, { hint: 'FORBIDDEN_ERROR' });
        toast({
          status: 'error',
          description: ts('alerts.error.invalidPassword'),
        });
      },
      INVALID_STATUS_ERROR: (error) => {
        // @log: for credit authorization errors
        Logger.reportGraphQLError(error, { hint: 'INVALID_STATUS_ERROR' });
        toast({
          status: 'error',
          description: ts('alerts.error.invalidStatus'),
        });
      },
    },
  });

  const { data, loading } = useUserQuery({
    onError: handleUserQueryError,
  });
  const user = data?.user;

  const labels = {
    fullName: user?.fullName || t('Common.na'),
    curp: user?.curp || t('Common.na'),
    rfc: user?.rfc || t('Common.na'),
    gender: formatGender(user),
    birth: formatBirthInfo(user),
    address: formatAddress(user?.address),
  };

  return {
    labels,
    loading,
    isSubmitting,
    authorizeCreditReport: (password) => {
      Logger.setContext({ provisionalContext: password });

      return authorizeCreditReport({
        variables: {
          input: {},
        },
        context: { password },
      });
    },
  };
};
