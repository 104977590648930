import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {};

const zipCodesReducer = createSlice({
  name: 'zipCodes',
  initialState,
  reducers: {
    addZipCode: (state, { payload }) => ({
      ...state,
      [payload.zipCode]: payload,
    }),
  },
});

export const { actions, reducer } = zipCodesReducer;

export const useZipCode = (zipCode) => useSelector((state) => state.zipCodes[zipCode] || null);

export const useZipCodes = () => useSelector((state) => state.zipCodes);
