export const init = (id) => {
  // STARTS code provided by Google Tag Manager
  /* eslint-disable */
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
  }(window, document, 'script', 'dataLayer', id));
  // ENDS code provided by Google Tag Manager
  /* eslint-enable */
};

const pushToDataLayer = (...args) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(...args);
};

export const addContext = (context) => {
  const event = {
    ...context,
    event: 'AddContext',
  };
  return pushToDataLayer(event);
};

export const sendEvent = (name, properties = {}) => {
  pushToDataLayer({ event: name, ...properties });
};

export const sendPageView = (location) => {
  pushToDataLayer({
    event: 'Pageview',
    pagePath: location,
    pageTitle: location,
  });
};
