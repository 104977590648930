import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const BURO_RETRY_GQL = gql`
  mutation tryQueryBuroReport {
    attemptUnlockBuroReport(input: {}) {
      activeRequisition {
        id
        status
        buroAttempts
      }   
    }
  }
`;

export const useUseRequestBuroReportMutation = (options) => useMutation(BURO_RETRY_GQL, options);
