import { useIsSessionActive } from '@hooks/session/useIsSessionActive';
import { getDaysLeft } from '@lib/Date';
import { ONE_MINUTE } from '@lib/durations';
import { useEffect, useMemo, useState } from 'react';

export const useController = ({ retryDate }) => {
  const [currentDay, setCurrentDay] = useState(new Date());
  const { hasExpiredSession } = useIsSessionActive();

  const count = useMemo(() => getDaysLeft(retryDate, currentDay), [currentDay, retryDate]);

  // The job that allows the user to reapply runs 5 minutes after midnight.
  const changeDateIn5Minutes = () => {
    setTimeout(() => {
      setCurrentDay(new Date());
    }, 5 * ONE_MINUTE);
  };

  useEffect(() => {
    if (hasExpiredSession) return () => { };

    const interval = setInterval(() => {
      const hasCurrentDayChanged = currentDay.getDate() !== new Date().getDate();

      if (hasCurrentDayChanged) {
        changeDateIn5Minutes();
      }
    }, ONE_MINUTE);

    return () => clearInterval(interval);
  }, [hasExpiredSession, currentDay]);

  return {
    count,
  };
};
