import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

function resolver(values) {
  const {
    household,
    transportation,
    entertainment,
    insurance,
  } = values;

  const errors = {
    household: Validator(household).required().result(),
    transportation: Validator(transportation).required().result(),
    entertainment: Validator(entertainment).required().result(),
    insurance: Validator(insurance).required().result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
