export const styles = {
  imageBox: {
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    height: { base: '52', md: '285px' },
  },
  image: {
    width: '100%',
    height: '100%',
    bgColor: 'green.500',
    rightCircleOpacity: '1',
    rightCircleColor: 'blue.500',
  },
  container: {
    gap: 5,
    zIndex: 'base',
    alignItems: 'center',
    justifyItems: 'center',
    marginX: 'auto',
    paddingX: { base: '0', md: '8', lg: '0' },
    maxWidth: { base: 'sm', md: '100%' },
  },
  cardBody: {
    paddingY: { base: '5', md: '12' },
    paddingX: { base: '5', md: '12' },
    width: { base: 'full', md: '2xl' },
  },
  form: {
    rowGap: '5',
  },
  title: {
    as: 'h1',
    color: 'white',
    textAlign: 'center',
    marginY: { md: '14' },
    textShadow: '1px 1px 1px var(--chakra-colors-green-500)',
  },
  textContainer: {
    maxWidth: { base: '100%', sm: '2xl', md: '2xl' },
    paddingX: 4,
  },
  text: {
    textAlign: 'center',
  },
  boldInstructions: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 'xl',
    marginY: { base: '2', md: '3' },
  },
  boldSpan: {
    fontWeight: 'bold',
  },
  icon: {
    display: { base: 'none', md: 'block' },
    w: 20,
    h: 20,
  },
  bannerCardBody: {
    display: 'flex',
    columnGap: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: { base: '5', md: '5' },
    paddingX: { base: '5', md: '16' },
  },
  submit: {
    justifySelf: 'end',
    marginTop: 5,
  },
};
