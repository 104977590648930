import { jsx, tscope } from '@i18n';
import React from 'react';

import {
  Card,
  CardBody,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  Link,
} from 'ui';
import { styles as s } from './BuroBlockedBanner.styles';

const ts = tscope('CancelledRequisition.cancelledUseCases.buroLocked.card');

export const BuroBlockedBanner = () => (
  <Card {...s.buroBlockedBanner}>
    <CardBody>
      <Heading {...s.buroBlockedBannerTitle}>
        {ts('title')}
      </Heading>
      <UnorderedList {...s.list}>
        <ListItem {...s.listItem}>
          <Text {...s.listItemText}>
            {jsx('step1', {
              t: ts,
              anchor: (
                <Link href={ts('anchor.href')}>
                  {ts('anchor.text')}
                </Link>
              ),
            })}
          </Text>
        </ListItem>
        <ListItem {...s.listItem}>
          <Text {...s.listItemText}>
            {ts('step2')}
          </Text>
        </ListItem>
      </UnorderedList>
    </CardBody>
  </Card>
);
