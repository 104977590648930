import React from 'react';
import {
  Text,
  Card,
  Grid,
  Heading,
} from 'ui';
import { tscope } from '@i18n';
import { EditableField } from '@components/Form/EditableField/EditableField';
import { AuthorizeWithPassword } from '@components/Form/AuthorizeWithPassword';
import { useController } from './hooks/useController';
import s from './Authorize.style';

const ts = tscope('Requisition.authorize');

export const Authorize = () => {
  const {
    labels,
    loading,
    isSubmitting,
    authorizeCreditReport,
  } = useController();

  return (
    <Grid
      {...s.container}
      title={ts('title')}
    >
      <Heading {...s.title}>
        {ts('title')}
      </Heading>
      <Card {...s.card}>
        <EditableField
          id="username"
          name="username"
          data-testid="Authorize.username"
          isLoading={loading}
          shouldRedirect={false}
          value={labels.fullName}
          labelText={ts('fields.username.label')}
        />
        <EditableField
          id="birth"
          name="birth"
          data-testid="Authorize.birth"
          isLoading={loading}
          shouldRedirect={false}
          value={labels.birth}
          labelText={ts('fields.birth.label')}
        />
        <EditableField
          id="rfc"
          name="rfc"
          data-testid="Authorize.rfc"
          value={labels.rfc}
          shouldRedirect={false}
          isLoading={loading}
          labelText={ts('fields.rfc.label')}
        />
        <EditableField
          id="curp"
          name="curp"
          data-testid="Authorize.curp"
          value={labels.curp}
          shouldRedirect={false}
          isLoading={loading}
          labelText={ts('fields.curp.label')}
        />
        <EditableField
          isTextarea
          rows="4"
          id="address"
          name="address"
          path="../address"
          data-testid="Authorize.address"
          isLoading={loading}
          value={labels.address}
          labelText={ts('fields.address.label')}
          inputProps={{ height: '4.5rem' }}
        />
      </Card>
      <Card {...s.card}>
        <Text>{ts('notice')}</Text>
        <AuthorizeWithPassword
          label={ts('fields.password.label')}
          submitText={ts('actions.submit')}
          onSubmit={authorizeCreditReport}
          loading={isSubmitting}
        />
      </Card>
    </Grid>
  );
};
