import React from 'react';
import { Fade } from 'ui';
import { SessionExpirationToast } from '@components';
import { useController } from './hooks';
import styles from './ActivityMonitor.styles';

export const ActivityMonitor = () => {
  const {
    toastTime,
    showToast,
    onSessionExpire,
    onExtendSession,
  } = useController();

  return (
    <Fade
      unmountOnExit
      in={showToast}
    >
      <SessionExpirationToast
        time={toastTime}
        onSessionExpire={onSessionExpire}
        onExtendSession={onExtendSession}
        data-testid="SessionExpirationToast"
        {...styles.toast}
      />
    </Fade>
  );
};
