import { useCallback } from 'react';
import { borrowerApi } from '@services/yoteprestoAPI';
import { USER } from '@config/constants';
import {
  getIsRequisitionAvailableToWatchLeads,
} from '@views/CancelledRequisition/utils';
import {
  getBorrowersHomeUrl,
  getInvestorsHomeUrl,
  getIsUserStatusHandled,
  getIsRequisitionStatusHandled,
} from '@config';

const shouldRedirectToBorrowerApp = ({
  role,
  userStatus,
  activeRequisition,
}) => {
  const isUserStatusHandled = getIsUserStatusHandled(userStatus);
  if (isUserStatusHandled === false) return true;
  const isForLeads = getIsRequisitionAvailableToWatchLeads(activeRequisition);
  if (isForLeads) return true;
  const isRequisitionStatusHandled = getIsRequisitionStatusHandled(activeRequisition?.status);

  return [
    role === 'borrower',
    isRequisitionStatusHandled === false,
  ].every(Boolean);
};

const shouldGoToExternalApp = ({
  role,
  status,
  activeRequisition,
  isPersonConfirmed,
}) => {
  const isProfileComplete = (role && isPersonConfirmed);
  const willRedirectBorrowerApp = isProfileComplete && shouldRedirectToBorrowerApp({
    role,
    activeRequisition,
    userStatus: status,
  });
  const willRedirectToInvestorApp = role === 'investor' && (status !== USER.STATUS.STARTED || isProfileComplete);
  return willRedirectToInvestorApp || willRedirectBorrowerApp;
};

export const useRedirectOutOfSite = ({
  role,
  status,
  isPersonConfirmed,
  activeRequisition,
}) => {
  const willRedirect = shouldGoToExternalApp({
    role,
    status,
    isPersonConfirmed,
    activeRequisition,
  });
  const [signInBorrower] = borrowerApi.useSignInBorrowerMutation();

  const goToAppByRole = useCallback(async () => {
    if (role === USER.ROLES.BORROWER) {
      await signInBorrower().unwrap();
      window.location.assign(getBorrowersHomeUrl());
    } else if (role === USER.ROLES.INVESTOR) {
      window.location.assign(getInvestorsHomeUrl());
    }
  }, [role, signInBorrower]);

  return {
    willRedirect,
    goToAppByRole,
  };
};
