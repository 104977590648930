import store from '@config/redux/store';
import { onError } from '@apollo/client/link/error';
import { setUnavailableService } from '@reducks/serviceStatus/serviceStatus';
import { UNAVAILABLE_SERVICE_STATUS } from '@config';

export const onUnavailableServiceError = ({ networkError }) => {
  if (networkError?.statusCode === UNAVAILABLE_SERVICE_STATUS) {
    store.dispatch(setUnavailableService());
  }
};

export const unavailableServiceLink = onError(onUnavailableServiceError);
