import { isNil } from '../Monads';

const INVALID_VALUES = [
  'null',
  'undefined',
  'NaN',
];

export const readString = (value) => {
  const parsed = String(value || '').trim();
  if (INVALID_VALUES.includes(parsed)) return null;

  return parsed || null;
};

export const writeString = (value) => {
  if (isNil(value)) return null;
  return String(value).trim() || null;
};

export const stringify = (value) => (isNil(value) ? '' : String(value).trim());
