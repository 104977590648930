import React from 'react';
import { t, jsx } from '@i18n';
import { Text, Link } from 'ui';
import { PATHS } from '@navigators/Root/Guest/constants';
import { Link as RouterLink } from 'react-router-dom';
import { Layout } from '@views/SignIn/components';
import { TypeEmailForm } from './components';

export const EmailStep = () => (
  <Layout>
    <TypeEmailForm data-testid="SignIn.TypeEmailForm" />
    <Text data-testid="SignIn.TypeEmailForm.linkToRegister" maxWidth="23rem" mt="2rem">
      {
        jsx(
          'SignIn.registerText',
          {
            link: (
              <Link
                as={RouterLink}
                to={`/${PATHS.REGISTER}`}
              >
                {t('SignIn.registerLink')}
              </Link>
            ),
          },
        )
      }
    </Text>
  </Layout>
);
