import React from 'react';
import {
  SelectField,
  PhoneField,
  RadioButton,
  RadioButtonField,
  TextField,
} from '@components/Form/Field';
import {
  Box,
  Flex,
  Text,
  HStack,
  Spinner,
  Collapse,
} from 'ui';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';
import { ts, companyNameLabel, EmploymentFormTitle } from './constants';

const requiredFieldProps = (required) => {
  const props = {};
  if (!required) props.display = 'none';
  return props;
};

export const Employment = () => {
  const {
    onBack,
    control,
    loading,
    onSubmit,
    phoneCodes,
    employment,
    isSubmitting,
    hasTaxSituation,
    frequencyOptions,
    isRequiredPosition,
    isRequiredCompanyName,
    isRequiredCompanyYears,
    isRequiredCompanyPhone,
    isRequiredHasTaxSituation,
    isRequiredPaymentFrequency,
  } = useController();

  if (loading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        m="auto"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <CreditForm
      onBack={onBack}
      title={EmploymentFormTitle[employment?.id] || EmploymentFormTitle.default}
      onSubmit={onSubmit}
      loading={isSubmitting}
    >
      <TextField
        control={control}
        name="companyName"
        data-testid="companyNameField"
        labelText={companyNameLabel[employment?.id] || companyNameLabel.default}
        {...requiredFieldProps(isRequiredCompanyName)}
      />
      <TextField
        control={control}
        name="companyPosition"
        data-testid="companyPositionField"
        labelText={ts('companyPositionLabel')}
        {...requiredFieldProps(isRequiredPosition)}
      />
      <SelectField
        name="paymentFrequency"
        control={control}
        data-testid="frequencyField"
        labelText={ts('frequencyLabel')}
        placeholder={ts('frequencyPlaceholder')}
        {...requiredFieldProps(isRequiredPaymentFrequency)}
      >
        {frequencyOptions.map((frequency) => (
          <option key={frequency.value} value={frequency.value}>
            {frequency.label}
          </option>
        ))}
      </SelectField>
      <TextField
        control={control}
        name="companyYears"
        type="number"
        maxLength={2}
        data-testid="companyYearsField"
        labelText={ts('companyYearsLabel')}
        {...requiredFieldProps(isRequiredCompanyYears)}
      />
      <HStack alignItems="start" {...requiredFieldProps(isRequiredCompanyPhone)}>
        <PhoneField
          control={control}
          options={phoneCodes.options}
          name="companyPhone"
          data-testid="companyPhone"
          showCode={false}
          labelText={ts('companyPhoneNumberLabel')}
        />
        <TextField
          control={control}
          name="companyExtensionNumber"
          type="number"
          width="33%"
          data-testid="companyExtensionNumberField"
          labelText={ts('companyExtensionNumberLabel')}
        />
      </HStack>
      <Box>
        <RadioButtonField
          control={control}
          variant="wrap"
          name="hasTaxSituation"
          id="hasTaxSituation"
          labelText={ts('hasTaxSituationLabel')}
          w="100%"
          mb="0.4rem"
          {...requiredFieldProps(isRequiredHasTaxSituation)}
        >
          <RadioButton
            value="yes"
          >
            {ts('answers.true')}
          </RadioButton>
          <RadioButton
            value="no"
          >
            {ts('answers.false')}
          </RadioButton>
        </RadioButtonField>
        <Collapse
          in={hasTaxSituation}
          animateOpacity
        >
          <Text
            color="gray.500"
          >
            {ts('taxSituationNote')}
          </Text>
        </Collapse>
      </Box>
      <RadioButtonField
        control={control}
        variant="wrap"
        name="hasImss"
        id="hasImss"
        labelText={ts('hasImssLabel')}
        w="100%"
      >
        <RadioButton
          value="yes"
        >
          {ts('answers.true')}
        </RadioButton>
        <RadioButton
          value="no"
        >
          {ts('answers.false')}
        </RadioButton>
      </RadioButtonField>
      <RadioButtonField
        control={control}
        variant="wrap"
        name="hasSgmm"
        id="hasSgmm"
        labelText={ts('hasSgmmLabel')}
        w="100%"
      >
        <RadioButton
          value="yes"
        >
          {ts('answers.true')}
        </RadioButton>
        <RadioButton
          value="no"
        >
          {ts('answers.false')}
        </RadioButton>
      </RadioButtonField>
    </CreditForm>
  );
};
