import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@reducks/session';
import { useSetHTMLAttrs } from '@hooks/htmlDocument';
import { useTracker } from './useTracker';
import { useLocationTracker } from './useLocationTracker';

export const useController = () => {
  useSetHTMLAttrs();
  useTracker();
  useLocationTracker();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  return {
    isAuthenticated,
  };
};
