import React from 'react';
import { Icon } from 'ui';

export const EmploymentIcon = (props) => (
  <Icon viewBox="0 0 21 19" {...props}>
    <mask id="mask0_1923_890" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="19">
      <path d="M17.4922 15.0825C17.4922 15.6345 17.0442 16.0825 16.4922 16.0825C15.9402 16.0825 15.4922 15.6345 15.4922 15.0825C15.4922 14.5305 15.9402 14.0825 16.4922 14.0825C17.0442 14.0825 17.4922 14.5305 17.4922 15.0825ZM10.4922 12.0825H2.49219L2.50819 2.08252H13.4922L13.4762 12.0825H10.4922ZM5.49219 15.0825C5.49219 15.6345 5.04419 16.0825 4.49219 16.0825C3.94019 16.0825 3.49219 15.6345 3.49219 15.0825C3.49219 14.5305 3.94019 14.0825 4.49219 14.0825C5.04419 14.0825 5.49219 14.5305 5.49219 15.0825ZM18.4922 9.56252V12.0825H15.4922V7.16352L18.4922 9.56252ZM20.1172 8.30152L15.4922 4.60152V2.08252C15.4922 0.979519 14.6792 0.0825195 13.6792 0.0825195H2.30419C1.30519 0.0825195 0.492188 0.979519 0.492188 2.08252V12.0825C0.492188 12.9555 1.00519 13.6915 1.71319 13.9645C1.57319 14.3105 1.49219 14.6865 1.49219 15.0825C1.49219 16.7365 2.83819 18.0825 4.49219 18.0825C6.14619 18.0825 7.49219 16.7365 7.49219 15.0825C7.49219 14.7295 7.42019 14.3965 7.30719 14.0825H10.4922H13.6772C13.5642 14.3965 13.4922 14.7295 13.4922 15.0825C13.4922 16.7365 14.8382 18.0825 16.4922 18.0825C18.1462 18.0825 19.4922 16.7365 19.4922 15.0825C19.4922 14.7295 19.4202 14.3965 19.3072 14.0825H19.4922C20.0452 14.0825 20.4922 13.6355 20.4922 13.0825V9.08252C20.4922 8.77852 20.3542 8.49152 20.1172 8.30152Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1923_890)">
      <path d="M17.4922 15.0825C17.4922 15.6345 17.0442 16.0825 16.4922 16.0825C15.9402 16.0825 15.4922 15.6345 15.4922 15.0825C15.4922 14.5305 15.9402 14.0825 16.4922 14.0825C17.0442 14.0825 17.4922 14.5305 17.4922 15.0825ZM10.4922 12.0825H2.49219L2.50819 2.08252H13.4922L13.4762 12.0825H10.4922ZM5.49219 15.0825C5.49219 15.6345 5.04419 16.0825 4.49219 16.0825C3.94019 16.0825 3.49219 15.6345 3.49219 15.0825C3.49219 14.5305 3.94019 14.0825 4.49219 14.0825C5.04419 14.0825 5.49219 14.5305 5.49219 15.0825ZM18.4922 9.56252V12.0825H15.4922V7.16352L18.4922 9.56252ZM20.1172 8.30152L15.4922 4.60152V2.08252C15.4922 0.979519 14.6792 0.0825195 13.6792 0.0825195H2.30419C1.30519 0.0825195 0.492188 0.979519 0.492188 2.08252V12.0825C0.492188 12.9555 1.00519 13.6915 1.71319 13.9645C1.57319 14.3105 1.49219 14.6865 1.49219 15.0825C1.49219 16.7365 2.83819 18.0825 4.49219 18.0825C6.14619 18.0825 7.49219 16.7365 7.49219 15.0825C7.49219 14.7295 7.42019 14.3965 7.30719 14.0825H10.4922H13.6772C13.5642 14.3965 13.4922 14.7295 13.4922 15.0825C13.4922 16.7365 14.8382 18.0825 16.4922 18.0825C18.1462 18.0825 19.4922 16.7365 19.4922 15.0825C19.4922 14.7295 19.4202 14.3965 19.3072 14.0825H19.4922C20.0452 14.0825 20.4922 13.6355 20.4922 13.0825V9.08252C20.4922 8.77852 20.3542 8.49152 20.1172 8.30152Z" fill="currentColor" />
    </g>
  </Icon>
);
