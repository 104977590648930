import { tscope } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { parseAmount } from '@lib/parsers';

export const ts = tscope('Requisition.confirmRequisition');
const isDivisibleBy100 = (amount) => amount % 100 !== 0;

function resolver(values, context) {
  const {
    requestedAmount,
    loanDestination,
    paymentTerms,
  } = values;

  const {
    maximumAmount,
    minimumAmount,
  } = context;

  const errors = {
    requestedAmount: Validator(parseAmount(requestedAmount))
      .required()
      .custom(isDivisibleBy100, ts('amount.errors.divisibleBy100'))
      .range({
        min: minimumAmount, max: maximumAmount, type: 'currency', precision: 0,
      })
      .result(),
    loanDestination: Validator(loanDestination).required().result(),
    paymentTerms: Validator(paymentTerms).required().result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
