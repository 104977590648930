import { t } from '@i18n';

const SPECIAL_CHARS_REGEXP = /[^\p{L}\s'-]/u;

const includesSpecialChars = (str) => SPECIAL_CHARS_REGEXP.test(str || '');

function validate(input, message = null) {
  if (!includesSpecialChars(input)) return null;

  return {
    type: 'danger',
    message: message || t('FormResolver.onlyLetters'),
    value: input,
  };
}

export default validate;
