import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_USER_MUTATION = gql`
mutation UpsertRequisition($requisitionInput: UpsertRequisitionMutationInput!, $userInput: UpdateUserMutationInput!) {
  upsertRequisition(input: $requisitionInput) {
    activeRequisition {
      id
      income {
        id
        paymentFrequency
        employment {
          id
          name
        }
      }
    }
  }
  updateUser(input: $userInput) {
    user {
      id
      workingSector {
        id
        name
      }
      workingActivity {
        id
        name
        sectorId
      }
    }
  }
}
`;

export const useUpdateUserMutation = (options) => useMutation(UPDATE_USER_MUTATION, options);
