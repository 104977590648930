export const PATHS = {
  CONFIRM: 'confirm',
  ADDRESS: 'address',
  HOUSEHOLD: 'household',
  WORK: 'work',
  EMPLOYMENT: 'employment',
  INCOME: 'income',
  EXPENSES: 'expenses',
  AUTHORIZE: 'authorize',
  BURO: 'buro',
  BURO_OFFLINE: 'offline',
  BURO_UNPROCESSABLE: 'unprocessable',
  BURO_BLOCKED: 'blocked',
};

export const CREDIT_STEPS = {
  REQUEST: 0,
  PRE_APPROVAL: 1,
  DOCUMENTS: 2,
  APPROVAL: 3,
  FUNDING: 4,
  FORMALIZE: 5,
};
