import React from 'react';
import { tscope } from '@i18n';
import {
  TextField,
  PhoneField,
  RadioButton,
  RadioButtonField,
} from '@components/Form/Field';
import { FunnelForm } from '../components';
import { useController } from './hooks';

const ts = tscope('Register.basicUpdate');

export const BasicUpdate = () => {
  const {
    control,
    onSubmit,
    phoneCodes,
    isSubmitting,
    shouldFillPhoneNumber,
  } = useController();

  return (
    <FunnelForm
      title={ts('title')}
      subtitle={ts('description')}
      onSubmit={onSubmit}
      loading={isSubmitting}
    >
      <TextField
        control={control}
        name="firstName"
        data-testid="firstName"
        labelText={ts('firstNameField.label')}
      />
      <TextField
        control={control}
        name="secondName"
        data-testid="secondName"
        labelText={ts('secondNameField.label')}
      />
      <TextField
        control={control}
        name="firstLastName"
        data-testid="firstLastName"
        labelText={ts('firstLastNameField.label')}
      />
      <TextField
        control={control}
        name="secondLastName"
        data-testid="secondLastName"
        labelText={ts('secondLastNameField.label')}
      />
      {
        shouldFillPhoneNumber
        && (
          <PhoneField
            control={control}
            options={phoneCodes.options}
            name="phone"
            data-testid="phone"
            labelText={ts('phoneField.label')}
          />
        )
      }
      <RadioButtonField
        name="gender"
        control={control}
        labelText={ts('genderField.label')}
        w="100%"
      >
        <RadioButton value="male">
          {ts('genderField.male')}
        </RadioButton>
        <RadioButton value="female">
          {ts('genderField.female')}
        </RadioButton>
      </RadioButtonField>
    </FunnelForm>
  );
};
