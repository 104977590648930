import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const apiServiceStatus = (builder) => ({
  apiServiceStatus: builder.query({
    query: () => ({
      url: endpoints.apiService.status.url,
      method: endpoints.apiService.status.method,
    }),
    transformResponse,
  }),
});

export default apiServiceStatus;
