import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';
import { USER_PROFILE_FIELDS } from './useUserQuery';

export const UPDATE_USER_MUTATION = gql`
  ${USER_PROFILE_FIELDS}
  mutation ($profile: ProfileInput!) {
    updateUser(input: { profile: $profile }) {
      user {
        ...UserProfileFields
      }
    }
  }
`;

export const useUserMutation = (options) => useMutation(UPDATE_USER_MUTATION, options);
