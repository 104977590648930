import endpoints from '@config/endpoints';
import transformResponse from '@config/services/transformResponse';

const unlockAccount = (builder) => ({
  unlockAccount: builder.mutation({
    query: (token) => ({
      url: endpoints.auth.unlockAccount.url,
      method: endpoints.auth.unlockAccount.method,
      params: {
        unlock_token: token,
      },
      responseHandler: (response) => response.json(),
    }),
    transformResponse,
  }),
});

export default unlockAccount;
