import { USER } from '@config/constants';

const DEFAULT_ROLE = USER.ROLES.BORROWER;

export const parseString = (input) => (input || '').trim();

export const parseRole = (input) => {
  const key = parseString(input).toUpperCase();
  return USER.ROLES[key] || DEFAULT_ROLE;
};
