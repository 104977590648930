import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_EXPENSES = gql`
  query GET_EXPENSES {
    activeRequisition {
      id
      spending {
        id
        expenses {
          id
          amount
          expenseId
        }
      }
    }
  }
`;

export const useGetExpensesQuery = (options = {}) => useQuery(GET_EXPENSES, {
  ...options,
});
