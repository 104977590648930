import React from 'react';
import PropTypes from 'prop-types';
import { t, jsx, tscope } from '@i18n';
import {
  Text,
  Heading,
  Grid,
} from 'ui';
import styles from './PreApprovalContainer.styles';

const ts = tscope('Requisition.buroSteps');

export const ContactUsText = (props) => (
  <Text
    data-testid="Buro.contactUs"
    textAlign="center"
    {...props}
  >
    {jsx('questions', {
      t: ts,
      contact: (
        <Text
          as="a"
          href={`mailto:${t('Common.brand.borrowersContact.email')}`}
        >
          {t('Common.brand.borrowersContact.email')}
        </Text>),
    })}
  </Text>
);

export const PreApprovalContainer = ({
  title,
  loading,
  children,
  ...contentProps
}) => (
  <Grid
    {...styles.outerContainer}
    {...contentProps}
  >
    {title && (
      <Heading
        {...styles.title}
      >
        {title}
      </Heading>
    )}
    {children}
    <ContactUsText />
  </Grid>
);

PreApprovalContainer.defaultProps = {
  title: null,
  children: null,
};

PreApprovalContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
