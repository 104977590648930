import React from 'react';
import { t } from '@i18n';
import {
  Grid,
  Card,
  Alert,
  Button,
  Heading,
  AlertIcon,
  AlertDescription,
} from 'ui';
import { PromoCodesWidget } from '@components';
import Checkbox from '@components/Form/Field/Checkbox';
import { EditableField } from '@components/Form/EditableField';
import { PATHS } from '@navigators/Root/App/Profile/constants';
import { useController } from './hooks/useController';
import { ReferralCouponWrapper } from '../components';

export const Confirm = () => {
  const {
    control,
    labels,
    isLoading,
    isSubmitting,
    confirmUserHandler,
  } = useController();

  return (
    <Grid
      maxWidth="28rem"
      gridRowGap="1rem"
      alignItems="center"
      data-testid="confirm-container"
      mx={{ base: '2rem', sm: 'auto' }}
    >
      <Heading
        textAlign="center"
        fontSize="1rem"
        color="gray.500"
        fontWeight="normal"
      >
        {t('Register.profileConfirm.description')}
      </Heading>
      <Heading
        textAlign="center"
        fontSize="2xl"
        color="gray.700"
        mb="2rem"
      >
        {t('Register.profileConfirm.title')}
      </Heading>

      <Card p={{ base: '1rem', md: '2rem' }} gridRowGap="1rem">
        <EditableField
          id="nameFieldId"
          name="nameField"
          linkTitle={t('Register.profileConfirm.nameField.title')}
          labelText={t('Register.profileConfirm.nameField.label')}
          path="../"
          value={labels.name}
          isLoading={isLoading}
        />
        <EditableField
          id="genderFieldId"
          name="genderField"
          linkTitle={t('Register.profileConfirm.genderField.title')}
          labelText={t('Register.profileConfirm.genderField.label')}
          path="../"
          value={labels.gender}
          isLoading={isLoading}
        />
        <EditableField
          id="birthFieldId"
          name="birthField"
          linkTitle={t('Register.profileConfirm.birthField.title')}
          labelText={t('Register.profileConfirm.birthField.label')}
          path={`../${PATHS.BIRTH}`}
          value={labels.birth}
          isLoading={isLoading}
        />
        <EditableField
          id="curpFieldId"
          name="curpField"
          linkTitle={t('Register.profileConfirm.curpField.title')}
          labelText={t('Register.profileConfirm.curpField.label')}
          path={`../${PATHS.IDENTIFICATION}`}
          value={labels.curp}
          isLoading={isLoading}
        />
        <EditableField
          id="rfcFieldId"
          name="rfcField"
          linkTitle={t('Register.profileConfirm.rfcField.title')}
          labelText={t('Register.profileConfirm.rfcField.label')}
          path={`../${PATHS.IDENTIFICATION}`}
          value={labels.rfc}
          isLoading={isLoading}
        />
      </Card>

      <ReferralCouponWrapper marginTop="1rem">
        <PromoCodesWidget />
      </ReferralCouponWrapper>

      <Card p={{ base: '1rem', md: '2rem' }}>
        <Grid as="form" onSubmit={confirmUserHandler} rowGap={{ base: '1rem', md: '2rem' }}>
          <Checkbox control={control} name="term2" data-testid="term2">
            {t('Register.profileConfirm.term2Field.label')}
          </Checkbox>
          <Alert colorScheme="gray" variant="subtle" borderRadius="xl" status="info" alignItems="flex-start">
            <AlertIcon />
            <AlertDescription>
              {t('Register.profileConfirm.notice.description')}
            </AlertDescription>
          </Alert>
          <Button
            isDisabled={isLoading || isSubmitting}
            isLoading={isSubmitting}
            type="submit"
            data-testid="submit"
          >
            {t('Register.profileConfirm.submit')}
          </Button>
        </Grid>
      </Card>
    </Grid>
  );
};
