import { usePreventMountElements } from './usePreventMountElements';
import { useUserQuery } from './useUserQuery';

export const useController = () => {
  const { data } = useUserQuery();
  const { activeRequisition } = data || {};
  const preventMountElementProps = usePreventMountElements({ activeRequisition });

  return {
    preventMountElementProps,
  };
};
