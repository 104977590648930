export default {
  outerContainer: {
    marginX: 'auto',
    alignSelf: 'start',
    paddingX: { base: '2rem', sm: '1rem' },
    maxWidth: { base: '100%', sm: '28rem' },
    templateRows: 'auto',
    rowGap: '10',
    alignItems: 'center',
    justifyItems: 'center',
  },
  subtitle: {
    fontSize: '1rem',
    color: 'gray.500',
    fontWeight: 'normal',
  },
  title: {
    fontSize: '2xl',
    color: 'gray.700',
    textAlign: 'center',
  },
};
