import React from 'react';
import { tscope } from '@i18n';
import { Flex, Link } from 'ui';
import { TextField } from '@components/Form/Field';
import { MAX_RFC_LENGTH, MAX_CURP_LENGTH } from '@config/constants';
import { FunnelForm } from '../components';
import { useController } from './hooks';

const ts = tscope('Register.identification');

export const IdentificationUpdate = () => {
  const {
    goBack,
    control,
    onSubmit,
    isSubmitting,
  } = useController();

  return (
    <FunnelForm
      title={ts('title')}
      subtitle={ts('description')}
      onSubmit={onSubmit}
      onBack={goBack}
      loading={isSubmitting}
    >
      <TextField
        control={control}
        name="rfc"
        data-testid="rfc"
        maxLength={MAX_RFC_LENGTH}
        labelText={ts('rfcField.label')}
        placeholder={ts('rfcField.placeholder')}
      />
      <Flex justifyContent="flex-end" w="full">
        <Link href={ts('rfcField.helpLink')} target="_blank" fontSize="sm">{ts('rfcField.helpText')}</Link>
      </Flex>
      <TextField
        control={control}
        name="curp"
        data-testid="curp"
        maxLength={MAX_CURP_LENGTH}
        labelText={ts('curpField.label')}
        placeholder={ts('curpField.placeholder')}
      />
      <Flex justifyContent="flex-end" w="full">
        <Link href={ts('curpField.helpLink')} target="_blank" fontSize="sm">{ts('curpField.helpText')}</Link>
      </Flex>
    </FunnelForm>
  );
};
