import { reducer as sessionReducer } from '@reducks/session';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { apiStatusSlice } from '@reducks/serviceStatus/serviceStatus';
import { reducer as zipCodesReducers } from '@reducks/zipCodes';
import { api, borrowerApi } from '@services/yoteprestoAPI';
import { initialState, storeStrategyMiddleware } from './persistReduxState';
import { unavailableServiceMiddleware } from './middleware';

export const createStore = ({ state = initialState } = {}) => configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [borrowerApi.reducerPath]: borrowerApi.reducer,
    session: sessionReducer,
    apiStatus: apiStatusSlice.reducer,
    zipCodes: zipCodesReducers,
  },
  preloadedState: state,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(unavailableServiceMiddleware)
    .concat(api.middleware)
    .concat(borrowerApi.middleware)
    .concat(storeStrategyMiddleware),
});

const store = createStore();

setupListeners(store.dispatch);

export default store;
