import React from 'react';
import {
  Grid,
  Button,
  ViewIcon,
  IconButton,
  ViewOffIcon,
} from 'ui';
import { useController } from './hooks/useController';
import TextGroupField from '../Field/Text/TextGroup';

export const AuthorizeWithPassword = (props) => {
  const {
    label,
    loading,
    onSubmit,
    submitText,
  } = props;
  const {
    control,
    isVisible,
    handleSubmit,
    onWatchPassword,
  } = useController({ onSubmit });

  return (
    <Grid
      as="form"
      rowGap="1rem"
      id="authorizePasswordForm"
      onSubmit={handleSubmit}
    >
      <TextGroupField
        autoFocus
        id="password"
        name="password"
        type={isVisible ? 'text' : 'password'}
        control={control}
        labelText={label}
        data-testid="Authorize.password"
        rightContent={(
          <IconButton
            size="sm"
            type="button"
            variant="ghost"
            animationType="none"
            data-testid="Authorize.password-toggle"
            icon={isVisible ? <ViewIcon /> : <ViewOffIcon />}
            onClick={onWatchPassword}
          />
        )}
      />
      <Button
        type="submit"
        colorScheme="green"
        isLoading={loading}
        form="authorizePasswordForm"
      >
        {submitText}
      </Button>
    </Grid>
  );
};
