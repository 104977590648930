import { getAppSubdomain } from '@config';
import { isNil } from '@lib/Monads';
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';

const HEIGHT_EVENT = 'yotepresto::setWidgetHeight';
const REDIRECT_EVENT = 'yotepresto::redirect';

export const useController = () => {
  const formRef = useRef();
  const [formHeight, setFormHeight] = useState(null);
  const formRect = formRef.current?.getBoundingClientRect();

  useEffect(() => {
    if (isNil(formHeight)) return;
    window.parent.postMessage([HEIGHT_EVENT, Math.ceil(formHeight)], '*');
  }, [formHeight]);

  useLayoutEffect(() => {
    setFormHeight(formRef?.current?.clientHeight);
  }, [formRect]);

  const onSuccess = useCallback(() => {
    const url = getAppSubdomain();
    window.parent.location.href = url;
    window.parent.postMessage([REDIRECT_EVENT, url], '*');
  }, []);

  return {
    formRef,
    onSuccess,
  };
};
