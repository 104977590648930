import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_INCOME = gql`
  mutation($input: UpsertRequisitionMutationInput!) {
    upsertRequisition(input: $input) {
      activeRequisition {
        id
        income {
          id
          income
          otherIncome
        }
      }
    }
  }
`;

export const useUpdateIncomeMutation = (options) => useMutation(UPDATE_INCOME, options);
