import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query BASIC_USER_DATA {
    user {
      id
      roleName
      firstName
    }
    activeRequisition {
      id
      status
      dateToStartANewRequisition
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_GQL, options);
