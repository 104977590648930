import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from 'ui';
import Base from '../Base';
import buildInputTypes from './buildInputTypes';
import { getTypeProps } from './utils';

const TextGroupField = (props) => {
  const {
    id,
    name,
    type,
    size,
    control,
    autoFocus,
    placeholder,
    leftContent,
    phoneLength,
    autoComplete,
    rightContent,
    'data-testid': dataTestId,
    ...contentProps
  } = props;

  const {
    field: {
      ref,
      value,
      onBlur,
      onChange,
    },
    fieldState: { error },
  } = useController({ name, control });

  const inputType = useMemo(() => buildInputTypes({ type }), [type]);
  const isPassword = type === 'password';
  const typePasswordDecorationProps = useMemo(
    () => (isPassword ? getTypeProps('password') : {}),
    [isPassword],
  );
  const testid = dataTestId ? `${dataTestId}-input` : null;

  return (
    <Base id={id} control={control} name={name} data-testid={dataTestId} {...contentProps}>
      <InputGroup w="100%">
        {leftContent && (
          <InputLeftElement
            height="100%"
            color="gray.500"
            pointerEvents="none"
          >
            {leftContent}
          </InputLeftElement>
        )}
        <Input
          id={id}
          ref={ref}
          name={name}
          size={size}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          isInvalid={!!error}
          data-testid={testid}
          autoFocus={autoFocus}
          type={inputType.type}
          borderColor="gray.400"
          as={inputType.inputAs}
          phoneLength={phoneLength}
          placeholder={placeholder}
          autoComplete={autoComplete}
          {...typePasswordDecorationProps}
        />
        {rightContent && (
          <InputRightElement
            height="100%"
            color="gray.500"
          >
            {rightContent}
          </InputRightElement>
        )}
      </InputGroup>
    </Base>
  );
};

TextGroupField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  leftContent:
    PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  'data-testid': PropTypes.string,
  autoComplete: PropTypes.string,
};

export default TextGroupField;
