import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query {
    user {
      id
    }
    activeRequisition {
      id
      status
    }
  }
`;

export const useUser = (options) => useQuery(USER_GQL, options);
