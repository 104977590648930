import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY_GQL = gql`
  query USER_GQL {
    user {
      id
      firstName
      cellPhoneNumber
    }
    activeRequisition {
      id
      references {
        id
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY_GQL, options);
