import { useCallback, useState } from 'react';

export const STEPS = {
  INITIAL: 0,
  OPEN: 1,
  COMPLETE: 2,
};

export const useSwitchController = () => {
  const [step, setStep] = useState(STEPS.INITIAL);

  const openCardHandler = useCallback(() => {
    setStep(STEPS.OPEN);
  }, []);

  const cancelHandler = useCallback(() => {
    setStep(STEPS.INITIAL);
  }, []);

  const completeHandler = useCallback(() => {
    setStep(STEPS.COMPLETE);
  }, []);

  return {
    step,
    open: openCardHandler,
    cancel: cancelHandler,
    complete: completeHandler,
  };
};
