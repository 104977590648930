import { t } from '@i18n';
import { parseDigits } from '@lib/parsers';

const wrapError = (message) => ({
  message,
  type: 'danger',
});

export function isZero(value, message = t('FormResolver.graterThanZero')) {
  if (['', null, undefined].some((val) => val === value)) return wrapError(t('FormResolver.isNaN'));
  const parsed = Number(parseDigits(value));
  if (Number.isNaN(parsed)) return wrapError(t('FormResolver.isNaN'));
  if (parsed !== 0) return null;
  return wrapError(message);
}
