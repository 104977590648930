import React from 'react';
import PropTypes from 'prop-types';
import { EllipsisContent, Dot } from './LoaderEllipsis.styles';

function LoaderEllipsis(props) {
  const { bgColorDot, countDots } = props;

  return (
    <EllipsisContent>
      {Array.from({ length: countDots }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Dot bgColor={bgColorDot} key={index} />
      ))}
    </EllipsisContent>
  );
}

LoaderEllipsis.defaultProps = {
  bgColorDot: 'black',
  countDots: 3,
};

LoaderEllipsis.propTypes = {
  bgColorDot: PropTypes.string,
  countDots: PropTypes.number,
};

export default LoaderEllipsis;
