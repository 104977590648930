import { t } from '@i18n';
import required from './required';

function validate(value, condition, message = t('FormResolver.required')) {
  if (!condition) return null;

  return required(value, message);
}

export default validate;
