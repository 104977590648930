export const getTypeProps = (type) => {
  const propsByType = {
    password: {
      fontFamily: 'password',
      letterSpacing: 1.5,
      _placeholder: { fontFamily: 'proxima-nova', letterSpacing: 'normal' },
    },
  };
  return propsByType[type] || {};
};
