import React from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { CreateAccount } from '@views/Register';
import { PasswordRecovery } from './PasswordRecovery';
import { PATHS } from './constants';
import { SignIn } from './SignIn';
import { useController } from './hooks';

export const Guest = () => {
  useController();

  return (
    <Routes>
      <Route
        path={`${PATHS.SIGN_IN}/*`}
        element={<SignIn />}
      />
      <Route
        path={`${PATHS.PASSWORD_RECOVERY}/*`}
        element={<PasswordRecovery />}
      />
      <Route
        path={`${PATHS.REGISTER}/*`}
        element={<CreateAccount enableScroll />}
      />
      <Route
        index
        element={<Navigate to={PATHS.SIGN_IN} replace />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${PATHS.SIGN_IN}`} replace />}
      />
    </Routes>
  );
};
