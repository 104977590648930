import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FullscreenLoader } from '@components';
import { SocketProvider } from '@providers/socket';
import { PreventMountRoute } from '@components/navigation';
import { ConfirmEmail } from '@views/Register';
import { CancelledRequisition } from '@views/CancelledRequisition';
import { References } from '@views/Approval';
import { useController } from './hooks';
import { Profile } from './Profile';
import { PasswordRecovery } from './PasswordRecovery';
import {
  CreditRequest,
  CreditPreApproval,
} from './Credit';
import { PATHS } from './constants';
import { PATHS as CREDIT_PATHS } from './Credit/constants';
import {
  PathResolver,
  SessionKeeper,
  ActivityMonitor,
} from './components';
import { CreditLayout } from './Credit/components';

export const App = () => {
  const {
    isLoading,
    allowedRouteMap,
    resolverOptions,
  } = useController();

  if (isLoading) return <FullscreenLoader />;

  return (
    <SocketProvider>
      <SessionKeeper>
        <ActivityMonitor />
        <Routes>
          <Route
            path={PATHS.CONFIRM_EMAIL}
            element={(
              <PreventMountRoute
                isAllowed={allowedRouteMap[PATHS.CONFIRM_EMAIL]}
              >
                <ConfirmEmail />
              </PreventMountRoute>
            )}
          />
          <Route
            path={`${PATHS.PROFILE}/*`}
            element={<Profile />}
          />
          <Route
            path={`${PATHS.PASSWORD_RECOVERY}/*`}
            element={(
              <PreventMountRoute
                isAllowed={allowedRouteMap[PATHS.PASSWORD_RECOVERY]}
              >
                <PasswordRecovery />
              </PreventMountRoute>
            )}
          />
          {/*  For the borrower flow: Every step of
           the lateral menu is a new Route at this level */}
          <Route element={<CreditLayout />}>
            <Route
              path={`${PATHS.REQUISITION}/*`}
              element={(
                <PreventMountRoute
                  isAllowed={allowedRouteMap[PATHS.REQUISITION]}
                >
                  <CreditRequest />
                </PreventMountRoute>
              )}
            />
            <Route
              path={`${PATHS.PRE_APPROVAL}/*`}
              element={(
                <PreventMountRoute
                  isAllowed={allowedRouteMap[PATHS.PRE_APPROVAL]}
                >
                  <CreditPreApproval />
                </PreventMountRoute>
              )}
            />
            <Route
              path={PATHS.REFERENCES}
              element={(
                <PreventMountRoute
                  isAllowed={allowedRouteMap[PATHS.REFERENCES]}
                >
                  <References />
                </PreventMountRoute>
              )}
            />
          </Route>
          <Route
            path={PATHS.CANCELLED_REQUISITION}
            element={(
              <PreventMountRoute
                redirectTo={`/${PATHS.REQUISITION}/${CREDIT_PATHS.ADDRESS}`}
                isAllowed={allowedRouteMap[PATHS.CANCELLED_REQUISITION]}
              >
                <CancelledRequisition />
              </PreventMountRoute>
            )}
          />

          <Route
            path="*"
            element={<PathResolver options={resolverOptions} />}
          />
        </Routes>
      </SessionKeeper>
    </SocketProvider>
  );
};
