import {
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import {
  headersLink,
  directionalLink,
  unavailableServiceLink,
} from '@config/apollo/links';

const client = new ApolloClient({
  link: from([
    unavailableServiceLink,
    headersLink,
    directionalLink,
  ]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default client;
