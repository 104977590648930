import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { UnavailableService } from '@views/Common';
import { useApiService } from '@hooks/apiService/useApiService';
import { useController } from './hooks';
import { App } from './App';
import { Guest } from './Guest';
import { Widgets } from './Widgets';
import { PATHS } from './constants';

export const Root = () => {
  const { isAuthenticated } = useController();
  useApiService();

  /**
   * isAuthenticated "null" means we have a token but don't know if valid.
   * Therefore redirect to App to check against the first request.
   *
   */
  const content = isAuthenticated === false ? <Guest /> : <App />;

  return (
    <Routes>
      <Route
        path={PATHS.UNAVAILABLE_SERVICE}
        element={<UnavailableService />}
        replace
      />
      <Route
        path={`${PATHS.WIDGETS}/*`}
        element={<Widgets />}
      />
      <Route
        path={'/*'}
        element={content}
      />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  );
};
