import React from 'react';
import { tscope } from '@i18n';
import PropTypes from 'prop-types';
import { useLogout } from '@hooks/auth';
import {
  Box,
  Text,
  Header,
  MenuItem,
  MenuDivider,
} from 'ui';
import { LoanEditor } from '@components/LoanEditor';
import { LoanInformation } from '@components/LoanInformation';
import { styles as s } from './Header.styles';

const ts = tscope('headers.public');

export const CreditRequestHeader = (props) => {
  const { title, displayEditableLoan } = props;
  const logout = useLogout();

  return (
    <Header
      {...s.header}
      variant="withDrawer"
      menuListComponents={[
        <MenuItem
          {...s.navItem}
          as="a"
          key="item1"
          target="_blank"
          href={ts('menu.borrowerHelpCenter')}
        >
          {ts('menu.item1.label')}
        </MenuItem>,
        <MenuDivider key="divider1" />,
        <MenuItem
          {...s.navItem}
          as="a"
          key="item2"
          target="_blank"
          href={ts('menu.borrowerFAQLink')}
        >
          {ts('menu.item2.label')}
        </MenuItem>,
        <MenuDivider key="divider2" />,
        <MenuItem
          {...s.navItem}
          as="a"
          key="item3"
          target="_blank"
          href={ts('menu.item3.link')}
        >
          {ts('menu.item3.label')}
        </MenuItem>,
        <MenuDivider key="divider3" />,
        <MenuItem
          key="item4"
          onClick={logout}
        >
          {ts('menu.item4.label')}
        </MenuItem>,
      ]}
    >
      {title && (
        <Box>
          <Text {...s.greeting}>{ts('greeting')}</Text>
          <Text {...s.username}>{title}</Text>
        </Box>
      )}
      {displayEditableLoan === true && <LoanEditor />}
      {displayEditableLoan === false && <LoanInformation />}
    </Header>
  );
};

CreditRequestHeader.defaultProps = {
  title: '',
  displayEditableLoan: false,
};

CreditRequestHeader.propTypes = {
  title: PropTypes.string,
  displayEditableLoan: PropTypes.bool,
};
