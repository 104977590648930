import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_EMPLOYMENT = gql`
  query GET_EMPLOYMENT {
    activeRequisition {
      id
      income {
        id
        hasImss
        hasSgmm
        companyName
        companyYears
        hasTaxSituation
        companyPosition
        paymentFrequency
        companyPhoneNumber
        companyExtensionNumber
        employment {
          id
          name
        }
      }
    }
  }
`;

export const useGetEmploymentQuery = (options = {}) => useQuery(GET_EMPLOYMENT, {
  ...options,
});
