import React from 'react';
import { t } from '@i18n';
import { BuroUnauthorizedImage } from '@assets/images';
import {
  Box,
  Grid,
  Text,
  Heading,
} from 'ui';
import { PrimaryLayout } from '@components/layouts';
import { Redirect } from '@components/navigation';
import { BuroBlockedBanner, CreditForm, Countdown } from './components';
import { styles as s } from './CancelledRequisition.styles';
import { useController } from './hooks';

export const CancelledRequisition = () => {
  const {
    name,
    content,
    roleName,
    isLoading,
    dateToStartANewRequisition,
  } = useController();

  const greeting = t('Requisition.common.greetings', { name });

  const {
    title,
    subtitle,
    displayCountdown,
    displayCreditForm,
    displayBuroBlockedBanner,
    displayRedirectToLegacySite,
  } = content;

  const titleStyle = displayCreditForm ? s.title : s.titlePadding;

  if (isLoading) return null;

  if (displayRedirectToLegacySite) {
    return <Redirect roleName={roleName} />;
  }

  return (
    <PrimaryLayout
      title={greeting}
      position="relative"
    >
      <Box {...s.imageBox}>
        <BuroUnauthorizedImage {...s.image} />
      </Box>
      <Grid {...s.container}>
        <Heading {...titleStyle}>{title}</Heading>
        <Text {...s.subtitle}>
          {subtitle}
        </Text>
        {displayBuroBlockedBanner && <BuroBlockedBanner />}
        {displayCreditForm && <CreditForm />}
        {displayCountdown && <Countdown retryDate={dateToStartANewRequisition} />}
      </Grid>
    </PrimaryLayout>
  );
};
