import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from '@i18n';
import { useCustomToast } from 'ui';
import { saveUserOnBrowser, setUserInfo } from '@reducks/session';
import { useLazyValidateEmailQuery } from '@services/yoteprestoAPI';
import { PATHS } from '@navigators/Root/Guest/constants';
import resolver from '../utils/resolver';

export const useController = () => {
  const toast = useCustomToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    validateEmail,
    validateEmailMeta,
  ] = useLazyValidateEmailQuery();

  const {
    data: emailValidationResult,
    isSuccess: isValidationEmailSuccess,
    isError: isValidationEmailFailed,
    isFetching: isValidatingEmail,
  } = validateEmailMeta;

  const {
    getValues,
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm({
    resolver,
  });

  const fullName = emailValidationResult?.maskedUsername;

  const isSubmitting = formState.isSubmitting || isValidatingEmail;

  const onSubmit = useMemo(
    () => handleSubmit(({ email }) => validateEmail({ email })),
    [handleSubmit, validateEmail],
  );

  useEffect(() => {
    if (isValidationEmailFailed) {
      const message = t('SignIn.errors.badEmail');

      toast({ id: 'signin-error-toast', status: 'error', description: message });
      setError('email', { type: 'error', message });
    }
  }, [isValidationEmailFailed, toast, setError]);

  useEffect(() => {
    if (isValidationEmailSuccess) {
      const [email, rememberUser] = getValues(['email', 'rememberUser']);

      const rememberUserValue = rememberUser ? email : null;

      dispatch(saveUserOnBrowser({ rememberUser: rememberUserValue }));
      dispatch(setUserInfo({ email, fullName }));

      navigate(PATHS.PASSWORD);
    }
  }, [dispatch, fullName, getValues, isValidationEmailSuccess, navigate]);

  return {
    control,
    onSubmit,
    isSubmitting,
  };
};
