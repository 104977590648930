import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const RECOVERY_PASSWORD_MUTATION = gql`
mutation PasswordRecovery($input: PasswordRecoveryMutationInput!) {
  passwordRecovery(input: $input) {
    passwordRecoveryId
  }
}
`;

export const useRecoveryPassword = (options) => useMutation(
  RECOVERY_PASSWORD_MUTATION,
  {
    context: { clientName: 'public' },
    ...options,
  },
);
