import React from 'react';
import { tscope } from '@i18n';
import {
  TextField,
  SelectField,
} from '@components/Form/Field';
import { DrawerForm } from '@components/DrawerForm';
import { Text, Flex } from 'ui';
import { useController } from './hooks';

const ts = tscope('Requisition.loanEditor');

export const LoanEditor = () => {
  const {
    title,
    isOpen,
    onOpen,
    onClose,
    control,
    onSubmit,
    formErrors,
    isSubmitting,
    paymentTerms,
    displayDetails,
    sortedPurposes,
  } = useController();

  return (
    <DrawerForm
      title={title}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      loading={isSubmitting}
      onSubmit={onSubmit}
      formErrors={formErrors}
      titleDetails={ts('yourLoan')}
    >
      <Flex
        columnGap="1rem"
      >
        <TextField
          type="currency"
          name="requestedAmount"
          data-testid="amountField"
          control={control}
          labelText={ts('amountLabel')}
        />
        <SelectField
          name="paymentTerms"
          data-testid="termField"
          control={control}
          labelText={ts('termLabel')}
        >
          {
            paymentTerms.map((term) => (
              <option
                value={term}
                key={term}
              >
                {`${ts('months', { count: term })}`}
              </option>
            ))
          }
        </SelectField>
      </Flex>
      <SelectField
        labelText={ts('destinationLabel')}
        control={control}
        name="loanDestination"
        data-testid="destinationField"
      >
        {sortedPurposes.map(
          (item) => <option value={item.id} key={item.id}>{item.name}</option>,
        )}
      </SelectField>
      {displayDetails && (
        <Text>
          {ts('instructions.payDebt')}
        </Text>
      )}
    </DrawerForm>
  );
};
