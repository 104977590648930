import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationQuery } from '@hooks/catalogs';
import { ORIGIN_COUNTRY_ID_DEFAULT } from '@config/index';
import { PATHS } from '@navigators/Root/App/Profile/constants';
import { useUserMutation, useUserQuery } from '../../hooks';
import { useBirthUpdateForm } from './useBirthUpdateForm';

const REMOVE_COUNTRIES = [ORIGIN_COUNTRY_ID_DEFAULT];

export const useController = () => {
  const navigate = useNavigate();
  const { data: userData } = useUserQuery();
  const { loading: isLoadingCatalogs, data: catalogData } = useLocationQuery();
  const user = userData?.user;
  const isPersonCompleted = user?.flags?.isPersonCompleted;

  const goToNextStep = useCallback(() => {
    const path = isPersonCompleted ? PATHS.CONFIRM : PATHS.IDENTIFICATION;
    navigate(`../${path}`);
  }, [navigate, isPersonCompleted]);

  const onBack = useCallback(() => {
    const path = isPersonCompleted ? PATHS.CONFIRM : '';
    navigate(`../${path}`);
  }, [navigate, isPersonCompleted]);

  const [updateUser, { loading: isSubmitting }] = useUserMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const birthUpdateForm = useBirthUpdateForm({
    user, updateUser,
  });

  const states = catalogData?.catalogs.states || [];

  const countries = useMemo(
    () => [...(catalogData?.catalogs?.countries || [])]
      .filter(({ id }) => !REMOVE_COUNTRIES.includes(id))
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })),
    [catalogData],
  );

  return {
    onBack,
    states,
    countries,
    isSubmitting,
    isLoadingCatalogs,
    ...birthUpdateForm,
  };
};
