import { useMemo, useEffect } from 'react';
import { tscope } from '@i18n/index';
import { USER } from '@config/constants';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@reducks/session';
import { useRoleLazyQuery } from './useRoleLazyQuery';

const ts = tscope('Common.brand');

const I18N_KEYS = {
  [USER.ROLES.BORROWER]: 'borrowersContact',
  [USER.ROLES.INVESTOR]: 'investorsContact',
};
const DEFAULT_ROLE = USER.ROLES.BORROWER;

export const useController = ({ role: propsRole }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [getRole, roleRequest] = useRoleLazyQuery({
    // Ignoring error since this is not a critical operation.
    onError: () => { },
  });

  const i18nKey = useMemo(() => {
    const role = roleRequest?.data?.user?.roleName || propsRole;
    return I18N_KEYS[role] || I18N_KEYS[DEFAULT_ROLE];
  }, [roleRequest, propsRole]);

  useEffect(() => { if (isAuthenticated) getRole(); }, [isAuthenticated, getRole]);

  const {
    email,
    phone,
    whatsapp,
    copyright,
  } = useMemo(() => ({
    email: ts(`${i18nKey}.email`),
    phone: ts(`${i18nKey}.phone`),
    whatsapp: ts(`${i18nKey}.whatsapp`) || '',
    copyright: ts('copyright', { year: new Date().getFullYear() }),
  }), [i18nKey]);

  const whatsappNumber = (number = '') => number.replaceAll(' ', '');

  return {
    email,
    phone,
    whatsapp,
    copyright,
    whatsappNumber,
  };
};
