import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { resolver } from '../resolver';
import { useUpdateUserMutation } from './graphql/useUpdateUserMutation';
import { useUserQuery } from './graphql/useUserQuery';

export const useUpdateUserForm = (options) => {
  const { onSuccess } = options;
  const { data, loading: loadingUser } = useUserQuery();
  const user = data?.user || null;
  const requisition = data?.activeRequisition || null;
  const handleOnSuccess = ({ upsertRequisition }) => {
    onSuccess(upsertRequisition.activeRequisition);
  };
  const [updateUser] = useUpdateUserMutation({
    onCompleted: handleOnSuccess,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    formState,
    handleSubmit,
    getFieldState,
  } = useForm({
    resolver,
    defaultValues: {
      employmentId: '',
      workingSectorId: '',
      workingActivityId: '',
    },
  });
  const { isSubmitting } = formState;
  const workingSectorIdValue = watch('workingSectorId');
  const employmentIdValue = watch('employmentId');
  const { isDirty: isWorkingSectorIdTouched } = getFieldState('workingSectorId', formState);
  const loading = loadingUser || isSubmitting;

  const onSubmit = handleSubmit(async ({ employmentId, workingSectorId, workingActivityId }) => {
    const newUser = {
      workingSectorId,
      workingActivityId,
    };
    await updateUser({
      variables: {
        userInput: { profile: newUser },
        requisitionInput: { requisition: { income: { employmentId } } },
      },
    });
  });

  useEffect(() => {
    if (!isWorkingSectorIdTouched) return;
    setValue('workingActivityId', '');
  }, [setValue, isWorkingSectorIdTouched]);

  useEffect(() => {
    if (!user || !requisition) return;
    reset({
      employmentId: requisition.income?.employment?.id || '',
      workingSectorId: user.workingSector?.id || '',
      workingActivityId: user.workingActivity?.id || '',
    });
  }, [reset, user, requisition]);

  return {
    control,
    loading,
    onSubmit,
    employmentId: employmentIdValue,
    workingSectorId: workingSectorIdValue,
  };
};
