import { t } from '@i18n';

export function custom(value, condition, message = t('FormResolver.custom')) {
  if (!condition(value)) return null;

  return {
    type: 'danger',
    message,
    value,
  };
}
