import { t } from '@i18n';

function validate(value, otherValue, fieldDesc, message = t('FormResolver.equalTo', { fieldDesc })) {
  if (value === otherValue) return null;

  return {
    type: 'danger',
    message,
  };
}

export default validate;
