import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import {
  PreventMountRoute,
} from '@components/navigation';
import {
  Work,
  Income,
  Address,
  Expenses,
  Authorize,
  Household,
  Employment,
  ConfirmRequisition,
} from '@views/Credit';
import { PATHS } from '../constants';
import { useController } from './hooks';
import { PathResolver } from './components';

export const CreditRequest = () => {
  const { preventMountOptions } = useController();

  return (
    <Routes>
      <Route
        path={PATHS.CONFIRM}
        element={<ConfirmRequisition />}
      />
      <Route
        path={PATHS.ADDRESS}
        element={<Address />}
      />
      <Route
        path={PATHS.HOUSEHOLD}
        element={<Household />}
      />
      <Route
        path={PATHS.EMPLOYMENT}
        element={<Employment />}
      />
      <Route
        path={PATHS.WORK}
        element={<Work />}
      />
      <Route
        path={PATHS.INCOME}
        element={<Income />}
      />
      <Route
        path={PATHS.EXPENSES}
        element={<Expenses />}
      />
      <Route
        path={PATHS.AUTHORIZE}
        element={(
          <PreventMountRoute {...preventMountOptions[PATHS.AUTHORIZE]}>
            <Authorize />
          </PreventMountRoute>
        )}
      />
      <Route
        path="*"
        element={<PathResolver />}
      />
    </Routes>
  );
};
