import { tscope } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { parseAmount } from '@lib/parsers';

export const ts = tscope('Requisition.confirmRequisition');
const isDivisibleBy100 = (amount) => amount % 100 !== 0;

export const resolver = (values, context) => {
  const {
    term,
    amount,
    reason,
    destination,
  } = values;

  const {
    maximumAmount,
    minimumAmount,
  } = context;

  const errors = {
    amount: Validator(parseAmount(amount))
      .required()
      .custom(isDivisibleBy100, ts('amount.errors.divisibleBy100'))
      .range({
        min: minimumAmount, max: maximumAmount, type: 'currency', precision: 0,
      })
      .result(),
    destination: Validator(destination).required().result(),
    term: Validator(term).required().result(),
    reason: Validator(reason).required().minLength({ min: 10 }).result(),
  };

  return {
    values,
    errors: removeNilValues(errors),
  };
};
