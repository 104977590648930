import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const REFERRAL_QUERY = gql`
  query REFERRAL_QUERY {
      user {
        id
        referred {
          referenceCode
        }
      }
  }
`;

export const useReferralCodeQuery = (options) => useQuery(REFERRAL_QUERY, options);
