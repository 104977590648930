export default {
  container: {
    gap: '2rem',
    height: '100vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  content: {
    flexDirection: 'column',
  },
  image: {
    mb: { base: '0', sm: '2rem' },
    width: { base: '100%', sm: 'auto' },
  },
  title: {
    px: '2rem',
    textAlign: 'center',
  },
  subtitle: {
    px: '2rem',
    textAlign: 'center',
  },
};
