import { useEffect } from 'react';

// This hook makes sure an input gets validated when
// an async validation changes.
export const useValidationWatcher = ({
  watch,
  trigger,
  isValid,
  inputName,
  isSubmitted,
  setInputState,
}) => {
  const inputValue = watch(inputName);

  useEffect(() => {
    setInputState(inputValue);
  }, [setInputState, inputValue]);

  useEffect(() => {
    if (isSubmitted && isValid !== null) trigger(inputName);
  }, [
    trigger,
    isValid,
    inputName,
    isSubmitted,
  ]);
};
