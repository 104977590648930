export default {
  container: {
    padding: '8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '6',
  },
  header: {
    padding: '0',
  },
  body: {
    padding: '0',
    color: 'gray.500',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    mt: '3',
  },
};
