import { t } from '@i18n';
import { useMemo } from 'react';
import { useLocationQuery } from '../catalogs/useLocationQuery';

const buildOption = ({
  id,
  name,
  emoji,
  phoneCode,
  phoneMaxLength,
}) => ({
  key: id,
  value: phoneCode,
  phoneMaxLength,
  description: t('phoneCodeSelector.option', {
    name,
    emoji,
    phoneCode,
  }),
});

const sorting = (a, b) => a.name.localeCompare(b.name);

export const usePhoneCodes = ({
  acceptedCodes = null,
  acceptedCountries = null,
  sorting: sortingFn = sorting,
} = {}) => {
  const { data, loading } = useLocationQuery();

  const countries = useMemo(
    () => (data?.catalogs?.countries || [])
      .filter(({ phoneCode }) => (acceptedCodes ? acceptedCodes.includes(phoneCode) : true))
      .filter(({ id }) => (acceptedCountries ? acceptedCountries.includes(id) : true))
      .filter(({ phoneCode }) => !!phoneCode)
      .sort(sortingFn),
    [
      data,
      sortingFn,
      acceptedCodes,
      acceptedCountries,
    ],
  );

  const options = useMemo(
    () => countries.map(buildOption),
    [countries],
  );

  const validations = useMemo(
    () => countries.reduce((prev, country) => {
      const { phoneCode, phoneMinLength: min, phoneMaxLength: max } = country;
      if (!prev[phoneCode]) Object.assign(prev, { [phoneCode]: { min, max } });
      const minOverall = Math.min(min, prev[phoneCode].min);
      const maxOverall = Math.max(max, prev[phoneCode].max);
      Object.assign(prev, { [phoneCode]: { min: minOverall, max: maxOverall } });
      return prev;
    }, {}),
    [countries],
  );

  return {
    loading,
    options,
    validations,
  };
};
