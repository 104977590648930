import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';
import responseHandler from './responseHandler';

const signUp = (builder) => ({
  signUp: builder.mutation({
    query: (newUser) => ({
      url: endpoints.auth.signUp.url,
      method: endpoints.auth.signUp.method,
      body: newUser,
      responseHandler,
    }),
    transformResponse,
  }),
});

export default signUp;
