import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const ACTIVE_REQUISITION_GQL = gql`
  query ACTIVE_REQUISITION_GQL {
    activeRequisition {
      id
    }
  }
`;

export const useActiveRequisitionQuery = (options) => useQuery(ACTIVE_REQUISITION_GQL, options);
