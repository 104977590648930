import { useUseRequestBuroReportMutation, useUserQuery } from './graphql';

const MAX_ATTEMPTS = 5;

export const useController = () => {
  const {
    data,
    loading: loadingRequisition,
  } = useUserQuery();
  const { buroAttempts } = data?.activeRequisition || {};
  const attemptsRemaining = MAX_ATTEMPTS - buroAttempts;

  const [
    requestBuroReportMutation,
    { loading },
  ] = useUseRequestBuroReportMutation();

  const requestBuroReport = () => {
    requestBuroReportMutation();
  };

  const showOneAttemptAlert = attemptsRemaining === 1;

  const showWarning = (attemptsRemaining !== MAX_ATTEMPTS) && !loadingRequisition;

  return {
    loading,
    showWarning,
    requestBuroReport,
    attemptsRemaining,
    showOneAttemptAlert,
  };
};
