import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateWithSearch = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback((pathname, options) => {
    const search = searchParams.toString();
    navigate({ pathname, search }, options);
  }, [navigate, searchParams]);
};
