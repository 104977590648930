import { t } from '@i18n';

function validate(value, message = t('FormResolver.minMaxDigits')) {
  if (!value || (String(value).match(/^[0-9]{1,2}$/))) return null;

  return {
    type: 'danger',
    message,
  };
}

export default validate;
