import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

const resolver = (values) => {
  const {
    term2,
  } = values;

  const errors = {
    term2: Validator(term2).truthy().result(),
  };

  return { values, errors: removeNilValues(errors) };
};

export default resolver;
