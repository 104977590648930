import { useEffect } from 'react';
import { useCustomToast } from 'ui';
import { useSearchParams } from 'react-router-dom';

export const useAccountEnabler = ({
  useMutation,
  urlParamName,
  errorToast,
  successToast,
}) => {
  const toast = useCustomToast();
  const [enableAccount, requestStatus] = useMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get(urlParamName);

  useEffect(() => {
    if (token) enableAccount(token);
  }, [enableAccount, token]);

  useEffect(() => {
    const showToast = requestStatus.isError && !toast.isActive(errorToast.id);
    if (showToast) toast(errorToast);
  }, [toast, errorToast, requestStatus.isError]);

  useEffect(() => {
    const showToast = requestStatus.isSuccess && !toast.isActive(successToast.id);
    if (showToast) toast(successToast);
  }, [toast, successToast, requestStatus.isSuccess]);

  // Removes URL param to prevent error on reload.
  useEffect(() => {
    if (!token || requestStatus.isUninitialized) return;
    searchParams.delete(urlParamName);
    setSearchParams(searchParams);
  }, [
    token,
    urlParamName,
    searchParams,
    setSearchParams,
    requestStatus.isUninitialized,
  ]);
};
