/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { tscope } from '@i18n';
import {
  Box,
  Button,
} from 'ui';
import { SearchField } from '@components/Form/SearchField/SearchField';
import { useController } from './hooks/useController';

const ts = tscope('Register.promos');

export const AddReferralCodeForm = (props) => {
  const {
    onCancel,
    ...contentProps
  } = props;

  const {
    control,
    submitHandler,
    isSubmitting,
  } = useController();

  return (
    <Box
      p="1.3rem 1.75rem"
      {...contentProps}
    >
      <Box
        as="form"
        id="addReferralForm"
        onSubmit={submitHandler}
      >
        <SearchField
          label={ts('referral.enter')}
          submitText={ts('common.add')}
          name="referral"
          control={control}
          isLoading={isSubmitting}
          form="addReferralForm"
          textTransform="uppercase"
          data-testid="referralField"
        />
      </Box>
      <Button
        mt="1rem"
        type="button"
        variant="link"
        colorScheme="blue"
        data-testid="CTA-cancel"
        onClick={onCancel}
      >
        {ts('common.cancel')}
      </Button>
    </Box>
  );
};

AddReferralCodeForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
