import { t } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';

const wrapError = (message) => ({ message, type: 'error' });

export function validatePasswordField({ password, isValid }) {
  if (!password) return wrapError(t('FormResolver.required'));
  return isValid === true
    ? null
    : wrapError(t('ConfirmPassword.passwordField.errors.verify'));
}

export function validatePasswordConfirmField({ password, passwordConfirm }) {
  if (!passwordConfirm) return wrapError(t('FormResolver.required'));
  if (password !== passwordConfirm) return wrapError(t('ConfirmPassword.passwordConfirmField.errors.notMatch'));
  return null;
}

export const resolver = async (values, context) => {
  const { password, passwordConfirm } = values;
  const { passIsValid } = context;

  return {
    values: {
      password,
      passwordConfirm,
    },
    errors: removeNilValues({
      password: validatePasswordField({ password, isValid: passIsValid }),
      passwordConfirm: validatePasswordConfirmField({ password, passwordConfirm }),
    }),
  };
};
