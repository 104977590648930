import React from 'react';
import { CreateAccountForm } from '../components';
import { useController } from './hooks';

export const CreateAccountWidget = () => {
  const { formRef, onSuccess } = useController();

  return (
    <CreateAccountForm
      ref={formRef}
      onSuccess={onSuccess}
    />
  );
};
