import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const REQUISITION_GQL = gql`
  query {
    activeRequisition {
      id
      status
      buroAttempts
    }
  }
`;

export const useUserQuery = (options) => useQuery(REQUISITION_GQL, options);
