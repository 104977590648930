import React from 'react';
import { ReactComponent as GreenCheck } from '@assets/components/CreditLateralBar/greenCheck.svg';
import { ReactComponent as BlueCheck } from '@assets/components/CreditLateralBar/blueCheck.svg';
import { STATUS_TAG_STATE } from '../../constants';

export const STATUS_MAP = {
  [STATUS_TAG_STATE.FINISHED]: {
    tagLabel: {
      color: 'white',
    },
    Icon: () => <GreenCheck />,
  },
  [STATUS_TAG_STATE.ACTIVE]: {
    tagLabel: {
      color: 'white',
    },
    Icon: () => <BlueCheck />,
  },
  [STATUS_TAG_STATE.INACTIVE]: {
    tagLabel: {
      color: 'gray.300',
    },
    Icon: () => <BlueCheck />,
  },
};

export const useConfig = (status) => STATUS_MAP[status]
  || STATUS_MAP[STATUS_TAG_STATE.INACTIVE];
