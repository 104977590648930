import React from 'react';
import {
  Center,
  CheckCircleIcon,
  CircularProgress,
  CircularProgressLabel,
} from 'ui';

export const Initial = () => (
  <CircularProgress
    data-testid="initial"
    size="16"
    color="green.500"
    thickness={4}
    capIsRound
    value={0}
  >
    <CircularProgressLabel>
      <CheckCircleIcon
        color="gray.200"
        fontSize="2xl"
      />
    </CircularProgressLabel>
  </CircularProgress>
);

export const InProgress = () => (
  <CircularProgress
    data-testid="inProgress"
    size="16"
    color="green.500"
    thickness={4}
    isIndeterminate
    capIsRound
  >
    <CircularProgressLabel>
      <CheckCircleIcon
        color="gray.200"
        fontSize="2xl"
      />
    </CircularProgressLabel>
  </CircularProgress>
);

export const Completed = () => (
  <Center
    data-testid="completed"
    boxSize="16"
    minWidth="56px"
    bgColor="green.100"
    rounded="full"
  >
    <CheckCircleIcon
      bgColor="white"
      color="green.500"
      fontSize="2xl"
      rounded="full"
    />
  </Center>
);
