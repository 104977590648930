import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query {
    user {
      id
      fullName
      birthDate
      curp
      rfc
      isMexican
      gender
      country {
        id
        name
      }
      birthState {
        id
        name
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
