import { removeNilValues } from '@lib/Object';
import Validator from '@lib/Validator';

export const resolver = (values) => {
  const {
    employmentId,
    workingSectorId,
    workingActivityId,
  } = values;

  return {
    values,
    errors: removeNilValues({
      employmentId: Validator(employmentId).required().result(),
      workingSectorId: Validator(workingSectorId).required().result(),
      workingActivityId: Validator(workingActivityId).required().result(),
    }),
  };
};
