import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { CreateAccountWidget } from '@views/Register';
import { PATHS } from './constants';

export const Widgets = () => (
  <Routes>
    <Route
      path={`${PATHS.REGISTER}/*`}
      element={<CreateAccountWidget />}
    />
    <Route
      path="*"
      element={<Navigate to="../" replace />}
    />
  </Routes>
);
