import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import {
  getValidUserAges,
  ORIGIN_COUNTRY_ID_DEFAULT,
} from '@config';
import resolver from '../resolver';

const CURRENT_YEAR = new Date().getFullYear();
const defaultValues = {
  isForeign: false,
  countryId: ORIGIN_COUNTRY_ID_DEFAULT,
  birthDate: '',
  birthStateId: '',
};

export const useBirthUpdateForm = ({
  user,
  updateUser,
}) => {
  const { min: minAge, max: maxAge } = getValidUserAges({ role: user?.roleName });
  const {
    watch,
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({ resolver, defaultValues, context: { minAge, maxAge } });

  const isForeign = watch('isForeign');

  const submitHandler = ({
    birthStateId,
    countryId,
    birthDate,
  }) => {
    updateUser({
      variables: {
        profile: {
          birthDate,
          countryId: countryId || ORIGIN_COUNTRY_ID_DEFAULT,
          birthStateId: isForeign ? null : Number(birthStateId),
        },
      },
    });
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (isForeign) {
      setValue('birthStateId', '');
      clearErrors(['birthStateId']);
    } else {
      setValue('countryId', '');
      clearErrors(['countryId']);
    }
  }, [setValue, clearErrors, isForeign]);

  useEffect(() => {
    if (!user) return;
    const {
      country,
      birthDate,
      isMexican,
      birthState,
    } = user;
    const newValues = {
      isForeign: isMexican === false,
      countryId: country?.id || ORIGIN_COUNTRY_ID_DEFAULT,
      birthStateId: birthState?.id || '',
      birthDate: birthDate || '',
    };
    reset(newValues);
  }, [user, reset, setValue]);

  return {
    control,
    onSubmit,
    isForeign,
    minYear: CURRENT_YEAR - maxAge - 1,
    maxYear: CURRENT_YEAR,
  };
};
