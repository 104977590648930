import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useMemo } from 'react';
import { useUpdateExpensesForm } from './useUpdateExpensesForm';
import { useUpdateExpensesMutation, useGetExpensesQuery } from './graphql';

const parseExpenses = (acc, expense) => (
  {
    ...acc,
    [expense.expenseId]: {
      id: expense.id,
      amount: expense.amount,
    },
  }
);

export const useController = () => {
  const navigate = useNavigate();

  const { data } = useGetExpensesQuery();

  const expenses = data?.activeRequisition?.spending?.expenses;

  const expensesData = useMemo(() => expenses
    ?.reduce((acc, expense) => parseExpenses(acc, expense), {}), [expenses]);

  const goToNextStep = () => {
    navigate(`../${PATHS.AUTHORIZE}`);
  };

  const [updateExpensesMutation, { loading: isSubmitting }] = useUpdateExpensesMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const onBack = () => navigate(`../${PATHS.INCOME}`);

  const {
    control,
    onSubmit,
  } = useUpdateExpensesForm({
    expensesData,
    updateExpensesMutation,
  });

  return {
    onBack,
    control,
    onSubmit,
    isSubmitting,
  };
};
