import { useRef } from 'react';
import { useNavigate as useOriginalNavigate } from 'react-router-dom';

/**
 * Override useNavigate hook to make navigate function an
 * stable variable so it can be used as hook dependency
 * without looping side effect
 *
 * @returns navigate
 */

export const useNavigate = () => {
  const navigateUnstable = useOriginalNavigate();
  const navigate = useRef(navigateUnstable).current;

  return navigate;
};
