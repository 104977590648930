import { tscope } from '@i18n/index';

const ts = tscope('Currency');

export const currencyParser = new Intl.NumberFormat(ts('locale'), {
  style: 'currency',
  currency: ts('currency'),
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
