import React from 'react';
import PropTypes from 'prop-types';
import { PATHS } from '@navigators/Root/Guest/constants';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { t } from '@i18n';
import {
  Card,
  Grid,
  Text,
  Link,
  Heading,
} from 'ui';
import { RegisterContainer, CreateAccountForm } from '../components';
import { parseRole } from '../utils';

export const CreateAccount = ({ enableScroll }) => {
  const [searchParams] = useSearchParams();
  const role = parseRole(searchParams.get('role'));
  const testimonials = t(`Common.testimonials.${role}`);

  return (
    <RegisterContainer
      testimonials={testimonials}
      enableScroll={enableScroll}
    >
      <Grid rowGap="2.5rem">
        <Heading textAlign="center">{t('Register.getRegistered')}</Heading>
        <Card
          padding={{ base: '1rem', md: '2rem' }}
          width={{ base: '100%', md: '28.57rem' }}
        >
          <CreateAccountForm />
        </Card>
        <Text textAlign="center">
          {t('Register.backToSignIn.0')}
          {' '}
          <Link as={RouterLink} to={`/${PATHS.SIGN_IN}`}>
            {t('Register.backToSignIn.1')}
          </Link>
        </Text>
      </Grid>
    </RegisterContainer>
  );
};

CreateAccount.defaultProps = {
  enableScroll: false,
};

CreateAccount.propTypes = {
  enableScroll: PropTypes.bool,
};
