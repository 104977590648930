import { t } from '@i18n';
import { useReactivateAccountMutation } from '@services/yoteprestoAPI';
import { useAccountEnabler } from './useAccountEnabler';

const REACTIVATE_TOKEN_PARAM = 'activate-token';
const ERROR_TOAST = {
  id: 'reactivate-account-error-toast',
  status: 'error',
  description: t('SignIn.reactivateAccount.errorToast'),
};
const SUCCESS_TOAST = {
  id: 'reactivate-account-success-toast',
  status: 'success',
  description: t('SignIn.reactivateAccount.successToast'),
};

export const useReactivateAccount = () => useAccountEnabler({
  useMutation: useReactivateAccountMutation,
  urlParamName: REACTIVATE_TOKEN_PARAM,
  errorToast: ERROR_TOAST,
  successToast: SUCCESS_TOAST,
});
