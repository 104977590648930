import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Flex,
  Icon,
  CloseButton,
  Text,
} from 'ui';
import { STEPS } from './hooks/useSwitchController';

export * from './hooks/useSwitchController';

export const StateSwitcher = (props) => {
  const {
    code,
    step,
    open,
    textCode,
    leftIcon,
    initialText,
    onRemoveCode,
    openFormComponent,
    'data-testid': testid,
  } = props;

  const testIdFor = {
    initialStep: {
      button: `${testid}-initialButton`,
    },
    completeStep: {
      container: `${testid}-completeContainer`,
      body: `${testid}-completeBody`,
      closeButton: `${testid}-completeCloseButton`,
    },
  };

  switch (step) {
    case STEPS.INITIAL:
      return (
        <Button
          padding="1.0rem 1.5rem"
          width="full"
          variant="link"
          colorScheme="blue"
          animationType="none"
          justifyContent="flex-start"
          onClick={open}
          data-testid={testIdFor.initialStep.button}
          leftIcon={(
            <Icon
              as={leftIcon}
              color="gray.700"
              height="2rem"
              width="2rem"
            />
          )}
        >
          {initialText}
        </Button>
      );

    case STEPS.OPEN:
      return openFormComponent;

    case STEPS.COMPLETE:
      return (
        <Flex
          m="1.5rem"
          px="2"
          borderRadius="lg"
          border="1px solid"
          borderColor="gray.400"
          justifyContent="space-between"
          alignItems="center"
          data-testid={testIdFor.completeStep.container}
        >
          <Text data-testid={testIdFor.completeStep.body}>
            {textCode}
            <Text
              as="strong"
              textTransform="uppercase"
            >
              {code}
            </Text>
          </Text>
          <CloseButton
            m="0"
            onClick={onRemoveCode}
            data-testid={testIdFor.completeStep.closeButton}
          />
        </Flex>
      );
    default:
      return null;
  }
};

StateSwitcher.defaultProps = {
  code: '',
  textCode: '',
  initialText: '',
  open: () => {},
  leftIcon: <svg />,
  onRemoveCode: () => {},
  openFormComponent: null,
};

StateSwitcher.propTypes = {
  initialText: PropTypes.string,
  textCode: PropTypes.string,
  code: PropTypes.string,
  open: PropTypes.func,
  onRemoveCode: PropTypes.func,
  leftIcon: PropTypes.shape({}),
  openFormComponent: PropTypes.node,
};
