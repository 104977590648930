import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPSERT_REQUISITION_REFERENCES_GQL = gql`
  mutation($references: [RequisitionReferenceInput!]!) {
    updateReferences(input: { references: $references }) {
      activeRequisition {
        id
        status
        references {
          id
          firstName
          lastName
          mothersLastName
          phoneCode
          phoneNumber
          isFamily
          referenceTypeId
        }
      }
    }
  }
`;

export const useCreateReferencesMutation = (options) => useMutation(
  UPSERT_REQUISITION_REFERENCES_GQL,
  options,
);
