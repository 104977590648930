import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  ChevronDownIcon,
  Text,
  Box,
} from 'ui';
import { t } from '@i18n';
import styles from './DrawerForm.styles';

export const DrawerForm = ({
  title,
  isOpen,
  onOpen,
  onClose,
  loading,
  onSubmit,
  children,
  titleDetails,
}) => {
  const firstField = useRef();

  return (
    <>
      <Button
        colorScheme="whiteAlpha"
        onClick={onOpen}
        data-testid="openDrawerBtn"
        {...styles.openButton}
      >
        {titleDetails
          && <Text {...styles.titleDetails}>{titleDetails}</Text>}
        <Flex
          {...styles.titleContainer}
        >
          {title}
          <ChevronDownIcon boxSize="1.6rem" />
        </Flex>
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          {...styles.container}
        >
          <DrawerBody>
            <Flex
              {...styles.formContainer}
            >
              <Box
                as="form"
                id="widget-form"
                data-testid="widget-form"
                onSubmit={onSubmit}
                {...styles.form}
              >
                {children}
              </Box>
              <Flex
                {...styles.buttonContainer}
              >
                <Button
                  type="button"
                  variant="ghost"
                  size="lg"
                  onClick={onClose}
                  isDisabled={loading}
                  {...styles.buttons}
                >
                  {t('Common.cancel')}
                </Button>
                <Button
                  {...styles.submit}
                  form="widget-form"
                  size="lg"
                  type="submit"
                  colorScheme="blue"
                  isDisabled={loading}
                  {...styles.buttons}
                >
                  {t('Common.update')}
                </Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

DrawerForm.defaultProps = {
  loading: false,
  children: null,
  onSubmit: () => { },
  loan: null,
};

DrawerForm.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  loan: PropTypes.shape({
    term: PropTypes.string,
    amount: PropTypes.string,
    purpose: PropTypes.string,
  }),
};

export default DrawerForm;
