import { PASSWORD } from '@config/constants';
import { removeAccents } from '@lib/Strings/Strings';

const normalizeString = (password) => removeAccents(password).toLowerCase();

export const validatePasswordSync = ({ password = '', email } = {}) => {
  const errors = [];
  if (password && email) {
    const [emailId] = email.split('@');
    const passwordNormalized = normalizeString(password);
    const emailNormalized = normalizeString(emailId);
    if (passwordNormalized.includes(emailNormalized)) {
      errors.push(PASSWORD.VALIDATION_ERRORS.CONTAINS_EMAIL);
    }
  }

  return errors.length
    ? errors
    : null;
};
