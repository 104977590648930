import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const UPDATE_EXPENSES = gql`
  mutation($input: UpsertRequisitionMutationInput!) {
    upsertRequisition(input: $input) {
      activeRequisition {
        id
        spending {
          id
          expenses {
            id
            amount
            expenseId
          }
        }
      }
    }
  }
`;

export const useUpdateExpensesMutation = (options) => useMutation(UPDATE_EXPENSES, options);
