import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const REQUISITION_CATALOG = gql`
query REQUISITION_CATALOG ($coupon: String){
  catalogs {
    id
    requisitionConfig(coupon: $coupon) {
      id
      maximumAmount
      minimumAmount
      paymentTerms
      purposes {
        id
        name
      }
    }
  }
}
`;

export const useRequisitionCatalog = (options) => useQuery(REQUISITION_CATALOG, {
  context: {
    clientName: 'public',
  },
  ...options,
});
