import { t } from '@i18n';

function required(value, message = t('FormResolver.required')) {
  if (value !== null && value !== undefined && value !== '') return null;

  return {
    type: 'danger',
    message,
  };
}

export default required;
