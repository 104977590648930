import { ONE_DAY } from '@lib/durations';
import { isNil } from '@lib/Monads';

export const getDaysLeft = (futureDate, today) => {
  // receives a date as a string in ISO format and returns the number of days left to or since
  if (isNil(futureDate)) return null;
  const futureDateFormatted = new Date(futureDate).getTime();

  return Math.ceil((futureDateFormatted - today) / (ONE_DAY));
};

export const getTimeLeft = (futureDate, timeUnit) => {
  // receives a date as a string in ISO format and
  // returns the number of units of time left to or since
  if (isNil(futureDate)) return null;
  const futureDateFormatted = new Date(futureDate);
  const today = new Date();

  return Math.ceil((futureDateFormatted - today) / timeUnit);
};
