import { removeNilValues } from '@lib/Object';
import { parseDigits } from '@lib/parsers';
import Validator from '@lib/Validator';

export const NAMES_MIN_LENGTH = 2;
export const NAMES_MAX_LENGTH = 50;
export const FIRST_LAST_NAME_MIN_LENGTH = 1;

const getErrorPerReference = (
  reference,
  {
    t,
    userPhoneNumber,
    phoneCodeValidations,
    otherPhoneNumbers = [],
  } = {},
) => {
  const errors = {
    name: Validator(reference.name)
      .required()
      .onlyLetters()
      .length({ min: NAMES_MIN_LENGTH, max: NAMES_MAX_LENGTH })
      .result(),
    firstLastName: Validator(reference.firstLastName)
      .required()
      .onlyLetters()
      .length({ min: FIRST_LAST_NAME_MIN_LENGTH, max: NAMES_MAX_LENGTH })
      .result(),
    secondLastName: Validator(reference.secondLastName)
      .onlyLetters()
      .result(),
    relationship: Validator(reference.relationship).required().result(),
    phoneNumber: Validator(reference.phoneNumber)
      .required()
      .phone({ phoneCode: reference.phoneCode, phoneCodeValidations })
      .custom((phone) => parseDigits(phone) === userPhoneNumber, t('errors.ownPhone'))
      .custom((phone) => otherPhoneNumbers.includes(phone), t('errors.repeatedPhone'))
      .result(),
  };
  const errorObject = removeNilValues(errors);
  const errorPayload = Object.keys(errorObject).length > 0 ? errorObject : null;
  const valuesPayload = {
    ...reference,
    name: reference?.name?.trim(),
    phoneNumber: parseDigits(reference?.phoneNumber),
  };
  return {
    values: valuesPayload,
    errors: errorPayload,
  };
};

export const resolver = (values, context) => {
  const {
    reference1,
    reference2,
    reference3,
  } = values;
  const {
    t,
    userPhoneNumber,
    phoneCodeValidations,
  } = context;

  const reference1Errors = getErrorPerReference(
    reference1,
    {
      t,
      otherPhoneNumbers: [reference2.phoneNumber, reference3.phoneNumber],
      phoneCodeValidations,
      userPhoneNumber,
    },
  );
  const reference2Errors = getErrorPerReference(
    reference2,
    {
      t,
      otherPhoneNumbers: [reference1.phoneNumber, reference3.phoneNumber],
      phoneCodeValidations,
      userPhoneNumber,
    },
  );
  const reference3Errors = getErrorPerReference(
    reference3,
    {
      t,
      otherPhoneNumbers: [reference1.phoneNumber, reference2.phoneNumber],
      phoneCodeValidations,
      userPhoneNumber,
    },
  );

  const errors = {
    reference1: reference1Errors.errors,
    reference2: reference2Errors.errors,
    reference3: reference3Errors.errors,
  };

  const clearedValues = {
    reference1: reference1Errors.values,
    reference2: reference2Errors.values,
    reference3: reference3Errors.values,
  };

  return {
    values: clearedValues,
    errors: removeNilValues(errors),
  };
};
