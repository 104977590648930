import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

function resolver(values) {
  const {
    hasCar,
    maritalStatus,
    educationLevel,
    dependentsNumber,
  } = values;

  const errors = {
    hasCar: Validator(hasCar).required().result(),
    maritalStatus: Validator(maritalStatus).required().result(),
    educationLevel: Validator(educationLevel).required().result(),
    dependentsNumber: Validator(dependentsNumber).required().result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
