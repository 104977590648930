import { useEffect } from 'react';
import { useNavigate } from '@hooks/navigation';
import { USER } from '@config/constants';
import { useUserQuery } from './useUserQuery';
import { usePreventMount } from './usePreventMount';

export const useController = () => {
  const navigate = useNavigate();
  const { data, loading: isLoading } = useUserQuery();
  const { user, activeRequisition } = data || {};
  const {
    roleName,
  } = data?.user || {};
  const {
    isPersonConfirmed,
  } = data?.user?.flags || {};
  const preventMountOptions = usePreventMount({
    user,
    isLoading,
    activeRequisition,
  });

  useEffect(() => {
    if (!roleName) return;
    const shouldGoBackToParentResolver = [
      isPersonConfirmed !== true,
      roleName !== USER.ROLES.BORROWER,
    ].some(Boolean);
    if (shouldGoBackToParentResolver) navigate('/');
  }, [
    navigate,
    roleName,
    isPersonConfirmed,
  ]);

  return {
    preventMountOptions,
  };
};
