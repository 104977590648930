export default {
  card: {
    padding: { base: '1.5rem', md: '2rem' },
    minWidth: { base: '100%', sm: '28rem' },
    maxWidth: { base: '100%', sm: '28rem' },
  },
  title: {
    as: 'h2',
    size: 'md',
    textAlign: 'center',
    marginBottom: '2rem',
  },
  subtitle: {
    marginBottom: '2rem',
  },
  button: {
    size: 'lg',
    width: '100%',
    type: 'submit',
    colorScheme: 'green',
    marginTop: '2rem',
  },
};
