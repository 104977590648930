import { t } from '@i18n';
import { useUnlockAccountMutation } from '@services/yoteprestoAPI';
import { useAccountEnabler } from './useAccountEnabler';

const UNLOCK_TOKEN_PARAM = 'unlock-token';
const ERROR_TOAST = {
  id: 'unlock-account-error-toast',
  status: 'error',
  description: t('SignIn.unlockAccount.errorToast'),
};
const SUCCESS_TOAST = {
  id: 'unlock-account-success-toast',
  status: 'success',
  description: t('SignIn.unlockAccount.successToast'),
};

export const useUnlockAccount = () => useAccountEnabler({
  useMutation: useUnlockAccountMutation,
  urlParamName: UNLOCK_TOKEN_PARAM,
  errorToast: ERROR_TOAST,
  successToast: SUCCESS_TOAST,
});
