import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query BASIC_USER_DATA {
    activeRequisition {
      id
      status
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_GQL, options);
