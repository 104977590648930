import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { t } from '@i18n';
import {
  Text,
  Link,
  Input,
  Skeleton,
  FormLabel,
  FormControl,
  InputGroup,
  InputRightElement,
} from 'ui';
import s from './EditableField.style';

export const EditableField = (props) => {
  const {
    id,
    name,
    path,
    rows,
    value,
    onChange,
    noBorder,
    labelText,
    isLoading,
    linkTitle,
    isEditable,
    isTextarea,
    inputProps,
    placeholder,
    shouldRedirect,
    'data-testid': testid,
    ...contentProps
  } = props;

  return (
    <FormControl
      {...s.control}
      {...contentProps}
      data-testid={testid}
      borderBottomWidth={noBorder ? '0' : '1px'}
    >
      <FormLabel
        {...s.label}
        htmlFor={id}
      >
        {labelText}
      </FormLabel>
      <InputGroup
        {...s.group}
        aria-describedby={`link-description-${id}`}
      >
        <Skeleton
          {...s.loader}
          isLoaded={!isLoading}
        >
          {
            isEditable
              ? (
                <Input
                  {...s.input}
                  id={id}
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  {...inputProps}
                />
              )
              : (
                <Text whiteSpace="pre-wrap">
                  {value || t('Common.na')}
                </Text>
              )
          }
        </Skeleton>
        {shouldRedirect && (
          <InputRightElement {...s.rightElement}>
            <Link
              {...s.actionLink}
              to={path}
              as={RouterLink}
              title={linkTitle}
              id={`link-description-${id}`}
            >
              {t('Register.formField.linkAction')}
            </Link>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

EditableField.defaultProps = {
  rows: '1',
  value: '',
  path: '/#',
  linkTitle: '',
  labelText: null,
  noBorder: false,
  isLoading: false,
  placeholder: null,
  isEditable: false,
  shouldRedirect: true,
  'data-testid': 'formFieldContainer',
};

EditableField.propTypes = {
  rows: PropTypes.string,
  path: PropTypes.string,
  value: PropTypes.string,
  noBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  labelText: PropTypes.string,
  linkTitle: PropTypes.string,
  placeholder: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  id: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};
