import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query BASIC_USER_DATA {
    user {
      id
      roleName
      flags {
        isPersonConfirmed
      }
      address {
        zip
      }
      maritalStatus {
        id
        name
      }
    }
    activeRequisition {
      id
      status
      loanInformation {
        id
        comment
      }
      income {
        id
        income
        hasImss
        hasSgmm
        employment {
          id
          name
        }
      }
      spending {
        id
        hasCar
        dependentsNumber
        expenses {
          id
          amount
        }
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_GQL, options);
