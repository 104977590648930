import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ONE_SECOND } from '@lib/durations';
import {
  selectExpiredByInactivity,
  selectExpiredByAnotherDevice,
} from '@reducks/session';
import {
  getListStateForBuroAnalyzing,
} from '../utils';
import { useUserQuery } from './graphql';

const POLLING_REQUISITION_INTERVAL = 10 * ONE_SECOND;

export const useController = () => {
  const {
    data,
    stopPolling,
    startPolling,
  } = useUserQuery();
  const activeRequisitionStatus = data?.activeRequisition?.status;
  const itemsList = getListStateForBuroAnalyzing(activeRequisitionStatus);
  const expiredByInactivity = useSelector(selectExpiredByInactivity);
  const expiredByAnotherDevice = useSelector(selectExpiredByAnotherDevice);
  const hasExpiredSession = expiredByInactivity || expiredByAnotherDevice;

  useEffect(() => {
    if (hasExpiredSession) return () => {};
    startPolling(POLLING_REQUISITION_INTERVAL);
    return () => stopPolling();
  }, [
    stopPolling,
    startPolling,
    hasExpiredSession,
  ]);

  return {
    itemsList,
  };
};
