import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = (ref) => {
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    ref.current?.scrollIntoView(true);
  }, [pathname, ref]);
};
