import React from 'react';
import PropTypes from 'prop-types';
import { FullscreenLoader } from '../../FullscreenLoader';
import { useController } from './hooks';

export const Redirect = (props) => {
  const { roleName } = props;
  useController({ roleName });

  return <FullscreenLoader />;
};

Redirect.propTypes = {
  roleName: PropTypes.string.isRequired,
};
