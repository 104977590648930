const { tscope } = require('@i18n/index');

const ts = tscope('Requisition');

const stepsLabels = ts('steps');

export const STEPS = stepsLabels.reduce((acc, label, id) => (
  { ...acc, [id]: { label, id } }), {});

export const STATUS_TAG_STATE = {
  FINISHED: 'finished',
  INACTIVE: 'inactive',
  ACTIVE: 'active',
};
