import { t } from '@i18n';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomToast } from 'ui';
import { useLoginPasswordForm } from '@hooks/auth';
import { useValidateEmailQuery } from '@services/yoteprestoAPI';
import { setUserInfo } from '@reducks/session';

export const useController = () => {
  const toast = useCustomToast();
  const dispatch = useDispatch();

  const {
    email,
    fullName: savedFullName,
  } = useSelector((state) => state.session);

  const {
    data: validateEmailData,
    isSuccess,
    isLoading,
  } = useValidateEmailQuery({ email }, { skip: !email });

  useEffect(() => {
    if (!isSuccess) return;

    const { maskedUsername } = validateEmailData;
    dispatch(setUserInfo({ email, fullName: maskedUsername }));
  }, [dispatch, email, isSuccess, validateEmailData]);

  const onSuccess = useCallback((data) => {
    const name = data?.user?.fullName;
    if (!name) return;

    toast({
      description: t('SignIn.welcomeUser', { name }),
      status: 'info',
    });
  }, [toast]);

  const {
    control,
    onSubmit,
    isLoading: isSubmitting,
  } = useLoginPasswordForm({ email, onSuccess });

  const fullName = savedFullName || t('SignIn.defaultUserName');

  return {
    fullName,
    control,
    onSubmit,
    isLoading,
    isSubmitting,
  };
};
