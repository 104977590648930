import Validator from '@lib/Validator';
import removeNilValues from '@lib/Object/removeNilValues';
import { useForm } from 'react-hook-form';
import { tscope } from '@i18n';
import { useCustomToast } from 'ui';
import { useAddCouponMutation } from './useAddCouponMutation';

export const INVALID_COUPON_ERROR = 'INVALID_COUPON_ERROR';

const ts = tscope('Register.promos');

const resolver = (values) => {
  const { coupon } = values;

  return {
    values,
    errors: removeNilValues({
      coupon: Validator(coupon).required().result(),
    }),
  };
};

const ADDED_COUPON_SUCCESS_ALERT = {
  status: 'success',
  description: ts('alerts.success.addedCoupon'),
};

export const useController = () => {
  const toast = useCustomToast();

  const { control, handleSubmit, setError } = useForm({
    resolver,
    defaultValues: { coupon: '' },
  });

  const handleInvalidCoupon = () => setError('coupon', {
    type: 'danger',
    message: ts('coupon.invalid'),
  });

  const onAddCouponCompleted = () => {
    toast(ADDED_COUPON_SUCCESS_ALERT);
  };

  const [addCoupon, {
    loading: addingCoupon,
  }] = useAddCouponMutation({
    onCompleted: onAddCouponCompleted,
    onErrorCode: {
      [INVALID_COUPON_ERROR]: handleInvalidCoupon,
    },
  });

  const submitHandler = handleSubmit(({ coupon }) => addCoupon({
    variables: {
      input: {
        coupon,
      },
    },
  }));

  return {
    control,
    submitHandler,
    isSubmitting: addingCoupon,
  };
};
