import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { usePhoneCodes } from '@hooks/form';
import { EMPLOYMENTS, PAY_FREQUENCY } from '@config/constants';
import { useUpdateEmploymentForm } from './useUpdateEmploymentForm';
import {
  useGetEmploymentQuery,
  useUpdateEmploymentMutation,
} from './graphql';
import { ts } from '../constants';

const frequencyOptions = Object.values(PAY_FREQUENCY).map((value, index) => (
  { value, label: ts('frequencyOptions')[index] }
));

export const useController = () => {
  const navigate = useNavigate();
  const phoneCodes = usePhoneCodes();

  const { data, loading } = useGetEmploymentQuery();

  const income = data?.activeRequisition?.income;
  const employment = income?.employment;

  const goToNextStep = () => {
    navigate(`../${PATHS.INCOME}`);
  };

  const [updateEmploymentMutation, { loading: isSubmitting }] = useUpdateEmploymentMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const onBack = () => navigate(`../${PATHS.WORK}`);

  const isRequiredCompanyName = [
    EMPLOYMENTS.RETIRED,
  ].every((id) => employment?.id !== id);

  const isRequiredPosition = [
    EMPLOYMENTS.OWNER,
    EMPLOYMENTS.RETIRED,
    EMPLOYMENTS.SELF_EMPLOYEE,
  ].every((id) => employment?.id !== id);

  const isRequiredCompanyYears = [
    EMPLOYMENTS.RETIRED,
  ].every((id) => employment?.id !== id);

  const isRequiredCompanyPhone = [
    EMPLOYMENTS.RETIRED,
  ].every((id) => employment?.id !== id);

  const isRequiredPaymentFrequency = [
    EMPLOYMENTS.SELF_EMPLOYEE,
    EMPLOYMENTS.OWNER,
  ].every((id) => employment?.id !== id);

  const isRequiredHasTaxSituation = [
    EMPLOYMENTS.OWNER,
    EMPLOYMENTS.RETIRED,
    EMPLOYMENTS.EMPLOYEE,
  ].every((id) => employment?.id !== id);

  const {
    control,
    onSubmit,
    hasTaxSituation,
  } = useUpdateEmploymentForm({
    income,
    updateEmploymentMutation,
    isRequiredPosition,
    isRequiredCompanyName,
    isRequiredCompanyYears,
    isRequiredCompanyPhone,
    isRequiredHasTaxSituation,
    isRequiredPaymentFrequency,
  });

  return {
    onBack,
    control,
    loading,
    onSubmit,
    employment,
    phoneCodes,
    isSubmitting,
    hasTaxSituation,
    frequencyOptions,
    isRequiredPosition,
    isRequiredCompanyName,
    isRequiredCompanyYears,
    isRequiredCompanyPhone,
    isRequiredHasTaxSituation,
    isRequiredPaymentFrequency,
  };
};
