import { tscope } from '@i18n';
import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

const ts = tscope('Requisition.income');

function resolver(values) {
  const {
    income,
    otherIncome,
  } = values;

  const errors = {
    income: Validator(income).required().isZero(ts('fieldErrors.income.isZero')).result(),
    otherIncome: Validator(otherIncome).required().result(),
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
