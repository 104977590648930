import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Checkbox, FormHelperText } from 'ui';
import { useController } from 'react-hook-form';

function CheckboxField(props) {
  const {
    control,
    name,
    size,
    variant,
    children,
    checkboxStyle,
    'data-testid': dataTestId,
    ...contentProps
  } = props;
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const testid = dataTestId ? `${dataTestId}-label` : null;

  return (
    <FormControl {...contentProps}>
      <Checkbox
        name={name}
        isChecked={value}
        onChange={onChange}
        onBlur={onBlur}
        size={size}
        variant={variant}
        isInvalid={!!error}
        data-testid={testid}
        style={checkboxStyle}
      >
        {children}
      </Checkbox>
      {error && (
        <FormHelperText
          color="red.500"
          fontSize="xs"
        >
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CheckboxField.defaultProps = {
  size: null,
  variant: null,
  children: null,
  checkboxStyle: {},
  'data-testid': '',
};

CheckboxField.propTypes = {
  control: PropTypes.shape({}).isRequired,
  checkboxStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  children: PropTypes.node,
  'data-testid': PropTypes.string,
};

export default CheckboxField;
