import { useForm } from 'react-hook-form';
import resolver from '../resolver';

export const useController = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ resolver });

  const submitForm = handleSubmit(({ email }) => onSubmit({ email }));

  return {
    control,
    submitForm,
    isSubmitting,
  };
};
