import { t } from '@i18n';

function required(value, message = t('FormResolver.required')) {
  if (value === true) return null;

  return {
    type: 'danger',
    message,
  };
}

export default required;
