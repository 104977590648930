import React from 'react';
import { tscope } from '@i18n';
import {
  RadioButton,
  SelectField,
  RadioButtonField,
} from '@components/Form/Field';
import { useController } from './hooks';
import { CreditForm } from '../components/CreditForm';
import { dependentsNumber } from './constants';

const ts = tscope('Requisition.household');

export const Household = () => {
  const {
    onBack,
    control,
    onSubmit,
    isSubmitting,
    maritalStatuses,
    educationLevels,
  } = useController();

  return (
    <CreditForm
      onBack={onBack}
      title={ts('title')}
      onSubmit={onSubmit}
      loading={isSubmitting}
    >
      <RadioButtonField
        control={control}
        variant="wrap"
        name="maritalStatus"
        data-testid="maritalStatusField"
        labelText={ts('maritalStatusLabel')}
        w="100%"
      >
        {
          maritalStatuses?.map((status) => (
            <RadioButton
              value={status.id}
              key={status.name}
            >
              {status.name}
            </RadioButton>
          ))
        }
      </RadioButtonField>
      <SelectField
        labelText={ts('educationLevelLabel')}
        control={control}
        name="educationLevel"
        data-testid="educationLevelField"
        placeholder={ts('educationPlaceholder')}
      >
        {
          educationLevels?.map(
            (level) => <option value={level.id} key={level.name}>{level.name}</option>,
          )
        }
      </SelectField>
      <RadioButtonField
        control={control}
        variant="wrap"
        name="hasCar"
        data-testid="hasCarField"
        labelText={ts('hasCarLabel')}
        w="100%"
      >
        <RadioButton
          value="true"
        >
          {ts('hasCar.true')}
        </RadioButton>
        <RadioButton
          value="false"
        >
          {ts('hasCar.false')}
        </RadioButton>
      </RadioButtonField>
      <RadioButtonField
        control={control}
        variant="wrap"
        name="dependentsNumber"
        data-testid="dependentsNumberField"
        labelText={ts('dependentsNumberLabel')}
        w="100%"
      >
        {
          dependentsNumber.map((dependent) => (
            <RadioButton
              value={dependent.value}
              key={dependent.value}
            >
              {`${dependent.label}`}
            </RadioButton>
          ))
        }
      </RadioButtonField>
    </CreditForm>
  );
};
