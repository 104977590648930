import { useCallback } from 'react';
import { PATHS } from '@navigators/Root/App/Profile/constants';
import { useNavigate } from 'react-router-dom';
import { usePhoneCodes } from '@hooks/form';
import { useUserQuery, useUserMutation } from '../../hooks';
import { useBasicUpdateForm } from './useBasicUpdateForm';

export const useController = () => {
  const navigate = useNavigate();
  const userQuery = useUserQuery();
  const user = userQuery.data?.user;
  const phoneCodes = usePhoneCodes();

  const goToNextStep = useCallback((isComplete) => {
    const navigateTo = isComplete ? PATHS.CONFIRM : PATHS.BIRTH;
    navigate(navigateTo);
  }, [navigate]);

  const [updateUser, { loading: isSubmitting }] = useUserMutation({
    onCompleted: (data) => {
      const isComplete = data.updateUser.user?.flags?.isPersonCompleted;
      goToNextStep(isComplete);
    },
  });

  const { control, onSubmit, shouldFillPhoneNumber } = useBasicUpdateForm({
    user,
    updateUser,
    phoneCodeValidations: phoneCodes.validations,
  });

  return {
    control,
    onSubmit,
    phoneCodes,
    isSubmitting,
    shouldFillPhoneNumber,
  };
};
