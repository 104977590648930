import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Input } from 'ui';
import Base from '../Base';
import buildInputTypes from './buildInputTypes';
import { getTypeProps } from './utils';

export const TextField = (props) => {
  const {
    id,
    name,
    control,
    autoFocus,
    labelText,
    size = 'md',
    type = 'text',
    maxDecimals = 0,
    minDecimals = 0,
    maxLength = null,
    noOfLines = null,
    placeholder = '',
    autoComplete = 'on',
    displayAsPassword = false,
    'data-testid': dataTestId = '',
    ...contentProps
  } = props;

  const {
    field: {
      ref,
      value,
      onBlur,
      onChange,
    },
    fieldState: { error },
  } = useController({ name, control, defaultValue: '' });

  const inputType = useMemo(() => buildInputTypes({ type }), [type]);

  const currencyProps = type === 'currency' ? {
    maxDecimals,
    minDecimals,
  } : {};

  const isPassword = !!(type === 'password' || displayAsPassword);

  const typePasswordDecorationProps = useMemo(
    () => (isPassword ? getTypeProps('password') : {}),
    [isPassword],
  );
  const testId = dataTestId ? `${dataTestId}-input` : null;

  return (
    <Base
      {...contentProps}
      id={id}
      name={name}
      control={control}
      labelText={labelText}
      data-testid={dataTestId}
      noOfLines={noOfLines}
    >
      <Input
        size={size}
        ref={ref}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        isInvalid={!!error}
        data-testid={testId}
        maxLength={maxLength}
        type={inputType.type}
        borderColor="gray.400"
        as={inputType.inputAs}
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        {...currencyProps}
        {...typePasswordDecorationProps}
      />
    </Base>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  labelText: PropTypes.string,
  noOfLines: PropTypes.number,
  placeholder: PropTypes.string,
  'data-testid': PropTypes.string,
  maxDecimals: PropTypes.number,
  minDecimals: PropTypes.number,
  displayAsPassword: PropTypes.bool,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password', 'current-password']),
};
