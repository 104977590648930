export default {
  openButton: {
    color: 'black',
    borderRadius: 0,
    height: '100%',
    _hover: {
      backgroundColor: 'gray.200',
    },
    _active: {
      backgroundColor: 'gray.200',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titleContainer: {
    display: {
      base: 'none',
      md: 'flex',
    },
    flexDirection: 'row',
    columnGap: '0.5rem',
    alignItems: 'center',
  },
  titleDetails: {
    fontWeight: '400',
  },
  container: {
    padding: { base: '1rem', md: '3rem 1rem' },
  },
  formContainer: {
    margin: 'auto',
    paddingTop: {
      base: '2rem',
      md: '0',
    },
    direction: 'column',
    alignItems: 'center',
    height: {
      base: '90vh',
      md: '21rem',
    },
    justifyContent: 'space-between',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: '1rem',
  },
  buttonContainer: {
    mt: '2rem',
    justifyContent: 'space-between',
    flexDirection: {
      base: 'column',
      md: 'row',
    },
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    width: '100%',
  },
  buttons: {
    width: {
      base: '100%',
      md: '45%',
    },
    height: '4rem',
  },
};
