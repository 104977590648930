import { useNavigate } from '@hooks/navigation';
import { PATHS } from '@navigators/Root/App/Credit/constants';

export const useNavigateResolver = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(`../${PATHS.HOUSEHOLD}`);
  const goNext = () => {
    navigate(`../${PATHS.EMPLOYMENT}`);
  };

  return {
    goBack,
    goNext,
  };
};
