import { useTrackLastLocation } from '@hooks/navigation';
import { useDispatch } from 'react-redux';
import { USER } from '@config/constants';
import { setAuthenticated } from '@reducks/session';
import { useChat } from './useChat';
import { useLoggerContext } from './useLoggerContext';
import { useUserQuery } from './useUserQuery';
import { useTrackerContext } from './useTrackerContext';
import { useRedirect } from './redirect/useRedirect';
import { useAllowedRoute } from './useAllowedRoute';
import { getIsCancelledRequisitionPath, getIsReferencesRequisitionPath } from '../Credit/utils';

export const useController = () => {
  const dispatch = useDispatch();
  const { data, loading: loadingUser } = useUserQuery({
    onCompleted: () => {
      dispatch(setAuthenticated());
    },
  });
  const user = data?.user || {};
  const activeRequisition = data?.activeRequisition || null;
  const {
    email,
    flags,
    status,
    id: userId,
    roleName: role,
  } = user;
  const {
    isEmailConfirmed,
    isPersonConfirmed,
    forcePasswordUpdate,
    isRecoveringPassword,
  } = flags || {};
  const skipEmailValidation = loadingUser
    || (role === USER.ROLES.INVESTOR && status !== USER.STATUS.STARTED)
    || (role === USER.ROLES.BORROWER && !!activeRequisition);
  const resolverOptions = {
    skipEmailValidation,
  };

  const requisitionStatus = activeRequisition?.status || '';

  const { isLoadingRedirection } = useRedirect({
    role,
    userId,
    status,
    isEmailConfirmed,
    activeRequisition,
    isPersonConfirmed,
    requisitionStatus,
    skipEmailValidation,
    forcePasswordUpdate,
    isRecoveringPassword,
  });
  const isLoading = loadingUser || isLoadingRedirection;

  const isCancelledRequisition = getIsCancelledRequisitionPath({ status: requisitionStatus });

  const isReferencesRequisition = getIsReferencesRequisitionPath({ status: requisitionStatus });

  const allowedRouteMap = useAllowedRoute({
    isEmailConfirmed,
    skipEmailValidation,
    forcePasswordUpdate,
    isRecoveringPassword,
    isCancelledRequisition,
    isReferencesRequisition,
    requisitionStatus,
  });

  useChat({ role });
  useLoggerContext({ userId });
  useTrackerContext({ userId, email, role });
  useTrackLastLocation({ userId });

  return {
    isLoading,
    allowedRouteMap,
    resolverOptions,
  };
};
