export const PASSWORD = {
  MIN_LENGTH: 8,
  VALIDATION_ERRORS: {
    // Should be larger than MIN_LENGTH
    MIN_LENGTH: 'min_length',
    // Should contain at least one digit
    MISSING_DIGIT: 'min_numerically',
    // Should contain at least one symbol E.G.($@.-)
    SPECIAL_CHAR: 'min_special_chars',
    // Should not contain the word yotepresto or legal name
    CONTAINS_RESERVED_WORDS: 'reserved_words',
    // Should contain at least N(defined by API) alphanumeric chars
    CONTAINS_ALPHABETIC: 'min_alphabetic',
    // Should not contain the email of the user
    CONTAINS_EMAIL: 'email',
    // Should not contain user ID
    CONTAINS_ID: 'ids',
    // Should not contain 3 chars repeated E.G.(paaasword)
    REPEATED_CHARS: 'repeated',
    // Should not contain 3 consecutive chars E.G.(1234 or abcd)
    CONSECUTIVE_CHARS: 'max_consecutive_chars',
    // Should contain up to N(defined by API) sequential chars
    SEQUENTIAL_CHARS: 'max_sequential_chars',
    // Should not use the same password as before
    CURRENT_PASSWORD: 'current_password',
  },
};
