import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const RESTART_CREDIT_GQL = gql`
  mutation RESTART_CREDIT_GQL ($input: CreateNextRequisitionMutationInput!) {
    createNextRequisition(input: $input) {
      user {
        id
      }
      activeRequisition {
        id
        status
      }
    }
  }
`;

export const useRestartCredit = (options) => useMutation(RESTART_CREDIT_GQL, options);
