import { useEffect, useMemo } from 'react';
import { useNavigate } from '@hooks/navigation';
import { REQUISITION } from '@config/constants';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { isNil } from '@lib/Monads';
import { useUser } from './graphql/useUser';

const isHouseholdComplete = ({ user, activeRequisition }) => {
  if (!user || !activeRequisition) return null;
  const { maritalStatus } = user;
  const { spending: { dependentsNumber, hasCar } = {} } = activeRequisition;
  return [!isNil(maritalStatus), !isNil(dependentsNumber), !isNil(hasCar)].every(Boolean);
};

export const creditResolvePath = ({
  user,
  activeRequisition,
  onCreditCompletePath = null,
  defaultPath = null,
}) => {
  if (!user || !activeRequisition) return null;
  const {
    address,
  } = user;
  const {
    status,
    income,
    spending,
    loanInformation,
  } = activeRequisition;
  if (!loanInformation?.comment) return PATHS.CONFIRM;
  if (address === null) return PATHS.ADDRESS;
  if (isHouseholdComplete({ user, activeRequisition }) === false) return PATHS.HOUSEHOLD;
  if (!income || !income.employment?.id) return PATHS.WORK;
  if (income.hasImss === null || income.hasSgmm === null) return PATHS.EMPLOYMENT;
  if (income.income === null || income.income === 0) return PATHS.INCOME;
  if (spending.expenses[0].amount === null) return PATHS.EXPENSES;
  if (status === REQUISITION.STATUS.SPENDING_COMPLETE) return onCreditCompletePath;
  return defaultPath;
};
export const useRedirect = () => {
  const navigate = useNavigate();
  const { data, loading } = useUser();
  const activeRequisition = useMemo(() => data?.activeRequisition || {}, [data]);
  const user = useMemo(() => data?.user || {}, [data]);

  useEffect(() => {
    if (loading) return;
    const navigateTo = creditResolvePath({
      user,
      activeRequisition,
      // if user already completes credit info then navigate to PATHS.ADDRESS to confirm info
      // it happens only when app resolves navigation otherwise user stay on its current page.
      onCreditCompletePath: PATHS.ADDRESS,
      defaultPath: PATHS.CONFIRM,
    });
    if (navigateTo) navigate(navigateTo, { replace: true });
  }, [
    user,
    loading,
    navigate,
    activeRequisition,
  ]);
};
