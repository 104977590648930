import React from 'react';
import { Icon } from 'ui';

export const FoodIcon = (props) => (
  <Icon viewBox="0 0 25 25" fill="none" {...props}>
    <path d="M3.49219 4.11963C3.49219 6.75003 3.49219 8.5665 3.49219 9.56904C3.49219 11.0729 5.40712 11.0729 5.40712 12.5198C5.40712 13.9668 5.40712 17.7668 5.40712 19.4608C5.40712 21.1548 9.52801 21.1548 9.52801 19.4608C9.52801 17.7668 9.52801 14.1348 9.52801 12.5198C9.52801 10.9049 11.3183 10.9049 11.3183 9.56904C11.3183 8.67845 11.3183 6.86198 11.3183 4.11963" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9277 9.06194C13.9277 10.8159 14.5146 11.8786 15.6882 12.2501C15.6882 15.9022 15.6882 18.2994 15.6882 19.4416C15.6882 21.1548 19.8615 21.1548 19.8615 19.4416C19.8615 18.2994 19.8615 15.9022 19.8615 12.2501C20.9491 11.9243 21.493 10.8616 21.493 9.06194C21.493 6.36238 20.0825 4.07251 17.7103 4.07251C15.3382 4.07251 13.9277 6.43095 13.9277 9.06194Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.70898 4.11963V8.46746" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.10156 4.11963V8.46746" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
