import { REQUISITION } from '@config/constants';
import { isNil } from '@lib/Monads';
import { PATHS } from '../constants';

// Cancelation statuses from sections 1 thru 6
export const getCancelledRequisitionStatuses = () => [
  REQUISITION.STATUS.CANCELED_FUNDING_DAYS_OVER,
  REQUISITION.STATUS.CANCELED_WRONG_PROFILE,
  REQUISITION.STATUS.CANCELED_PROPOSAL_REJECTED,
  REQUISITION.STATUS.CANCELED_NO_NEEDED,
  REQUISITION.STATUS.CANCELED_DOUBLE_REQ,
  REQUISITION.STATUS.CANCELED_NO_AUTH,
  REQUISITION.STATUS.CANCELED_MISSING_DOCS,
  REQUISITION.STATUS.CANCELED_MISSING_CLABE,
  REQUISITION.STATUS.CANCELED_WRONG_AMOUNT,
  REQUISITION.STATUS.CANCELED_CONSOLIDATION_NOT_ACCEPTED,
  REQUISITION.STATUS.CANCELED_BY_BAD_REFERENCES,
  REQUISITION.STATUS.CANCELED_BC_EXPIRED,
  REQUISITION.STATUS.CANCELED_FORMALIZE_EXPIRED,
  REQUISITION.STATUS.CANCELED_BY_RATE_NOT_ACCEPTED,
  REQUISITION.STATUS.CANCELED_BY_UNDESIRED_AMOUNT,
  REQUISITION.STATUS.CANCELED_GOT_BETTER_RATE,
  REQUISITION.STATUS.CANCELED_GOT_ANOTHER_SOURCE,
  REQUISITION.STATUS.CANCELED_BY_OTHER_REASON,
  REQUISITION.STATUS.CANCELED_BEFORE_BURO,
  REQUISITION.STATUS.CANCELED_AFTER_REFERENCES,
  REQUISITION.STATUS.CANCELED_BY_FRAUD,
  REQUISITION.STATUS.CANCELED_BY_UNVALIDATED_INFORMATION,
  REQUISITION.STATUS.CANCELED_BY_RISKY_PROFILE,
  REQUISITION.STATUS.CANCELED_BY_INACTIVITY,
  REQUISITION.STATUS.CANCELED_BY_FAKE_INFO,
  REQUISITION.STATUS.CANCELED_BY_BURO_LOCKED,
  REQUISITION.STATUS.REJECTED_FAKE_INFO,
  REQUISITION.STATUS.REJECTED_LEAD_CRITERIA_COMPLIANCE,
  REQUISITION.STATUS.REJECTED_UNABLE_CREDIT_INFO,
  REQUISITION.STATUS.REJECTED_PAYMENT_CAPACITY,
  REQUISITION.STATUS.REJECTED_BY_BCSCORE,
  REQUISITION.STATUS.REJECTED_INCOME_RECORDS,
  REQUISITION.STATUS.REJECTED_SHORT_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_INSUFFICIENT_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_OVER_DEBTS,
  REQUISITION.STATUS.REJECTED_OUTSIDE_THE_COLLECTION_AREA,
  REQUISITION.STATUS.REJECTED_BY_YODA,
  REQUISITION.STATUS.REJECTED_BAD_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_UNABLE_TO_VERIFY_INFO,
  REQUISITION.STATUS.REJECTED_GOVERNMENT_EMPLOYEE,
];

export const getReferencesStatuses = () => [
  REQUISITION.STATUS.REFERENCES,
];

export const getIsCancelledRequisitionPath = ({ status }) => {
  if (isNil(status)) return null;

  const matchStatus = getCancelledRequisitionStatuses()
    .includes(status);

  return matchStatus ? PATHS.CANCELLED_REQUISITION : false;
};

export const getIsReferencesRequisitionPath = ({ status }) => {
  if (isNil(status)) return null;

  const matchStatus = getReferencesStatuses()
    .includes(status);
  return matchStatus ? PATHS.REFERENCES : false;
};
