export const USER = {
  ROLES: {
    INVESTOR: 'investor',
    BORROWER: 'borrower',
  },
  STATUS: {
    STARTED: 'started',
    BASE_PROFILE_CONFIRMED: 'base_profile_confirmed',
  },
  STATUS_INVESTOR: {
    STARTED: 'started',
  },
  STATUS_BORROWER: {
    STARTED: 'started',
    PROFILE_COMPLETED: 'profile_completed',
    RISKED: 'risked', // This status is not used in the web-app, but let users stay in the web-app.
  },
};
