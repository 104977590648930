import { useCallback } from 'react';
import { PATHS } from '@navigators/Root/App/Profile/constants';
import { useNavigate } from 'react-router-dom';
import { useUserMutation } from '../../hooks';
import { useIdentificationQuery } from './useIdentificationQuery';
import { useIdentificationForm } from './useIdentificationForm';

export const useController = () => {
  const navigate = useNavigate();
  const userQuery = useIdentificationQuery();
  const user = userQuery.data?.user;
  const isPersonCompleted = user?.flags?.isPersonCompleted;

  const goToNextStep = useCallback(() => {
    navigate(`../${PATHS.CONFIRM}`);
  }, [navigate]);

  const onBack = useCallback(() => {
    const path = isPersonCompleted ? PATHS.CONFIRM : PATHS.BIRTH;
    navigate(`../${path}`);
  }, [navigate, isPersonCompleted]);

  const [updateUser, { loading: isSubmitting }] = useUserMutation({
    onCompleted: goToNextStep,
  });

  const { control, onSubmit } = useIdentificationForm({
    user,
    updateUser,
  });

  return {
    control,
    onSubmit,
    isSubmitting,
    goBack: onBack,
  };
};
