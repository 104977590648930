import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Text,
} from 'ui';
import {
  Initial,
  Completed,
  InProgress,
} from './components';
import style from './styles';
import { STEPS_STATES } from './utils';

const getStep = (step) => ({
  [STEPS_STATES.INITIAL]: <Initial />,
  [STEPS_STATES.IN_PROGRESS]: <InProgress />,
  [STEPS_STATES.COMPLETED]: <Completed />,
})[step];

export const ProgressItem = ({
  state,
  stepText,
  ...props
}) => (
  <Flex {...style.item} {...props}>
    {getStep(state)}
    <Text>{stepText}</Text>
  </Flex>
);

export const ProgressList = (props) => {
  const { children, 'data-testid': dataTestid } = props;

  return (
    <Flex
      data-testid={dataTestid}
      {...style.list}
    >
      {children}
    </Flex>
  );
};

ProgressList.defaultProps = {
  children: null,
  dataTestid: 'ProgressList',
};

ProgressList.propTypes = {
  children: PropTypes.node,
  dataTestid: PropTypes.string,
};
