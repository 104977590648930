import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@i18n/index';
import { isZipCode } from '@lib/Monads';
import { fetchZipCodeInfoCatalog } from '@services/zipCode';
import { useCustomToast } from 'ui';
import { useZipCodes, actions } from '@reducks/zipCodes';
import { parse } from './parser';

const readCache = (cache) => {
  if (!cache) {
    return {
      data: null,
      error: null,
    };
  }

  if (cache.success) {
    return {
      data: cache,
      error: null,
    };
  }

  return {
    data: null,
    error: t('Common.errors.zip'),
  };
};

export const useZipCodeData = (zip) => {
  const dispatch = useDispatch();
  const zipHistory = useZipCodes();
  const currentZipCode = zipHistory[zip] || null;
  const toast = useCustomToast();
  const [loading, setLoading] = useState(false);

  const handleUnknownError = useCallback(() => {
    toast({
      type: 'danger',
      message: t('Common.errors.default'),
    });
  }, [toast]);

  const handleInvalidZipCodeError = useCallback(() => {
    dispatch(actions.addZipCode({
      zipCode: zip,
      success: false,
    }));
  }, [dispatch, zip]);

  const handleValidZipCode = useCallback((zipCodeData) => {
    const addressData = parse(zipCodeData);
    dispatch(actions.addZipCode(addressData));
  }, [dispatch]);

  const updateZipData = useCallback(
    async () => {
      setLoading(true);
      const zipCodeData = await fetchZipCodeInfoCatalog(zip);
      if (zipCodeData.error) handleUnknownError();
      else if (zipCodeData.success) handleValidZipCode(zipCodeData);
      else handleInvalidZipCodeError();
      setLoading(false);
    },
    [handleInvalidZipCodeError, handleUnknownError, handleValidZipCode, zip],
  );

  useEffect(() => {
    if (!isZipCode(zip)) return;
    if (currentZipCode) return;
    updateZipData();
  }, [
    zip,
    zipHistory,
    updateZipData,
    currentZipCode,
  ]);

  return {
    loading,
    ...readCache(currentZipCode),
  };
};
