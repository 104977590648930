import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useZipCodeData } from '@hooks/zipData';
import { t } from '@i18n/index';
import { isZipCode } from '@lib/Monads';
import { currencyToNumber } from '@lib/parsers';
import { ZIP_CODE_LENGTH } from '@config';
import resolver from '../resolver';
import { RENT_PROPERTY_ID } from '../constants';

const defaultValues = {
  street: '',
  extNumber: '',
  intNumber: '',
  zip: '',
  neighborhood: '',
  stateName: '',
  municipality: '',
  city: '',
  addressYears: '',
  homeOwnershipId: '',
  rentAmount: '',
};

export const useUpdateAddressForm = ({
  user,
  expenses,
  statesMap,
  rentExpenseId,
  updateAddressMutation,
}) => {
  const {
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm(
    {
      resolver,
      defaultValues,
    },
  );

  const [currentState, setCurrentState] = useState(null);
  const selectedPropertyType = watch('homeOwnershipId');
  const zip = watch('zip');
  const {
    error: zipError,
    data: addressData,
    loading: zipLoading,
  } = useZipCodeData(zip);

  const neighborhoodsData = addressData?.neighborhoods;

  const requisitionInput = (homeOwnershipId, rentAmount) => {
    if (Number(homeOwnershipId) !== RENT_PROPERTY_ID) {
      return {};
    }
    return {
      requisition: {
        spending: {
          expenses: [{
            expenseId: rentExpenseId,
            amount: currencyToNumber(rentAmount),
            id: expenses?.filter((expense) => expense.name === 'Renta')[0]?.id,
          }],
        },
      },
    };
  };

  const submitHandler = (values) => updateAddressMutation({
    variables: {
      input: requisitionInput(values.homeOwnershipId, values.rentAmount),
      userInput: {
        profile: {
          addressYears: Number(values.addressYears),
          homeOwnershipId: Number(values.homeOwnershipId),
          address: {
            street: values.street,
            extNumber: String(values.extNumber),
            intNumber: String(values.intNumber),
            zip: String(values.zip),
            neighborhood: values.neighborhood,
            stateId: Number(currentState.id),
            municipality: values.municipality,
            city: values.city,
          },
        },
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!user) return;

    const rentExpense = expenses?.filter((expense) => expense.name === 'Renta')[0]?.amount || '';

    reset({
      street: user?.address?.street || '',
      extNumber: user?.address?.extNumber || '',
      intNumber: user?.address?.intNumber || '',
      zip: user?.address?.zip || '',
      neighborhood: user?.address?.neighborhood || '',
      addressYears: String(user?.addressYears || ''),
      homeOwnershipId: String(user?.homeOwnership?.id || ''),
      rentAmount: rentExpense,
      // These come from the Zip API, not from the user.
      stateName: '',
      municipality: '',
      city: '',
    });
  }, [user, expenses, reset]);

  useEffect(() => {
    if (!addressData || !user) return;
    const neighborhood = user.address?.neighborhood || '';
    const neighborhoodSelected = neighborhoodsData?.includes(neighborhood) ? neighborhood : '';

    const {
      city,
      stateName,
      municipality,
      stateConstId,
    } = addressData;
    setValue('neighborhood', neighborhoodSelected);
    setValue('city', city);
    setValue('stateName', stateName);
    setValue('municipality', municipality);
    setCurrentState(statesMap[stateConstId]);
  }, [
    user,
    setValue,
    statesMap,
    addressData,
    neighborhoodsData,
  ]);

  useEffect(() => {
    if (zipError) return;
    if (!isZipCode(zip)) return;

    clearErrors('zip');
  }, [clearErrors, zip, zip.length, zipError]);

  useEffect(() => {
    if (!zipError) return;

    setError('zip', { type: 'danger', message: t('Common.errors.zip') });
  }, [setError, zipError]);

  useEffect(() => {
    if (zip.length < ZIP_CODE_LENGTH) return;
    if (isZipCode(zip)) return;

    setError('zip', { type: 'danger', message: t('Common.errors.zip') });
  }, [setError, zip]);

  return {
    zip,
    control,
    onSubmit,
    zipError,
    zipLoading,
    selectedPropertyType: Number(selectedPropertyType),
    neighborhoods: neighborhoodsData,
  };
};
