import { REQUISITION } from '@config/constants';
import { isNil } from '@lib/Monads';
import { PATHS } from '@navigators/Root/App/constants';
import { PATHS as CREDIT_PATHS } from '../constants';

export const getPreApprovalStatuses = () => [
  REQUISITION.STATUS.BURO,
  REQUISITION.STATUS.BURO_REPORT,
  REQUISITION.STATUS.YODA,
  REQUISITION.STATUS.BURO_OFFLINE,
  REQUISITION.STATUS.BURO_FAILED,
  REQUISITION.STATUS.AUTH_UNCOMPLETED,
  REQUISITION.STATUS.REJECTED_BURO,
  REQUISITION.STATUS.BURO_LOCKED,
];

export const getIsPreApprovalAllowed = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;
  const isPreApprovalAllowed = getPreApprovalStatuses()
    .includes(requisitionStatus);
  return isPreApprovalAllowed;
};

export const getPreApprovalPath = (requisitionStatus) => {
  if (isNil(requisitionStatus)) return null;
  const isAnalyzingBuro = getPreApprovalStatuses()
    .includes(requisitionStatus);
  if (isAnalyzingBuro) return PATHS.PRE_APPROVAL;
  return false;
};

export const getBuroReportStatuses = () => [
  REQUISITION.STATUS.BURO,
  REQUISITION.STATUS.BURO_REPORT,
  REQUISITION.STATUS.YODA,
];

export const getIsBuroReportPath = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;

  const matchStatus = getBuroReportStatuses()
    .includes(requisitionStatus);
  return matchStatus ? CREDIT_PATHS.BURO : false;
};

export const getBuroOfflineStatuses = () => [
  REQUISITION.STATUS.BURO_OFFLINE,
  REQUISITION.STATUS.BURO_FAILED,
];

export const getIsBuroOfflinePath = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;

  const matchStatus = getBuroOfflineStatuses()
    .includes(requisitionStatus);
  return matchStatus ? CREDIT_PATHS.BURO_OFFLINE : false;
};

export const getIsBuroUnprocessablePath = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;

  const matchStatus = requisitionStatus === REQUISITION.STATUS.AUTH_UNCOMPLETED;
  return matchStatus ? CREDIT_PATHS.BURO_UNPROCESSABLE : false;
};

export const getIsBuroBlockedPath = ({ requisitionStatus }) => {
  if (isNil(requisitionStatus)) return null;

  const matchStatus = requisitionStatus === REQUISITION.STATUS.BURO_LOCKED;

  return matchStatus ? CREDIT_PATHS.BURO_BLOCKED : false;
};
