import React from 'react';
import { createRoot } from 'react-dom/client';
import { UIProvider, Fonts } from 'ui';
import { ApolloProvider } from '@apollo/client';
import { preventTranslationErrors } from '@lib/Monads';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StateProvider } from 'react-redux';
import { fullPath } from '@config';
import { Root } from '@navigators';
import client from '@config/apollo/client';
import store from '@config/redux/store';
import GlobalStyle from '@config/globalStyle';
import * as serviceWorker from '@config/serviceWorker';
import * as Logger from '@lib/Logger';

Logger.init();
preventTranslationErrors();

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <UIProvider>
      <StateProvider store={store}>
        <ApolloProvider client={client}>
          <GlobalStyle />
          <Fonts />
          <BrowserRouter basename={fullPath()}>
            <Root />
          </BrowserRouter>
        </ApolloProvider>
      </StateProvider>
    </UIProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
