import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export const PreventMountRoute = ({
  children,
  isAllowed,
  redirectTo,
}) => {
  if (isAllowed === null) return null;

  const provisionalElement = isAllowed === true
    ? children
    : <Navigate to={redirectTo} replace />;

  return provisionalElement;
};

PreventMountRoute.defaultProps = {
  children: null,
  isAllowed: null,
  redirectTo: '../',
};

PreventMountRoute.propTypes = {
  children: PropTypes.node,
  isAllowed: PropTypes.bool,
  redirectTo: PropTypes.string,
};
