/**
 * Chrome translations make React to break and display users a blank screen.
 * This happens when texts are nor properly wrapped into a <span /> tag,
 * as described here: https://github.com/facebook/react/issues/11538#issuecomment-390386520
 *
 * The following code, prevents the error to be propagated and helps developers to find the
 * elements causing it.
 * See https://github.com/facebook/react/issues/11538#issuecomment-417504600 for details.
 */
export const preventTranslationErrors = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function removeChild(child) {
      if (child.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function insertBefore(newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }
};
