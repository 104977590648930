export default {
  title: {
    fontSize: '2xl',
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  footer: {
    padding: '2rem 3rem',
    backgroundColor: 'gray.50',
  },
};
