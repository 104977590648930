import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo/';

export const CONFIRM_EMAIL_GQL = gql`
mutation CONFIRM_EMAIL ($input: ConfirmEmailMutationInput!) {
  confirmEmail(input: $input) {
    user {
      id
      flags {
        id
        isEmailConfirmed
      }
    }
  }
}
`;

export const useConfirmEmailMutation = (options) => useMutation(CONFIRM_EMAIL_GQL, options);
