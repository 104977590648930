import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import { PreventMountRoute } from '@components/navigation';
import {
  BuroAnalysis,
  BuroOffline,
  BuroUnprocessable,
} from '@views/PreApproval';
import { BuroBlocked } from '@views/PreApproval/BuroBlocked';
import { PATHS } from '../constants';
import { PathResolver } from './components';
import { useController } from './hooks';

export const CreditPreApproval = () => {
  const {
    preventMountElementProps,
  } = useController();

  return (
    <Routes>
      <Route
        path={PATHS.BURO}
        element={(
          <PreventMountRoute
            {...preventMountElementProps[PATHS.BURO]}
          >
            <BuroAnalysis />
          </PreventMountRoute>
          )}
      />
      <Route
        path={PATHS.BURO_OFFLINE}
        element={(
          <PreventMountRoute
            {...preventMountElementProps[PATHS.BURO_OFFLINE]}
          >
            <BuroOffline />
          </PreventMountRoute>
          )}
      />
      <Route
        path={PATHS.BURO_UNPROCESSABLE}
        element={(
          <PreventMountRoute
            {...preventMountElementProps[PATHS.BURO_UNPROCESSABLE]}
          >
            <BuroUnprocessable />
          </PreventMountRoute>
          )}
      />
      <Route
        path={PATHS.BURO_BLOCKED}
        element={(
          <PreventMountRoute
            {...preventMountElementProps[PATHS.BURO_BLOCKED]}
          >
            <BuroBlocked />
          </PreventMountRoute>
          )}
      />
      <Route
        path="*"
        element={<PathResolver />}
      />
    </Routes>
  );
};
