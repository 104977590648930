import { t } from '@i18n';

export const minLength = (value, { min }, message = t('FormResolver.minLength', { length: min })) => {
  if (!value || (value.length > min)) return null;

  return {
    type: 'danger',
    message,
  };
};
