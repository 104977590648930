import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const GET_HOUSEHOLD = gql`
  query GET_HOUSEHOLD {
    user {
      id
      educationLevel {
        id
        name
      }
      maritalStatus {
        id
        name
      }
    }
    activeRequisition {
      id
      spending {
        id
        hasCar
        dependentsNumber
      }
    }
  }
`;

export const useGetHouseholdQuery = (options = {}) => useQuery(GET_HOUSEHOLD, {
  ...options,
});
