import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query USER_GQL {
    user {
      id
      firstName
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_GQL, options);
