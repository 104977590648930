/* eslint-disable react/prop-types */
/*
 Definition of custom input types
 This function creator extends all standard input types, ie;
 type = ["text", "email", "autocomplete-email", "currency"]

 Implementation:
 you can write code like <Input type="currency"> then it display input's content formatted
*/
import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { CurrencyInput } from '@lib/CurrencyInput';
import { isNil } from '@lib/Monads';
import { parseDigits, parseName } from '@lib/parsers';

const PhoneInput = forwardRef(({
  phoneLength = 10,
  disabled,
  ...props
}, ref) => (
  <InputMask
    mask={`(999) ${'9'.repeat(phoneLength - 3)}`}
    disabled={disabled}
    {...props}
  >
    {(inputProp) => <input ref={ref} disabled={disabled} {...inputProp} />}
  </InputMask>
));
/**
 * Override input type="number" to add maxLength prop and
 * validate it since it is not supported when type is number
 */
const NumberInput = forwardRef(({ onChange, maxLength, ...props }, ref) => (
  <input
    {...props}
    ref={ref}
    type="text"
    maxLength={maxLength}
    inputMode="numeric"
    onChange={(ev) => {
      const { value } = ev.target;
      const parsed = parseDigits(value) || '';
      if (isNil(maxLength)) { onChange(parsed); return; }
      if (maxLength >= parsed?.length) onChange(parsed);
    }}
  />
));

const NameInput = forwardRef(({
  onBlur,
  onChange,
  maxLength,
  ...props
}, ref) => (
  <input
    {...props}
    ref={ref}
    type="text"
    style={{ textTransform: 'capitalize' }}
    maxLength={maxLength}
    onChange={onChange}
    onBlur={(ev) => {
      if (onBlur) onBlur();
      const { value } = ev.target;
      const parsed = parseName(value) || '';
      if (isNil(maxLength)) { onChange(parsed); return; }
      if (maxLength >= parsed?.length) onChange(parsed);
    }}
  />
));

const buildInputTypes = ({ type }) => {
  const defaultInputType = {
    type,
    inputAs: 'input',
  };

  const types = ({
    currency: {
      type: 'text',
      inputAs: CurrencyInput,
    },
    telephone: {
      type: 'tel',
      inputAs: PhoneInput,
    },
    number: {
      type: 'number',
      inputAs: NumberInput,
    },
    name: {
      type: 'text',
      inputAs: NameInput,
    },
  });

  return types[type] || defaultInputType;
};

export default buildInputTypes;
