import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
} from 'ui';
import { Footer } from '@components';
import { useRestoreScroll } from '@hooks/navigation/useRestoreScroll';
import { PublicHeader } from './components/Header';

export const PrimaryLayout = (props) => {
  const { children, title, ...contentProps } = props;
  const ref = useRef(null);
  useRestoreScroll();

  return (
    <Flex
      minH="100vh"
      flexDirection="column"
      justifyContent="space-between"
      {...contentProps}
    >
      <Box>
        <PublicHeader title={title} />
        <Box
          ref={ref}
          pt={{ base: '3.5rem', md: '3.5rem' }}
          pb={{ base: '3.5rem', md: '3.5rem' }}
          width="100%"
          justifySelf="center"
          position="relative"
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Flex>
  );
};

PrimaryLayout.defaultProps = {
  children: null,
  title: '',
};

PrimaryLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
