import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { parseDigits } from '@lib/parsers';
import { useUpdateActiveRequisition } from '@hooks/requisition';
import { resolver } from '../utils';
import { useRestartCredit } from './graphql';

const buildCreditInput = (values) => ({
  variables: {
    input: {
      metaData: {
        requestedAmount: Number(parseDigits(values.amount)),
        paymentTerm: Number(values.term),
        loanDestinationId: values.destination,
        comment: values.reason,
      },
    },
  },
});

export const useCreditForm = ({
  creditConfig,
  creditInformation,
}) => {
  const {
    watch,
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver,
    context: {
      minimumAmount: creditConfig?.minimumAmount,
      maximumAmount: creditConfig?.maximumAmount,
    },
  });
  const reasonValue = watch('reason');
  const updateActiveRequisition = useUpdateActiveRequisition();
  const [
    restartCredit,
    { loading },
  ] = useRestartCredit({
    update: (cache, { data }) => updateActiveRequisition({
      cache,
      newActiveRequisition: data.createNextRequisition.activeRequisition,
    }),
  });

  const onSubmit = handleSubmit((values) => {
    const creditInput = buildCreditInput(values);
    return restartCredit(creditInput);
  });

  useEffect(() => {
    if (!creditInformation) return;
    reset({
      amount: creditInformation.requestedAmount || '',
      destination: creditInformation.loanDestination?.id || '',
      term: creditInformation.paymentTerms || '',
      reason: '',
    });
  }, [
    reset,
    creditInformation,
  ]);

  return {
    control,
    onSubmit,
    reasonValue,
    isLoading: loading,
  };
};
