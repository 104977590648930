import { t } from '@i18n';

export const includesDigits = ({ value }) => /[0-9]+/.test(value);

function validate(value, message = t('FormResolver.includesNumbers')) {
  if (includesDigits({ value })) return null;

  return {
    type: 'danger',
    message,
    value,
  };
}

export default validate;
