import removeNilValues from '@lib/Object/removeNilValues';
import { parseDigits } from '@lib/parsers';
import Validator from '@lib/Validator';

const resolver = (values, context) => {
  const {
    hasImss,
    hasSgmm,
    companyName,
    companyYears,
    companyPosition,
    hasTaxSituation,
    paymentFrequency,
    companyPhoneNumber,
  } = values;
  const companyPhoneNumberParsed = parseDigits(companyPhoneNumber);

  const {
    isRequiredPosition,
    isRequiredCompanyName,
    isRequiredCompanyPhone,
    isRequiredCompanyYears,
    isRequiredHasTaxSituation,
    isRequiredPaymentFrequency,
  } = context;

  const errors = {
    hasImss: Validator(hasImss).required().result(),
    hasSgmm: Validator(hasSgmm).required().result(),
    companyName: isRequiredCompanyName
      ? Validator(companyName).required().result()
      : null,
    companyYears: isRequiredCompanyYears
      ? Validator(companyYears)
        .required()
        .digits()
        .result()
      : null,
    companyPhoneNumber: isRequiredCompanyPhone
      ? Validator(companyPhoneNumberParsed)
        .required()
        .phone()
        .result()
      : null,
    companyPosition: isRequiredPosition
      ? Validator(companyPosition).required().result()
      : null,
    paymentFrequency: isRequiredPaymentFrequency
      ? Validator(paymentFrequency).required().result() : null,
    hasTaxSituation: isRequiredHasTaxSituation
      ? Validator(hasTaxSituation).required().result() : null,
  };

  return { values, errors: removeNilValues(errors) };
};

export default resolver;
