// Persist credentials for auth using cookies strategy
// see: https://github.com/grofers/redux-cookies-middleware
import reduxCookiesMiddleware from 'redux-cookies-middleware';
import getStateFromCookies from 'redux-cookies-middleware/lib/getStateFromCookies';
import jsCookie from 'js-cookie';
import { getCookiesDomain } from '@config';
import { setSessionCookie, getSessionCookie } from '@lib/storage/credentials';

const paths = {
  session: { name: 'session' },
};

const getCookieHandler = (name) => {
  if (name === 'session') {
    return getSessionCookie();
  }
  // default; It saves on cookie without modify current format state
  return jsCookie.get(name);
};

const setCookieHandler = (name, value, expiry = 365, secure = true) => {
  if (name === 'session') {
    const obj = value ? JSON.parse(value) : {};
    setSessionCookie(obj, secure);
    return;
  }
  // default; It gets from cookie and restore with same format state
  jsCookie.set(name, value, {
    expires: expiry, path: '/', secure, domain: getCookiesDomain(),
  });
};

const defaultInitialState = null;

export const initialState = getStateFromCookies(defaultInitialState, paths, getCookieHandler);

export const storeStrategyMiddleware = reduxCookiesMiddleware(paths, {
  setCookie: setCookieHandler,
});
