import React from 'react';
import AsideImageSrc from '@assets/PasswordRecovery/aside.jpg';
import RightSideSrc from '@assets/SignIn/rightSide.svg';
import ytpBrand from '@assets/branding/ytp-default.png';
import TwoSidesLayout from '@components/layouts/TwoSides';
import { Heading, Image } from 'ui';
import { useController } from './hooks';
import { ConfirmCard, SendEmailForm } from '../components';

export const VerifyEmail = () => {
  const {
    submit,
    isLoading,
    passwordId,
    showConfirmation,
  } = useController();

  const content = showConfirmation ? (
    <ConfirmCard passwordId={passwordId} />
  ) : (
    <SendEmailForm
      onSubmit={submit}
      isLoading={isLoading}
    />
  );

  return (
    <TwoSidesLayout
      bgImageLeftSideSrc={AsideImageSrc}
      bgImageRightSideSrc={RightSideSrc}
      rightSide={(
        <>
          <Heading>
            <Image src={ytpBrand} w="12rem" mb="4rem" />
          </Heading>
          {content}
        </>
      )}
    />
  );
};
