export default {
  control: {
    borderBottom: '1px solid',
    borderColor: 'gray.200',
  },
  label: {
    fontSize: 'sm',
    fontWeight: 'normal',
    color: 'gray.500',
    mb: '1',
  },
  group: {
    size: 'md',
  },
  loader: {
    width: '100%',
    borderRadius: 'md',
    marginRight: '5rem',
    marginBottom: '4px',
    endColor: 'gray.200',
  },
  input: {
    height: '6',
    variant: 'unstyled',
    textTransform: 'capitalize',
  },
  textareaInput: {
    height: '20',
    paddingY: '0',
  },
  rightElement: {
    height: '6',
    width: '5rem',
    flexGrow: '1',
    justifyContent: 'flex-end',
  },
  actionLink: {
    fontSize: 'sm',
  },
};
