import { useEffect } from 'react';
import { useNavigate, useLastLocation } from '@hooks/navigation';
import { PATHS } from '@navigators/Root/App/constants';
import { PATHS as PROFILE_PATHS } from '@navigators/Root/App/Profile/constants';
import { useUserQuery } from '@navigators/Root/App/hooks';
import { USER } from '@config/constants';
import { getPreApprovalPath } from '@navigators/Root/App/Credit/PreApproval/utils';
import { getIsCancelledRequisitionPath, getIsReferencesRequisitionPath } from '@navigators/Root/App/Credit/utils';

const getRedirectPath = ({
  roleName,
  isEmailConfirmed,
  isPersonCompleted,
  isPersonConfirmed,
  requisitionStatus,
  skipEmailValidation,
}) => {
  if (isEmailConfirmed === false && !skipEmailValidation) return `/${PATHS.CONFIRM_EMAIL}`;
  if (isPersonCompleted === false) return `/${PATHS.PROFILE}`;
  if (isPersonCompleted === true && isPersonConfirmed === false) return `/${PATHS.PROFILE}/${PROFILE_PATHS.CONFIRM}`;
  if (roleName === USER.ROLES.INVESTOR) return null;
  if (getPreApprovalPath(requisitionStatus)) return `/${PATHS.PRE_APPROVAL}`;
  if (getIsCancelledRequisitionPath({ status: requisitionStatus })) return `/${PATHS.CANCELLED_REQUISITION}`;
  if (getIsReferencesRequisitionPath({ status: requisitionStatus })) return `/${PATHS.REFERENCES}`;
  if (isPersonConfirmed === true) return `/${PATHS.REQUISITION}`;
  return null;
};

export const useRedirect = ({
  skipEmailValidation,
}) => {
  const navigate = useNavigate();
  const { data, loading } = useUserQuery();
  const { id: userId, flags, roleName } = data?.user || {};
  const { status: requisitionStatus } = data?.activeRequisition || {};
  const lastLocation = useLastLocation({ userId });
  const isPersonCompleted = flags?.isPersonCompleted;
  const isPersonConfirmed = flags?.isPersonConfirmed;
  const isEmailConfirmed = flags?.isEmailConfirmed;

  const navigateTo = getRedirectPath({
    roleName,
    isEmailConfirmed,
    isPersonCompleted,
    isPersonConfirmed,
    requisitionStatus,
    skipEmailValidation,
  });

  useEffect(() => {
    if (loading) return;
    if (navigateTo) navigate(navigateTo, { replace: true });
    else if (lastLocation) navigate(lastLocation, { replace: true });
  }, [loading, navigate, navigateTo, lastLocation]);
};
