import { useCallback, useEffect } from 'react';
import { useNavigate } from '@hooks/navigation';
import {
  getIsBuroReportPath,
  getIsBuroOfflinePath,
  getIsBuroBlockedPath,
  getIsBuroUnprocessablePath,
} from '@navigators/Root/App/Credit/PreApproval/utils';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useUser } from './graphql/useUser';

export const useRedirect = () => {
  const navigate = useNavigate();
  const { data, loading } = useUser();
  const { status: requisitionStatus } = data?.activeRequisition || {};

  const resolvePath = useCallback(() => {
    if (loading || !requisitionStatus) return null;

    if (getIsBuroOfflinePath({ requisitionStatus })) return PATHS.BURO_OFFLINE;
    if (getIsBuroReportPath({ requisitionStatus })) return PATHS.BURO;
    if (getIsBuroOfflinePath({ requisitionStatus })) return PATHS.BURO_OFFLINE;
    if (getIsBuroBlockedPath({ requisitionStatus })) return PATHS.BURO_BLOCKED;
    if (getIsBuroUnprocessablePath({ requisitionStatus })) return PATHS.BURO_UNPROCESSABLE;

    return '../';
  }, [loading, requisitionStatus]);

  useEffect(() => {
    const navigateTo = resolvePath();
    if (navigateTo) navigate(navigateTo, { replace: true });
  }, [navigate, resolvePath]);
};
