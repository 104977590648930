import React from 'react';
import AsideImageSrc from '@assets/PasswordRecovery/aside.jpg';
import RightSideSrc from '@assets/SignIn/rightSide.svg';
import ytpBrand from '@assets/branding/ytp-default.png';
import TwoSidesLayout from '@components/layouts/TwoSides';
import { Heading, Image } from 'ui';
import { UpdatePasswordForm } from '../components';
import { useController } from './hooks';

export const UpdatePassword = () => {
  const {
    email,
    isLoading,
    submitNewPassword,
  } = useController();

  return (
    <TwoSidesLayout
      bgImageLeftSideSrc={AsideImageSrc}
      bgImageRightSideSrc={RightSideSrc}
      rightSide={(
        <>
          <Heading>
            <Image src={ytpBrand} w="12rem" mb="4rem" />
          </Heading>
          <UpdatePasswordForm
            email={email || ''}
            isLoading={isLoading}
            onSubmit={submitNewPassword}
            data-testid="update-password-form"
          />
        </>
      )}
    />
  );
};
