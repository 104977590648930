export const styles = {
  buroBlockedBanner: {
    textAlign: 'center',
    maxWidth: 'md',
    bgColor: 'white',
    paddingTop: 3,
    paddingBottom: 3,
  },
  buroBlockedBannerTitle: {
    fontSize: 'lg',
    paddingBottom: 4,
  },
  list: {
    textAlign: 'left',
  },
  listItem: {
    color: 'green.500',
  },
  listItemText: {
    color: 'black',
  },
};
