import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

async function validator(values) {
  const { email } = values;

  return {
    values: {
      ...values,
      email,
    },
    errors: removeNilValues({
      email: Validator(email)
        .required()
        .email(undefined, { ignoreSpecialChars: true })
        .result(),
    }),
  };
}

export default validator;
