import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/constants';
import { PATHS as CREDIT_PATHS } from '@navigators/Root/App/Credit/constants';

const useResolvePathConfig = (configs) => {
  const location = useLocation();

  return useMemo(() => {
    const configArray = Object.entries(configs);
    const path = location.pathname;
    const config = configArray.find(([key]) => path.match(key));

    return config ? config[1] : null;
  }, [configs, location.pathname]);
};

const defaultConfig = {
  currentStep: -1,
  displayEditableLoan: null,
  displayLateralBar: false,
};

export const useConfig = () => {
  const config = useResolvePathConfig({
    [`${PATHS.REQUISITION}/${CREDIT_PATHS.CONFIRM}`]: {
      currentStep: 0,
      displayEditableLoan: null,
      displayLateralBar: true,
    },
    [`${PATHS.REQUISITION}/*`]: {
      currentStep: 0,
      displayEditableLoan: true,
      displayLateralBar: true,
    },
    [`${PATHS.PRE_APPROVAL}/*`]: {
      currentStep: 1,
      displayEditableLoan: false,
      displayLateralBar: true,
    },
    [`${PATHS.REFERENCES}`]: {
      currentStep: 2,
      displayEditableLoan: true,
      displayLateralBar: false,
    },
  });

  return config || defaultConfig;
};
