import { t } from '@i18n';

const DEFAULT_PHONE_LENGTH = 10;

const wrapError = (message) => ({ message, type: 'danger' });

export const phone = (phoneNumber, { phoneCode, phoneCodeValidations = {} } = {}) => {
  const lengthValidations = phoneCodeValidations[phoneCode] || {};
  const min = lengthValidations.min || DEFAULT_PHONE_LENGTH;
  const max = lengthValidations.max || DEFAULT_PHONE_LENGTH;
  const parsed = phoneNumber?.match(/(\d+)/g)?.join('');
  if (!parsed) return wrapError(t('FormResolver.required'));

  const lengthError = parsed.length < min || parsed.length > max;
  if (lengthError) {
    if (min === max) return wrapError(t('FormResolver.exactDigits', { count: min }));
    return wrapError(t('FormResolver.minLengthPhone', { length: min, max }));
  }

  return null;
};
