import { useGoToAppByRole } from '@hooks/useGoToAppByRole';
import { useEffect } from 'react';

export const useController = ({ roleName }) => {
  const { goToAppByRole } = useGoToAppByRole();

  useEffect(() => {
    goToAppByRole(roleName);
  }, [roleName, goToAppByRole]);
};
