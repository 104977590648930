import { PATHS } from '@navigators/Root/Guest/constants';
import { useNavigate } from '@hooks/navigation';
import { useAuthTokenParam } from '@hooks/auth/useAuthTokenParam';

export const useController = ({ searchName, redirectPath }) => {
  const navigate = useNavigate();

  const handleWrongToken = () => {
    navigate(`/${PATHS.SIGN_IN}`);
  };

  useAuthTokenParam({
    searchName,
    redirectPath,
    onInvalidToken: handleWrongToken,
    onMissingToken: handleWrongToken,
  });
};
