import { USER } from '@config/constants';
import { useUserQuery } from './useUserQuery';

export const useController = () => {
  const { data } = useUserQuery();
  const role = data?.user?.roleName;
  const shouldDisplayCoupon = role && USER.ROLES.BORROWER === role;

  return {
    shouldDisplayCoupon,
  };
};
