import jsCookieOriginal from 'js-cookie';
import {
  getCookiesDomain,
  getCookiesSameSite,
  getSessionCookieExpiry,
  REMEMBER_USER_EXPIRY,
} from '@config';
import { readString } from '../serializers/string';
import { isNotNil, isNil } from '..';

const COOKIE_UID = 'ytp_uid';
const COOKIE_EXPIRY = 'ytp_expiry';
const COOKIE_CLIENT = 'ytp_client';
const COOKIE_TOKEN_TYPE = 'ytp_token_type';
const COOKIE_ACCESS_TOKEN = 'ytp_access_token';
const COOKIE_REMEMBER = 'ytp_remember_email';

export const hasCredentials = (credentials) => [
  credentials?.uid,
  credentials?.expiry,
  credentials?.client,
  credentials?.tokenType,
  credentials?.accessToken,
].every(Boolean);

export const jsCookie = jsCookieOriginal.withConverter({
  read: (value) => decodeURIComponent(value),
  write: (value) => encodeURIComponent(value),
});

export const readCredentials = () => ({
  uid: readString(jsCookie.get(COOKIE_UID)),
  expiry: readString(jsCookie.get(COOKIE_EXPIRY)),
  client: readString(jsCookie.get(COOKIE_CLIENT)),
  tokenType: readString(jsCookie.get(COOKIE_TOKEN_TYPE)),
  accessToken: readString(jsCookie.get(COOKIE_ACCESS_TOKEN)),
});

export const getSessionCookie = () => {
  const rememberUser = jsCookie.get(COOKIE_REMEMBER);
  const credentials = readCredentials();

  return JSON.stringify({
    credentials,
    rememberUser,
    email: rememberUser,
    // If credentials are present we still need to check is they are valid,
    // otherwise we know for sure is not authenticated
    isAuthenticated: hasCredentials(credentials) ? null : false,
  });
};

export const storeCredentials = ({
  uid,
  expiry,
  client,
  tokenType,
  accessToken,
}) => {
  const options = {
    domain: getCookiesDomain(),
    sameSite: getCookiesSameSite(),
    expires: getSessionCookieExpiry(),
    secure: true,
  };

  jsCookie.set(COOKIE_UID, uid, options);
  jsCookie.set(COOKIE_EXPIRY, expiry, options);
  jsCookie.set(COOKIE_CLIENT, client, options);
  jsCookie.set(COOKIE_TOKEN_TYPE, tokenType, options);
  jsCookie.set(COOKIE_ACCESS_TOKEN, accessToken, options);
};

export const setSessionCookie = (value, secure = true) => {
  const domain = getCookiesDomain();
  const expiry = getSessionCookieExpiry();
  const options = { expires: expiry, secure, domain };
  const { credentials, rememberUser } = value || {};

  storeCredentials(credentials || {});

  if (isNotNil(rememberUser)) {
    jsCookie.set(COOKIE_REMEMBER, rememberUser, { ...options, expires: REMEMBER_USER_EXPIRY });
  }

  if (isNil(rememberUser)) {
    jsCookie.remove(COOKIE_REMEMBER, { domain });
  }
};
