import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const ACTIVE_REQUISITION_GQL = gql`
  query ActiveRequisition {
    activeRequisition {
      id
      buroErrorCode
    }
  }
`;

export const useActiveRequisition = (options) => useQuery(ACTIVE_REQUISITION_GQL, options);
