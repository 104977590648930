import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query BASIC_USER_DATA {
    user {
      id
      email
      status
      firstName
      roleName
      flags {
        id
        isEmailConfirmed
        isPersonCompleted
        isPersonConfirmed
        forcePasswordUpdate
        isRecoveringPassword
      }
    }
    activeRequisition {
      id
      status
      dateToStartANewRequisition
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
