import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const createPassword = (builder) => ({
  createPassword: builder.mutation({
    query: ({ email }) => ({
      url: endpoints.auth.createPassword.url,
      method: endpoints.auth.createPassword.method,
      body: {
        email,
      },
      responseHandler: async (response) => {
        const responseJSON = await response.json();

        if (responseJSON.errors) {
          return {
            type: 'error',
            message: responseJSON.errors[0],
          };
        }

        return ({
          ...responseJSON,
        });
      },
    }),
    transformResponse,
  }),
});

export default createPassword;
