import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormHelperText,
} from 'ui';

export const SearchField = (props) => {
  const {
    id,
    label,
    submitText,
    name,
    control,
    isLoading,
    form,
    textTransform,
    buttonType,
    'data-testid': testId,
    ...contentProps
  } = props;

  const {
    field: {
      ref,
      value,
      onBlur,
      onChange,
    },
    fieldState: {
      invalid,
      error,
    },
  } = useController({
    name,
    control,
  });

  const testIdFor = {
    container: testId,
    input: `${testId}-input`,
    label: `${testId}-label`,
    button: `${testId}-button`,
    textHelper: `${testId}-textHelper`,
  };

  return (
    <FormControl data-testid={testIdFor.container} {...contentProps}>
      <FormLabel
        htmlFor={id}
        mr="0"
        data-testid={testIdFor.label}
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          id={id}
          pr="20"
          borderColor="gray.400"
          data-testid={testIdFor.input}
          textTransform={textTransform}
          ref={ref}
          isInvalid={invalid}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        <InputRightElement width="20">
          <Button
            type={buttonType}
            colorScheme="blue"
            animationType="none"
            borderRadius="0 0.75rem .75rem 0"
            isLoading={isLoading}
            form={form}
            data-testid={testIdFor.button}
          >
            {submitText}
          </Button>
        </InputRightElement>
      </InputGroup>
      {(invalid && error) && (
        <FormHelperText
          color="red.500"
          fontSize="xs"
          data-testid={testIdFor.textHelper}
        >
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

SearchField.defaultProps = {
  id: null,
  isLoading: false,
  buttonType: 'submit',
  form: null,
};

SearchField.propTypes = {
  id: PropTypes.string,
  form: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonType: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  submitText: PropTypes.string.isRequired,
};
