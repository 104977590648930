import React from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { AuthTokenParam } from '@navigators/Root/Guest/components';
import { VerifyEmail } from '@views/PasswordRecovery';
import { PATHS as APP_PATHS } from '@navigators/Root/App/constants';
import { PATHS as APP_PASSWORD_RECOVERY_PATHS } from '@navigators/Root/App/PasswordRecovery/constants';
import { PATHS } from './constants';

const RECOVERY_PASSWORD_PATH = `/${APP_PATHS.PASSWORD_RECOVERY}/${APP_PASSWORD_RECOVERY_PATHS.UPDATE}`;

export const PasswordRecovery = () => (
  <Routes>
    <Route
      index
      element={<VerifyEmail />}
    />
    <Route
      path={PATHS.UPDATE}
      element={(
        <AuthTokenParam
          searchName="token"
          redirectPath={RECOVERY_PASSWORD_PATH}
        />
      )}
    />
    <Route
      path="*"
      element={<Navigate to="/" replace />}
    />
  </Routes>
);
