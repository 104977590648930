import { documentAttributes } from '@config/index';
import { useEffect } from 'react';

const {
  lang,
} = documentAttributes;

export const useSetHTMLAttrs = () => {
  useEffect(() => {
    document.documentElement.lang = lang;
  }, []);
};
