import { ApolloLink } from '@apollo/client';
import { getAppVersion, getAppName } from '@config/index';
import { hasCredentials, readCredentials, storeCredentials } from '@lib/storage/credentials';

const buildContext = (context) => {
  const headers = context?.headers || {};
  const clientHeaders = {
    'X-Client-Platform': getAppName(),
    'X-Client-Version': getAppVersion(),
  };
  const credentials = readCredentials();
  const authHeaders = {
    uid: credentials.uid,
    expiry: credentials.expiry,
    client: credentials.client,
    'token-type': credentials.tokenType,
    'access-token': credentials.accessToken,
  };

  if (context.password) authHeaders.password = encodeURIComponent(context.password);

  return {
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
      ...headers,
      ...authHeaders,
      ...clientHeaders,
    },
  };
};

const getCredentialsFromHeaders = (headers) => ({
  uid: headers.get('uid'),
  expiry: headers.get('expiry'),
  client: headers.get('client'),
  tokenType: headers.get('token-type'),
  accessToken: headers.get('access-token'),
});

export const headersLink = new ApolloLink((operation, forward) => {
  operation.setContext(buildContext);

  return forward(operation).map((data) => {
    const headers = operation.getContext().response?.headers;
    const credentials = getCredentialsFromHeaders(headers);
    if (hasCredentials(credentials)) storeCredentials(credentials);
    return data;
  });
});
