import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useUpdateHouseholdForm } from './useUpdateHouseholdForm';
import { useGetCatalogQuery, useUpdateHouseholdMutation, useGetHouseholdQuery } from './graphql';

export const useController = () => {
  const navigate = useNavigate();

  const { data: catalogData } = useGetCatalogQuery();
  const { data: household } = useGetHouseholdQuery();

  const maritalStatuses = catalogData?.catalogs.maritalStatuses;
  const educationLevels = catalogData?.catalogs.educationLevels;

  const goToNextStep = () => {
    navigate(`../${PATHS.WORK}`);
  };

  const [updateHouseholdMutation, { loading: isSubmitting }] = useUpdateHouseholdMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const onBack = () => navigate(`../${PATHS.ADDRESS}`);

  const {
    control,
    onSubmit,
  } = useUpdateHouseholdForm({
    household,
    updateHouseholdMutation,
  });

  return {
    onBack,
    control,
    onSubmit,
    isSubmitting,
    maritalStatuses,
    educationLevels,
  };
};
