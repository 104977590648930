export const styles = {
  card: {
    maxWidth: 'md',
  },
  cardBody: {
    padding: { base: '7', md: '10' },
  },
  cardTitle: {
    marginBottom: '8',
    textAlign: 'center',
  },
  formContainer: {
    rowGap: '3',
    alignItems: 'center',
    justifyItems: 'start',
    gridTemplateColumns: '1fr 2fr',
  },
  label: {
    marginTop: '2',
    color: 'gray.500',
  },
  divider: {
    gridColumn: '1 / -1',
  },
  destinationGroup: {
    width: 'full',
    gridColumn: '1 / 3',
  },
  submit: {
    placeSelf: 'center',
    gridColumn: '1 / 3',
    colorScheme: 'green',
    width: { base: '40', sm: 'xs' },
  },
  footNote: {
    gridColumn: '1 / 3',
    textAlign: 'center',
  },
  reasonNote: {
    marginY: '1',
    fontSize: 'sm',
    color: 'gray.500',
    fontWeight: '500',
    fontStyle: 'italic',
  },
};
