import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { tscope } from '@i18n';
import { useCustomToast } from 'ui';
import { api } from '@services/yoteprestoAPI';
import { useDispatch } from 'react-redux';
import { setInitialAppPath } from '@reducks/session';

const t = tscope('hooks.useAuthTokenParam');

const I18N_MAP = {
  'auth.bad_credentials': t('alerts.error.invalidToken'),
  'auth.not_confirmed': t('alerts.error.notConfirmed'),
  'auth.last_attempt': t('alerts.error.invalidToken'),
  default: t('alerts.error.invalidToken'),
};

export const useAuthTokenParam = ({
  searchName,
  redirectPath = '/',
  onMissingToken = null,
  onInvalidToken = null,
}) => {
  const toast = useCustomToast();
  const dispatch = useDispatch();
  const [signIn, {
    error,
    isError,
    isSuccess,
    isLoading,
    isUninitialized,
  }] = api.useLoginMutation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get(searchName);

  useEffect(() => {
    if (!isSuccess || !redirectPath) return;
    dispatch(setInitialAppPath({ path: redirectPath }));
  });

  useEffect(() => {
    if (!isUninitialized || token || !onMissingToken) return;
    onMissingToken();
  }, [
    token,
    onMissingToken,
    isUninitialized,
  ]);

  useEffect(() => {
    if (!isUninitialized || !token) return;
    const credentials = {
      reset_password_token: token,
    };
    signIn(credentials);
  }, [
    token,
    signIn,
    searchParams,
    isUninitialized,
  ]);

  const handleUnauthorizedError = useCallback(() => {
    const errorKey = error?.data?.message;
    const message = I18N_MAP[errorKey] || I18N_MAP.default;
    toast({
      status: 'error',
      description: message,
    });
  }, [error?.data?.message, toast]);

  useEffect(() => {
    if (!isError) return;
    handleUnauthorizedError();
    if (!onInvalidToken) return;
    onInvalidToken();
  }, [
    toast,
    isError,
    onInvalidToken,
    handleUnauthorizedError,
  ]);

  return {
    isLoading,
  };
};
