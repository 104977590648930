import { t } from '@i18n';
import { useCustomToast } from 'ui';
import { USER } from '@config/constants';
import { useForm } from 'react-hook-form';
import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { getDefaultPhoneCode } from '@config';
import { parseDigits } from '@lib/parsers';
import { removeNilValues } from '@lib/Object';
import { usePasswordValidation, useValidationWatcher, usePhoneCodes } from '@hooks/form';
import { validatePasswordSync } from '@lib/validatePasswordSync';
import { useReferral } from './useReferral';
import resolver, { parseAmount } from '../utils/resolver';

const PASS_INPUT = 'password';
const INVALID_DOMAIN_ERROR = 'Invalid domain';

export const useRegisterForm = ({
  role,
  term,
  amount,
  destination,
  coupon,
  gclid,
  signUp,
  signUpError,
  minimumAmount,
  maximumAmount,
  referralToken,
}) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const syncErrors = useMemo(() => validatePasswordSync({ password, email }), [password, email]);
  const passwordValidation = usePasswordValidation({
    email,
    password,
    errors: syncErrors,
  });
  const phoneCodes = usePhoneCodes();
  const toast = useCustomToast();
  const referral = useReferral({ referralToken });

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setError,
    setValue,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      term,
      coupon,
      amount,
      destination,
      phoneNumber: '',
      password: '',
      passwordConfirm: '',
      phoneCode: getDefaultPhoneCode(),
      agreeWithTermConditions: false,
      publicity: true,
    },
    context: {
      role,
      minimumAmount,
      maximumAmount,
      passIsValid: passwordValidation.isValid,
      phoneCodeValidations: phoneCodes.validations,
    },
    resolver,
  });

  useValidationWatcher({
    watch,
    trigger,
    isSubmitted,
    inputName: PASS_INPUT,
    setInputState: setPassword,
    isValid: passwordValidation.isValid,
  });

  useEffect(() => {
    if (referral.email) setValue('email', referral.email);
  }, [setValue, referral.email]);

  useEffect(() => {
    if (referral.phoneNumber) setValue('phoneNumber', referral.phoneNumber);
  }, [setValue, referral.phoneNumber]);

  // Used to sync validation between phone number and code
  const phoneCode = watch('phoneCode');
  const phoneNumber = watch('phoneNumber');
  useEffect(() => {
    if (!isSubmitted) return;
    trigger();
  }, [
    trigger,
    isSubmitted,
    phoneCode,
    phoneNumber,
  ]);

  const userEmail = watch('email');
  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  const submitRegisterHandler = (values) => {
    const borrowerMetaData = role === USER.ROLES.BORROWER ? {
      payment_term: values.term || null,
      requested_amount: parseAmount(values.amount),
      loan_destination_id: values.destination || null,
    } : {};

    const metaData = {
      coupon: coupon || null,
      ...borrowerMetaData,
    };

    return signUp({
      role,
      gclid,
      email: values.email,
      password: values.password,
      publicity: values.publicity,
      cell_phone_number: parseDigits(values.phoneNumber),
      cell_phone_code: values.phoneCode,
      referral_token: referralToken,
      meta_data: removeNilValues(metaData),
    });
  };

  const onSubmit = handleSubmit(submitRegisterHandler);

  const handleSingUpError = useCallback((errors) => {
    if (!errors) return;

    const toastId = 'signup-error';
    const [emailError] = (errors?.email || []);

    if (emailError === INVALID_DOMAIN_ERROR) {
      const message = t('Register.accountCreate.emailField.errors.invalidDomain');
      setError('email', { type: 'error', message });
    } else if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        status: 'error',
        description: t('Common.errors.default'),
      });
    }
  }, [setError, toast]);

  useEffect(() => {
    const errors = signUpError?.data?.errors;
    handleSingUpError(errors);
  }, [signUpError, handleSingUpError]);

  return {
    control,
    onSubmit,
    phoneCodes,
    passwordValidation,
  };
};
