import React from 'react';
import PropTypes from 'prop-types';
import { tscope } from '@i18n';
import {
  Box,
  Button,
} from 'ui';
import { SearchField } from '@components/Form/SearchField/SearchField';
import { useController } from './hooks';

const ts = tscope('Register.promos');

export const AddCouponForm = (props) => {
  const {
    onCancel,
    ...contentProps
  } = props;

  const {
    control,
    isSubmitting,
    submitHandler,
  } = useController();

  return (
    <Box
      p="1.3rem 1.75rem"
      {...contentProps}
    >
      <Box
        as="form"
        id="addCouponForm"
        onSubmit={submitHandler}
      >
        <SearchField
          label={ts('coupon.enter')}
          submitText={ts('common.add')}
          name="coupon"
          control={control}
          isLoading={isSubmitting}
          form="addCouponForm"
          textTransform="uppercase"
          data-testid="couponField"
        />
      </Box>
      <Button
        mt="1rem"
        type="button"
        variant="link"
        colorScheme="blue"
        data-testid="CTA-cancel"
        onClick={onCancel}
      >
        {ts('common.cancel')}
      </Button>
    </Box>
  );
};

AddCouponForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
