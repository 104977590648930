import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';
import { parseAmount } from '@lib/parsers';
import { ts } from './constants';

const isDivisibleBy100 = (amount) => amount % 100 !== 0;

function resolver(values, context) {
  const {
    requestedAmount,
    loanDestination,
    comment,
    paymentTerms,
  } = values;
  const parsedAmount = parseAmount(requestedAmount);

  const {
    maximumAmount,
    minimumAmount,
  } = context;

  const errors = {
    requestedAmount: Validator(parsedAmount)
      .required()
      .custom(isDivisibleBy100, ts('amount.errors.divisibleBy100'))
      .range({
        min: minimumAmount, max: maximumAmount, type: 'currency', precision: 0,
      })
      .result(),
    loanDestination: Validator(loanDestination).required().result(),
    comment: Validator(comment).required().length({ min: 10, max: 512 }).result(),
    paymentTerms: Validator(paymentTerms).required().result(),
  };

  return {
    values,
    errors: removeNilValues(errors),
  };
}

export default resolver;
