export const styles = {
  container: {
    gap: 5,
    width: 'full',
    alignItems: 'start',
    gridTemplateColumns: { base: '1fr', md: 'repeat(3, 1fr)' },
  },
  nameField: {
    gridArea: '1 / 1',
    size: 'sm',
  },
  firstLastNameField: {
    gridArea: { base: '2 / 1', md: '1 / 2' },
    size: 'sm',
  },
  secondLastNameField: {
    gridArea: { base: '3 / 1', md: '1 / 3' },
    justifySelf: 'start',
    size: 'sm',
  },
  relationshipField: {
    gridArea: { base: '5 / 1', md: '2 / 1' },
    justifySelf: 'end',
    size: 'sm',
  },
  phoneField: {
    gridArea: { base: '4 / 1', md: '2 / 1 / 2 / -1' },
    justifySelf: 'end',
    size: 'sm',
    width: { base: '100%', md: '260px' },
  },
};
