import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const updatePassword = (builder) => ({
  updatePassword: builder.mutation({
    query: ({
      email,
      password,
      passwordConfirmation,
    }) => ({
      url: endpoints.auth.updatePassword.url,
      method: endpoints.auth.updatePassword.method,
      body: {
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
    transformResponse,
  }),
});

export default updatePassword;
