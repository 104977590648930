export const styles = {
  header: {
    top: '0',
    zIndex: 10,
    position: 'sticky',
    displayBrand: { base: 'flex', md: 'none' },
  },
  greeting: {
    color: 'gray.500',
    lineHeight: 4,
    fontSize: { base: 'xs', md: 'sm' },
  },
  username: {
    lineHeight: { base: 5, md: 7 },
    color: 'gray.700',
    textTransform: 'capitalize',
    fontSize: { base: 'md', md: 'lg' },
  },
  navItem: {
    borderColor: 'gray.200',
  },
};
