import { useNavigate } from '@hooks/navigation';
import { t } from '@i18n';
import { useCallback } from 'react';
import { useUpdatePasswordMutation } from './graphql/useUpdatePasswordMutation';

export const useSetNewPassword = ({
  toast,
}) => {
  const navigate = useNavigate();
  const handleSuccess = useCallback(() => {
    toast({
      description: t('passwordRecovery.toast.passwordChangedSuccess'),
      status: 'success',
    });
    navigate('/');
  }, [navigate, toast]);

  const invalidPasswordError = useCallback(() => {
    toast({
      description: t('passwordRecovery.toast.invalidPassword'),
      status: 'error',
    });
  }, [toast]);

  const handleError = useCallback(() => {
    const description = t('Common.errors.default');
    toast({
      description,
      status: 'error',
    });
  }, [toast]);

  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    onError: handleError,
    onCompleted: handleSuccess,
    onErrorCode: {
      INVALID_PASSWORD_ERROR: invalidPasswordError,
    },
  });

  const submitNewPassword = useCallback(async ({ password }) => {
    updatePassword({
      variables: {
        input: {
          newPassword: password,
        },
      },
    });
  }, [
    updatePassword,
  ]);

  return {
    isLoading: loading,
    submitNewPassword,
  };
};
