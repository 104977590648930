import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import resolver from '../resolver';
import {
  householdId,
  insuranceId,
  parseExpenses,
  entertainmentId,
  transportationId,
} from '../expensesParser';

const defaultValues = {
  household: '',
  transportation: '',
  entertainment: '',
  insurance: '',
};

export const useUpdateExpensesForm = ({
  expensesData,
  updateExpensesMutation,
}) => {
  const {
    control,
    reset,
    handleSubmit,
  } = useForm(
    {
      resolver,
      defaultValues,
    },
  );

  const submitHandler = (values) => updateExpensesMutation({
    variables: {
      input: {
        requisition: {
          spending: {
            expenses: parseExpenses(values, expensesData),
          },
        },
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!expensesData) return;

    const parseAmount = (expenseCatalogueId) => expensesData[expenseCatalogueId]?.amount;

    const household = parseAmount(householdId);
    const transportation = parseAmount(transportationId);
    const entertainment = parseAmount(entertainmentId);
    const insurance = parseAmount(insuranceId);

    reset({
      household,
      transportation,
      entertainment,
      insurance,
    });
  }, [expensesData, reset]);

  return {
    control,
    onSubmit,
  };
};
