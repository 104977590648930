import { useForm } from 'react-hook-form';
import { useDisclosure } from 'ui';
import { resolver } from '../resolver';

const initialValues = {
  password: '',
};

export const useController = (options) => {
  const { onSubmit } = options;
  const {
    isOpen: isVisible,
    onToggle: onWatchPassword,
  } = useDisclosure();
  const {
    watch,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    resolver,
    initialValues,
  });

  const password = watch('password');

  const submitHandler = handleSubmit(async () => {
    try {
      await onSubmit(password);
    } finally {
      setValue('password', '');
    }
  });

  return {
    control,
    isVisible,
    onWatchPassword,
    handleSubmit: submitHandler,
  };
};
