import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_QUERY = gql`
  query {
    user {
      id
      fullName
      birthDate
      curp
      rfc
      gender
      isMexican
      country {
        id
        name
      }
      address {
        city
        extNumber
        intNumber
        municipality
        neighborhood
        state {
          name
        }
        street
        zip
      }
      birthState {
        id
        name
      }
    }
  }
`;

export const useUserQuery = (options) => useQuery(USER_QUERY, options);
