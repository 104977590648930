import { USER } from '@config/constants';
import { useNavigate } from '@hooks/navigation';
import { useEffect, useState } from 'react';
import { useUserStatusQuery } from './useUserStatus';

export const STEPS = {
  EDIT: 'edit',
  CONFIRM: 'confirm',
};

export const useController = () => {
  const navigate = useNavigate();
  const { data } = useUserStatusQuery();
  const role = data?.user?.roleName || USER.ROLES.INVESTOR;
  const email = data?.user?.email || '';
  const isEmailConfirmed = data?.user?.flags?.isEmailConfirmed;
  const [currentStep, setCurrentStep] = useState(STEPS.CONFIRM);

  const showEditStep = () => {
    setCurrentStep(STEPS.EDIT);
  };

  const showConfirmStep = () => {
    setCurrentStep(STEPS.CONFIRM);
  };

  useEffect(() => {
    if (isEmailConfirmed) navigate('/');
  }, [isEmailConfirmed, navigate]);

  return {
    role,
    email,
    currentStep,
    changeEmailSuccessHandler: showConfirmStep,
    editEmailBackHandler: showConfirmStep,
    changeEmailHandler: showEditStep,
  };
};
