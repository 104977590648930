import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import {
  Text,
  Card,
  Button,
  Heading,
} from 'ui';
import { ConfirmPassword } from '@components/Form/ConfirmPassword';
import { useController } from './hooks';
import styles from './UpdatePasswordForm.styles';

export const UpdatePasswordForm = ({
  email,
  title,
  subtitle,
  onSubmit,
  submitText,
  isLoading,
  'data-testid': testId,
}) => {
  const {
    control,
    submitForm,
    isSubmitting,
    passwordValidation,
  } = useController({ onSubmit, email });

  return (
    <Card {...styles.card}>
      <Heading {...styles.title}>
        {title}
      </Heading>
      {subtitle && (
        <Text {...styles.subtitle}>
          {subtitle}
        </Text>
      )}
      <form onSubmit={submitForm} data-testid={testId}>
        <ConfirmPassword
          name="password"
          control={control}
          errors={passwordValidation.errors}
          isLoading={passwordValidation.isLoading}
          data-testid="update-password-inputs"
        />
        <Button
          data-testid="update-password-submit"
          isDisabled={isLoading || isSubmitting}
          isLoading={isLoading || isSubmitting}
          {...styles.button}
        >
          {submitText}
        </Button>
      </form>
    </Card>
  );
};

UpdatePasswordForm.defaultProps = {
  isLoading: false,
  'data-testid': null,
  title: t('passwordRecovery.updatePasswordForm.title'),
  subtitle: '',
  submitText: t('passwordRecovery.updatePasswordForm.submit'),
};

UpdatePasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  submitText: PropTypes.string,
};
