import { t } from '@i18n';
import validateCurp from 'validate-curp';

const INVALID_FORMAT_ERROR = 'INVALID_FORMAT';

function validate(value, message = null) {
  const { isValid, errors } = validateCurp(value);
  if (!value || isValid) return null;

  const defaultMessage = (errors || []).includes(INVALID_FORMAT_ERROR)
    ? t('validation.curp.invalidFormat')
    : t('validation.curp.invalidData');

  return {
    type: 'danger',
    value: value.toUpperCase(),
    message: message || defaultMessage,
  };
}

export default validate;
