import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const CONFIRM_USER = gql`
mutation ConfirmUser($input: ConfirmUserMutationInput!) {
  confirmUser(input: $input) {
    user {
      id
      isMexican
      flags {
        id
        isPersonConfirmed
      }
    }
    activeRequisition {
      id
      status
    }
  }
}
`;

export const useConfirmMutation = (options) => useMutation(CONFIRM_USER, options);
