import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { readCredentials } from '@lib/storage/credentials';
import { getApiUrl, getAppVersion, getAppName } from '@config';

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl(),
  prepareHeaders: (headers) => {
    const credentials = readCredentials();

    headers.set('X-Client-Platform', getAppName());
    headers.set('X-Client-Version', getAppVersion());

    if (credentials?.accessToken) {
      headers.set('access-token', credentials.accessToken);
      headers.set('uid', credentials.uid);
      headers.set('expiry', credentials.expiry);
      headers.set('client', credentials.client);
      headers.set('token-type', credentials.tokenType);
    }

    return headers;
  },
});

export default baseQuery;
