import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { getDefaultPhoneCode } from '@config';
import { parseDigits } from '@lib/parsers';
import resolver from '../resolver';

export const useBasicUpdateForm = ({
  user,
  updateUser,
  phoneCodeValidations,
}) => {
  /**
   * Users with no cellphone must save a new one
   */
  const shouldFillPhoneNumber = user && !user.cellPhoneNumber;
  const { control, handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {
      gender: '',
      firstName: '',
      secondName: '',
      phoneNumber: '',
      firstLastName: '',
      secondLastName: '',
    },
    context: {
      phoneCodeValidations,
      shouldFillPhoneNumber,
    },
  });

  const submitHandler = (values) => {
    const profile = {
      gender: values.gender,
      firstName: values.firstName,
      secondName: values.secondName,
      firstLastName: values.firstLastName,
      secondLastName: values.secondLastName,
    };

    /**
     * Users with no cellphone must save a new one
     */
    if (!user.cellPhoneNumber) {
      profile.cellPhoneNumber = parseDigits(values.phoneNumber);
      profile.cellPhoneCode = values.phoneCode;
    }

    return updateUser({
      variables: {
        profile,
      },
    });
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!user) return;
    reset({
      gender: user.gender || '',
      firstName: user.firstName || '',
      secondName: user.secondName || '',
      firstLastName: user.firstLastName || '',
      secondLastName: user.secondLastName || '',
      phoneNumber: user.cellPhoneNumber || '',
      phoneCode: getDefaultPhoneCode(),
    });
  }, [user, reset]);

  return {
    control,
    onSubmit,
    shouldFillPhoneNumber,
  };
};
