import React, {
  forwardRef,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { isNil } from '..';
import { parseDigits } from '../parsers/parseDigits';

const toCurrency = (value, options) => {
  const { maxDecimals, minDecimals } = options;
  const currencyFormatter = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });
  if (value === '.') return '';
  const [integer = '0', decimal] = value.split('.');
  const number = decimal ? [integer, decimal].join('.') : integer;
  const result = currencyFormatter.format(number);
  return result;
};

export const CurrencyInput = forwardRef((props, ref) => {
  const {
    onBlur,
    onFocus,
    onChange,
    maxLength,
    maxDecimals,
    minDecimals,
    ...inputProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const toCurrencyValue = useCallback((value) => {
    if (!value) return '';
    const numberStr = parseDigits(value);
    const numberParsed = maxLength
      ? numberStr?.slice(0, maxLength)
      : numberStr;
    const [integer = '0', decimal] = numberParsed.split('.');
    if (maxDecimals > 0 && decimal) return [integer, decimal.slice(0, maxDecimals)].join('.');
    return numberParsed;
  }, [
    maxDecimals,
    maxLength,
  ]);

  const changeValueHandler = (ev) => {
    if (maxDecimals === 0 && ev.target.value.includes('.')) return;
    const currencyNumber = toCurrencyValue(ev.target.value);
    onChange(currencyNumber);
  };

  const format = useCallback((value) => {
    if (value === '' || isNil(value)) return '';
    const result = toCurrency(`${value}`, { minDecimals, maxDecimals });
    return result;
  }, [minDecimals, maxDecimals]);

  const unFormat = useCallback((value) => {
    if (!value) return '';
    const result = parseDigits(`${value}`);
    return result;
  }, []);

  const onBlurHandler = () => {
    if (onBlur) onBlur();
    setIsFocused(false);
  };
  const onFocusHandler = () => {
    if (onFocus) onFocus();
    setIsFocused(true);
  };

  const value = isFocused ? unFormat(inputProps.value) : format(inputProps.value);

  return (
    <input
      ref={ref}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onChange={changeValueHandler}
      {...inputProps}
      value={value}
    />
  );
});

CurrencyInput.defaultProps = {
  maxDecimals: 2,
  minDecimals: 2,
  onBlur: () => {},
  onChange: () => {},
};

CurrencyInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  maxDecimals: PropTypes.number,
  minDecimals: PropTypes.number,
};
