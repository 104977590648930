import { REQUISITION } from '@config/constants';
import { tscope } from '@i18n/index';
import { getDaysLeft } from '@lib/Date';
import { isNil } from '@lib/Monads';

const ts = tscope('CancelledRequisition.cancelledUseCases');
export const getSectionFromStatus = ({ status }) => (
  {
    [REQUISITION.STATUS.CANCELED_BY_FRAUD]: 'rejected',
    [REQUISITION.STATUS.REJECTED_FAKE_INFO]: 'rejected',
    [REQUISITION.STATUS.CANCELED_BY_FAKE_INFO]: 'rejected',
    [REQUISITION.STATUS.CANCELED_BY_RISKY_PROFILE]: 'rejected',
    [REQUISITION.STATUS.REJECTED_UNABLE_CREDIT_INFO]: 'rejected',
    [REQUISITION.STATUS.CANCELED_NO_AUTH]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_DOUBLE_REQ]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_BEFORE_BURO]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_MISSING_DOCS]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_WRONG_PROFILE]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_MISSING_CLABE]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_BY_OTHER_REASON]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_AFTER_REFERENCES]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_BY_BAD_REFERENCES]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_BY_UNVALIDATED_INFORMATION]: 'cancelled',
    [REQUISITION.STATUS.CANCELED_NO_NEEDED]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_WRONG_AMOUNT]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_GOT_BETTER_RATE]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_PROPOSAL_REJECTED]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_GOT_ANOTHER_SOURCE]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_BY_UNDESIRED_AMOUNT]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_BY_RATE_NOT_ACCEPTED]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_CONSOLIDATION_NOT_ACCEPTED]: 'cancelledByUser',
    [REQUISITION.STATUS.CANCELED_BY_BURO_LOCKED]: 'buroLocked',
    [REQUISITION.STATUS.CANCELED_BC_EXPIRED]: 'expired',
    [REQUISITION.STATUS.CANCELED_BY_INACTIVITY]: 'expired',
    [REQUISITION.STATUS.CANCELED_FUNDING_DAYS_OVER]: 'notFormalized',
    [REQUISITION.STATUS.CANCELED_FORMALIZE_EXPIRED]: 'notFormalized',
  }[status]
);

export const rejectedWithoutLeadsStatus = [
  REQUISITION.STATUS.REJECTED_LEAD_CRITERIA_COMPLIANCE,
];

export const rejectedWithLeadsStatus = [
  REQUISITION.STATUS.REJECTED_PAYMENT_CAPACITY,
  REQUISITION.STATUS.REJECTED_BAD_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_UNABLE_TO_VERIFY_INFO,
  REQUISITION.STATUS.REJECTED_BY_BCSCORE,
  REQUISITION.STATUS.REJECTED_INCOME_RECORDS,
  REQUISITION.STATUS.REJECTED_SHORT_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_INSUFFICIENT_CREDIT_HISTORY,
  REQUISITION.STATUS.REJECTED_GOVERNMENT_EMPLOYEE,
  REQUISITION.STATUS.REJECTED_OVER_DEBTS,
  REQUISITION.STATUS.REJECTED_OUTSIDE_THE_COLLECTION_AREA,
  REQUISITION.STATUS.REJECTED_BY_YODA,
];

export const getIsRequisitionAvailableToWatchLeads = (requisition) => {
  if (!requisition) return null;
  const { status, dateToStartANewRequisition } = requisition;
  return rejectedWithLeadsStatus.includes(status)
  && getDaysLeft(dateToStartANewRequisition, new Date()) > 0;
};

const getContentWhenRejected = ({ status, daysLeft }) => {
  if (daysLeft <= 0) {
    return {
      title: ts('foreignUserRejection.title'),
      subtitle: ts('foreignUserRejection.readyToReapply'),
      displayCreditForm: true,
    };
  }

  if (rejectedWithLeadsStatus.includes(status)) {
    return {
      title: null,
      subtitle: null,
      displayRedirectToLegacySite: true,
    };
  }

  if (rejectedWithoutLeadsStatus.includes(status)) {
    return {
      title: ts('foreignUserRejection.title'),
      subtitle: ts('foreignUserRejection.subtitle'),
      displayCountdown: true,
    };
  }

  return null;
};

export const getContentFromStatus = ({ status, daysLeft }) => {
  if (isNil(status)) return null;

  const isForeignerRejected = [
    ...rejectedWithLeadsStatus,
    ...rejectedWithoutLeadsStatus,
  ].includes(status);

  if (isForeignerRejected) return getContentWhenRejected({ status, daysLeft });

  const section = getSectionFromStatus({ status });
  if (!section) return null;

  const content = {
    title: ts(`${section}.title`),
    subtitle: ts(`${section}.subtitle`),
    displayBuroBlockedBanner: section === 'buroLocked',
    displayCreditForm: section !== 'rejected',
  };

  return content;
};
