import { gql } from '@apollo/client';
import { useLazyQuery } from '@hooks/apollo';

export const ROLE_QUERY = gql`
  query GET_ROLE {
    user {
      id
      roleName
    }
  }
`;

export const useRoleLazyQuery = (options) => useLazyQuery(ROLE_QUERY, options);
