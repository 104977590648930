import React from 'react';
import { t } from '@i18n';
import { Heading, Center, Box } from 'ui';
import BGImage from '@assets/components/FullscreenLoader/loading.svg';
import LoaderEllipsis from './LoaderEllipsis/LoaderEllipsis';

export const FullscreenLoader = () => (
  <Center
    minHeight="100vh"
    minWidth="100vw"
    bgImage={`url(${BGImage})`}
    bgSize={{ base: '14rem auto', md: '21rem auto' }}
    bgRepeat="no-repeat"
    bgPosition="center"
    flexDirection="column"
    gridRowGap="1rem"
    data-testid="loading"
  >
    <Heading>
      {t('FullscreenLoader.title')}
    </Heading>
    <Box>
      <LoaderEllipsis bgColorDot="blue.400" />
    </Box>
  </Center>
);
