import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// eslint-disable-next-line import/no-extraneous-dependencies
import { captureConsoleIntegration } from '@sentry/browser';
import { getSentryConfig, isProduction } from '@config';

export const init = () => {
  const config = getSentryConfig();
  Sentry.init({
    dsn: config.dsn,
    release: config.release,
    environment: config.environment,
    tracesSampleRate: config.tracesSampleRate,
    replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration(),
    ],
  });
};

export const setUserId = (id) => {
  Sentry.setUser({ id });
};

export const setContext = (context = {}) => {
  Sentry.setContext('context', context);
};

const send = (level, event, context = null) => {
  const options = { level };
  if (context) options.contexts = context;
  Sentry.captureMessage(event, options);
};

export const info = (event, context = null) => send('info', event, context);

export const warn = (event, context = null) => send('warning', event, context);

export const error = (event, context = null) => send('error', event, context);

export const reportGraphQLError = (operationError, { hint = 'UNHANDLED_ERROR' } = {}) => {
  const { graphQLErrors, networkError } = operationError;
  const errorCodeList = graphQLErrors.map((e) => `query: [${e.path}], code: [${e.extensions.code}], message: ${e.message}`).join('\n');
  Sentry.setContext('GraphQL Error', {
    networkError,
    message: operationError.message,
    errorCodes: errorCodeList,
  });
  Sentry.captureMessage(`Report GraphQL Error Alert: ${hint}`);
};
