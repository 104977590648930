import React from 'react';
import { tscope } from '@i18n';
import { Skeleton } from 'ui';
import { ReactComponent as CouponIcon } from '@assets/components/coupon.svg';
import { StateSwitcher } from '../StateSwitcher/StateSwitcher';
import { AddCouponForm } from './components/AddCouponForm/AddCouponForm';
import { useController } from './hooks/useController';

const ts = tscope('Register.promos');

export const CouponPromo = () => {
  const {
    open,
    step,
    cancel,
    coupon,
    removeCoupon,
    loadingCoupon,
    isRemovingCoupon,
  } = useController();

  return (
    <Skeleton
      isLoaded={!loadingCoupon}
      borderRadius="0"
      endColor="gray.200"
      width="full"
    >
      <StateSwitcher
        initialText={ts('coupon.gotCoupon')}
        textCode={ts('coupon.yourCoupon')}
        data-testid="couponPromo"
        open={open}
        step={step}
        code={coupon}
        leftIcon={CouponIcon}
        onRemoveCode={removeCoupon}
        isRemoving={isRemovingCoupon}
        openFormComponent={(
          <AddCouponForm
            key="coupon"
            data-testid="addCouponForm"
            onCancel={cancel}
          />
      )}
      />
    </Skeleton>
  );
};
