import React from 'react';
import { FullscreenLoader } from '@components/FullscreenLoader';
import { useController } from './hooks';

export const AuthTokenParam = ({
  searchName,
  redirectPath,
}) => {
  useController({
    searchName,
    redirectPath,
  });

  return <FullscreenLoader />;
};
