export const style = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5',
    marginTop: '20',
    maxWidth: '200',
  },
  waitText: {
    fontSize: 'lg',
    textAlign: 'center',
  },
  count: {
    fontSize: '2xl',
  },
};
