import React from 'react';
import { tscope } from '@i18n/index';
import { BuroOfflineImage } from '@assets/images';
import {
  Card,
  Text,
  Stack,
  Divider,
  Heading,
  CardBody,
  CardFooter,
} from 'ui';
import { PreApprovalContainer } from '../components';
import style from './BuroOffline.style';

const ts = tscope('Requisition.offline');

export const BuroOffline = () => (
  <PreApprovalContainer>
    <Card maxW="sm">
      <CardBody padding={{ base: '5', md: '10' }}>
        <BuroOfflineImage
          data-testid="buroIcon"
          style={{ margin: 'auto' }}
        />
        <Stack mt="6" spacing="7">
          <Heading {...style.title}>
            {ts('title')}
          </Heading>
          <Text data-testid="body" {...style.text}>
            {ts('body')}
            <Text as="b">
              {ts('email')}
            </Text>
          </Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter {...style.footer}>
        <Text data-testid="notice" {...style.text}>
          {ts('notice')}
          <Text as="b">
            {ts('noticeBold')}
          </Text>
        </Text>
      </CardFooter>
    </Card>
  </PreApprovalContainer>
);
