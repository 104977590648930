import { PATHS } from '../../constants';
import {
  getIsBuroReportPath,
  getIsBuroOfflinePath,
  getIsBuroUnprocessablePath,
  getIsBuroBlockedPath,
} from '../utils';

const isPathAllowed = (allowed) => (allowed ? true : allowed);

export const usePreventMountElements = ({ activeRequisition }) => {
  const { status } = activeRequisition || {};
  const isBuroAllowed = getIsBuroReportPath({ requisitionStatus: status });
  const isBuroOfflineAllowed = getIsBuroOfflinePath({ requisitionStatus: status });
  const isBuroUnprocessableAllowed = getIsBuroUnprocessablePath({ requisitionStatus: status });
  const getIsBuroBlockedAllowed = getIsBuroBlockedPath({ requisitionStatus: status });
  const preventMountElements = {
    [PATHS.BURO]: {
      isAllowed: isPathAllowed(isBuroAllowed),
    },
    [PATHS.BURO_OFFLINE]: {
      isAllowed: isPathAllowed(isBuroOfflineAllowed),
    },
    [PATHS.BURO_UNPROCESSABLE]: {
      isAllowed: isPathAllowed(isBuroUnprocessableAllowed),
    },
    [PATHS.BURO_BLOCKED]: {
      isAllowed: isPathAllowed(getIsBuroBlockedAllowed),
    },
  };

  return preventMountElements;
};
