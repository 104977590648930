import {
  useUserQuery,
  useReferencesCatalogQuery,
  useCreateReferencesMutation,
} from './graphql';
import { useReferencesForm } from './useReferencesForm';

export const useController = ({
  t,
}) => {
  const { data: catalogueData, loading: isCatalogLoading } = useReferencesCatalogQuery();
  const personalReferences = catalogueData?.catalogs?.personalReferences;
  const { data, loading: isUserLoading } = useUserQuery();
  const references = data?.activeRequisition?.references || null;
  const username = data?.user?.firstName;
  const userPhoneNumber = data?.user?.cellPhoneNumber;
  const [updateReferencesMutation, { loading: isSubmitting }] = useCreateReferencesMutation();
  const {
    control,
    onSubmit,
  } = useReferencesForm({
    t,
    references,
    userPhoneNumber,
    updateReferencesMutation,
  });
  const isLoading = isCatalogLoading || isUserLoading || isSubmitting;

  return {
    control,
    onSubmit,
    username,
    isLoading,
    personalReferences,
  };
};
