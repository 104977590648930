import { t, l } from '@i18n';
import { isNil } from '../index';

export const formatBirthInfo = (user) => {
  if (!user) return t('Common.na');

  const {
    country,
    birthDate,
    isMexican,
    birthState,
  } = user;
  if (isNil(isMexican)) return t('Common.na');
  const place = isMexican
    ? birthState.name
    : country.name;
  return `${l('date.formats.short', birthDate)}, ${place}`;
};

export const formatGender = (user) => {
  if (!user?.gender) return t('Common.na');
  return t(`Register.profileConfirm.genderField.${user.gender}`);
};
