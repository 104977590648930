import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { logout as removeCredentials } from '@reducks/session';
import { api } from '@services/yoteprestoAPI';

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const logout = useCallback(async ({ navigateTo = null } = {}) => {
    apolloClient.clearStore();
    dispatch(api.util.resetApiState());
    dispatch(removeCredentials());

    // Flushing pending ticks to navigate after state is reset.
    await new Promise((callback) => { setTimeout(callback, 0); });

    navigate(navigateTo || '/');
  }, [navigate, dispatch, apolloClient]);

  return logout;
};
