import { useLocation as useOriginalLocation } from 'react-router-dom';

export const useLocation = () => {
  const {
    pathname,
    search,
    hash,
    ...other
  } = useOriginalLocation();

  const location = `${pathname || ''}${search || ''}${hash || ''}`;

  return {
    location,
    pathname,
    search,
    hash,
    ...other,
  };
};
