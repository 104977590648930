import { useInterval } from '@hooks/time';
import { ONE_SECOND } from '@lib/durations';
import { t } from '@i18n';

export const useController = ({ time, onSessionExpire }) => {
  const { humanize } = useInterval({
    initialTime: time,
    interval: ONE_SECOND,
    onFinish: onSessionExpire,
  });
  const description = t('SignIn.sessionToast.expiresIn', { count: humanize.seconds });
  const btnText = t('SignIn.sessionToast.renew');

  return {
    description,
    btnText,
  };
};
