import transformResponse from '@config/services/transformResponse';
import endpoints from '@config/endpoints';

const signOut = (builder) => ({
  logout: builder.mutation({
    query: (credentials) => ({
      url: endpoints.auth.signOut.url,
      method: endpoints.auth.signOut.method,
      body: credentials,
    }),
    transformResponse,
  }),
});

export default signOut;
