import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@components/Form/Field';
import { tscope } from '@i18n';
import {
  Button,
  Flex,
  Card,
  CardHeader,
} from 'ui';
import { useController } from './hooks';

const ts = tscope('Register.emailConfirm');

export const EditEmailForm = (props) => {
  const { onBackClick, onChangeEmailSuccess } = props;
  const { control, submitHandler, isSubmitting } = useController({ onChangeEmailSuccess });

  return (
    <Card w={{ base: '100%', sm: '28rem' }}>
      <CardHeader padding="2rem">
        <form
          onSubmit={submitHandler}
        >
          <TextField
            control={control}
            name="email"
            type="email"
            labelText={ts('changeEmailField.label')}
            placeholder={ts('changeEmailField.placeholder')}
            mb="0.5rem"
            data-testid="email"
          />
          <Flex
            justifyContent="stretch"
            gridColumnGap="2rem"
            mt="2rem"
            width="100%"
          >
            <Button
              variant="ghost"
              colorScheme="blue"
              size="lg"
              flexGrow="1"
              onClick={onBackClick}
              data-testid="back-button"
            >
              {ts('changeEmailField.goBack')}
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              flexGrow="1"
              data-testid="submit"
              isLoading={isSubmitting}
            >
              {ts('changeEmailField.submit')}
            </Button>
          </Flex>
        </form>
      </CardHeader>
    </Card>
  );
};

EditEmailForm.propTypes = {
  onChangeEmailSuccess: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default EditEmailForm;
