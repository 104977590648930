import Validator from '@lib/Validator';
import removeNilValues from '@lib/Object/removeNilValues';
import { useEffect, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Logger from '@lib/Logger';
import { useLoginMutation } from '@services/yoteprestoAPI';
import { useLoginErrorHandler } from './useLoginErrorHandler';

const resolver = async ({ password }) => ({
  values: { password },
  errors: removeNilValues({
    password: Validator(password).required().result(),
  }),
});

export const useLoginPasswordForm = ({ email, onSuccess, onError }) => {
  const [
    login,
    {
      data,
      error,
      isError,
      isSuccess,
      isLoading: isLoadingRequest,
    },
  ] = useLoginMutation();

  const {
    control,
    handleSubmit,
    formState,
    setError,
  } = useForm({ resolver });

  const isLoading = isLoadingRequest || formState.isSubmitting;

  const setInputError = useCallback((message) => {
    setError('password', { type: 'warning', message });
  }, [setError]);

  const handleError = useLoginErrorHandler({ setInputError });

  const onSubmit = useMemo(
    () => handleSubmit(({ password }) => {
      Logger.setContext({ sessionContext: password });
      return login({ email, password });
    }),
    [login, email, handleSubmit],
  );

  useEffect(() => {
    if (!isSuccess || !data) return;
    if (onSuccess) onSuccess(data);
  }, [data, isSuccess, onSuccess]);

  useEffect(() => {
    if (!isError || !error) return;
    if (onError) onError(error);
    handleError(error);
  }, [error, isError, onError, handleError]);

  return {
    control,
    onSubmit,
    isLoading,
  };
};
