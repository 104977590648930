const container = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  overflowY: 'hidden',
};

const content = {
  overflowY: 'scroll',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const styles = {
  container: {
    ...container,
    gridTemplateColumns: { base: '100%', md: '14rem auto' },
    gridTemplateRows: { base: '61px auto', md: '5.42rem auto' },
  },
  containerWithoutLateralBar: {
    ...container,
    gridTemplateColumns: { base: '100%', md: 'auto' },
    gridTemplateRows: { base: '61px auto', md: '5.42rem auto' },
  },
  content: {
    ...content,
    gridArea: { md: '2 / 2' },
  },
  contentWithoutLateralBar: {
    ...content,
  },
  header: {
    gridArea: { md: '1 / span 1 / 2 / span 1' },
  },
  sideBar: {
    zIndex: 2,
    gridArea: { md: '1 / 1 / 3 / 1' },
  },
  footer: {
    alignSelf: 'end',
    width: 'full',
  },
  innerContent: {
    width: '100%',
    flexGrow: 1,
    position: 'relative',
    justifySelf: 'center',
    pt: '16',
    pb: '10',
  },
};
