import { useNavigate } from 'react-router-dom';
import { PATHS } from '@navigators/Root/App/Credit/constants';
import { useUpdateIncomeForm } from './useUpdateIncomeForm';
import { useUpdateIncomeMutation, useGetIncomeQuery } from './graphql';

export const useController = () => {
  const navigate = useNavigate();

  const { data: incomeData } = useGetIncomeQuery();

  const goToNextStep = () => {
    navigate(`../${PATHS.EXPENSES}`);
  };

  const [updateIncomeMutation, { loading: isSubmitting }] = useUpdateIncomeMutation({
    onCompleted: () => {
      goToNextStep();
    },
  });

  const onBack = () => navigate(`../${PATHS.EMPLOYMENT}`);

  const {
    control,
    onSubmit,
  } = useUpdateIncomeForm({
    incomeData,
    updateIncomeMutation,
  });

  return {
    onBack,
    control,
    onSubmit,
    isSubmitting,
  };
};
