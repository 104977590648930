import { tscope } from '@i18n/index';

const ts = tscope('Requisition.household');

export const dependentsNumber = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: ts('sixOrMoreDependents') },
];
