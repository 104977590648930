import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import { PASSWORD } from '@config/constants';
import { CheckList, CheckItem } from '@components/CheckList';

const ERRORS_CONFIG = [
  {
    alwaysDisplay: true,
    key: PASSWORD.VALIDATION_ERRORS.MIN_LENGTH,
    text: t('ConfirmPassword.rulesList.length', { length: PASSWORD.MIN_LENGTH }),
  },
  {
    alwaysDisplay: true,
    key: PASSWORD.VALIDATION_ERRORS.MISSING_DIGIT,
    text: t('ConfirmPassword.rulesList.digit'),
  },
  {
    alwaysDisplay: true,
    key: PASSWORD.VALIDATION_ERRORS.SPECIAL_CHAR,
    text: t('ConfirmPassword.rulesList.special'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CONTAINS_ALPHABETIC,
    text: t('ConfirmPassword.rulesList.alphabetic'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CONTAINS_RESERVED_WORDS,
    text: t('ConfirmPassword.rulesList.reservedWords'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CONTAINS_EMAIL,
    text: t('ConfirmPassword.rulesList.email'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.REPEATED_CHARS,
    text: t('ConfirmPassword.rulesList.repeated'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CONSECUTIVE_CHARS,
    text: t('ConfirmPassword.rulesList.consecutive'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.SEQUENTIAL_CHARS,
    text: t('ConfirmPassword.rulesList.sequential'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CURRENT_PASSWORD,
    text: t('ConfirmPassword.rulesList.currentPass'),
  },
  {
    key: PASSWORD.VALIDATION_ERRORS.CONTAINS_ID,
    text: t('ConfirmPassword.rulesList.ids'),
  },
];

const getType = (hasError, empty) => {
  if (empty) return null;
  return hasError ? 'error' : 'success';
};

const List = ({ errors }) => {
  const isEmpty = !errors;

  return ERRORS_CONFIG.map(({ alwaysDisplay, key, text }, index) => {
    const hasError = !isEmpty && (errors || []).includes(key);
    if (!hasError && !alwaysDisplay) return null;
    return (
      <CheckItem
        key={key}
        type={getType(hasError, isEmpty)}
        mt={index === 0 ? '0' : '0.5rem'}
      >
        <span>{text}</span>
      </CheckItem>
    );
  }).filter((e) => !!e);
};

List.defaultProps = {
  errors: [],
};

List.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export const PasswordValidations = ({
  isLoading,
  errors,
  'data-testid': testId,
}) => (
  <CheckList data-testid={testId} opacity={isLoading ? 0.5 : 1}>
    <List errors={errors} />
  </CheckList>
);

PasswordValidations.defaultProps = {
  isLoading: false,
  errors: [],
  'data-testid': null,
};

PasswordValidations.propTypes = {
  isLoading: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  'data-testid': PropTypes.string,
};

export default PasswordValidations;
