import removeNilValues from '@lib/Object/removeNilValues';
import Validator from '@lib/Validator';

function resolver(values, context) {
  const {
    gender,
    phoneCode,
    firstName,
    secondName,
    phoneNumber,
    firstLastName,
    secondLastName,
  } = values;

  const {
    phoneCodeValidations,
    shouldFillPhoneNumber,
  } = context;

  const errors = {
    gender: Validator(gender).required().result(),
    firstName: Validator(firstName).required().onlyLetters().result(),
    secondName: Validator(secondName).onlyLetters().result(),
    firstLastName: Validator(firstLastName).required().onlyLetters().result(),
    secondLastName: Validator(secondLastName).onlyLetters().result(),
    phoneNumber: shouldFillPhoneNumber
      ? Validator(phoneNumber).phone({ phoneCodeValidations, phoneCode }).result()
      : null,
  };

  return { values, errors: removeNilValues(errors) };
}

export default resolver;
