export const styles = {
  titleContainer: {
    display: {
      base: 'none',
      md: 'flex',
    },
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem',
  },
  title: {
    fontWeight: '700',
  },
};
