import { gql } from '@apollo/client';
import { useMutation } from '@hooks/apollo';

export const REMOVE_REFERENCE = gql`
mutation removeReferral($referral: RemoveReferralMutationInput!) {
  removeReferral(input: $referral) {
    user {
      id
      referred {
        referenceCode
      }
    }
  }
}
`;

export const useRemoveReferralMutation = (options) => useMutation(REMOVE_REFERENCE, options);
