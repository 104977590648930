import { t } from '@i18n';
import { useCallback } from 'react';
import { useCustomToast } from 'ui';

const I18N_MAP = {
  'auth.bad_credentials': {
    toast: t('SignIn.errors.toast.badCredentials'),
    input: t('SignIn.errors.input.badCredentials'),
  },
  'auth.not_confirmed': {
    toast: t('SignIn.errors.toast.notConfirmed'),
    input: t('SignIn.errors.input.notConfirmed'),
  },
  'auth.last_attempt': {
    toast: t('SignIn.errors.toast.lastAttempt'),
    input: t('SignIn.errors.input.badCredentials'),
  },
  'auth.user_not_found': {
    toast: t('SignIn.errors.toast.userNotFound'),
    input: t('SignIn.errors.input.userNotFound'),
  },
  default: {
    toast: t('Common.errors.default'),
    input: t('SignIn.errors.input.badCredentials'),
  },
};

export const useLoginErrorHandler = ({ setInputError } = {}) => {
  const toast = useCustomToast();

  const handlerError = useCallback((error) => {
    const errorKey = error?.data?.message;
    const messages = I18N_MAP[errorKey] || I18N_MAP.default;

    toast({
      status: 'warning',
      description: messages.toast,
    });

    if (setInputError) setInputError(messages.input);
  }, [toast, setInputError]);

  return handlerError;
};
