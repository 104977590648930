import { t } from '@i18n';
import { useCallback } from 'react';
import { useCustomToast } from 'ui';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuthenticated, setExpiredByInactivity } from '@reducks/session';
import { useLogout } from './useLogout';

export const UNAUTHORIZED_ALERT = {
  id: 'session-expired-toast',
  description: t('Common.errors.sessionExpired'),
  status: 'error',
};

export const useUnauthenticatedHandler = () => {
  const logout = useLogout();
  const dispatch = useDispatch();
  const showToast = useCustomToast();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const handleUnauthenticated = useCallback(() => {
    if (isAuthenticated === true) {
      dispatch(setExpiredByInactivity());
    } else {
      if (!showToast.isActive(UNAUTHORIZED_ALERT.id)) showToast(UNAUTHORIZED_ALERT);
      logout();
    }
  }, [
    logout,
    dispatch,
    showToast,
    isAuthenticated,
  ]);

  return handleUnauthenticated;
};
