import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_GQL = gql`
  query {
    user {
      id
      maritalStatus {
        id
      }
      address {
        zip
      }
    }
    activeRequisition {
      id
      status
      spending {
        id
        hasCar
        dependentsNumber
        expenses {
          id
          amount
        }
      }
      loanInformation {
        id
        comment
      }
      income {
        income
        hasImss
        hasSgmm
        employment {
          id
          name
        }
      }
    }
  }
`;

export const useUser = (options) => useQuery(USER_GQL, options);
