export const maskEmailAddress = (emailAddress) => {
  const mask = (str) => {
    const strLen = str.length;
    if (strLen > 4) {
      return `${str.substr(0, 1)}${str.substr(1, strLen - 2).replace(/\w/g, '*')}${str.substr(-1)}`;
    }
    return str.replace(/\w/g, '*');
  };
  return emailAddress.replace(
    /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
    (m, p1, p2, p3) => `${mask(p1)}@${mask(p2)}${p3}`,
  );
};

export const maskPhoneNumber = (value) => {
  if (!value) return '-';
  return `${value.substr(0, 2)}******${value.substr(-2)}`;
};
