import React from 'react';
import { t } from '@i18n';
import { RegisterContainer } from '../components';
import { ConfirmEmailForm, EditEmailForm } from './components';
import { useController, STEPS } from './hooks/useController';

export const ConfirmEmail = () => {
  const {
    role,
    email,
    currentStep,
    changeEmailHandler,
    editEmailBackHandler,
    changeEmailSuccessHandler,
  } = useController();

  return (
    <RegisterContainer
      testimonials={t(`Common.testimonials.${role}`)}
    >
      {currentStep === STEPS.EDIT && (
        <EditEmailForm
          onBackClick={editEmailBackHandler}
          onChangeEmailSuccess={changeEmailSuccessHandler}
        />
      )}
      {currentStep === STEPS.CONFIRM && (
        <ConfirmEmailForm
          email={email}
          onChangeEmail={changeEmailHandler}
        />
      )}
    </RegisterContainer>
  );
};
