import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setUnavailableService } from '@reducks/serviceStatus/serviceStatus';
import { UNAVAILABLE_SERVICE_STATUS } from '@config';

/**
 * Applied ioC (inversion of control) for isRejectedWithValue
 * function in order to avoid to mock '@reduxjs/toolkit' which
 * cannot be mocked since this module is hosted at very top of
 * test environment
 */

export const createUnavailableServiceMiddleware = (RTKToolkit) => (store) => (next) => (action) => {
  if (
    RTKToolkit.isRejectedWithValue(action)
    && action.payload?.originalStatus === UNAVAILABLE_SERVICE_STATUS
  ) {
    store.dispatch(setUnavailableService());
  }

  return next(action);
};

export const unavailableServiceMiddleware = createUnavailableServiceMiddleware({
  isRejectedWithValue,
});
