import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@i18n';
import { Box, Text } from 'ui';
import styles from './ReferralCouponWrapper.styles';

export const ReferralCouponWrapper = ({
  children,
  ...other
}) => (
  <Box
    {...styles.box}
    {...other}
  >
    {children}
    <Text fontSize="sm" {...styles.optional}>
      {t('Common.optional')}
    </Text>
  </Box>
);

ReferralCouponWrapper.defaultProps = {
  children: null,
};

ReferralCouponWrapper.propTypes = {
  children: PropTypes.node,
};
