import { EXPENSES } from '@lib/Catalogue/Expenses';
import { currencyToNumber } from '@lib/parsers';

const getId = (catalogueExpenseID, expensesData) => {
  const id = expensesData?.[catalogueExpenseID]?.id;

  if (id) return { id };
  return null;
};

export const householdId = EXPENSES.household.expenseId;
export const transportationId = EXPENSES.transportation.expenseId;
export const entertainmentId = EXPENSES.entertainment.expenseId;
export const insuranceId = EXPENSES.insurance.expenseId;

export const parseExpenses = (values, expensesData) => [
  {
    ...getId(householdId, expensesData),
    expenseId: householdId,
    amount: currencyToNumber(values.household),
  },
  {
    ...getId(transportationId, expensesData),
    expenseId: transportationId,
    amount: currencyToNumber(values.transportation),
  },
  {
    ...getId(entertainmentId, expensesData),
    expenseId: entertainmentId,
    amount: currencyToNumber(values.entertainment),
  },
  {
    ...getId(insuranceId, expensesData),
    expenseId: insuranceId,
    amount: currencyToNumber(values.insurance),
  },
];
