import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Textarea } from 'ui';
import Base from '../Base';

export const TextAreaField = (props) => {
  const {
    id,
    name,
    type,
    rows,
    control,
    onFocus,
    autoFocus,
    noOfLines,
    labelText,
    inputHeight,
    placeholder,
    autoComplete,
    onBlur: onBlurParent,
    'data-testid': dataTestId,
    ...contentProps
  } = props;

  const {
    field: {
      value,
      onChange,
      onBlur,
      ref,
    },
    fieldState: { error },
  } = useController({ name, control, defaultValue: '' });

  const testId = dataTestId ? `${dataTestId}-input` : null;

  const handleOnBlur = (ev) => {
    onBlur(ev);
    onBlurParent(ev);
  };

  return (
    <Base
      {...contentProps}
      id={id}
      name={name}
      control={control}
      labelText={labelText}
      data-testid={dataTestId}
      noOfLines={noOfLines}
    >
      <Textarea
        size="md"
        borderColor="gray.400"
        ref={ref}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        onChange={onChange}
        name={name}
        value={value}
        rows={rows}
        isInvalid={!!error}
        height={inputHeight}
        data-testid={testId}
        placeholder={placeholder}
        autoComplete={autoComplete}
        borderRadius="xl"
        autoFocus={autoFocus}
      />
    </Base>
  );
};

TextAreaField.defaultProps = {
  id: '',
  type: 'text',
  labelText: '',
  placeholder: '',
  'data-testid': '',
  inputHeight: '',
  noOfLines: null,
  onBlur: () => {},
};

TextAreaField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  labelText: PropTypes.string,
  inputHeight: PropTypes.string,
  placeholder: PropTypes.string,
  'data-testid': PropTypes.string,
  noOfLines: PropTypes.number,
  onBlur: PropTypes.func,
};
