import { t } from '@i18n';
import { useMemo } from 'react';
import * as Logger from '@lib/Logger';
import { useUnauthenticatedHandler } from '@hooks/auth';
import { isDevelopment } from '@config';
import { useCustomToast } from 'ui';

export const GENERIC_ALERT = {
  id: 'generic-error-toast',
  description: t('Common.errors.default'),
  status: 'error',
};

const buildDefaultHandlers = ({ showToast, handleUnauthenticated }) => ({
  401: handleUnauthenticated,
  generic: (data) => {
    Logger.reportGraphQLError(data);
    if (!showToast.isActive(GENERIC_ALERT.id)) showToast(GENERIC_ALERT);
  },
});

const getAllErrors = (data) => {
  const errors = (data?.graphQLErrors || []).map((e) => e?.extensions?.code);
  const networkError = data?.networkError?.statusCode;
  if (networkError) errors.push(networkError);
  return errors;
};

const errorMatcher = (allErrors, onErrorCode, defaultHandlers, data) => {
  const handledErrors = allErrors.reduce((previous, currentError) => {
    const handleError = onErrorCode[currentError] ?? defaultHandlers[currentError];

    if (!handleError) return previous;

    handleError(data);
    return true;
  }, false);
  return handledErrors;
};

export const useErrorHandler = () => {
  const showToast = useCustomToast();
  const handleUnauthenticated = useUnauthenticatedHandler();

  const defaultHandlers = useMemo(
    () => buildDefaultHandlers({ showToast, handleUnauthenticated }),
    [showToast, handleUnauthenticated],
  );

  return (options) => {
    const onError = (data, ...args) => {
      const onErrorCode = options?.onErrorCode || {};
      const skipErrorHandler = options?.skipErrorHandler || false;

      const allErrors = getAllErrors(data);

      const errorsMatched = errorMatcher(allErrors, onErrorCode, defaultHandlers, data);

      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.warn('Error handler', {
          allErrors,
          errorsMatched,
          graphQLErrors: data?.graphQLErrors,
          networkError: data?.networkError,
        });
      }

      const showGenericError = !errorsMatched && !options.onError && !skipErrorHandler;
      if (showGenericError) defaultHandlers.generic(data);

      if (options.onError) options.onError(data, ...args);
    };
    return {
      ...options,
      onError,
    };
  };
};
