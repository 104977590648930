import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@config/services/baseQuery';
import baseQueryBorrower from '@config/services/baseQueryBorrower';
import apiServiceStatus from '@services/apiService/status';
import login from '@services/auth/login/login';
import createPassword from '@services/auth/password/createPassword';
import updatePassword from '@services/auth/password/updatePassword';
import signInBorrower from '@services/borrower/signIn';
import validateEmail from '@services/users/validateEmail';
import signUp from '@services/auth/login/signUp';
import validatePassword from '@services/users/validatePassword';
import generateOTP from './users/generateOTP';
import reactivateAccount from './users/reactivateAccount';
import unlockAccount from './users/unlockAccount';
import logout from './auth/login/logout';

const endpointsList = [
  login,
  signUp,
  logout,
  generateOTP,
  validateEmail,
  unlockAccount,
  createPassword,
  updatePassword,
  apiServiceStatus,
  validatePassword,
  reactivateAccount,
];

const endpoints = (builder) => endpointsList
  .reduce((acc, sliceEndpoint) => ({
    ...acc,
    ...sliceEndpoint(builder),
  }), {});

export const api = createApi({
  reducerPath: 'yotepresto_api',
  baseQuery,
  endpoints,
});

const endpointsBorrowerList = [
  signInBorrower,
];

const endpointsBorrower = (builder) => endpointsBorrowerList
  .reduce((acc, sliceEndpoint) => ({
    ...acc,
    ...sliceEndpoint(builder),
  }), {});

export const borrowerApi = createApi({
  reducerPath: 'yotepresto_borrower_api',
  baseQuery: baseQueryBorrower,
  endpoints: endpointsBorrower,
});

// export all individual hooks from API
export const {
  useLoginMutation,
  useLogoutMutation,
  useSignUpMutation,
  useValidateEmailQuery,
  useGenerateOTPMutation,
  useUnlockAccountMutation,
  useApiServiceStatusQuery,
  useCreatePasswordMutation,
  useUpdatePasswordMutation,
  useLazyValidateEmailQuery,
  useValidatePasswordMutation,
  useReactivateAccountMutation,
} = api;
