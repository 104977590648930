import { numberToCurrency } from '@lib/parsers';
import { tscope } from '@i18n/index';
import { useLoanDataQuery } from '../graphql';

const ts = tscope('Requisition.loanEditor');

export const useController = () => {
  const { data } = useLoanDataQuery();

  const loanInformation = data?.activeRequisition?.loanInformation;

  const requestedAmount = numberToCurrency(loanInformation?.requestedAmount);
  const destination = loanInformation?.loanDestination?.name;
  const term = loanInformation?.paymentTerms;

  const title = loanInformation ? ts('informationTitle', { count: term, requestedAmount, destination }) : '';

  return {
    title,
  };
};
