import React from 'react';
import {
  Box,
  Center,
  Text,
  Grid,
  Heading,
} from 'ui';
import { ReactComponent as UnavailableServiceImage } from '@assets/views/unavailableService/unavailableService.svg';
import { tscope } from '@i18n';
import { PublicHeader } from '@components/layouts/Primary/components/Header';
import { Footer } from '@components';
import style from './UnavailableService.style';

const t = tscope('UnavailableService');

export const UnavailableService = () => (
  <Grid {...style.container}>
    <PublicHeader hideLogOutItem />
    <Center {...style.content}>
      <Box
        {...style.image}
        as={UnavailableServiceImage}
      />
      <Heading {...style.title}>{t('title')}</Heading>
      <Text {...style.subtitle}>{t('subtitle')}</Text>
    </Center>
    <Footer />
  </Grid>
);
