import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { currencyToNumber } from '@lib/parsers';
import resolver from '../resolver';

const parseRequisition = (requisition) => {
  const {
    requestedAmount,
    loanDestination,
    paymentTerms,
  } = requisition;
  return {
    loanInformation: {
      comment: String(requisition.comment),
      requestedAmount: currencyToNumber(requestedAmount),
      paymentTerms: Number(paymentTerms),
      loanDestination: String(loanDestination),
    },
  };
};

export const useUpdateConfirmForm = ({
  loanInformation,
  maximumAmount,
  minimumAmount,
  upsertRequisition,
}) => {
  const {
    control, handleSubmit, reset, watch,
  } = useForm(
    {
      resolver,
      context: {
        maximumAmount,
        minimumAmount,
      },
      defaultValues: {
        requestedAmount: '',
        loanDestination: '',
        comment: '',
        paymentTerms: '',
      },
    },
  );
  const commentValue = watch('comment');

  const submitHandler = (requisition) => upsertRequisition({
    variables: {
      input: {
        requisition: parseRequisition(requisition),
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (!loanInformation) return;

    reset({
      requestedAmount: loanInformation?.requestedAmount,
      loanDestination: String(loanInformation?.loanDestination?.id || ''),
      paymentTerms: String(loanInformation.paymentTerms || ''),
      comment: loanInformation?.comment || '',
    });
  }, [loanInformation, reset]);

  return {
    control,
    onSubmit,
    commentValue,
  };
};
