import { gql } from '@apollo/client';
import { useQuery } from '@hooks/apollo';

export const USER_STATUS_QUERY = gql`
  query {
    user {
      id
      email
      roleName
      flags {
        id
        isEmailConfirmed
      }
    }
  }
`;

export const useUserStatusQuery = (options) => useQuery(USER_STATUS_QUERY, options);
